import { TextField } from "@mui/material";
import { ChangeEvent, Dispatch, SetStateAction } from "react";

type RangeInputProps = {
    label: string;
    name: "start" | "end" | "cost";
    value: number;
    isAdaptive: boolean;
    index: number;
    consumption: number;
    mainState: UnitChargeRules;
    setMainState: Dispatch<SetStateAction<UnitChargeRules>>;
    disabled?: boolean;
    required?: boolean;
    min?: number;
    max?: number;
};

export const RangeInput = ({
    label,
    name,
    value,
    isAdaptive,
    index,
    consumption,
    mainState,
    setMainState,
    disabled = false,
    min,
    max,
    required,
}: RangeInputProps) => {
    const handleChange = (
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        rangeIndex: number,
        consumptionIndex: number
    ) => {
        const isCostAfterLastRule = index === -1 && name === "cost";

        if (isCostAfterLastRule) {
            setMainState({
                ...mainState,
                [consumption]: {
                    ...mainState[consumption],
                    costAfterLastRule: parseInt(e.target.value),
                },
            });
        } else {
            const compsEntries = Object.entries(
                mainState[consumption].components
            );

            const currEntryIndex = compsEntries
                .map((x) => parseInt(x[0]))
                .indexOf(index);

            const prevEntryIndex =
                currEntryIndex > 0
                    ? parseInt(compsEntries.at(currEntryIndex - 1)![0])
                    : parseInt("-1");

            const nextEntryIndex =
                currEntryIndex < compsEntries.length
                    ? parseInt(
                          compsEntries.at(currEntryIndex + 1)!
                              ? compsEntries.at(currEntryIndex + 1)![0]
                              : "-1"
                      )
                    : parseInt("-1");

            // console.log("prev: ", prevEntryIndex, "nex: ", nextEntryIndex);
            const targetComps =
                name === "start"
                    ? { end: 0 }
                    : name === "end" && nextEntryIndex !== -1
                    ? {
                          [nextEntryIndex]: {
                              start: parseInt(e.target.value) + 1,
                              end: parseInt(e.target.value) + 2,
                          },
                      }
                    : {};

            setMainState({
                ...mainState,
                [consumption]: {
                    ...mainState[consumption],
                    components: {
                        ...mainState[consumption].components,
                        [index]: {
                            ...mainState[consumption].components[index],
                            [e.target.name]: parseInt(e.target.value),
                        },

                        ...(targetComps as any),
                    },
                },
            });
        }
    };

    return (
        <TextField
            label={label}
            name={name}
            value={value ? Math.max(value, min ?? 0) : ""}
            size="small"
            type="number"
            onChange={(e) => handleChange(e, index, consumption)}
            disabled={disabled}
            inputProps={{ min, max }}
            fullWidth
            required={required}
        />
    );
};
// const compsEntries = Object.entries(mainState[consumption].components);

// const currEntryIndex = compsEntries
//     .map((x) => parseInt(x[0]))
//     .indexOf(index);

// const prevEntryIndex =
//     currEntryIndex > 0
//         ? parseInt(compsEntries.at(currEntryIndex - 1)![0])
//         : parseInt("-1");

// const nextEntryIndex =
//     currEntryIndex < compsEntries.length
//         ? parseInt(compsEntries.at(currEntryIndex + 1)![0])
//         : parseInt("-1");
