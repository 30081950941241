import { Box, Typography } from "@mui/material";
import { basePath } from "../../Utilities/constants";
import { ReportGmailerrorredOutlined } from "@mui/icons-material";

const FourOFourPage = () => {
    return (
        <Box
            height="80vh"
            my="auto"
            display="flex"
            justifyContent="center"
            alignItems="center"
        >
            <Box textAlign="center">
                {/* <img src={`${basePath}/404.jpg`} alt="" width={320} /> */}

                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    gap={2}
                    mb={4}
                >
                    <ReportGmailerrorredOutlined
                        sx={{ fontSize: 75 }}
                        color="error"
                    />

                    <Typography color="error" variant="h2" fontWeight="bold">
                        404
                    </Typography>
                </Box>

                <Typography
                    variant="h4"
                    textTransform="uppercase"
                    // fontWeight="bold"
                    color="secondary"
                >
                    page not found
                </Typography>
            </Box>
        </Box>
    );
};

export default FourOFourPage;
