import { Add } from "@mui/icons-material";
import {
    Alert,
    Button,
    Card,
    CardContent,
    CardHeader,
    CircularProgress,
    Divider,
    Grid,
    TextField,
    useTheme,
} from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { GET, PATCH, POST } from "../../Utilities/BaseService";
import UpdatedSearchableInput from "../../Utilities/UpdatedSearchableInput";

type ConnectionType = {
    name: string;
    lineRent: string;
    unitChargeRule: number;
    district: number | null;
    village: number | null;
    hamlet: number | null;
    powerstation: number | null;
};

const getUnitChargeRules = () => {
    return GET("/connection/unit-charge-rule");
};

const addConnectionType = (connectionType: ConnectionType) =>
    POST("/connection", connectionType);

const updateConnectionType = (id: number, connectionType: ConnectionType) => {
    console.log(connectionType);
    return PATCH("/connection", connectionType, { id });
};

const AddConnectionType = ({ updateMode }: any) => {
    const [id, setId] = useState("");
    const [connectionType, setConnectionType] = useState({
        name: "",
    });
    const [regions, setRegions] = useState<any>({
        district: "",
        village: "",
        hamlet: "",
        powerstation: "",
    });
    const [feedback, setFeedback] = useState();
    const [defaultRegions, setDefaultRegions] = useState({
        district: "",
        village: "",
        hamlet: "",
        powerstation: "",
    });
    const [clearAll, setClearAll] = useState({
        district: false,
        village: false,
        hamlet: false,
        powerstation: false,
    });
    const theme = useTheme();
    const location = useLocation();
    const navigate = useNavigate();

    const { data } = useQuery(["unitChargeRules"], getUnitChargeRules);

    const getConnectionTypeById = () => {
        return GET(`/connection?id=${id}`);
    };

    const handleChange = (ev: any) => {
        const value = ev.target.value;
        setConnectionType({
            ...connectionType,
            [ev.target.name]: value,
        });
    };

    const { data: connectionTypeById } = useQuery(
        [`connection-type`, id],
        getConnectionTypeById,
        {
            enabled: Boolean(id),
            onSuccess(response) {
                if (response.data.rows.length > 0) {
                    const _ct = response.data.rows[0];

                    setConnectionType({
                        name: _ct.name,
                    });

                    setRegions({
                        district: _ct.district?.id ?? null,
                        village: _ct.village?.id ?? null,
                        hamlet: _ct.hamlet?.id ?? null,
                        powerstation: _ct.powerstation?.id ?? null,
                    });

                    setDefaultRegions({
                        district: _ct.district?.id ?? null,
                        village: _ct.village?.id ?? null,
                        hamlet: _ct.hamlet?.id ?? null,
                        powerstation: _ct.powerstation?.id ?? null,
                    });
                }
            },
            refetchOnMount: true,
        }
    );

    const handleSubmit = (ev: any) => {
        ev.preventDefault();

        const targetConnectionType: ConnectionType = {
            ...(connectionType as any),
            ...Object.fromEntries(
                Object.entries(regions).filter(([k, v]) => v && v !== "all")
            ),
        };

        !updateMode ? addMutation.mutate() : updateMutation.mutate();
    };

    const addMutation = useMutation(
        () =>
            addConnectionType({
                ...(connectionType as any),
                ...Object.fromEntries(
                    Object.entries(regions).filter(([k, v]) => v && v !== "all")
                ),
            }),
        {
            onSuccess(res) {
                return setFeedback(res.data.message);
            },
            onError(err: any) {
                return setFeedback(err.response.data.message);
            },
        }
    );

    const updateMutation = useMutation(
        () =>
            updateConnectionType(id as any, {
                ...(connectionType as any),
                ...Object.fromEntries(
                    Object.entries(regions).map(([k, v]) =>
                        v && v !== "all" ? [k, v] : [k, null]
                    )
                ),
            }),
        {
            onSuccess(res) {
                setFeedback(res.data.message);
            },
            onError(err: any) {
                return setFeedback(err.response.data.message);
            },
        }
    );

    useEffect(() => {
        if (updateMode) {
            const params = new URLSearchParams(location.search);
            const _id = params.get("id");

            if (_id) {
                setId(_id);
            }
        }
    }, [updateMode]);

    useEffect(() => {
        if (regions.district === "all") {
            setClearAll({
                district: true,
                village: true,
                hamlet: true,
                powerstation: true,
            });

            setRegions({
                district: "",
                village: "",
                hamlet: "",
                powerstation: "",
            });
        }

        if (regions.village === "all") {
            setClearAll({ ...clearAll, village: true, hamlet: true });
        }

        if (regions.hamlet === "all") {
            setClearAll({ ...clearAll, hamlet: true });
        }

        if (defaultRegions.district !== regions.district) {
            setClearAll({
                ...clearAll,
                village: true,
                hamlet: true,
                powerstation: true,
            });

            setDefaultRegions({
                ...defaultRegions,
                district: regions.district,
            });
        }

        return () => {
            setClearAll({
                district: false,
                village: false,
                hamlet: false,
                powerstation: false,
            });
        };
    }, [regions.district, regions.village, regions.hamlet]);

    return (
        <>
            <Card
                elevation={0}
                sx={{ bgcolor: theme.palette.common.white, mb: "1rem" }}
            >
                <CardHeader
                    title={
                        updateMode
                            ? "update connection type"
                            : "add connection type"
                    }
                    titleTypographyProps={{ color: "gray" }}
                    sx={{
                        textTransform: "capitalize",
                    }}
                />

                <CardContent>
                    <Divider sx={{ mb: 5 }} />

                    <Grid
                        container
                        component="form"
                        onSubmit={handleSubmit}
                        spacing={2}
                    >
                        <Grid item xs={12} md={6} lg={3}>
                            <TextField
                                size="small"
                                name="name"
                                value={connectionType.name}
                                label="Name"
                                onChange={handleChange}
                                fullWidth
                                type="text"
                                required
                            />
                        </Grid>

                        <Grid item xs={12} md={6} lg={3}>
                            <UpdatedSearchableInput
                                label="District"
                                api="/region/district"
                                filter={regions}
                                setFilter={setRegions}
                                defaultValue={
                                    updateMode && defaultRegions.district
                                }
                                clearAll={clearAll.district}
                            />
                        </Grid>

                        <Grid item xs={12} md={6} lg={3}>
                            <UpdatedSearchableInput
                                label="Village"
                                api="/region/village"
                                dep={regions.district}
                                params={{ district: regions.district }}
                                filter={regions}
                                setFilter={setRegions}
                                defaultValue={
                                    updateMode && defaultRegions.village
                                }
                                clearAll={clearAll.village}
                            />
                        </Grid>

                        <Grid item xs={12} md={6} lg={3}>
                            <UpdatedSearchableInput
                                label="Hamlet"
                                api="/region/hamlet"
                                filter={regions}
                                setFilter={setRegions}
                                dep={regions.village}
                                params={
                                    regions.village && regions.village !== "all"
                                        ? { village: [regions.village] }
                                        : { village: [0] }
                                }
                                defaultValue={
                                    updateMode && defaultRegions.hamlet
                                }
                                clearAll={clearAll.hamlet}
                            />
                        </Grid>

                        <Grid item xs={12} md={6} lg={3}>
                            <UpdatedSearchableInput
                                label="Powerstation"
                                api="/powerstation"
                                filter={regions}
                                setFilter={setRegions}
                                dep={regions.district}
                                params={{ district: regions.district }}
                                defaultValue={
                                    (updateMode &&
                                        defaultRegions.powerstation) ??
                                    undefined
                                }
                                clearAll={clearAll.powerstation}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Button
                                startIcon={<Add />}
                                endIcon={
                                    addMutation.isLoading ? (
                                        <CircularProgress
                                            color="secondary"
                                            size="1rem"
                                        />
                                    ) : (
                                        ""
                                    )
                                }
                                color="secondary"
                                variant="outlined"
                                size="small"
                                type="submit"
                            >
                                {updateMode ? "update" : "add"} connection type
                            </Button>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>

            {addMutation.isSuccess || updateMutation.isSuccess ? (
                <Alert severity="success">{feedback}.</Alert>
            ) : addMutation.isError || updateMutation.isError ? (
                <Alert severity="error">{feedback}.</Alert>
            ) : null}
        </>
    );
};

export default AddConnectionType;
