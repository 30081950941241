import { ReceiptLongOutlined } from "@mui/icons-material";
import {
    Alert,
    Button,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    LinearProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
    useTheme,
} from "@mui/material";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { ChangeEvent, FormEvent, useContext, useEffect, useState } from "react";
import { AppContext } from "../../../Utilities/AppContext";
import { GET, POST } from "../../../Utilities/BaseService";
import { MultiPermissionAuthorize } from "../../../Utilities/MultiPermissionAuthorize";
import UpdatedSearchableInput from "../../../Utilities/UpdatedSearchableInput";
import { CollecteesFilter } from "./CollecteesFilter";
import { CollectionRow } from "./CollectionRow";

type DateConstraintVariant =
    | "collection-back"
    | "collection-forward"
    | "reading-back"
    | "reading-forward";

type DateConstraint = { key: DateConstraintVariant; value: string };

export const fetchCollectees = (filter: any) => {
    return GET(
        "/reading/collection/collectees",
        Object.fromEntries(
            Object.entries(filter).filter(
                (entry) => Boolean(entry[1]) && entry[1] !== "all"
            )
        )
    );
};

export const collectByMeter = (data: any) => {
    console.log(data);
    return POST("/reading/collection/collect-by-meter", data);
};

export const fetchPaymentMethods = () => {
    return GET("/reading/payment-method");
};

export const CollectBills = () => {
    const [pagination, setPagination] = useState({
        page: 0,
        count: 0,
        limit: 10,
    });
    const [filter, setFilter] = useState({
        meterNo: "",
        id: "",
        district: "",
        village: "",
        hamlet: "",
        paymentMethod: "",
    });
    const [collectionResults, setCollectionResults] = useState<any>({});
    const [activeResult, setActiveResult] = useState<any>(null);
    const [feedback, setFeedback] = useState({
        message: "",
        show: false,
        severity: null,
    });
    const [collectionDate, setCollectionDate] = useState<string>(
        new Date().toISOString().split("T")[0]
    );
    const [collections, setCollections] = useState<{
        [key: number]: RawCollection;
    }>({});
    const [checkAll, setCheckAll] = useState(false);

    const theme = useTheme();
    const { user } = useContext(AppContext);

    const queryClient = useQueryClient();

    const shouldFilter = () => {
        return (
            Boolean(filter.id) ||
            Boolean(filter.meterNo) ||
            (Boolean(filter.district) &&
                Boolean(filter.village) &&
                Boolean(filter.hamlet))
        );
    };

    const { data: collectionDetailsResponse, isFetching } = useQuery(
        [
            "collectees",
            filter.district,
            filter.village,
            filter.hamlet,
            filter.id,
            filter.meterNo,
            pagination.page,
            pagination.limit,
            collectionDate,
        ],
        () =>
            fetchCollectees({
                ...filter,
                page: pagination.page + 1,
                limit: pagination.limit,
            }),
        {
            enabled: shouldFilter(),
            onSuccess(res) {
                if (res.data) {
                    setPagination({
                        ...pagination,
                        count: res.data?.count ?? 0,
                    });
                }
            },
        }
    );

    const { data: paymentMethodsRes } = useQuery(["payment-methods"], () =>
        fetchPaymentMethods()
    );

    const mutation = useMutation(["collect"], (data: any) =>
        collectByMeter(data)
    );

    const handleCollectBills = (ev: FormEvent<any>) => {
        ev.preventDefault();

        if (!collectionDate) {
            return;
        }

        const actualPayments = Object.entries(collections)
            .filter((entry) => entry[1].checked)
            .map((entry) => ({
                ...entry[1],
                // paymentMethod: entry[1]?.paymentMethod?.id ?? entry[1],
                paymentName: entry[1].paymentMethod.name,
                meter: entry[0],
            }));

        let targetRes: any = {};

        for (const payment of actualPayments) {
            console.log(payment);
            mutation
                .mutateAsync({
                    ...payment,
                    collectionDate: collectionDate,
                })
                .then((res) => {
                    targetRes = {
                        ...targetRes,
                        [payment.meter]: {
                            success: true,
                            message: res.data.message,
                        },
                    };

                    setCollectionResults(targetRes);
                })
                .catch((err) => {
                    targetRes = {
                        ...targetRes,
                        [payment.meter]: {
                            success: false,
                            message: err?.response?.data.message,
                        },
                    };

                    setCollectionResults(targetRes);
                });
        }

        queryClient.refetchQueries([
            "collectees",
            filter.district,
            filter.village,
            filter.hamlet,
            filter.id,
            filter.meterNo,
            pagination.page,
            pagination.limit,
            collectionDate,
        ]);
    };

    function manipulateDate(
        currentDate: Date,
        daysToAddOrSubtract: number,
        op: "add" | "sub"
    ) {
        const dateObj = new Date(currentDate);

        const millisecondsInADay = 24 * 60 * 60 * 1000;

        let newDate: Date;
        switch (op) {
            case "add":
                newDate = new Date(
                    dateObj.getTime() + daysToAddOrSubtract * millisecondsInADay
                );
                break;

            case "sub":
                newDate = new Date(
                    dateObj.getTime() - daysToAddOrSubtract * millisecondsInADay
                );
                break;
        }

        return newDate;
    }

    function getRestrictedDate(target: DateConstraintVariant) {
        const hasDateRestriction = Boolean(
            user.constraints.find((c: DateConstraint) => c.key === target)
        );

        if (hasDateRestriction) {
            const days: number = user.constraints.find(
                (c: DateConstraint) => c.key === target
            ).value;

            switch (target) {
                case "collection-back":
                    const backDate = manipulateDate(new Date(), days, "sub")
                        .toISOString()
                        .slice(0, 10);

                    return backDate;

                case "collection-forward":
                    const forwardDate = manipulateDate(new Date(), days, "add")
                        .toISOString()
                        .slice(0, 10);

                    return forwardDate;
            }
        } else return undefined;
    }

    const handleCheckAll = (
        e: ChangeEvent<HTMLInputElement>,
        checked: boolean
    ) => {};

    useEffect(() => {
        if (filter.paymentMethod) {
            setCollections((c) => {
                const target = collectionDetailsResponse?.data.rows.reduce(
                    (prev: any, curr: any) => ({
                        ...prev,
                        [curr?.id]: {
                            ...collections[curr?.id],
                            paymentMethod:
                                filter.paymentMethod === "all"
                                    ? ""
                                    : filter.paymentMethod,
                        },
                    }),
                    {}
                );

                return { ...c, ...target };
            });
        }
    }, [filter.paymentMethod]);

    return (
        <Grid container spacing={1}>
            <CollecteesFilter
                filter={filter}
                setFilter={setFilter}
                billsFetchStatus={isFetching}
            />

            <Grid item xs={12} display="flex" justifyContent="space-between">
                <div />
                {/* <BlindPagination
                    pagination={pagination}
                    setPagination={setPagination as any}
                /> */}

                <TablePagination
                    component="div"
                    onPageChange={(ev, page) =>
                        setPagination({ ...pagination, page: page })
                    }
                    onRowsPerPageChange={(ev) =>
                        setPagination({
                            ...pagination,
                            limit: parseInt(ev.target.value),
                            page: 0,
                        })
                    }
                    count={pagination.count}
                    page={pagination.page}
                    rowsPerPage={pagination.limit}
                />
            </Grid>

            <Grid item xs={12}>
                <Divider />
            </Grid>

            <Grid item xs={12}>
                {isFetching && <LinearProgress />}
            </Grid>

            <Grid
                item
                container
                spacing={1}
                xs={12}
                component="form"
                onSubmit={handleCollectBills}
            >
                <Grid item xs={6}>
                    {/* <MultiPermissionAuthorize ops={["CREATE COLLECTION_DATE"]}> */}
                    <TextField
                        required
                        fullWidth
                        size="small"
                        name="collectionDate"
                        label="Collection Date"
                        InputLabelProps={{ shrink: true }}
                        type="date"
                        inputProps={{
                            min: getRestrictedDate("collection-back"),
                            max: getRestrictedDate("collection-forward"),
                        }}
                        value={collectionDate}
                        onChange={(ev) => setCollectionDate(ev.target.value)}
                    />
                    {/* </MultiPermissionAuthorize> */}
                </Grid>

                <Grid item xs={6}>
                    <UpdatedSearchableInput
                        api="/reading/payment-method"
                        label="Payment Method"
                        _name="paymentMethod"
                        // filter={filter}
                        // setFilter={setFilter}
                        required={Object.values(collections).every(
                            (c) => !c.paymentMethod
                        )}
                        disabled={!collectionDetailsResponse?.data.rows}
                        setOutput={(val) => {
                            console.log(val);
                            setCollections(
                                Object.fromEntries(
                                    Object.entries(collections).map(
                                        ([k, v]) => [
                                            k,
                                            {
                                                ...v,
                                                paymentMethod: val?.id ?? "",
                                                paymentName: val?.name ?? "",
                                            },
                                        ]
                                    )
                                )
                            );
                        }}
                    />
                </Grid>

                <Grid item xs={12}>
                    <TableContainer>
                        <Table size="small">
                            <TableHead>
                                <TableRow sx={{ whiteSpace: "nowrap" }}>
                                    <TableCell>
                                        {/* <FormControl>
                                            <Checkbox
                                                checked={checkAll}
                                                disabled={
                                                    !collectionDetailsResponse
                                                        ?.data.rows.length
                                                }
                                                onChange={(e, check) =>
                                                    setCheckAll(check)
                                                }
                                            />
                                        </FormControl> */}
                                    </TableCell>
                                    <TableCell>Response</TableCell>
                                    <TableCell>Serial no.</TableCell>
                                    <TableCell>Meter no.</TableCell>
                                    <TableCell>Customer</TableCell>
                                    <TableCell>Property name</TableCell>
                                    <TableCell>Total</TableCell>
                                    <TableCell>Collect amount</TableCell>
                                    <MultiPermissionAuthorize
                                        ops={["CREATE READING_ADJUSTMENT"]}
                                    >
                                        <TableCell>Adjustment</TableCell>
                                    </MultiPermissionAuthorize>
                                    <TableCell>Payment Method</TableCell>
                                    <TableCell>Description</TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {collectionDetailsResponse?.data.rows.map(
                                    (coll: Collectee, id: number) => (
                                        <CollectionRow
                                            count={
                                                id +
                                                1 +
                                                pagination.limit *
                                                    pagination.page
                                            }
                                            key={id}
                                            collectionResults={
                                                collectionResults
                                            }
                                            collectee={coll}
                                            collections={collections}
                                            setCollections={setCollections}
                                            collectionDate={collectionDate}
                                            paymentMethodRes={
                                                paymentMethodsRes?.data.rows
                                            }
                                            setActiveResult={setActiveResult}
                                            checkAll={checkAll}
                                        />
                                    )
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>

                {feedback.show && (
                    <Grid item xs={12}>
                        <Alert severity="error">{feedback.message}</Alert>
                    </Grid>
                )}

                {!shouldFilter() && (
                    <Grid item xs={12}>
                        <Alert severity="info">
                            Enter a meter no. or meter ID or select a district,
                            village, and hamlet.
                        </Alert>
                    </Grid>
                )}

                <Grid item xs={12} sx={{ mb: theme.spacing(2) }}>
                    <Button
                        fullWidth
                        variant="outlined"
                        type="submit"
                        endIcon={
                            mutation.isLoading ? (
                                <CircularProgress size="1rem" />
                            ) : (
                                <ReceiptLongOutlined />
                            )
                        }
                    >
                        Collect
                    </Button>
                </Grid>
            </Grid>

            {activeResult && (
                <Dialog
                    open={Boolean(activeResult)}
                    fullWidth
                    onClose={() => setActiveResult(null)}
                >
                    <DialogTitle>Collection result</DialogTitle>
                    <DialogContent>
                        {collectionResults[activeResult].message}
                    </DialogContent>
                </Dialog>
            )}
        </Grid>
    );
};
