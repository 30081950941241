import {
    DFilter,
    IDashboardService,
} from "../Features/Dashboard/SimpleDashboard/types";
import { GET } from "../Utilities/BaseService";
import { notConnectedMessage } from "../Utilities/constants";

export class DashboardService implements IDashboardService {
    static instance: DashboardService;

    static getInstance() {
        if (DashboardService.instance) {
            return DashboardService.instance;
        } else {
            DashboardService.instance = new DashboardService();
            return DashboardService.instance;
        }
    }

    getConnectionsCount = async (filter: DFilter) => {
        try {
            const response = await GET(
                "/reports/dashboard/connection-count",
                filter
            );

            return {
                data: response.data.report,
                err: null,
            };
        } catch (err: any) {
            return {
                data: null,
                err: err.response?.data?.message || notConnectedMessage,
            };
        }
    };

    getConnectionsAmount = async (filter: DFilter) => {
        try {
            const response = await GET(
                "/reports/dashboard/connection-amount",
                filter
            );

            return {
                data: response.data.report,
                err: null,
            };
        } catch (err: any) {
            return {
                data: null,
                err: err.response?.data?.message || notConnectedMessage,
            };
        }
    };

    getReadingAmount = async (filter: DFilter) => {
        try {
            const response = await GET(
                "/reports/dashboard/bill-amounts",
                filter
            );

            return {
                data: response.data.report,
                err: null,
            };
        } catch (err: any) {
            return {
                data: null,
                err: err.response?.data?.message || notConnectedMessage,
            };
        }
    };

    getAdjustedAmount = async (filter: DFilter) => {
        try {
            const response = await GET(
                "/reports/dashboard/adjusted-amount",
                filter
            );

            return {
                data: {
                    adj: { totalAdjustment: response.data.adj.totalAdjustment },
                },
                err: null,
            };
        } catch (err: any) {
            return {
                data: null,
                err: err.response?.data?.message || notConnectedMessage,
            };
        }
    };

    getTotalGeneratedUnits = async (filter: DFilter) => {
        try {
            const response = await GET(
                "/reports/dashboard/generated-units",
                filter
            );

            return {
                data: { totalUnits: response.data.totalUnits },
                err: null,
            };
        } catch (err: any) {
            return {
                data: null,
                err: err.response?.data?.message || notConnectedMessage,
            };
        }
    };

    getSurchargeCount = async (filter: DFilter) => {
        try {
            const response = await GET("/reports/dashboard/surcharge", filter);

            return {
                data: {
                    applicableSurcharge:
                        response.data.surcharge.applicableSurcharge,
                    payedSurcharge: response.data.surcharge.payedSurcharge,
                },
                err: null,
            };
        } catch (err: any) {
            return {
                data: null,
                err: err.response?.data?.message || notConnectedMessage,
            };
        }
    };

    getBillsCount = async (filter: DFilter) => {
        try {
            const response = await GET(
                "/reports/dashboard/bills-record",
                filter
            );

            return {
                data: {
                    total: parseInt(response.data.billsCount),
                    completedBills: parseInt(response.data.billsCompleted),
                    remainingBills: parseInt(response.data.billsRemaining),
                },
                err: null,
            };
        } catch (err: any) {
            return {
                data: null,
                err: err.response?.data?.message || notConnectedMessage,
            };
        }
    };
}
