import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { ListItem, ListItemIcon, useTheme } from "@mui/material";
import Collapse from "@mui/material/Collapse";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { Fragment, useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { MultiPermissionAuthorize } from "../../Utilities/MultiPermissionAuthorize";
import { sidebarData } from "./SidebarData";

export default function BaseCollapse({ sidebarOpen, setSidebarOpen }) {
    const location = useLocation();
    const [open, setOpen] = useState({});
    const theme = useTheme();

    const handleClick = (id) => {
        setOpen({ ...open, [id]: !open[id] });
    };

    const handleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
    };

    return (
        <>
            {sidebarData.map((item, idx) => (
                <Fragment key={idx}>
                    <MultiPermissionAuthorize
                        ops={item.subfields
                            .map((field) => field.permissions)
                            .flat()}
                        strategy="some"
                    >
                        {
                            <ListItem
                                disablePadding
                                sx={{
                                    width: "100%",
                                    maxWidth: 360,
                                    textTransform: "capitalize",
                                }}
                                component="nav"
                                selected={open[item.id]}
                            >
                                {item.subfields.length === 1 ? (
                                    <Link
                                        to={item.subfields[0].href}
                                        style={{
                                            textDecoration: "none",
                                            width: "100%",
                                            color: "black",
                                        }}
                                    >
                                        <ListItemButton
                                            onClick={() => handleClick(item.id)}
                                            sx={{
                                                bgcolor:
                                                    location &&
                                                    location.pathname
                                                        .split("/")
                                                        .includes(
                                                            item.subfields[0]
                                                                .name
                                                        )
                                                        ? theme.palette
                                                              .secondary.main
                                                        : theme.palette.primary
                                                              .light,
                                                color:
                                                    location &&
                                                    location.pathname
                                                        .split("/")
                                                        .includes(
                                                            item.subfields[0]
                                                                .name
                                                        )
                                                        ? "white"
                                                        : "inherit",
                                            }}
                                        >
                                            <ListItemIcon>
                                                {item.icon}
                                            </ListItemIcon>
                                            <ListItemText primary={item.name} />
                                        </ListItemButton>
                                    </Link>
                                ) : (
                                    <ListItemButton
                                        onClick={() => handleClick(item.id)}
                                        sx={{
                                            bgcolor:
                                                location &&
                                                location.pathname === item.href
                                                    ? theme.palette.secondary
                                                          .main
                                                    : theme.palette.primary
                                                          .light,
                                            color:
                                                location &&
                                                location.pathname === item.href
                                                    ? "white"
                                                    : "inherit",
                                        }}
                                    >
                                        <ListItemIcon>{item.icon}</ListItemIcon>
                                        <ListItemText primary={item.name} />
                                        {item.subfields.length > 1 &&
                                            (open[item.id] ? (
                                                <ExpandLess />
                                            ) : (
                                                <ExpandMore />
                                            ))}
                                    </ListItemButton>
                                )}
                            </ListItem>
                        }
                    </MultiPermissionAuthorize>
                    {item.subfields.length > 1 && (
                        <Collapse
                            in={open[item.id]}
                            timeout="auto"
                            unmountOnExit
                            sx={{ textTransform: "capitalize" }}
                        >
                            <List component="div" disablePadding>
                                {item.subfields.map((btn, idx) => (
                                    <MultiPermissionAuthorize
                                        key={idx}
                                        ops={btn.permissions || []}
                                        strategy="some"
                                    >
                                        <ListItem
                                            key={idx}
                                            to={btn.href}
                                            button
                                            component={NavLink}
                                            sx={{
                                                bgcolor:
                                                    (location &&
                                                        location.pathname ===
                                                            btn.href) ||
                                                    location.pathname ===
                                                        `${btn.href}/add` /* ||
                                                    location.pathname ===
                                                        `${btn.href}/update` */
                                                        ? theme.palette
                                                              .secondary.main
                                                        : theme.palette.primary
                                                              .main,
                                                color:
                                                    (location &&
                                                        location.pathname ===
                                                            btn.href) ||
                                                    location.pathname ===
                                                        `${btn.href}/add` /* ||
                                                    location.pathname ===
                                                        `${btn.href}/update` */
                                                        ? "white"
                                                        : "inherit",
                                            }}
                                        >
                                            <ListItemIcon />
                                            <ListItemText
                                                primary={btn.name}
                                                primaryTypographyProps={{
                                                    variant: "body1",
                                                }}
                                            />
                                        </ListItem>
                                    </MultiPermissionAuthorize>
                                ))}
                            </List>
                        </Collapse>
                    )}
                </Fragment>
            ))}
        </>
    );
}
