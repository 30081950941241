import {
    Add,
    FilterAltOutlined,
    VisibilityOutlined,
} from "@mui/icons-material";
import {
    Alert,
    Box,
    Button,
    Card,
    Checkbox,
    Chip,
    FormControlLabel,
    Grid,
    IconButton,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { GET } from "../../../Utilities/BaseService";
import {
    filterSelectionMessage,
    hasValue,
    noDataMessage,
} from "../../../Utilities/constants";
import { MultiPermissionAuthorize } from "../../../Utilities/MultiPermissionAuthorize";
import UpdatedSearchableInput from "../../../Utilities/UpdatedSearchableInput";
import BaseTable from "../../Table/BaseTable";
import SearchBar from "../../Table/Components/SearchBar";
import RuleView from "../Components/RuleView";

const getUnitChargeRules = (pagination: any, filter: any) => {
    const params = Object.fromEntries(
        Object.entries(filter).filter(([k, v]) => v && v !== "all")
    );
    return GET("/connection/unit-charge-rule", {
        page: pagination.page + 1,
        limit: pagination.limit,
        ...params,
        isAdaptive: params.isAdaptive ? 1 : undefined,
    });
};

type Filter = {
    search: string;
    district: number | "" | "all";
    village: number | "" | "all";
    hamlet: number | "" | "all";
    powerstation: number | "" | "all";
    isAdaptive: boolean;
};

const initialFilter: Filter = {
    search: "",
    district: "",
    village: "",
    hamlet: "",
    powerstation: "",
    isAdaptive: false,
};

const initialClearAll = {
    district: false,
    village: false,
    hamlet: false,
    powerstation: false,
};

const UnitChargeRule = () => {
    const [activeRow, setActiveRow] = useState<any>(null);
    const [rowsCount, setRowsCount] = useState(0);
    const [pagination, setPagination] = useState<any>({ page: 0, limit: 100 });
    const [filter, setFilter] = useState(initialFilter);
    const [feedback, setFeedback] = useState("");
    const [clearAll, setClearAll] = useState(initialClearAll);
    const [changeCount, setChangeCount] = useState(0);
    const [intermediateFilter, setIntermediateFilter] =
        useState<Filter>(initialFilter);

    const theme = useTheme();
    const mdDown = useMediaQuery(theme.breakpoints.down("md"));

    const { data, isLoading } = useQuery(
        ["unit-charge-rules", pagination.page, pagination.limit, filter],
        () => getUnitChargeRules(pagination, filter),
        {
            enabled: Boolean(changeCount),
            onSuccess(res) {
                if (res) setRowsCount(res?.data.count);
            },
        }
    );

    const handleViewRule = (row: any) => {
        setActiveRow(row);
    };

    useEffect(() => {
        if (intermediateFilter.district === "all") {
            setClearAll({
                district: true,
                village: true,
                hamlet: true,
                powerstation: true,
            });
            setIntermediateFilter({
                ...intermediateFilter,
                district: "",
                village: "",
                hamlet: "",
                powerstation: "",
            });
        }

        if (intermediateFilter.village === "all") {
            setClearAll({ ...clearAll, village: true, hamlet: true });
            setIntermediateFilter({
                ...intermediateFilter,
                village: "",
                hamlet: "",
            });
        }

        if (intermediateFilter.hamlet === "all") {
            setClearAll({ ...clearAll, hamlet: true });
            setIntermediateFilter({ ...intermediateFilter, hamlet: "" });
        }

        if (intermediateFilter.powerstation === "all") {
            setClearAll({ ...clearAll, powerstation: true });
            setIntermediateFilter({ ...intermediateFilter, powerstation: "" });
        }

        return () => {
            setClearAll({
                district: false,
                village: false,
                hamlet: false,
                powerstation: false,
            });
        };
    }, [
        intermediateFilter.district,
        intermediateFilter.village,
        intermediateFilter.hamlet,
        intermediateFilter.powerstation,
    ]);

    useEffect(() => {
        if (changeCount) {
            setFilter({ ...filter, ...(intermediateFilter as Filter) });
        }
    }, [changeCount]);

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                height: mdDown ? "auto" : `calc(100vh - 96px)`,
            }}
        >
            <Card
                elevation={0}
                sx={{
                    bgcolor: theme.palette.common.white,
                    p: 2,
                    mb: "1rem",
                }}
            >
                <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                    spacing={1.5}
                >
                    <Grid item xs={12} md="auto">
                        <Typography color="GrayText" variant="h5">
                            Unit Charges
                        </Typography>
                    </Grid>

                    <Grid
                        item
                        xs={12}
                        md={6}
                        container
                        justifyContent="flex-end"
                        spacing={1.5}
                    >
                        <Grid item flex={1}>
                            <SearchBar
                                filter={intermediateFilter}
                                setFilter={setIntermediateFilter}
                            />
                        </Grid>

                        <Grid item xs={12} md="auto">
                            <MultiPermissionAuthorize ops={["CREATE RULE"]}>
                                <Link
                                    to="/connections/unit-charges/add"
                                    style={{ textDecoration: "none" }}
                                >
                                    <Button
                                        sx={{ height: "100%" }}
                                        variant="outlined"
                                        color="secondary"
                                        startIcon={<Add />}
                                        fullWidth
                                    >
                                        add unit charge
                                    </Button>
                                </Link>
                            </MultiPermissionAuthorize>
                        </Grid>
                    </Grid>

                    <Grid item container xs={12} spacing={1.5}>
                        <MultiPermissionAuthorize ops={["READ DISTRICT"]}>
                            <Grid item xs={12} sm={6} lg={3}>
                                <UpdatedSearchableInput
                                    api="/region/district"
                                    label="District"
                                    filter={intermediateFilter}
                                    setFilter={setIntermediateFilter}
                                    clearAll={clearAll.district}
                                />
                            </Grid>
                        </MultiPermissionAuthorize>

                        <MultiPermissionAuthorize ops={["READ VILLAGE"]}>
                            <Grid item xs={12} sm={6} lg={3}>
                                <UpdatedSearchableInput
                                    api="/region/village"
                                    label="Village"
                                    filter={intermediateFilter}
                                    setFilter={setIntermediateFilter}
                                    dep={intermediateFilter.district}
                                    params={{
                                        district: intermediateFilter.district,
                                    }}
                                    clearAll={clearAll.village}
                                />
                            </Grid>
                        </MultiPermissionAuthorize>

                        <MultiPermissionAuthorize ops={["READ HAMLET"]}>
                            <Grid item xs={12} sm={6} lg={3}>
                                <UpdatedSearchableInput
                                    api="/region/hamlet"
                                    label="Hamlet"
                                    filter={intermediateFilter}
                                    setFilter={setIntermediateFilter}
                                    dep={intermediateFilter.village}
                                    params={Object.fromEntries(
                                        Object.entries(intermediateFilter)
                                            .filter(
                                                ([k, v]) =>
                                                    [
                                                        "district",
                                                        "village",
                                                    ].includes(k) &&
                                                    v &&
                                                    v !== "all"
                                            )
                                            .map(([k, v]) => [k, [v]])
                                    )}
                                    clearAll={clearAll.hamlet}
                                />
                            </Grid>
                        </MultiPermissionAuthorize>

                        <MultiPermissionAuthorize ops={["READ POWERSTATION"]}>
                            <Grid item xs={12} sm={6} lg={3}>
                                <UpdatedSearchableInput
                                    api="/powerstation"
                                    label="Powerstation"
                                    filter={intermediateFilter}
                                    setFilter={setIntermediateFilter}
                                    dep={intermediateFilter.district}
                                    params={{
                                        district: intermediateFilter.district,
                                    }}
                                    clearAll={clearAll.powerstation}
                                />
                            </Grid>
                        </MultiPermissionAuthorize>

                        <Grid item xs={12} sm={6} lg={3}>
                            <Box
                                sx={{
                                    border: `1px solid ${theme.palette.action.disabled}`,
                                    borderRadius: theme.spacing(0.5),
                                    px: 1.5,
                                }}
                            >
                                <FormControlLabel
                                    label="Is adaptive?"
                                    control={
                                        <Checkbox
                                            size="small"
                                            checked={
                                                intermediateFilter.isAdaptive
                                            }
                                            onChange={(e) =>
                                                setIntermediateFilter({
                                                    ...intermediateFilter,
                                                    isAdaptive:
                                                        e.target.checked,
                                                })
                                            }
                                        />
                                    }
                                />
                            </Box>
                        </Grid>

                        <Grid item xs={12} md={6} lg={3}>
                            <Button
                                color="secondary"
                                variant="outlined"
                                onClick={() => setChangeCount(changeCount + 1)}
                                sx={{ height: "100%" }}
                                startIcon={<FilterAltOutlined />}
                                fullWidth
                            >
                                filter
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Card>

            {data?.data.rows.length > 0 ? (
                <Box sx={{ height: "60%" }}>
                    <BaseTable
                        headers={{
                            id: "ID",
                            name: "Name",
                            view: "Show",
                            adaptive: "Adaptive",
                            district: "District",
                            village: "Village",
                            hamlet: "Hamlet",
                            powerstation: "Powerstation",
                        }}
                        data={data?.data.rows.map((row: any) => ({
                            ...row,
                            view: (
                                <IconButton
                                    onClick={() => handleViewRule(row)}
                                    size="small"
                                >
                                    <VisibilityOutlined fontSize="small" />
                                </IconButton>
                            ),
                            adaptive: row.isAdaptive ? (
                                <Chip
                                    size="small"
                                    variant="outlined"
                                    label="Adaptive"
                                    color="secondary"
                                />
                            ) : (
                                <Chip
                                    size="small"
                                    variant="outlined"
                                    label="Constant"
                                    color="primary"
                                />
                            ),
                            district: row?.district?.name ?? "Global",
                            village: row?.village?.name ?? "Global",
                            hamlet: row?.hamlet?.name ?? "Global",
                            powerstation: row?.powerstation?.name ?? "Global",
                        }))}
                        load={Boolean(changeCount)}
                        reportName="Unit Charges List"
                        delEndPoint="/connection/unit-charge-rule"
                        rowsCount={rowsCount}
                        pagination={pagination}
                        setPagination={setPagination}
                        isLoading={isLoading}
                        permissions={{
                            edit: [""],
                            delete: ["DELETE RULE"],
                        }}
                        feedback={feedback}
                        setFeedback={setFeedback}
                        queryKey={[
                            "unit-charge-rules",
                            pagination.page,
                            pagination.limit,
                            filter,
                        ]}
                        defaultSelectedHeaders={["id", "name", "view"]}
                        _printables={[
                            "id",
                            "name",
                            "adaptive",
                            "district",
                            "village",
                            "hamlet",
                            "powerstation",
                        ]}
                    />

                    {Boolean(activeRow) && (
                        <RuleView
                            activeRow={activeRow}
                            setActiveRow={setActiveRow}
                        />
                    )}
                </Box>
            ) : hasValue(filter) ? (
                <Alert severity="info">{noDataMessage}</Alert>
            ) : (
                <Alert severity="info">{filterSelectionMessage}</Alert>
            )}
        </Box>
    );
};

export default UnitChargeRule;
