import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import { useMemo } from "react";
import { Cell } from "./Cell";
import { currencyFormatter } from "./Last12Summary";

const Charge = ({ charge }: any) => {
    const collected = useMemo(() => {
        if (charge.collections) {
            return charge.collections.reduce(
                (p: any, c: any) => p + c.amount,
                0
            );
        } else {
            return 0;
        }
    }, [charge]);

    return (
        <TableRow>
            <TableCell
                padding="none"
                sx={{
                    border: "1px solid black",
                    borderRight: "none",
                    borderLeft: "none",
                    borderTop: "none",
                }}
            >
                <Typography variant="caption" fontWeight="bold">
                    {charge.head?.name}
                </Typography>
            </TableCell>

            <TableCell
                padding="none"
                sx={{
                    textAlign: "end",
                    border: "1px solid black",
                    borderRight: "none",
                    borderLeft: "none",
                    borderTop: "none",
                }}
            >
                <Typography variant="caption">
                    {currencyFormatter.format(charge.amount - collected)}
                </Typography>
            </TableCell>
        </TableRow>
    );
};

export const AdditionalChargesDescription = ({ reading }: any) => {
    return (
        <Cell
            xs={12}
            noTypography
            padding={0}
            value={
                <Table size="small">
                    <TableBody>
                        {reading.additionalCharges.map((c: any) => (
                            <Charge key={c.title + c.amount} charge={c} />
                        ))}
                    </TableBody>
                </Table>
            }
        />
    );
};
