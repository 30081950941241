import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import * as React from "react";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { AppContext } from "../../Utilities/AppContext";
import MainView from "./Mainview";
import Sidebar from "./Sidebar";
import { Topbar } from "./Topbar";

export const Layout = (props) => {
    const [sidebarOpen, setSidebarOpen] = useState(true);
    const location = useLocation();
    const { user } = React.useContext(AppContext);

    return (
        <Box {...props}>
            <CssBaseline />
            <>
                {user && location.pathname !== "/login" && (
                    <>
                        <Topbar
                            sidebarOpen={sidebarOpen}
                            setSidebarOpen={setSidebarOpen}
                        />
                        <Sidebar
                            sidebarOpen={sidebarOpen}
                            setSidebarOpen={setSidebarOpen}
                        />
                    </>
                )}
                <MainView open={sidebarOpen} />
            </>
        </Box>
    );
};
