import {
    Button,
    CircularProgress,
    Icon,
    Typography,
    useTheme,
} from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { GET } from "../../Utilities/BaseService";

const OfflineIcon = ({ color }: any) => {
    return (
        <svg
            fill="currentColor"
            color={color}
            width="auto"
            height="auto"
            focusable="false"
            aria-hidden="true"
            viewBox="0 0 24 24"
            data-testid="PublicOffIcon"
        >
            <path d="M11 8.17 6.49 3.66C8.07 2.61 9.96 2 12 2c5.52 0 10 4.48 10 10 0 2.04-.61 3.93-1.66 5.51l-1.46-1.46C19.59 14.87 20 13.48 20 12c0-3.35-2.07-6.22-5-7.41V5c0 1.1-.9 2-2 2h-2v1.17zm10.19 13.02-1.41 1.41-2.27-2.27C15.93 21.39 14.04 22 12 22 6.48 22 2 17.52 2 12c0-2.04.61-3.93 1.66-5.51L1.39 4.22 2.8 2.81l18.39 18.38zM11 18c-1.1 0-2-.9-2-2v-1l-4.79-4.79C4.08 10.79 4 11.38 4 12c0 4.08 3.05 7.44 7 7.93V18z"></path>
        </svg>
    );
};

export const OfflinePage = () => {
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const theme = useTheme();

    const checkConn = async () => {
        setLoading(true);

        try {
            const response = await GET("/");
            if (response?.data) {
                navigate(-1);
            }
        } catch (err) {}
        setLoading(false);
    };

    return (
        <div
            style={{
                width: "100%",
                height: "calc(100vh - 64px)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <div style={{ textAlign: "center" }}>
                <Icon
                    sx={{
                        width: "7rem",
                        height: "7rem",
                    }}
                >
                    {/* <Public style={{ fontSize: "7rem" }} /> */}
                    <OfflineIcon color={theme.palette.grey[400]} />
                </Icon>
                <Typography variant="h5">You are offline</Typography>
                <Button
                    onClick={checkConn}
                    style={{ marginTop: "2rem" }}
                    fullWidth
                    variant="outlined"
                    endIcon={loading ? <CircularProgress size="1rem" /> : null}
                >
                    Retry
                </Button>
            </div>
        </div>
    );
};
