import {
    Alert,
    Button,
    CircularProgress,
    Divider,
    FormControlLabel,
    Grid,
    Switch,
    TextField,
    Typography,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { Fragment, useState } from "react";
import { POST } from "../../../Utilities/BaseService";
import { AdaptiveRule } from "../Components/AdaptiveRule";
import { FlatRule } from "../Components/FlatRule";
import Regions from "../Components/Regions";

const addUnitChargeRule = (unitChargeRule: any) => {
    return POST("/connection/unit-charge-rule/from-components", unitChargeRule);
};

const initialMetaData = { ruleName: "", isAdaptive: false };
const initialRules: UnitChargeRules = {
    [parseInt("-1")]: {
        consumption: 0,
        components: {},
        costAfterLastRule: 0,
        open: true,
    },
};

const AddUnitChargeRule = () => {
    const [unitChargeRules, setUnitChargeRules] = useState(initialRules);
    const [metaData, setMetaData] = useState(initialMetaData);
    const [feedback, setFeedback] = useState("");
    const [regionsData, setRegionsData] = useState<any>({});

    const addNewConsumption = () => {
        const maxIndexInConsumption = parseInt(
            Object.keys(unitChargeRules).sort(
                (a, b) => parseInt(b) - parseInt(a)
            )[0]
        );

        const newConsumptionIndex = maxIndexInConsumption + 1 || 0;

        setUnitChargeRules({
            ...unitChargeRules,
            [newConsumptionIndex]: { ...initialRules[-1], open: false },
        });
    };

    const addNewRange = (isAdaptive: boolean, consumptionIndex?: number) => {
        if (isAdaptive) {
            const maxIndexInConsumptionRange = parseInt(
                Object.keys(unitChargeRules[consumptionIndex!].components).sort(
                    (a, b) => parseInt(b) - parseInt(a)
                )[0]
            );
            const newRangeIndex = maxIndexInConsumptionRange + 1 || 0;

            const isFirst =
                Object.keys(unitChargeRules[consumptionIndex!].components)
                    .length === 0;

            setUnitChargeRules({
                ...unitChargeRules,
                [consumptionIndex!]: {
                    ...unitChargeRules[consumptionIndex!],
                    components: {
                        ...unitChargeRules[consumptionIndex!].components,
                        [newRangeIndex]: isFirst
                            ? { start: 1, end: 2, cost: 0 }
                            : {
                                  start:
                                      unitChargeRules[consumptionIndex!]
                                          .components[
                                          maxIndexInConsumptionRange
                                      ].end + 1,
                                  end:
                                      unitChargeRules[consumptionIndex!]
                                          .components[
                                          maxIndexInConsumptionRange
                                      ].end + 2,
                                  cost: 0,
                              },
                    },
                },
            });
        } else {
            const maxIndexInRange = parseInt(
                Object.keys(unitChargeRules[-1].components).sort(
                    (a, b) => parseInt(b) - parseInt(a)
                )[0]
            );

            const newRangeIndex = maxIndexInRange + 1 || 0;

            const isFirst =
                Object.keys(unitChargeRules[-1].components).length === 0;

            setUnitChargeRules({
                ...unitChargeRules,
                [-1]: {
                    ...unitChargeRules[-1],
                    components: {
                        ...unitChargeRules[-1].components,
                        [newRangeIndex]: isFirst
                            ? { start: 1, end: 2, cost: 0 }
                            : {
                                  start:
                                      unitChargeRules[-1].components[
                                          maxIndexInRange
                                      ].end + 1,
                                  end:
                                      unitChargeRules[-1].components[
                                          maxIndexInRange
                                      ].end + 2,
                                  cost: 0,
                              },
                    },
                },
            });
        }
    };

    const addMutation = useMutation(addUnitChargeRule, {
        onSuccess(res) {
            setFeedback(res.data.message);
        },

        onError(err: any) {
            setFeedback(err.response.data.message);
        },
    });

    const handleSubmit = (ev: any) => {
        ev.preventDefault();

        const rules: any = Object.entries(unitChargeRules).map(([k, v]) => ({
            components: {
                [metaData.isAdaptive ? v.consumption || "-1" : k]: {
                    components: Object.values(v.components),
                    costAfterLastRule: v.costAfterLastRule,
                },
            },
            isAdaptive: metaData.isAdaptive,
        }));

        const _regions = Object.fromEntries(
            Object.entries(regionsData).filter(
                ([k, v]: any) => v && v !== "all"
            )
        );

        console.log(_regions);

        addMutation.mutate(
            metaData.isAdaptive
                ? {
                      name: metaData.ruleName,
                      isAdaptive: metaData.isAdaptive,
                      ...rules.reduce(
                          (prev: any, curr: any) => ({
                              components: {
                                  ...prev.components,
                                  ...curr.components,
                              },
                          }),
                          {}
                      ),
                      ..._regions,
                  }
                : {
                      name: metaData.ruleName,
                      isAdaptive: metaData.isAdaptive,
                      components: {
                          [-1]: {
                              components: Object.values(
                                  unitChargeRules[-1].components
                              ),
                              costAfterLastRule:
                                  unitChargeRules[-1].costAfterLastRule,
                          },
                      },
                      ..._regions,
                  }
        );
    };

    return (
        <>
            <Typography variant="h5" mb={5}>
                Add Unit Charge Rules
            </Typography>

            <Grid
                container
                component="form"
                spacing={2}
                justifyContent="center"
                flexDirection="column"
                onSubmit={handleSubmit}
                mb={2}
            >
                <Regions
                    regionsData={regionsData}
                    setRegionsData={setRegionsData}
                />

                <Grid item container spacing={1}>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            size="small"
                            label="Name"
                            required
                            value={metaData.ruleName}
                            onChange={(e) =>
                                setMetaData({
                                    ...metaData,
                                    ruleName: e.target.value,
                                })
                            }
                        />
                    </Grid>

                    <Grid
                        item
                        xs={12}
                        mt={2}
                        mb={1}
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <FormControlLabel
                            onChange={() =>
                                setMetaData({
                                    ...metaData,
                                    isAdaptive: !metaData.isAdaptive,
                                })
                            }
                            control={
                                <Switch
                                    checked={metaData.isAdaptive}
                                    color="secondary"
                                />
                            }
                            label="is adaptive?"
                        />

                        <Button
                            onClick={
                                metaData.isAdaptive
                                    ? addNewConsumption
                                    : () => addNewRange(false)
                            }
                            color="secondary"
                            variant="outlined"
                            sx={{ minWidth: "200px" }}
                        >
                            {metaData.isAdaptive
                                ? "add consumption"
                                : "add range"}
                        </Button>
                    </Grid>
                </Grid>

                <Grid item xs={12} mt={1} mb={3}>
                    <Divider />
                </Grid>

                <Grid item container xs={12} spacing={2} alignItems="center">
                    {metaData.isAdaptive ? (
                        Object.entries(unitChargeRules).map(
                            ([consumptionIndex, rule]) => (
                                <Fragment key={consumptionIndex}>
                                    <AdaptiveRule
                                        rule={unitChargeRules}
                                        setUnitChargeRules={setUnitChargeRules}
                                        consumptionIndex={parseInt(
                                            consumptionIndex
                                        )}
                                        addNewRange={() =>
                                            addNewRange(
                                                true,
                                                parseInt(consumptionIndex)
                                            )
                                        }
                                    />
                                </Fragment>
                            )
                        )
                    ) : (
                        <FlatRule
                            unitChargeRules={unitChargeRules}
                            setUnitChargeRules={setUnitChargeRules}
                            isAdaptive={false}
                        />
                    )}
                </Grid>

                <Grid item>
                    <Button
                        variant="outlined"
                        type="submit"
                        color="secondary"
                        disabled={addMutation.isLoading}
                        endIcon={
                            addMutation.isLoading ? (
                                <CircularProgress size={20} />
                            ) : undefined
                        }
                    >
                        submit
                    </Button>
                </Grid>
            </Grid>

            {addMutation.isSuccess ? (
                <Alert severity="success">{feedback}.</Alert>
            ) : addMutation.isError ? (
                <Alert severity="error">{feedback}.</Alert>
            ) : null}
        </>
    );
};

export default AddUnitChargeRule;
