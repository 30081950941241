import {
    Avatar,
    Box,
    Dialog,
    DialogContent,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    useTheme,
} from "@mui/material";
import { Dispatch, SetStateAction } from "react";

type LogDetailsProps = {
    open: boolean;
    setOpen: Dispatch<SetStateAction<null | number>>;
    log: Log;
};

export const SingleLogDetails = ({ open, setOpen, log }: LogDetailsProps) => {
    const theme = useTheme();
    const payload = JSON.parse(log.payload);

    const parseActionText = (text: string, payload: any) => {
        const regex = /#[a-zA-Z]+/g;
        const matches = Array.from(text.matchAll(regex)).map((exp) => exp[0]);

        payload = JSON.parse(payload);

        for (const match of matches) {
            text = text.replaceAll(match, payload[match.slice(1)]);
        }

        return text;
    };

    return (
        <Dialog open={open} onClose={() => setOpen(null)} fullWidth>
            <DialogContent>
                <Grid container flexDirection="column">
                    <Grid
                        item
                        mb={theme.spacing(2)}
                        display="flex"
                        alignItems="center"
                        gap={theme.spacing(1)}
                    >
                        <Avatar />

                        <Box>
                            <Typography>{log.name}</Typography>
                        </Box>
                    </Grid>

                    <Grid item>
                        <Typography></Typography>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell colSpan={2}>{`${
                                        log?.name
                                    } ${parseActionText(
                                        log.actionText,
                                        log.payload
                                    )}`}</TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {Object.entries(payload).map(([k, v]: any) => (
                                    <TableRow key={k}>
                                        <TableCell
                                            sx={{ textTransform: "capitalize" }}
                                        >
                                            {k}
                                        </TableCell>
                                        <TableCell>{v}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};
