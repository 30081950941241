import { Add } from "@mui/icons-material";
import { Box, Button, Card, Grid, Typography, useTheme } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { Link } from "react-router-dom";
import { GET } from "../../Utilities/BaseService";
import LoadViewButton from "../../Utilities/LoadViewButton";
import { MultiPermissionAuthorize } from "../../Utilities/MultiPermissionAuthorize";
import BaseTable from "../Table/BaseTable";
import SearchBar from "../Table/Components/SearchBar";

const Roles = () => {
    const [rowsCount, setRowsCount] = useState(0);
    const [pagination, setPagination] = useState<any>({ page: 0, limit: 100 });
    const [filter, setFilter] = useState<any>({ search: "" });
    const [feedback, setFeedback] = useState("");
    const [load, setLoad] = useState(false);

    const theme = useTheme();

    const getRoles = () => {
        return GET("/rolesystem/role/", {
            page: pagination.page + 1,
            limit: pagination.limit,
            search: filter.search,
        });
    };

    const { data, isLoading } = useQuery(
        ["roles", pagination.page, pagination.limit, filter.search],
        getRoles,
        {
            onSuccess(res) {
                if (res) setRowsCount(res?.data.count);
            },
        }
    );

    return (
        <Box>
            <Card
                elevation={0}
                sx={{
                    bgcolor: theme.palette.common.white,
                    p: 2,
                    mb: "1rem",
                }}
            >
                <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Grid item xs={12} md="auto">
                        <Typography variant="h5" color="GrayText">
                            Roles
                        </Typography>
                    </Grid>

                    <Grid
                        item
                        xs={12}
                        md={6}
                        container
                        spacing={1.5}
                        justifyContent="flex-end"
                    >
                        <Grid item flex={1}>
                            <SearchBar
                                filter={filter}
                                setFilter={setFilter}
                                debounceChange
                            />
                        </Grid>

                        <Grid item xs={12} md="auto">
                            <MultiPermissionAuthorize ops={["CREATE ROLE"]}>
                                <Link
                                    to="/roles/add"
                                    style={{ textDecoration: "none" }}
                                >
                                    <Button
                                        sx={{ height: "100%" }}
                                        variant="outlined"
                                        color="secondary"
                                        startIcon={<Add />}
                                        fullWidth
                                    >
                                        add role
                                    </Button>
                                </Link>
                            </MultiPermissionAuthorize>
                        </Grid>
                    </Grid>
                </Grid>
            </Card>

            {!load && (
                <LoadViewButton
                    label="view roles"
                    load={load}
                    setLoad={setLoad}
                />
            )}

            <BaseTable
                headers={{
                    id: "ID",
                    name: "Name",
                    description: "Description",
                }}
                data={data?.data.rows}
                defaultSelectedHeaders={["id", "name", "description"]}
                delEndPoint="/rolesystem/role/"
                feedback={feedback}
                setFeedback={setFeedback}
                rowsCount={rowsCount}
                pagination={pagination}
                setPagination={setPagination}
                isLoading={isLoading}
                load={load}
                permissions={{
                    edit: ["UPDATE ROLE"],
                    delete: ["DELETE ROLE"],
                }}
                queryKey={[
                    "roles",
                    pagination.page,
                    pagination.limit,
                    filter.search,
                ]}
                reportName="Roles Report"
            />
        </Box>
    );
};

export default Roles;
