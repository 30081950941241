import { ReceiptOutlined } from "@mui/icons-material";
import {
    Box,
    Card,
    CardContent,
    CardHeader,
    CircularProgress,
    Grid,
    Tab,
    Tabs,
    TextField,
    Typography,
    useTheme,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../Utilities/AppContext";
import { GET } from "../../Utilities/BaseService";
import { MultiPermissionAuthorize } from "../../Utilities/MultiPermissionAuthorize";
import UpdatedSearchableInput from "../../Utilities/UpdatedSearchableInput";
import { numberFormatter } from "../Reading/Bill/Cells/Last12Summary";
import DashboardCard from "./Components/DashboardCard";
import SearchableInput from "./Components/SearchableInput";
import { MeterCountReport } from "./MeterCountReport/MeterCountReport";
import { SimpleDashboard } from "./SimpleDashboard/SimpleDashboard";

type Filter = {
    district?: number[];
    village?: number[];
    hamlet?: number[];
    powerstation?: number[];
    startDate?: string;
    endDate?: string;
};

const targetFilter = (filter: Filter) =>
    Object.fromEntries(
        Object.entries(filter).filter(
            ([k, v]) => v && v !== ("all" as any) && v.length > 0
        )
    );

const getPaymentReport = (filter: any) => {
    return GET("/reports/dashboard/payment-report", targetFilter(filter));
};

const getBillReport = (filter: any) => {
    return GET("/reports/dashboard/no-of-bills", targetFilter(filter));
};

const getUnitReport = (filter: any) => {
    return GET("/reports/dashboard/no-of-units", targetFilter(filter));
};

const Dashboard = () => {
    const [filter, setFilter] = useState<Filter>({});
    const [paymentFilter, setPaymentFilter] = useState({
        connectionType: [],
        referenceDate: "",
    });
    const [paymentReport, setPaymentReport] = useState<any>({});
    const [dashboardVariant, setDashboardVariant] = useState<
        "graph" | "simple"
    >("graph");
    const theme = useTheme();
    const { user } = useContext(AppContext);

    const { data: _paymentReport, isFetching: isPaymentFetching } = useQuery(
        ["Payment Report", { ...filter, paymentFilter }],
        () => getPaymentReport({ ...filter, ...paymentFilter }),
        {
            onSuccess: (res) => {
                setPaymentReport(res.data.paymentReport);
            },
        }
    );

    const { data: billReport, isFetching: isBillFetching } = useQuery(
        ["Bill Report", filter],
        () => getBillReport(filter)
    );

    const { data: unitReport, isFetching: isUnitsFetching } = useQuery(
        ["Unit Report", filter],
        () => getUnitReport(filter)
    );

    useEffect(() => {
        if (user?.id) {
            const dashbaordOps: string[] = user.ops.filter((op: string) =>
                ["READ DASHBOARD", "READ SIMPLE_DASHBOARD"].includes(op)
            );

            dashbaordOps.length == 1 &&
            dashbaordOps[0] === "READ SIMPLE_DASHBOARD"
                ? setDashboardVariant("simple")
                : setDashboardVariant("graph");
        }
    }, [user]);

    return (
        <Box>
            <Tabs
                value={dashboardVariant}
                indicatorColor="secondary"
                textColor="secondary"
                onChange={(e, v) => setDashboardVariant(v)}
            >
                {user?.ops?.includes("READ DASHBOARD") ? (
                    <Tab label="Graph Dashboard" value="graph" />
                ) : null}

                {user?.ops?.includes("READ SIMPLE_DASHBOARD") && (
                    <Tab label="Simple Dashboard" value="simple" />
                )}
            </Tabs>

            {dashboardVariant === "graph" ? (
                <MultiPermissionAuthorize ops={["READ DASHBOARD"]}>
                    <Box mt={2}>
                        <Card
                            elevation={0}
                            sx={{
                                bgcolor: theme.palette.common.white,
                                p: 2,
                                mb: "1rem",
                            }}
                        >
                            <Grid container spacing={2}>
                                <MultiPermissionAuthorize
                                    ops={["READ DISTRICT"]}
                                >
                                    <Grid item xs={12} md={6} lg={4}>
                                        <UpdatedSearchableInput
                                            label="District"
                                            api="/region/district"
                                            filter={filter}
                                            setFilter={setFilter}
                                            multiple={true}
                                        />
                                    </Grid>
                                </MultiPermissionAuthorize>

                                <MultiPermissionAuthorize
                                    ops={["READ VILLAGE"]}
                                >
                                    <Grid item xs={12} md={6} lg={4}>
                                        <UpdatedSearchableInput
                                            label="Village"
                                            api="/region/village"
                                            dep={filter.district}
                                            params={
                                                filter.district &&
                                                filter.district !==
                                                    ("all" as any)
                                                    ? {
                                                          otherOps:
                                                              JSON.stringify([
                                                                  {
                                                                      op: "in",
                                                                      operands:
                                                                          [
                                                                              filter.district,
                                                                          ],
                                                                      isDate: false,
                                                                      col: "district",
                                                                  },
                                                              ]),
                                                      }
                                                    : { district: 0 }
                                            }
                                            filter={filter}
                                            setFilter={setFilter}
                                            multiple={true}
                                        />
                                    </Grid>
                                </MultiPermissionAuthorize>

                                <MultiPermissionAuthorize ops={["READ HAMLET"]}>
                                    <Grid item xs={12} md={6} lg={4}>
                                        <UpdatedSearchableInput
                                            label="Hamlet"
                                            api="/region/hamlet"
                                            filter={filter}
                                            setFilter={setFilter}
                                            dep={filter.village}
                                            params={
                                                filter.village
                                                    ? {
                                                          village: [
                                                              filter.village,
                                                          ],
                                                      }
                                                    : { village: [0] }
                                            }
                                            multiple={true}
                                        />
                                    </Grid>
                                </MultiPermissionAuthorize>

                                <MultiPermissionAuthorize
                                    ops={["READ POWERSTATION"]}
                                >
                                    <Grid item xs={12} md={6} lg={4}>
                                        <SearchableInput
                                            label="Powerstation"
                                            api="/powerstation"
                                            filter={filter}
                                            setFilter={setFilter}
                                            dep={{ district: filter.district }}
                                            multiple={true}
                                        />
                                    </Grid>
                                </MultiPermissionAuthorize>

                                <Grid item xs={12} md={6} lg={4}>
                                    <TextField
                                        type="date"
                                        size="small"
                                        fullWidth
                                        label="From Date"
                                        InputLabelProps={{ shrink: true }}
                                        onChange={(e) =>
                                            setFilter({
                                                ...filter,
                                                startDate: e.target.value,
                                            })
                                        }
                                    />
                                </Grid>

                                <Grid item xs={12} md={6} lg={4}>
                                    <TextField
                                        type="date"
                                        size="small"
                                        fullWidth
                                        label="To Date"
                                        InputLabelProps={{ shrink: true }}
                                        onChange={(e) =>
                                            setFilter({
                                                ...filter,
                                                endDate: e.target.value,
                                            })
                                        }
                                    />
                                </Grid>

                                <MultiPermissionAuthorize
                                    ops={["READ CONNECTION_TYPE"]}
                                >
                                    <Grid item xs={12} md={6} lg={4}>
                                        <UpdatedSearchableInput
                                            label="Connection Types"
                                            _name="connectionType"
                                            api="/connection"
                                            filter={paymentFilter}
                                            setFilter={setPaymentFilter}
                                            multiple={true}
                                        />
                                    </Grid>
                                </MultiPermissionAuthorize>

                                <Grid item xs={12} md={6} lg={4}>
                                    <TextField
                                        label="Reference Date"
                                        type="date"
                                        size="small"
                                        fullWidth
                                        InputLabelProps={{ shrink: true }}
                                        onChange={(e) =>
                                            setPaymentFilter({
                                                ...paymentFilter,
                                                referenceDate: e.target.value,
                                            })
                                        }
                                    />
                                </Grid>
                            </Grid>
                        </Card>

                        <Grid container spacing={2}>
                            <DashboardCard
                                isFetching={isPaymentFetching}
                                title="Payment Report"
                                data={{
                                    chartData: {
                                        series: [
                                            parseFloat(paymentReport?.lineRent),
                                            parseFloat(
                                                paymentReport.additionalCharges
                                            ) ?? 0,
                                            parseFloat(
                                                paymentReport.connectionCharges
                                            ),
                                            paymentReport.totalAmount -
                                                (parseFloat(
                                                    paymentReport.lineRent
                                                ) +
                                                    paymentReport.connectionCharges +
                                                    paymentReport.additionalCharges) ??
                                                0,
                                        ],
                                        labels: [
                                            "Line Rent",
                                            "Additional Charges",
                                            "Connection Charges",
                                            "Units Amount",
                                        ],
                                    },
                                    cardData: {
                                        total:
                                            paymentReport.totalAmount +
                                            paymentReport.applicableSurcharge +
                                            paymentReport.payedSurcharge,
                                        // paymentReport.surcharge,
                                        collected:
                                            paymentReport.collection_amount +
                                            paymentReport.collection_adjustment,

                                        arrears:
                                            paymentReport.totalAmount +
                                            paymentReport.payedSurcharge +
                                            paymentReport.applicableSurcharge -
                                            (paymentReport.collection_amount +
                                                paymentReport.collection_adjustment),
                                    },
                                }}
                            />

                            <Grid item xs={6}>
                                <Card variant="outlined">
                                    <CardHeader
                                        subheader={
                                            <Box
                                                display="flex"
                                                gap={theme.spacing(1)}
                                                alignItems="center"
                                            >
                                                <ReceiptOutlined color="primary" />
                                                <Typography>
                                                    No. of Bills
                                                </Typography>
                                            </Box>
                                        }
                                    />

                                    <CardContent
                                        sx={{
                                            display: "flex",
                                            gap: theme.spacing(2),
                                        }}
                                    >
                                        <Typography
                                            variant="h6"
                                            color="primary"
                                        >
                                            Total Count :
                                        </Typography>

                                        <Typography variant="h6">
                                            {!isBillFetching ? (
                                                numberFormatter.format(
                                                    billReport?.data.noOfBills
                                                )
                                            ) : (
                                                <CircularProgress size={15} />
                                            )}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>

                            <Grid item xs={6}>
                                <Card variant="outlined">
                                    <CardHeader
                                        subheader={
                                            <Box
                                                display="flex"
                                                gap={theme.spacing(1)}
                                                alignItems="center"
                                            >
                                                <ReceiptOutlined color="primary" />
                                                <Typography>
                                                    No. of Units
                                                </Typography>
                                            </Box>
                                        }
                                    />

                                    <CardContent
                                        sx={{
                                            display: "flex",
                                            gap: theme.spacing(2),
                                        }}
                                    >
                                        <Typography
                                            variant="h6"
                                            color="primary"
                                        >
                                            Total Count :
                                        </Typography>

                                        <Typography variant="h6">
                                            {!isUnitsFetching ? (
                                                numberFormatter.format(
                                                    unitReport?.data.noOfBills
                                                )
                                            ) : (
                                                <CircularProgress size={15} />
                                            )}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>

                            <Grid item xs={12}>
                                <MeterCountReport filter={filter} />
                            </Grid>
                        </Grid>
                    </Box>
                </MultiPermissionAuthorize>
            ) : (
                <MultiPermissionAuthorize ops={["READ SIMPLE_DASHBOARD"]}>
                    <Box mt={2}>
                        <SimpleDashboard />
                    </Box>
                </MultiPermissionAuthorize>
            )}
        </Box>
    );
};

export default Dashboard;
