import { Add } from "@mui/icons-material";
import {
    Box,
    Button,
    Card,
    Grid,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { Link } from "react-router-dom";
import { GET } from "../../../Utilities/BaseService";
import LoadViewButton from "../../../Utilities/LoadViewButton";
import { MultiPermissionAuthorize } from "../../../Utilities/MultiPermissionAuthorize";
import BaseTable from "../../Table/BaseTable";
import SearchBar from "../../Table/Components/SearchBar";

export const getAllDistricts = (pagination: any, filter: any) => {
    return GET("/region/district", {
        page: pagination.page + 1,
        limit: pagination.limit,
        search: filter.search ? filter.search : "",
    });
};

export const getAllVillages = (
    pagination: any,
    filter: any,
    search: string
) => {
    return GET("/region/village", {
        ...pagination,
        ...filter,
        page: pagination.page + 1,
        search: search,
    });
};

const District = () => {
    const [rowsCount, setRowsCount] = useState(0);
    const [pagination, setPagination] = useState<any>({ page: 0, limit: 100 });
    const [filter, setFilter] = useState<any>({ search: "" });
    const [feedback, setFeedback] = useState("");
    const [load, setLoad] = useState(false);
    const theme = useTheme();
    const mdDown = useMediaQuery(theme.breakpoints.down("md"));

    const { data, isLoading } = useQuery(
        ["districts", pagination.page, pagination.limit, filter.search],
        () => getAllDistricts(pagination, filter),
        {
            onSuccess(res) {
                if (res) setRowsCount(res?.data.count);
            },
        }
    );

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                height: mdDown ? "auto" : `calc(100vh - 96px)`,
            }}
        >
            <Card
                elevation={0}
                sx={{
                    bgcolor: theme.palette.common.white,
                    p: 2,
                    mb: "1rem",
                }}
            >
                <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Grid item xs={12} md="auto">
                        <Typography variant="h5" color="GrayText">
                            Districts
                        </Typography>
                    </Grid>

                    <Grid item xs={12} md={6} container spacing={1.5}>
                        <Grid item flex={1}>
                            <SearchBar
                                filter={filter}
                                setFilter={setFilter}
                                debounceChange
                            />
                        </Grid>

                        <Grid item xs={12} md="auto">
                            <MultiPermissionAuthorize ops={["CREATE DISTRICT"]}>
                                <Link
                                    to="/regions/districts/add"
                                    style={{ textDecoration: "none" }}
                                >
                                    <Button
                                        sx={{ height: "100%" }}
                                        variant="outlined"
                                        color="secondary"
                                        startIcon={<Add />}
                                    >
                                        add district
                                    </Button>
                                </Link>
                            </MultiPermissionAuthorize>
                        </Grid>
                    </Grid>
                </Grid>
            </Card>

            <Box sx={{ height: "70%" }}>
                {!load && (
                    <LoadViewButton
                        label="view districts"
                        load={load}
                        setLoad={setLoad}
                    />
                )}

                <BaseTable
                    headers={{ id: "ID", name: "Name" }}
                    defaultSelectedHeaders={["id", "name"]}
                    data={data?.data.rows}
                    delEndPoint="/region/district"
                    feedback={feedback}
                    setFeedback={setFeedback}
                    rowsCount={rowsCount}
                    pagination={pagination}
                    setPagination={setPagination}
                    isLoading={isLoading}
                    permissions={{
                        edit: ["UPDATE DISTRICT"],
                        delete: ["DELETE DISTRICT"],
                    }}
                    load={load}
                    queryKey={[
                        "districts",
                        pagination.page,
                        pagination.limit,
                        filter.search,
                    ]}
                    reportName="Districts List"
                />
            </Box>
        </Box>
    );
};

export default District;
