import { FileDownload } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import { DownloadTableExcel } from "react-export-table-to-excel";

type Props = any;
export const TableToExcel = ({ fileName, sheetName, targetRef }: Props) => {
    return (
        <DownloadTableExcel
            filename={fileName}
            sheet={sheetName}
            currentTableRef={targetRef.current}
        >
            <Tooltip title="Export To Excel">
                <IconButton sx={{ ml: 1 }} size="small">
                    <FileDownload fontSize="small" />
                </IconButton>
            </Tooltip>
        </DownloadTableExcel>
    );
};
