import { Autorenew, AutorenewOutlined } from "@mui/icons-material";
import {
    Alert,
    Button,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    TextField,
} from "@mui/material";
import { FormEvent, useState } from "react";
import UpdatedSearchableInput from "../../../Utilities/UpdatedSearchableInput";
import { useMutation } from "@tanstack/react-query";
import { PATCH } from "../../../Utilities/BaseService";

const ReplacePriceType = ({ row }: any) => {
    const [open, setOpen] = useState(false);
    const [data, setData] = useState({ replacer: "", effectiveDate: "" });
    const [feedback, setFeedback] = useState("");

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        replacePriceType.mutate();
    };
    const replacePriceType = useMutation(
        () =>
            PATCH("/pricetype/replace", {
                replacee: row.id,
                replacer: data.replacer,
                effectiveDate: data.effectiveDate,
            }),
        {
            onSuccess(res) {
                setFeedback(res.data.message);
            },

            onError(err: any) {
                setFeedback(err.response.data.message);
            },
        }
    );

    return (
        <>
            <IconButton size="small" onClick={() => setOpen(true)}>
                <AutorenewOutlined fontSize="small" />
            </IconButton>

            <Dialog open={open} onClose={() => setOpen(false)} fullWidth>
                <DialogTitle>Replace Price Type</DialogTitle>

                <DialogContent>
                    <Grid
                        container
                        spacing={1}
                        mt={0.25}
                        component="form"
                        onSubmit={handleSubmit}
                    >
                        <Grid item xs={12} md={6}>
                            <TextField
                                value={row?.name}
                                fullWidth
                                size="small"
                                label="Current Price Type"
                                disabled
                                required
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <UpdatedSearchableInput
                                api="/pricetype"
                                label="New Price Type"
                                _name="replacer"
                                filter={data}
                                setFilter={setData}
                                dep={[open, row]}
                                optionsPreprocessor={(opt) => opt}
                                optionsFilter={(opts: any[]) =>
                                    opts.filter((opt: any) => opt.id !== row.id)
                                }
                                required
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <TextField
                                label="Effective Date"
                                type="date"
                                size="small"
                                value={data.effectiveDate}
                                onChange={(e) =>
                                    setData({
                                        ...data,
                                        effectiveDate: e.target.value,
                                    })
                                }
                                InputLabelProps={{ shrink: true }}
                                inputProps={{
                                    min: new Date().toISOString().split("T")[0],
                                }}
                                required
                                fullWidth
                            />
                        </Grid>

                        {(replacePriceType.isSuccess ||
                            replacePriceType.isError) && (
                            <Grid item xs={12}>
                                <Alert
                                    severity={
                                        replacePriceType.isSuccess
                                            ? "success"
                                            : "error"
                                    }
                                >
                                    {feedback}
                                </Alert>
                            </Grid>
                        )}

                        <Grid item xs={12} mt={2}>
                            <Button
                                variant="contained"
                                disableElevation
                                type="submit"
                                startIcon={<Autorenew />}
                                disabled={replacePriceType.isLoading}
                                endIcon={
                                    replacePriceType.isLoading ? (
                                        <CircularProgress size={20} />
                                    ) : undefined
                                }
                            >
                                {replacePriceType.isLoading
                                    ? "replacing unit charge..."
                                    : "replace"}
                            </Button>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default ReplacePriceType;
