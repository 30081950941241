import { Add, Visibility, VisibilityOff } from "@mui/icons-material";
import {
    Alert,
    Button,
    Card,
    CardContent,
    CardHeader,
    Checkbox,
    CircularProgress,
    Divider,
    FormControlLabel,
    Grid,
    IconButton,
    MenuItem,
    TextField,
    useTheme,
} from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import { ChangeEvent, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { GET, PATCH, POST } from "../../Utilities/BaseService";
import SearchableInput from "../Dashboard/Components/SearchableInput";
import { UserConstraints } from "./UserConstraints";
import { UserRegions } from "./UserRegions";

const addUser = (user: any) =>
    POST("/user", {
        ...user,
        dateRestriction: JSON.stringify({
            restricted: false,
            backward: 0,
            forward: 0,
        }),
    });

const updateUser = ({ user, id }: any) => {
    return PATCH(
        "/user",
        { ...user, dateRestriction: JSON.stringify(user.dateRestriction) },
        { id }
    );
};

const AddUser = ({ updateMode }: any) => {
    const [filter, setFilter] = useState({
        district: "",
        village: "",
        hamlet: "",
        role: "",
    });
    const [user, setUser] = useState<any>({
        name: "",
        username: "",
        password: "",
        role: "",
        allRegionAccess: false,
        regions: [],
        status: "active",
        // dateRestriction: { restricted: false, backward: 0, forward: 0 },
        constraints: [],
    });
    const [id, setId] = useState("");
    const [feedback, setFeedback] = useState();
    const location = useLocation();
    const [changes, setChanges] = useState({
        district: 0,
        village: 0,
        hamlet: 0,
        powerstation: 0,
    });
    const [showPassword, setShowPassword] = useState(true);
    const theme = useTheme();

    const getUserById = () => {
        return GET(`/user?id=${id}`);
    };

    const { data: userById } = useQuery([`user`, id], getUserById, {
        enabled: Boolean(id),
        onSuccess(response) {
            if (response.data.rows.length > 0) {
                const _u = response.data.rows[0];

                console.log(_u);

                const regions = _u.regions.map((region: any) => ({
                    district: region.district?.id,
                    village: region.village?.id,
                    hamlet: region.hamlet?.id,
                    powerstation: region.powerstation?.id,
                }));

                setUser({
                    ...user,
                    allRegionAccess: _u.allRegionAccess,
                    name: _u.name,
                    password: _u.password,
                    regions: regions,
                    role: _u.role,
                    username: _u.username,
                    status: _u.status,
                    constraints: _u.constraints,
                });

                setChanges({
                    district: 1,
                    village: 1,
                    hamlet: 1,
                    powerstation: 1,
                });
            }
        },
    });

    const handleChange = (ev: any) => {
        const value = ev.target.value;
        setUser({
            ...user,
            [ev.target.name]: value,
        });
    };

    const handleSubmit = (ev: any) => {
        ev.preventDefault();
        !updateMode
            ? addMutation.mutate(user)
            : updateMutation.mutate({ user, id });
    };

    const addMutation = useMutation(addUser, {
        onSuccess(res) {
            return setFeedback(res.data.message);
        },
        onError(err: any) {
            return setFeedback(err.response.data.message);
        },
    });

    const updateMutation = useMutation(updateUser, {
        onSuccess(res) {
            setFeedback(res.data.message);
        },
        onError(err: any) {
            setFeedback(err.response.data.message);
        },
    });

    const handleDateRestrictionChange = (ev: ChangeEvent<HTMLInputElement>) => {
        setUser({
            ...user,
            dateRestriction: {
                ...user.dateRestriction,
                [ev.target.name]: Math.max(0, parseInt(ev.target.value || "0")),
            },
        });
    };

    useEffect(() => {
        if (updateMode) {
            const params = new URLSearchParams(location.search);
            const _id = params.get("id");

            if (_id) {
                setId(_id);
            }
        }
    }, [updateMode]);

    useEffect(() => {
        setUser({
            ...user,
            role: filter.role,
        });
    }, [filter]);

    return (
        <>
            <Card
                elevation={0}
                sx={{ bgcolor: theme.palette.common.white, mb: "1rem" }}
            >
                <CardHeader
                    title={!updateMode ? "add user" : "update user"}
                    sx={{
                        textTransform: "capitalize",
                    }}
                />

                <CardContent>
                    <Divider sx={{ mb: 5 }} />
                    <Grid
                        container
                        spacing={1}
                        component="form"
                        method="post"
                        onSubmit={handleSubmit}
                    >
                        <Grid item xs={12} md={6}>
                            <TextField
                                variant="outlined"
                                label="Name"
                                value={user.name}
                                name="name"
                                type="text"
                                onChange={handleChange}
                                fullWidth
                                required
                                size="small"
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <TextField
                                variant="outlined"
                                label="Username"
                                value={user.username}
                                name="username"
                                type="text"
                                onChange={handleChange}
                                fullWidth
                                required
                                size="small"
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <TextField
                                variant="outlined"
                                label="Password"
                                value={user.password}
                                name="password"
                                type={showPassword ? "text" : "password"}
                                InputProps={{
                                    endAdornment: (
                                        <IconButton
                                            size="small"
                                            title={
                                                showPassword
                                                    ? "Hide Password"
                                                    : "Show Password"
                                            }
                                            onClick={() =>
                                                setShowPassword(!showPassword)
                                            }
                                        >
                                            {showPassword ? (
                                                <VisibilityOff fontSize="small" />
                                            ) : (
                                                <Visibility fontSize="small" />
                                            )}
                                        </IconButton>
                                    ),
                                }}
                                onChange={handleChange}
                                fullWidth
                                required
                                size="small"
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <SearchableInput
                                label="Role"
                                api="/rolesystem/role/"
                                filter={filter}
                                setFilter={setFilter}
                                defaultValue={
                                    updateMode && userById?.data.rows[0].role
                                }
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={user.allRegionAccess}
                                        onChange={() =>
                                            setUser({
                                                ...user,
                                                allRegionAccess:
                                                    !user.allRegionAccess,
                                            })
                                        }
                                    />
                                }
                                label="This user has access to all regions?"
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <TextField
                                size="small"
                                fullWidth
                                label="Status"
                                name="status"
                                value={user.status}
                                onChange={handleChange}
                                select
                            >
                                <MenuItem value="active">Active</MenuItem>
                                <MenuItem value="inactive">Inactive</MenuItem>
                            </TextField>
                        </Grid>

                        <Grid item container xs={12}>
                            <UserConstraints user={user} setUser={setUser} />
                        </Grid>

                        <Grid item xs={12} m={2}>
                            <Divider />
                        </Grid>

                        {!user.allRegionAccess && (
                            <Grid item xs={12} overflow="auto">
                                <UserRegions
                                    user={user}
                                    setUser={setUser}
                                    updateMode={updateMode}
                                    changes={changes}
                                    setChanges={setChanges}
                                />
                            </Grid>
                        )}

                        <Grid item xs={12}>
                            <Button
                                startIcon={
                                    updateMutation.isLoading ? (
                                        <CircularProgress
                                            color="secondary"
                                            size="1rem"
                                        />
                                    ) : (
                                        <Add />
                                    )
                                }
                                variant="outlined"
                                type="submit"
                                color="secondary"
                                endIcon={
                                    addMutation.isLoading ? (
                                        <CircularProgress
                                            color="secondary"
                                            size="1rem"
                                        />
                                    ) : (
                                        ""
                                    )
                                }
                            >
                                {!updateMode ? "add" : "update"} user
                            </Button>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>

            {addMutation.isSuccess || updateMutation.isSuccess ? (
                <Alert severity="success" sx={{ mb: 2 }}>
                    {feedback}.
                </Alert>
            ) : addMutation.isError || updateMutation.isError ? (
                <Alert severity="error" sx={{ mb: 2 }}>
                    {feedback}.
                </Alert>
            ) : null}
        </>
    );
};

export default AddUser;
