import { Add, HorizontalRule } from "@mui/icons-material";
import { Box, Card, Grid, Typography, Button } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { theme } from "../../../Theme/theme";
import { GET } from "../../../Utilities/BaseService";
import { MultiPermissionAuthorize } from "../../../Utilities/MultiPermissionAuthorize";
import UpdatedSearchableInput from "../../../Utilities/UpdatedSearchableInput";
import { currencyFormatter } from "../../Reading/Bill/Cells/Last12Summary";
import BaseTable from "../../Table/BaseTable";
import SearchBar from "../../Table/Components/SearchBar";

const ConnectionCharge = () => {
    const [rowsCount, setRowsCount] = useState(0);
    const [pagination, setPagination] = useState<any>({ page: 0, limit: 100 });
    const [filter, setFilter] = useState({
        search: "",
        district: "",
        village: "",
        hamlet: "",
        powerstation: "",
    });
    const [feedback, setFeedback] = useState("");
    const [clear, setClear] = useState({
        district: false,
        village: false,
        hamlet: false,
        powerstation: false,
    });

    const { data, isLoading } = useQuery(
        ["connection-charges", pagination.page, pagination.limit, filter],
        () =>
            GET("/connection-charges", {
                page: pagination.page + 1,
                limit: pagination.limit,
                ...Object.fromEntries(
                    Object.entries(filter).filter(([k, v]) => v && v !== "all")
                ),
            }),
        {
            onSuccess(res) {
                if (res) setRowsCount(res?.data.count);
            },
        }
    );

    useEffect(() => {
        if (filter.district === "all") {
            setClear({
                district: true,
                village: true,
                hamlet: true,
                powerstation: true,
            });
            setFilter({
                ...filter,
                district: "",
                village: "",
                hamlet: "",
                powerstation: "",
            });
        }

        if (filter.village === "all") {
            setClear({ ...clear, village: true, hamlet: true });
            setFilter({ ...filter, village: "", hamlet: "" });
        }

        if (filter.hamlet === "all") {
            setClear({ ...clear, hamlet: true });
            setFilter({ ...filter, hamlet: "" });
        }

        return () => {
            setClear({
                district: false,
                village: false,
                hamlet: false,
                powerstation: false,
            });
        };
    }, [filter.district, filter.village, filter.hamlet]);

    return (
        <Box>
            <Card
                elevation={0}
                sx={{
                    bgcolor: theme.palette.common.white,
                    p: 2,
                    mb: "1rem",
                }}
            >
                <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Grid item xs={12} md={4}>
                        <Typography variant="h5" color="GrayText">
                            Connection Charges
                        </Typography>
                    </Grid>

                    <Grid item xs={12} md={8} lg={6} container spacing={1}>
                        <Grid item flex={1}>
                            <SearchBar filter={filter} setFilter={setFilter} />
                        </Grid>

                        <Grid item>
                            <MultiPermissionAuthorize
                                ops={["CREATE CONNECTION_CHARGES"]}
                            >
                                <Link
                                    to="/connections/charges/add"
                                    style={{ textDecoration: "none" }}
                                >
                                    <Button
                                        variant="outlined"
                                        color="secondary"
                                        startIcon={<Add />}
                                    >
                                        add connection charge
                                    </Button>
                                </Link>
                            </MultiPermissionAuthorize>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} container spacing={1} mt={1}>
                        <Grid item xs={12} md={6} lg={3}>
                            <UpdatedSearchableInput
                                api="/region/district"
                                label="District"
                                filter={filter}
                                setFilter={setFilter}
                                clearAll={clear.district}
                            />
                        </Grid>

                        <Grid item xs={12} md={6} lg={3}>
                            <UpdatedSearchableInput
                                api="/region/village"
                                label="Village"
                                filter={filter}
                                setFilter={setFilter}
                                dep={filter.district}
                                params={{ district: filter.district }}
                                clearAll={clear.village}
                            />
                        </Grid>

                        <Grid item xs={12} md={6} lg={3}>
                            <UpdatedSearchableInput
                                api="/region/hamlet"
                                label="Hamlet"
                                filter={filter}
                                setFilter={setFilter}
                                dep={filter.village}
                                params={{ village: filter.village }}
                                clearAll={clear.hamlet}
                            />
                        </Grid>

                        <Grid item xs={12} md={6} lg={3}>
                            <UpdatedSearchableInput
                                api="/powerstation"
                                label="Powerstation"
                                filter={filter}
                                setFilter={setFilter}
                                dep={filter.district}
                                params={{ district: filter.district }}
                                clearAll={clear.powerstation}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Card>

            <BaseTable
                headers={{
                    id: "ID",
                    name: "Name",
                    amount: "Charges",
                    regions: "Regions",
                }}
                defaultSelectedHeaders={["id", "name", "amount", "regions"]}
                data={data?.data.rows.map((row: any) => ({
                    ...row,
                    amount: currencyFormatter.format(row.amount),
                    regions: row.district
                        ? `${row.district.name}  ${
                              row.village?.name
                                  ? " ----> " + row.village.name
                                  : ""
                          } ${
                              row.hamlet?.name
                                  ? " ----> " + row.hamlet.name
                                  : ""
                          } ${
                              row.powerstation?.name
                                  ? " ----> " + row.powerstation.name
                                  : ""
                          }`
                        : "Global",
                }))}
                delEndPoint="/connection-charges"
                feedback={feedback}
                setFeedback={setFeedback}
                rowsCount={rowsCount}
                pagination={pagination}
                setPagination={setPagination}
                isLoading={isLoading}
                permissions={{
                    edit: ["UPDATE CONNECTION_CHARGES"],
                    delete: ["DELETE CONNECTION_CHARGES"],
                }}
                queryKey={[
                    "connection-charges",
                    pagination.page,
                    pagination.limit,
                    filter,
                ]}
                reportName="Connection Charges Report"
            />
        </Box>
    );
};

export default ConnectionCharge;
