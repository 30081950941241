import { ExpandMore, InfoOutlined } from "@mui/icons-material";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Checkbox,
    Divider,
    FormControlLabel,
    List,
    ListItem,
    ListItemText,
    Typography,
    useTheme,
} from "@mui/material";
import { ChangeEvent, Dispatch, SetStateAction, useState } from "react";
import { SlipField } from "./Connection/AddConnectionSlip";

type CustomFields = { [key: string]: SlipField[] };

type Props = {
    fields: CustomFields;
    setFields: Dispatch<SetStateAction<CustomFields>>;
    categories?: { [key: string]: string };
};

const initialChecked: CustomFields = {};

const SlipCustomFields = ({ fields, setFields, categories }: Props) => {
    const [checked, setChecked] = useState(initialChecked);
    const theme = useTheme();

    const handleCheck = (
        e: ChangeEvent<HTMLInputElement>,
        check: boolean,
        target: {
            type: "category" | "field";
            categoryKey: string;
            fieldKey?: string;
        }
    ) => {
        switch (target.type) {
            case "category":
                let updatedCategory: SlipField[] = [];

                updatedCategory = fields[target.categoryKey].map((f) => ({
                    ...f,
                    checked: check,
                }));

                setFields({ ...fields, [target.categoryKey]: updatedCategory });

                break;

            case "field":
                const updatedFiedls = fields[target.categoryKey].map((f) =>
                    f.key === target.fieldKey ? { ...f, checked: check } : f
                );

                setFields({ ...fields, [target.categoryKey]: updatedFiedls });

                break;
        }
    };

    return (
        <Accordion
            variant="outlined"
            elevation={0}
            sx={{
                bgcolor: "transparent",
            }}
        >
            <AccordionSummary expandIcon={<ExpandMore />}>
                <Typography
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: theme.spacing(1),
                        color: theme.palette.info.main,
                    }}
                >
                    <InfoOutlined />

                    <span>
                        Select the fields from the following list to show in the
                        slip
                    </span>
                </Typography>
            </AccordionSummary>

            <AccordionDetails
                sx={{
                    maxHeight: "320px",
                    overflowY: "auto",
                    py: 0,
                }}
            >
                <List disablePadding>
                    {Object.entries(fields).map(([fieldCat, _fields], id) => (
                        <ListItem
                            key={fieldCat + id}
                            dense
                            disableGutters
                            disablePadding
                        >
                            <ListItemText>
                                <Box
                                    position="sticky"
                                    top={0}
                                    bgcolor={theme.palette.common.white}
                                    zIndex={1}
                                >
                                    <Divider />

                                    <FormControlLabel
                                        label={
                                            categories && categories[fieldCat]
                                                ? categories[fieldCat]
                                                : fieldCat
                                        }
                                        control={
                                            <Checkbox
                                                size="small"
                                                indeterminate={
                                                    _fields.filter(
                                                        (f) => f.checked
                                                    ).length < _fields.length
                                                        ? _fields.some(
                                                              (f) => f.checked
                                                          )
                                                        : undefined
                                                }
                                                checked={_fields.every(
                                                    (f) => f.checked
                                                )}
                                                onChange={(e, check) =>
                                                    handleCheck(e, check, {
                                                        type: "category",
                                                        categoryKey: fieldCat,
                                                    })
                                                }
                                            />
                                        }
                                    />

                                    <Divider />
                                </Box>

                                <List disablePadding>
                                    {_fields.map((f) => (
                                        <ListItem
                                            disableGutters
                                            disablePadding
                                            key={f.key}
                                        >
                                            <ListItemText>
                                                <FormControlLabel
                                                    label={f.label}
                                                    control={
                                                        <Checkbox
                                                            size="small"
                                                            checked={f.checked}
                                                            onChange={(
                                                                e,
                                                                check
                                                            ) =>
                                                                handleCheck(
                                                                    e,
                                                                    check,
                                                                    {
                                                                        type: "field",
                                                                        fieldKey:
                                                                            f.key,
                                                                        categoryKey:
                                                                            fieldCat,
                                                                    }
                                                                )
                                                            }
                                                        />
                                                    }
                                                />
                                            </ListItemText>
                                        </ListItem>
                                    ))}
                                </List>
                            </ListItemText>
                        </ListItem>
                    ))}
                </List>
            </AccordionDetails>
        </Accordion>
    );
};

export default SlipCustomFields;
