import { Add, FilterAltOutlined } from "@mui/icons-material";
import {
    Alert,
    Box,
    Button,
    Card,
    Grid,
    Typography,
    useTheme,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../../../Utilities/AppContext";
import { GET } from "../../../Utilities/BaseService";
import {
    filterSelectionMessage,
    hasValue,
    noDataMessage,
} from "../../../Utilities/constants";
import { MultiPermissionAuthorize } from "../../../Utilities/MultiPermissionAuthorize";
import UpdatedSearchableInput from "../../../Utilities/UpdatedSearchableInput";
import BaseTable from "../../Table/BaseTable";
import SearchBar from "../../Table/Components/SearchBar";

type Filter = { search: string; targetTypes: "" | "all" | number };
const initialFilter: Filter = {
    search: "",
    targetTypes: "",
};

const getAllPaymentHeads = (filter: any) => {
    return GET("/payment/head", filter);
};

const getTargetTypes = () => {
    return GET("/payment/head/targetType");
};

export const PaymentHead = () => {
    const [rowsCount, setRowsCount] = useState(0);
    const [pagination, setPagination] = useState({ page: 0, limit: 100 });
    const [feedback, setFeedback] = useState("");
    const [load, setLoad] = useState(false);
    const [filter, setFilter] = useState(initialFilter);
    const [changeCount, setChangeCount] = useState(0);
    const [intermediateFilter, setIntermediateFilter] =
        useState<Filter>(initialFilter);
    const theme = useTheme();
    const [reportTemplates, setReportTemplates] = useState<string[]>([]);

    const { user } = useContext(AppContext);

    const { data, isLoading } = useQuery(
        ["payment-heads", pagination.page, pagination.limit, filter],
        () =>
            getAllPaymentHeads({
                search: filter.search ? filter.search : undefined,
                targetType:
                    filter.targetTypes && filter.targetTypes !== "all"
                        ? filter.targetTypes
                        : undefined,
            }),
        {
            enabled: Boolean(changeCount),
            onSuccess(res) {
                if (res) setRowsCount(res?.data.count);
            },
        }
    );

    useEffect(() => {
        if (changeCount) {
            setFilter({ ...filter, ...intermediateFilter });
        }
    }, [changeCount]);

    return (
        <Box>
            <Card
                elevation={0}
                sx={{
                    bgcolor: theme.palette.common.white,
                    p: 2,
                    mb: "1rem",
                }}
            >
                <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                    spacing={1.5}
                >
                    <Grid item xs={12} md={4}>
                        <Typography variant="h5" color="GrayText">
                            Payment Heads
                        </Typography>
                    </Grid>

                    <Grid item xs={12} md={6} container spacing={1.5}>
                        <Grid item flex={1}>
                            <SearchBar
                                filter={intermediateFilter}
                                setFilter={setIntermediateFilter}
                            />
                        </Grid>

                        <Grid item xs={12} md="auto">
                            <MultiPermissionAuthorize
                                ops={["CREATE PAYMENT_HEAD"]}
                            >
                                <Link
                                    to="/payment/head/add"
                                    style={{ textDecoration: "none" }}
                                >
                                    <Button
                                        sx={{
                                            height: "100%",
                                            minWidth: "25ch",
                                        }}
                                        variant="outlined"
                                        color="secondary"
                                        startIcon={<Add />}
                                        fullWidth
                                    >
                                        add payment head
                                    </Button>
                                </Link>
                            </MultiPermissionAuthorize>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} container spacing={1.5}>
                        <Grid item flex={1}>
                            <UpdatedSearchableInput
                                api="/payment/head/targetType"
                                label="Target Type"
                                filter={intermediateFilter}
                                setFilter={setIntermediateFilter}
                                _name="targetTypes"
                                optionsPreprocessor={(opt: any) => ({
                                    ...opt,
                                    id: opt.type,
                                    name: opt.label,
                                })}
                            />
                        </Grid>

                        <Grid item xs={12} md="auto">
                            <Button
                                color="secondary"
                                variant="outlined"
                                onClick={() => setChangeCount(changeCount + 1)}
                                sx={{ minWidth: "25ch", height: "100%" }}
                                startIcon={<FilterAltOutlined />}
                                fullWidth
                            >
                                filter
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Card>

            {data?.data.rows.length > 0 ? (
                <BaseTable
                    headers={{
                        id: "ID",
                        name: "Name",
                        targetType: "Target Type",
                        amount: "Amount",
                    }}
                    load={Boolean(changeCount)}
                    defaultSelectedHeaders={[
                        "id",
                        "name",
                        "targetType",
                        "amount",
                    ]}
                    data={data?.data.rows}
                    delEndPoint="/payment/head"
                    feedback={feedback}
                    setFeedback={setFeedback}
                    rowsCount={rowsCount}
                    pagination={pagination}
                    setPagination={setPagination}
                    isLoading={isLoading}
                    permissions={{
                        edit: ["UPDATE PAYMENT_HEAD"],
                        delete: ["DELETE PAYMENT_HEAD"],
                    }}
                    queryKey={[
                        "payment-heads",
                        pagination.page,
                        pagination.limit,
                        filter,
                    ]}
                    reportName="Payment Heads"
                    reportTemplates={reportTemplates}
                />
            ) : hasValue(filter) ? (
                <Alert severity="info">{noDataMessage}</Alert>
            ) : (
                <Alert severity="info">{filterSelectionMessage}</Alert>
            )}
        </Box>
    );
};
