import {
    Autocomplete,
    Button,
    Chip,
    CircularProgress,
    Divider,
    List,
    ListItem,
    Pagination,
    TextField,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { GET } from "../../../Utilities/BaseService";

type Props = any;

const getData = (
    api: string,
    search: string,
    dep: any,
    otherOps: any,
    multiple: boolean,
    page: any,
    limit: any,
    clearAll: boolean
) => {
    const params = multiple
        ? { page, limit, search, otherOps }
        : { page, limit, search, ...dep };

    return GET(api, { ...params });
};

const SearchableInput = ({
    api,
    label,
    filter,
    setFilter,
    size,
    dep,
    multiple,
    defaultValue,
    disabled,
    required = false,
    clearAll,
}: Props) => {
    const [options, setOptions] = useState<any>([]);
    const [search, setSearch] = useState<string>("");
    const [value, setValue] = useState<any>(multiple ? [] : null);
    const [pagination, setPagination] = useState({
        count: 0,
        page: 1,
        limit: 10,
    });

    const otherOps = useMemo(() => {
        if (!dep) return "[]";
        return JSON.stringify([
            {
                op: "in",
                operands: [filter[Object.keys(dep)[0]] ?? []],
                isDate: false,
                col: Object.keys(dep)[0],
            },
        ]);
    }, [filter, dep]);

    const { isLoading } = useQuery(
        [label, dep, search, pagination.page, pagination.limit, value],
        () =>
            getData(
                api,
                search,
                dep,
                otherOps,
                multiple,
                pagination.page,
                pagination.limit,
                clearAll
            ),
        {
            onSuccess(data) {
                setPagination({
                    ...pagination,
                    count: data.data.count,
                });

                setOptions(data.data.rows);
            },
        }
    );

    const ref = useRef(0);

    const handleSelectChange = (_val: any) => {
        setValue(_val);
        multiple
            ? setFilter({
                  ...filter,
                  [label.toLocaleLowerCase()]: _val.map((v: any) => v.id),
              })
            : setFilter({
                  ...filter,
                  [label.toLocaleLowerCase()]: _val ? _val.id : "all",
              });
    };

    const handleInputChange = (ev: any, value: any, reason: any) => {
        if (reason === "reset") return;

        ref.current++;
        const newRef = ref.current;

        setTimeout(() => {
            if (newRef === ref.current) {
                setSearch(value);
                ref.current = 0;
            }
        }, 1000);
    };

    const handleSelectAll = () => {
        if (value.length !== options.length) {
            const modOpt = options.filter(
                (opt: any, idx: number) => !value.includes(opt)
            );
            const _val = [...value, ...modOpt];
            setValue(_val);

            setFilter({
                ...filter,
                [label.toLocaleLowerCase()]: _val.map((v: any) => v.id),
            });
        } else {
            setValue([]);
        }
    };

    useEffect(() => {
        if (!defaultValue) return;

        setValue(defaultValue);
    }, [defaultValue]);

    useEffect(() => {
        if (clearAll) {
            console.log("Clear all hit.");

            setValue(multiple ? [] : null);
            setFilter({ ...filter, [label.toLocaleLowerCase()]: "" });
        }
    }, [clearAll]);

    return (
        <Autocomplete
            disabled={disabled}
            fullWidth
            multiple={multiple}
            disableCloseOnSelect
            renderOption={(props, options, { selected }) => (
                <Fragment key={options.id}>
                    <li {...props} style={{ paddingTop: 1, paddingBottom: 1 }}>
                        {/* <Checkbox checked={selectAll} /> */}
                        {options.name}
                    </li>
                    <Divider />
                </Fragment>
            )}
            size={size ? size : "small"}
            options={options}
            getOptionLabel={(option) =>
                multiple ? option.id + "" : option.name + ""
            }
            value={value}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderTags={(value) =>
                value.map((val: any, idx) => (
                    <Chip
                        sx={{ m: 0.25 }}
                        key={idx}
                        size="small"
                        label={val.name}
                        onDelete={
                            multiple
                                ? () =>
                                      setValue(
                                          value.filter(
                                              (item: any) => item !== val
                                          )
                                      )
                                : undefined
                        }
                    />
                ))
            }
            onChange={(ev, val, reason) => handleSelectChange(val)}
            renderInput={(props) => (
                <TextField
                    {...props}
                    label={label}
                    name="name"
                    required={required}
                    InputProps={{
                        ...props.InputProps,
                        endAdornment: (
                            <>
                                {isLoading ? (
                                    <CircularProgress
                                        color="inherit"
                                        size={20}
                                    />
                                ) : null}
                                {props.InputProps.endAdornment}
                            </>
                        ),
                    }}
                />
            )}
            onInputChange={(event, value, reason) =>
                handleInputChange(event, value, reason)
            }
            groupBy={(option) => ""}
            renderGroup={(params) => (
                <List key={params.key}>
                    {multiple && (
                        <ListItem
                            sx={{ paddingTop: 0, paddingBottom: 0, mb: 1 }}
                        >
                            {/* <FormControlLabel
                            label="Select All"
                            control={
                                <Checkbox
                                    onClick={() => handleSelectAll(params)}
                                />
                            }
                        /> */}

                            <Button size="small" onClick={handleSelectAll}>
                                select all
                            </Button>
                        </ListItem>
                    )}
                    {params.group}
                    {params.children}
                    <ListItem
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        <Pagination
                            size="small"
                            count={Math.ceil(
                                pagination.count / pagination.limit
                            )}
                            page={pagination.page}
                            onChange={(ev, page) =>
                                setPagination({ ...pagination, page })
                            }
                        />
                    </ListItem>
                </List>
            )}
            loading={isLoading}
            filterOptions={(x) => x}
        />
    );
};

export default SearchableInput;
