import { Box, useTheme } from "@mui/material";
import Chart from "react-apexcharts";

type Props = {
    selectedConnTypes: "all" | number[];
    meterReport?: MeterCountReport;
};

export const ComparisonBar = ({ selectedConnTypes, meterReport }: Props) => {
    const theme = useTheme();

    if (meterReport) {
        return (
            <Box>
                <Chart
                    height="500"
                    plotOption={{
                        bar: { horizontal: true },
                    }}
                    options={{
                        chart: {
                            type: "bar",
                        },
                    }}
                    series={[
                        {
                            data: meterReport
                                .filter(
                                    (connType) =>
                                        selectedConnTypes === "all" ||
                                        selectedConnTypes.includes(
                                            connType.typeId
                                        )
                                )
                                .map((connType) => ({
                                    x: connType.typeName,
                                    y: parseInt(connType.typeCount) ?? 0,
                                })),
                        },
                    ]}
                    type="bar"
                />
            </Box>
        );
    } else {
        return null;
    }
};
