import { KeyboardArrowDown } from "@mui/icons-material";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Checkbox,
    FormControl,
    Grid,
    TextField,
    Typography,
} from "@mui/material";
import {
    ChangeEvent,
    Dispatch,
    SetStateAction,
    useEffect,
    useState,
} from "react";

type Props = {
    user: any;
    setUser: Dispatch<SetStateAction<any>>;
};

type DateConstraint =
    | "collection-back"
    | "collection-forward"
    | "reading-back"
    | "reading-forward"
    | "connection-back"
    | "connection-forward";

const initialRestrictions = {
    "collection-back": "",
    "collection-forward": "",
    "reading-back": "",
    "reading-forward": "",
    "connection-back": "",
    "connection-forward": "",
};

const initialRestrict = {
    "collection-back": false,
    "collection-forward": false,

    "reading-back": false,
    "reading-forward": false,

    "connection-back": false,
    "connection-forward": false,
};

export const UserConstraints = ({ user, setUser }: Props) => {
    // const [checkAll, setCheckAll] = useState(false);
    const [restrict, setRestrict] = useState(initialRestrict);
    const [restrictions, setRestrictions] = useState(initialRestrictions);

    const handleCheckRestrict = (ev: ChangeEvent<HTMLInputElement>) => {
        setRestrict({ ...restrict, [ev.target.name]: ev.target.checked });
    };

    const handleChange = (ev: ChangeEvent<HTMLInputElement>) => {
        setRestrictions({ ...restrictions, [ev.target.name]: ev.target.value });
    };

    useEffect(() => {
        if (user.role) {
            setRestrictions(
                () =>
                    Object.fromEntries(
                        user.constraints.map(
                            (constraint: { key: string; value: string }) => [
                                constraint.key,
                                constraint.value,
                            ]
                        )
                    ) as any
            );

            const defaultRestricts = Object.fromEntries(
                user.constraints.map((c: { key: string }) => [[c.key], true])
            );

            setRestrict(defaultRestricts as any);
        }
    }, [user.role]);

    useEffect(() => {
        setUser({
            ...user,
            constraints: Object.entries(restrictions)
                .filter((item) => restrict[item[0] as DateConstraint])
                .map((item) => ({
                    key: item[0],
                    value: item[1] || "0",
                })),
        });
    }, [restrict, restrictions]);

    return (
        <Accordion
            disableGutters
            variant="outlined"
            sx={{ width: "100%", backgroundColor: "transparent" }}
        >
            <AccordionSummary expandIcon={<KeyboardArrowDown />}>
                <Typography
                    variant="body1"
                    color={restrict ? "textPrimary" : "textSecondary"}
                >
                    User Restrictions
                </Typography>
            </AccordionSummary>

            <AccordionDetails>
                <Grid container spacing={1.5}>
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            size="small"
                            disabled={!restrict["collection-back"]}
                            label="Collection Date Back Entry"
                            variant="outlined"
                            type="number"
                            inputProps={{ min: "0", step: "1" }}
                            name="collection-back"
                            value={restrictions["collection-back"]}
                            onChange={handleChange}
                            InputLabelProps={{
                                shrink: restrict["collection-back"],
                            }}
                            placeholder="Number of Days"
                            InputProps={{
                                endAdornment: (
                                    <FormControl>
                                        <Checkbox
                                            onChange={handleCheckRestrict}
                                            name="collection-back"
                                            checked={
                                                restrict["collection-back"]
                                            }
                                        />
                                    </FormControl>
                                ),
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            size="small"
                            disabled={!restrict["collection-forward"]}
                            label="Collection Date Forward Entry"
                            variant="outlined"
                            type="number"
                            inputProps={{ min: "0", step: "1" }}
                            value={restrictions["collection-forward"]}
                            onChange={handleChange}
                            name="collection-forward"
                            InputLabelProps={{
                                shrink: restrict["collection-forward"],
                            }}
                            placeholder="Number of Days"
                            InputProps={{
                                endAdornment: (
                                    <FormControl>
                                        <Checkbox
                                            onChange={handleCheckRestrict}
                                            name="collection-forward"
                                            checked={
                                                restrict["collection-forward"]
                                            }
                                        />
                                    </FormControl>
                                ),
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            size="small"
                            disabled={!restrict["reading-back"]}
                            label="Reading Date Back Entry"
                            variant="outlined"
                            type="number"
                            inputProps={{ min: "0", step: "1" }}
                            name="reading-back"
                            value={restrictions["reading-back"]}
                            onChange={handleChange}
                            InputLabelProps={{
                                shrink: restrict["reading-back"],
                            }}
                            placeholder="Number of Days"
                            InputProps={{
                                endAdornment: (
                                    <FormControl>
                                        <Checkbox
                                            onChange={handleCheckRestrict}
                                            name="reading-back"
                                            checked={restrict["reading-back"]}
                                        />
                                    </FormControl>
                                ),
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            size="small"
                            disabled={!restrict["reading-forward"]}
                            label="Reading Date Forward Entry"
                            variant="outlined"
                            type="number"
                            inputProps={{ min: "0", step: "1" }}
                            value={restrictions["reading-forward"]}
                            onChange={handleChange}
                            name="reading-forward"
                            InputLabelProps={{
                                shrink: restrict["reading-forward"],
                            }}
                            placeholder="Number of Days"
                            InputProps={{
                                endAdornment: (
                                    <FormControl>
                                        <Checkbox
                                            onChange={handleCheckRestrict}
                                            name="reading-forward"
                                            checked={
                                                restrict["reading-forward"]
                                            }
                                        />
                                    </FormControl>
                                ),
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            size="small"
                            disabled={!restrict["connection-back"]}
                            label="Connection Date Back Entry"
                            variant="outlined"
                            type="number"
                            inputProps={{ min: "0", step: "1" }}
                            name="connection-back"
                            value={restrictions["connection-back"]}
                            onChange={handleChange}
                            InputLabelProps={{
                                shrink: restrict["connection-back"],
                            }}
                            placeholder="Number of Days"
                            InputProps={{
                                endAdornment: (
                                    <FormControl>
                                        <Checkbox
                                            onChange={handleCheckRestrict}
                                            name="connection-back"
                                            checked={
                                                restrict["connection-back"]
                                            }
                                        />
                                    </FormControl>
                                ),
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            size="small"
                            disabled={!restrict["connection-forward"]}
                            label="Connection Date Forward Entry"
                            variant="outlined"
                            type="number"
                            inputProps={{ min: "0", step: "1" }}
                            value={restrictions["connection-forward"]}
                            onChange={handleChange}
                            name="connection-forward"
                            InputLabelProps={{
                                shrink: restrict["connection-forward"],
                            }}
                            placeholder="Number of Days"
                            InputProps={{
                                endAdornment: (
                                    <FormControl>
                                        <Checkbox
                                            onChange={handleCheckRestrict}
                                            name="connection-forward"
                                            checked={
                                                restrict["connection-forward"]
                                            }
                                        />
                                    </FormControl>
                                ),
                            }}
                        />
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>
    );
};
