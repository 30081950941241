import { AddOutlined, Close, Done, InfoOutlined } from "@mui/icons-material";
import {
    Box,
    Checkbox,
    Chip,
    Dialog,
    DialogContent,
    Divider,
    IconButton,
    InputAdornment,
    TableCell,
    TableRow,
    TextField,
    Tooltip,
} from "@mui/material";
import { ChangeEvent, useContext, useMemo, useState } from "react";
import { currencyFormatter } from "../../Bill/Cells/Last12Summary";
import { ReadingCtx } from "../AddReadings";

type Props = {
    target: any;
    updateMode?: boolean;
    count: number;
};

type Surcharge = {
    id: number;
    isSurchargePct: boolean;
    name: string;
    value: number;
    calculationStrategy: string;
};

const getUnitPriceAdaptive = (components: any[], units: number) => {
    let unitsAmount = 0;
    let unitsRemaining = units;
    let unitPrice = [];

    const componentsByConsumption: {
        [consumption: string]: any[];
    } = {};

    for (const comp of components) {
        if (
            Object.keys(componentsByConsumption).includes(
                comp.consumption.toString()
            )
        ) {
            componentsByConsumption[comp.consumption].push(comp);
        } else {
            componentsByConsumption[comp.consumption] = [comp];
        }
    }

    const maximumRange = Object.keys(componentsByConsumption)
        .map((c) => parseInt(c))
        .sort((a, b) => b - a)[0];
    const targetSubCategory =
        units > maximumRange ? "-1" : maximumRange.toString();

    const targetComps = componentsByConsumption[targetSubCategory].sort(
        (a, b) => a.start - b.start
    );

    for (const comp of targetComps) {
        if (unitsRemaining === 0) break;

        const targetCost = comp.cost;

        let unitsInRange = 0;

        if (comp.end == -1) {
            unitsInRange = unitsRemaining;
            unitsRemaining = 0;
        } else {
            const compRange = comp.end - Math.max(0, comp.start - 1);

            if (unitsRemaining - compRange < 0) {
                unitsInRange = unitsRemaining;
                unitsRemaining = 0;
            } else {
                unitsInRange = compRange;
                unitsRemaining -= compRange;
            }
        }

        unitsAmount += targetCost * unitsInRange;

        unitPrice.push([unitsInRange, targetCost]);
    }

    return [unitsAmount, unitPrice];
};

// TODO Add adaptive surcharge calculation
const getUnitPrice = (meter: any, units: number) => {
    const intUnits = units;
    const components = meter.priceType.unitChargeRule.components;

    const lastComponent = components.find((c: any) => c.end === -1);

    if (!lastComponent) {
    }

    if (intUnits > lastComponent?.start) {
        return lastComponent?.cost;
    } else {
        const targetComp = components
            .filter((c: any) => c?.end !== -1)
            .find((c: any) => intUnits >= c?.start && intUnits <= c?.end);
        return targetComp?.cost ?? 0;
    }
};

export const ReadingRow = ({ target, updateMode = false, count }: Props) => {
    const { readingsData, setReadingsData } = useContext(ReadingCtx);

    const handleSelect = (ev: any, checked: boolean) => {
        setReadingsData({
            ...readingsData,
            [target.id]: { ...readingsData[target.id], select: checked },
        });
    };

    return readingsData[target.id] ? (
        <TableRow>
            <TableCell>
                <Checkbox
                    size="small"
                    checked={readingsData[target.id].select}
                    onChange={handleSelect}
                />
            </TableCell>
            <StatusCell target={target} />
            <SerialNoCell count={count} />
            <TableCell sx={{ whiteSpace: "nowrap" }}>
                {target.meterNo}
            </TableCell>
            <TableCell>{target.property?.customer?.name}</TableCell>
            <PreviousReadingCell target={target} updateMode={updateMode} />
            <CurrentReadingCell target={target} />
            <LRCell target={target} />
            <ACSCell target={target} />
            <UnitAmountCalcCell target={target} />
            <SurchargeCell target={target} />
            <ConnChargesCell target={target} />
        </TableRow>
    ) : null;
};

const StatusCell = ({ target }: any) => {
    const [open, setOpen] = useState(false);

    const { readingsData } = useContext(ReadingCtx);

    const status = useMemo(
        () => readingsData[target.id]?.feedback?.status,
        [readingsData[target.id]]
    );

    return readingsData[target.id] ? (
        <TableCell>
            <IconButton
                disabled={status === null}
                size="small"
                onClick={
                    status === "success" || status === "error"
                        ? () => setOpen(true)
                        : undefined
                }
            >
                {status === "success" ? (
                    <Done color="success" />
                ) : status === "error" ? (
                    <Close color="error" />
                ) : (
                    <InfoOutlined />
                )}
            </IconButton>

            <Dialog open={open} onClose={() => setOpen(false)}>
                <DialogContent>
                    {readingsData[target.id]?.feedback?.message}
                </DialogContent>
            </Dialog>
        </TableCell>
    ) : null;
};

const PreviousReadingCell = ({ target, updateMode }: any) => {
    return (
        <TableCell>
            {updateMode ? target.lastReadingPrevUnits : target.lastReading ?? 0}
        </TableCell>
    );
};

const CurrentReadingCell = ({ target }: any) => {
    const { readingsData, setReadingsData, mode } = useContext(ReadingCtx);
    const [value, setValue] = useState<any>(
        mode ? readingsData[target.id].units : ""
    );

    return readingsData[target.id] ? (
        <TableCell>
            <TextField
                type="text"
                size="small"
                onChange={(e) => {
                    const value = parseInt(e.target.value) || 0;
                    setValue(value);
                    setReadingsData({
                        ...readingsData,
                        [target.id]: {
                            ...readingsData[target.id],
                            units: value,
                            select: true,
                        },
                    });
                }}
                value={value}
                sx={{ width: "20ch" }}
            />
        </TableCell>
    ) : null;
};

const LRCell = ({ target }: any) => {
    const { readingsData, setReadingsData } = useContext(ReadingCtx);

    return readingsData[target.id] ? (
        <TableCell>
            <TextField
                type="number"
                onChange={
                    target.lineRentEditable
                        ? (e) =>
                              setReadingsData({
                                  ...readingsData,
                                  [target.id]: {
                                      ...readingsData[target.id],
                                      lineRent: e.target.value,
                                      select: true,
                                  },
                              })
                        : undefined
                }
                disabled={!target.lineRentEditable}
                size="small"
                value={readingsData[target.id].lineRent}
                sx={{ width: "12ch" }}
            />
        </TableCell>
    ) : null;
};

const ACSCell = ({ target }: any) => {
    const { readingsData, setReadingsData, setActiveMeter } =
        useContext(ReadingCtx);

    const handleDeleteACS = (idx: number) => {
        const newACS = readingsData[target.id].additionalCharges.filter(
            (x, id) => id != idx
        );

        setReadingsData({
            ...readingsData,
            [target.id]: {
                ...readingsData[target.id],
                additionalCharges: newACS,
            },
        });
    };

    return readingsData[target.id] ? (
        <TableCell>
            <Box display="flex" alignItems="center">
                <Box display="flex" gap="2px">
                    {readingsData[target.id].additionalCharges.map((c, idx) => (
                        <Chip
                            key={idx}
                            variant="filled"
                            size="small"
                            label={c.amount}
                            onDelete={() => handleDeleteACS(idx)}
                        />
                    ))}
                </Box>

                <IconButton
                    size="small"
                    onClick={() => setActiveMeter(target.id)}
                >
                    <AddOutlined />
                </IconButton>
            </Box>
        </TableCell>
    ) : null;
};

const UnitAmountCalcCell = ({ target }: any) => {
    const { readingsData } = useContext(ReadingCtx);

    const calcRows = useMemo(() => {
        if (target.priceType.unitChargeRule.isAdaptive) {
            return getUnitPriceAdaptive(
                target.priceType.unitChargeRule.components,
                readingsData[target.id].units - target.lastReading
            );
        } else {
            return getUnitPrice(
                target,
                readingsData[target.id].units - target.lastReading
            );
        }
    }, [target, readingsData]);

    return (
        <TableCell sx={{ minWidth: "20ch" }}>
            {target.priceType.unitChargeRule?.isAdaptive ? (
                <div style={{ textAlign: "right" }}>
                    {calcRows[1].map(([cost, units]: any[], idx: number) => (
                        <div key={idx}>{`${cost} x ${units} = ${
                            cost * units
                        }`}</div>
                    ))}
                    <Divider />
                    <div>=&nbsp;{calcRows[0]}</div>
                </div>
            ) : (
                <>
                    {readingsData[target.id].units - target.lastReading} x{" "}
                    {calcRows} ={" "}
                    {calcRows *
                        (readingsData[target.id].units - target.lastReading)}
                </>
            )}
        </TableCell>
    );
};

const SurchargeCell = ({ target }: any) => {
    const { readingsData } = useContext(ReadingCtx);

    const surcharge = useMemo(() => {
        if (!target || !(readingsData && readingsData[target.id])) return 0;
        let totalSurcharge = 0;

        if (target.priceType.surcharge.isSurchargePct) {
            const strategies = JSON.parse(
                target.priceType.surcharge.calculationStrategy ?? "[]"
            );
            const pct = target.priceType.surcharge.value / 100;

            for (const strat of strategies) {
                switch (strat) {
                    case "units":
                        const currentUnits = parseInt(
                            readingsData[target.id].units + ""
                        );
                        const prevUnits = target.lastReading;
                        const totalUnits = currentUnits - prevUnits;
                        const unitsAmount =
                            totalUnits * getUnitPrice(target, totalUnits) * pct;
                        totalSurcharge += unitsAmount;

                        console.log(
                            "units amount: ",
                            getUnitPrice(target, totalUnits),
                            "surcharge pct: ",
                            pct
                        );
                        break;

                    case "lr":
                        const meterLR = target.priceType.lineRent.amount;
                        totalSurcharge +=
                            (target.lineRentEditable
                                ? readingsData[target.id].lineRent
                                : meterLR) * pct;
                        break;

                    case "arrears":
                        totalSurcharge += target.arrears * pct;
                        break;

                    case "acs":
                        const acs = readingsData[target.id].additionalCharges;
                        const totalAmount = acs.reduce(
                            (prev, curr) => prev + curr.amount,
                            0
                        );
                        totalSurcharge += totalAmount * pct;
                        break;

                    case "connCharges":
                        totalSurcharge += target.connectionChargesApplied
                            ? 0
                            : target.connectionCharges * pct;
                        break;
                }
            }

            return totalSurcharge;
        } else {
            return target.priceType.surcharge.value;
        }
    }, [target, readingsData]);

    return <TableCell>{currencyFormatter.format(surcharge)}</TableCell>;
};

const ConnChargesCell = ({ target }: any) => {
    return (
        <TableCell>
            {currencyFormatter.format(
                target.connectionChargesApplied ? 0 : target.connectionCharges
            )}
        </TableCell>
    );
};

const ArrearsCell = ({ target }: any) => {
    const { readingsData, setReadingsData } = useContext(ReadingCtx);
    const [enabled, setEnabled] = useState(false);

    const handleChange = (ev: ChangeEvent<HTMLInputElement>) => {
        setReadingsData({
            ...readingsData,
            [target.id]: {
                ...readingsData[target.id],
                arrears: enabled
                    ? Math.max(0, parseFloat(ev.target.value))
                    : null,
            },
        });
    };

    return (
        <TableCell>
            <TextField
                disabled={!enabled}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <Tooltip title="Enable arrears">
                                <Checkbox
                                    size="small"
                                    checked={enabled}
                                    onChange={(ev, checked) => {
                                        handleChange(ev);
                                        setEnabled(checked);
                                    }}
                                />
                            </Tooltip>
                        </InputAdornment>
                    ),
                }}
                type="number"
                variant="outlined"
                sx={{ width: "20ch" }}
                size="small"
                value={readingsData[target.id].arrears}
                onChange={handleChange}
            />
        </TableCell>
    );
};

const SerialNoCell = ({ count }: { count: number }) => {
    return <TableCell>{count}</TableCell>;
};
