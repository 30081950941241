import { Dispatch, SetStateAction, useMemo } from "react";
import { LastRange } from "./LastRange";
import { RuleRange } from "./RuleRange";

type FlatRuleProps = {
    unitChargeRules: UnitChargeRules;
    setUnitChargeRules: Dispatch<SetStateAction<UnitChargeRules>>;
    isAdaptive: boolean;
};

export const FlatRule = ({
    unitChargeRules,
    setUnitChargeRules,
    isAdaptive,
}: FlatRuleProps) => {
    const lastEnd = useMemo(() => {
        const comps = Object.values(unitChargeRules[-1].components);
        return comps[comps.length - 1]?.end ?? 0;
    }, [unitChargeRules[-1].components]);

    const maxStartNCostInRule = useMemo(() => {
        const allStartsNCosts = Object.values(
            unitChargeRules[-1].components
        ).map((item) => ({ start: item.start, cost: item.cost }));

        const allStartsInRule = allStartsNCosts.map((s) => s.start);
        const allCostsInRule = allStartsNCosts.map((c) => c.cost);

        const maxStart = allStartsInRule.reduce(
            (prev, curr) => Math.max(prev, curr),
            0
        );

        const maxCost = allCostsInRule.reduce(
            (prev, curr) => Math.max(prev, curr),
            0
        );

        return { maxCost, maxStart };
    }, [unitChargeRules[-1]]);
    return (
        <>
            {Object.entries(unitChargeRules[-1].components).map((range) => (
                <RuleRange
                    key={range[0]}
                    index={parseInt(range[0])}
                    range={range[1]}
                    unitChargeRules={unitChargeRules}
                    setUnitChargeRules={setUnitChargeRules}
                    isAdaptive={isAdaptive}
                    consumption={-1}
                />
            ))}

            <LastRange
                range={{
                    start: Math.max(
                        maxStartNCostInRule.maxStart + 1,
                        lastEnd + 1
                    ),
                    cost: unitChargeRules[-1].costAfterLastRule,
                }}
                isAdaptive={isAdaptive}
                index={-1}
                consumption={-1}
                unitChargeRules={unitChargeRules}
                setUnitChargeRules={setUnitChargeRules}
                minCost={maxStartNCostInRule.maxCost + 1}
            />
        </>
    );
};
