import { Sync } from "@mui/icons-material";
import { Alert, Box, Button, CircularProgress, Grid } from "@mui/material";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { FormEvent, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { GET, PATCH } from "../../Utilities/BaseService";
import { MultiPermissionAuthorize } from "../../Utilities/MultiPermissionAuthorize";
import UpdatedSearchableInput from "../../Utilities/UpdatedSearchableInput";

import { MultipleLogos } from "./Components/MultipleLogos";
import Range from "./Components/Range";
import { Reorder } from "./Components/ReorderSetting";
import SingleRange from "./Components/SingleRange";
import { TextSetting } from "./Components/TextSetting";

type Filter = {
    district?: number | null;
    village?: number | null;
    hamlet?: number | null;
};

export type Setting = {
    id: number;
    key:
        | "reading-range"
        | "collection-order"
        | "adjustment-order"
        | "termination-threshold";
    label: string;
    IsDefault: boolean;
    value: string;
    district: null | {
        id: number;
        name: string;
    };
    village: null | {
        id: number;
        name: string;
    };
    hamlet: null | {
        id: number;
        name: string;
    };
};

const updateSettings = (updatedSettings: any, filter: Filter) => {
    /* console.log(
        Object.entries(updatedSettings).map((item: any) => ({
            [item[0]]: item[1],
        }))
    ); */

    const dif = Object.entries(filter).filter(
        ([key, value]) => value !== null || value !== "" || value !== "all"
    );

    const regions = Object.fromEntries(dif);

    return PATCH("/settings", {
        settings: Object.entries(updatedSettings).map((entry: any[]) => ({
            key: entry[0],
            value: entry[1],
        })),
        ...regions,
    });

    /*  const payload = {
        settings: Object.entries(updatedSettings).map((item: any) => ({
            [item[0]]: item[1],
        })),
        ...regions,
    };

    return POST("/settings/bulk", payload); */
};
const initialFilter = {
    district: null,
    village: null,
    hamlet: null,
};

const AddSettings = () => {
    const [updatedSettings, setUpdatedSettings] = useState({});
    const [settings, setSettings] = useState<any[]>([]);
    const [filteredSettings, setFilteredSettings] = useState<any[]>([]);
    const [feedback, setFeedback] = useState("");
    const [filter, setFilter] = useState(initialFilter);
    const [clearAll, setClearAll] = useState({
        district: false,
        village: false,
        hamlet: false,
    });

    const queryClient = useQueryClient();
    const location = useLocation();

    const { data: globalSettings } = useQuery(
        ["global-settings"],
        () => GET("/settings"),
        {
            onSuccess(res) {
                setSettings(res.data.settings);
            },
        }
    );

    const { data: filteredSetts } = useQuery(
        ["filtered-settings", filter],
        () =>
            GET(
                "/settings",
                Object.fromEntries(
                    Object.entries(filter).filter(
                        ([region, regionId]: any) =>
                            regionId && regionId !== "all"
                    )
                )
            ),
        {
            // enabled: Boolean(filter.district && filter.district !== "all"),

            onSuccess(res) {
                console.log("Filtered Settings : ", res.data.settings);

                const filteredRows: any[] = res.data.settings.filter(
                    (setting: Setting) => {
                        const isAllRegions = Boolean(
                            filter.district &&
                                filter.district !== "all" &&
                                filter.village &&
                                filter.village !== "all" &&
                                filter.hamlet &&
                                filter.hamlet !== "all"
                        );
                        const isVillage = Boolean(
                            filter.district &&
                                filter.district !== "all" &&
                                filter.village &&
                                filter.village !== "all"
                        );
                        const isDistrict = Boolean(
                            filter.district && filter.district !== "all"
                        );

                        if (isAllRegions) {
                            /*  console.log(
                                "Setting of hamlet level.",
                                "District : ",
                                filter.district,
                                setting.district?.id,
                                "Village : ",
                                filter.village,
                                setting.village?.id,
                                "Hamlet : ",
                                filter.hamlet,
                                setting.hamlet?.id
                            ); */
                            return (
                                filter.district === setting.district?.id &&
                                filter.village === setting.village?.id &&
                                filter.hamlet === setting.hamlet?.id
                            );
                        } else if (isVillage) {
                            // console.log("Setting of all village level.");

                            return (
                                filter.district === setting.district?.id &&
                                filter.village === setting.village?.id &&
                                setting.hamlet === null
                            );
                        } else if (isDistrict) {
                            // console.log("Setting of all district level.");

                            return (
                                filter.district === setting.district?.id &&
                                setting.village === null &&
                                setting.hamlet === null
                            );
                        }
                    }
                );

                setFilteredSettings(filteredRows);

                /* 
                const filteredSettingsKeys = filteredSettings.map(
                    (setting: Setting) => setting.key
                );
                  console.log(
                    "Filtered Rows : ",
                    filteredRows,
                    "Global Settings : ",
                    settings,
                    "Target Settings : ",
                    [
                        ...settings.filter(
                            (s: Setting) =>
                                !filteredSettingsKeys.includes(s.key)
                        ),
                        ...filteredRows,
                    ]
                ); */
            },
        }
    );

    const updateMutation = useMutation(
        ["update-settings"],
        () => updateSettings(updatedSettings, filter),
        {
            onSuccess(res: any) {
                setFeedback(res.data.message);
                queryClient.invalidateQueries(["settings"]);
                queryClient.invalidateQueries(["filtered-settings", filter]);
            },

            onError(err: any) {
                setFeedback(
                    err?.response?.data?.message ||
                        "Failed to update the settings"
                );
            },
        }
    );

    const handleSubmit = (ev: FormEvent<HTMLFormElement>) => {
        ev.preventDefault();

        if (Object.keys(updatedSettings).length > 0) {
            updateMutation.mutate();
        }
    };

    useEffect(() => {
        if (location?.state?.district) {
            setFilter(location.state);
        }
    }, []);

    useEffect(() => {
        if (filteredSettings.length > 0) {
            const filteredSettingsKeys = filteredSettings.map(
                (setting: Setting) => setting.key
            );

            const targetSettings = [
                ...settings.filter(
                    (s: Setting) => !filteredSettingsKeys.includes(s.key)
                ),
                ...filteredSettings,
            ];

            console.log(
                "Target Settings : ",
                targetSettings,
                "Filtered Settings : ",
                filteredSettings
            );

            setSettings(targetSettings);
        } else {
        }
    }, [filteredSettings]);

    useEffect(() => {
        if (filter.district === "all") {
            setClearAll({ district: true, village: true, hamlet: true });
            setFilter(initialFilter);
        }

        if (filter.village === "all") {
            setClearAll({ ...clearAll, village: true, hamlet: true });
            setFilter({ ...filter, village: null, hamlet: null });
        }

        if (filter.hamlet === "all") {
            setClearAll({ ...clearAll, hamlet: true });
            setFilter({ ...filter, hamlet: null });
        }

        return () => {
            setClearAll({ district: false, village: false, hamlet: false });

            queryClient.invalidateQueries(["global-settings"]);
            // queryClient.invalidateQueries(["filtered-settings", filter]);
        };
    }, [filter.district, filter.village, filter.hamlet]);

    return (
        <Box>
            <Grid container mb={3} pt={1} spacing={2}>
                <Grid item xs={12} sm={4}>
                    <UpdatedSearchableInput
                        api="/region/district"
                        label="District"
                        filter={filter}
                        setFilter={setFilter}
                        clearAll={clearAll.district}
                        defaultValue={location.state?.district ?? undefined}
                    />
                </Grid>

                <Grid item xs={12} sm={4}>
                    <UpdatedSearchableInput
                        api="/region/village"
                        label="Village"
                        filter={filter}
                        setFilter={setFilter}
                        dep={filter.district!}
                        params={{
                            district: filter.district,
                        }}
                        clearAll={clearAll.village}
                        defaultValue={location.state?.village ?? undefined}
                    />
                </Grid>

                <Grid item xs={12} sm={4}>
                    <UpdatedSearchableInput
                        api="/region/hamlet"
                        label="Hamlet"
                        filter={filter}
                        setFilter={setFilter}
                        dep={filter.village!}
                        params={
                            filter.village && filter.village !== "all"
                                ? {
                                      village: [filter.village],
                                  }
                                : { village: [0] }
                        }
                        clearAll={clearAll.hamlet}
                        defaultValue={location.state?.hamlet ?? undefined}
                    />
                </Grid>
            </Grid>

            <Box component="form" onSubmit={handleSubmit}>
                {settings.length > 0 && (
                    <>
                        <Range
                            data={settings.find(
                                (row: any) => row.key === "reading-range"
                            )}
                            updatedSettings={updatedSettings}
                            setUpdatedSettings={setUpdatedSettings}
                        />

                        <SingleRange
                            data={settings.find(
                                (r: any) => r.key === "termination-threshold"
                            )}
                            updatedSettings={updatedSettings}
                            setUpdatedSettings={setUpdatedSettings}
                        />

                        <Reorder
                            labels={{
                                lineRent: "Line rent",
                                units: "Units",
                                "additional-charges": "Additional charges",
                            }}
                            setting={settings.find(
                                (setting: any) =>
                                    setting.key === "collection-order"
                            )}
                            name="Collection order"
                            updatedSettings={updatedSettings}
                            setUpdatedSettings={setUpdatedSettings}
                        />

                        <Reorder
                            labels={{
                                lineRent: "Line rent",
                                units: "Units",
                                "additional-charges": "Additional charges",
                            }}
                            setting={settings.find(
                                (setting: any) =>
                                    setting.key === "adjustment-order"
                            )}
                            name="Adjustment order"
                            updatedSettings={updatedSettings}
                            setUpdatedSettings={setUpdatedSettings}
                        />

                        <TextSetting
                            setting={settings.find(
                                (s: any) => s.key === "name"
                            )}
                            updatedSettings={updatedSettings}
                            setUpdatedSettings={setUpdatedSettings}
                        />

                        <TextSetting
                            setting={settings.find(
                                (s: any) => s.key === "address"
                            )}
                            updatedSettings={updatedSettings}
                            setUpdatedSettings={setUpdatedSettings}
                        />

                        <TextSetting
                            setting={settings.find(
                                (s: any) => s.key === "phone"
                            )}
                            updatedSettings={updatedSettings}
                            setUpdatedSettings={setUpdatedSettings}
                        />

                        <TextSetting
                            setting={settings.find(
                                (s: any) => s.key === "mobile"
                            )}
                            updatedSettings={updatedSettings}
                            setUpdatedSettings={setUpdatedSettings}
                        />
                    </>
                )}

                <MultipleLogos />

                {updateMutation.isSuccess ? (
                    <Alert severity="success" sx={{ mb: 1 }}>
                        {feedback}
                    </Alert>
                ) : updateMutation.isError ? (
                    <Alert severity="error" sx={{ mb: 1 }}>
                        {feedback}
                    </Alert>
                ) : null}

                <MultiPermissionAuthorize ops={["UPDATE SETTING"]}>
                    <Button
                        sx={{ my: 2 }}
                        color="secondary"
                        variant="outlined"
                        endIcon={
                            updateMutation.isLoading ? (
                                <CircularProgress size="1rem" />
                            ) : (
                                <Sync />
                            )
                        }
                        type="submit"
                    >
                        update settings
                    </Button>
                </MultiPermissionAuthorize>
            </Box>
        </Box>
    );
};

export default AddSettings;
