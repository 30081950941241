import { AllInclusiveOutlined, ExpandMoreOutlined } from "@mui/icons-material";
import {
    Button,
    Collapse,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { currencyFormatter } from "../../Reading/Bill/Cells/Last12Summary";

const RuleView = ({ activeRow, setActiveRow }: any) => {
    const [open, setOpen] = useState(true);
    const [groupedConsumptions, setGroupedConsumptions] = useState({});
    const [rowOpen, setRowOpen] = useState<any>({ 0: false });

    const theme = useTheme();

    const handleClose = () => {
        setOpen(!open);
        setActiveRow(!activeRow);
    };

    useEffect(() => {
        if (activeRow.isAdaptive) {
            const groupedObjects = activeRow.components.reduce(
                (result: any, object: any) => {
                    const consumption = object.consumption;
                    if (!result[consumption]) {
                        result[consumption] = [];
                    }
                    result[consumption].push(object);
                    return result;
                },
                {}
            );

            setGroupedConsumptions(groupedObjects);
        }
    }, [activeRow.isAdaptive]);

    return (
        <Dialog open={open} onClose={handleClose} fullWidth>
            <DialogTitle>{activeRow.name}</DialogTitle>
            <DialogContent>
                {activeRow.isAdaptive ? (
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>Consumption</TableCell>

                                <TableCell></TableCell>

                                <TableCell>Details</TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {Object.entries(groupedConsumptions).map(
                                ([consumption, rule]: any) => (
                                    <>
                                        <TableRow key={consumption}>
                                            <TableCell>
                                                <Typography
                                                    fontWeight="bold"
                                                    variant="body2"
                                                >
                                                    {consumption == "-1" ? (
                                                        <AllInclusiveOutlined />
                                                    ) : (
                                                        consumption
                                                    )}
                                                </Typography>
                                            </TableCell>

                                            <TableCell></TableCell>

                                            <TableCell>
                                                <IconButton
                                                    size="small"
                                                    onClick={() =>
                                                        setRowOpen({
                                                            ...rowOpen,
                                                            [consumption]:
                                                                !rowOpen[
                                                                    consumption
                                                                ] ?? false,
                                                        })
                                                    }
                                                >
                                                    <ExpandMoreOutlined fontSize="small" />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>

                                        <TableRow>
                                            <TableCell
                                                colSpan={3}
                                                padding="none"
                                            >
                                                <Collapse
                                                    in={rowOpen[consumption]}
                                                >
                                                    <Table size="small">
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell>
                                                                    <Typography variant="body2">
                                                                        Start
                                                                    </Typography>
                                                                </TableCell>

                                                                <TableCell>
                                                                    <Typography variant="body2">
                                                                        End
                                                                    </Typography>
                                                                </TableCell>

                                                                <TableCell>
                                                                    <Typography variant="body2">
                                                                        Cost
                                                                    </Typography>
                                                                </TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {rule.map(
                                                                (r: any) => (
                                                                    <TableRow
                                                                        key={
                                                                            r.id
                                                                        }
                                                                    >
                                                                        <TableCell>
                                                                            {
                                                                                r.start
                                                                            }
                                                                        </TableCell>

                                                                        <TableCell>
                                                                            {r.end ==
                                                                            "-1" ? (
                                                                                <AllInclusiveOutlined color="disabled" />
                                                                            ) : (
                                                                                r.end
                                                                            )}
                                                                        </TableCell>

                                                                        <TableCell>
                                                                            {currencyFormatter.format(
                                                                                r.cost
                                                                            )}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                )
                                                            )}
                                                        </TableBody>
                                                    </Table>
                                                </Collapse>
                                            </TableCell>
                                        </TableRow>
                                    </>
                                )
                            )}
                        </TableBody>
                    </Table>
                ) : (
                    <Table size="small">
                        <TableHead>
                            <TableRow
                                sx={{ bgcolor: theme.palette.action.selected }}
                            >
                                <TableCell>
                                    <Typography
                                        fontWeight="bold"
                                        variant="body2"
                                    >
                                        Start
                                    </Typography>
                                </TableCell>

                                <TableCell>
                                    <Typography
                                        fontWeight="bold"
                                        variant="body2"
                                    >
                                        End
                                    </Typography>
                                </TableCell>

                                <TableCell>
                                    <Typography
                                        fontWeight="bold"
                                        variant="body2"
                                    >
                                        Cost
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {activeRow?.components.length &&
                                activeRow.components.map((component: any) => (
                                    <TableRow key={component.id}>
                                        <TableCell>{component.start}</TableCell>
                                        <TableCell>
                                            {component.end == "-1" ? (
                                                <AllInclusiveOutlined color="disabled" />
                                            ) : (
                                                component.end
                                            )}
                                        </TableCell>
                                        <TableCell>
                                            {currencyFormatter.format(
                                                component.cost
                                            )}
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                )}
            </DialogContent>

            <DialogActions>
                <Button variant="outlined" color="error" onClick={handleClose}>
                    close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default RuleView;
