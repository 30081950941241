import { Add } from "@mui/icons-material";
import {
    Alert,
    Button,
    Card,
    CardContent,
    CardHeader,
    CircularProgress,
    Divider,
    Grid,
    TextField,
} from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { theme } from "../../../Theme/theme";
import { GET, PATCH, POST } from "../../../Utilities/BaseService";
import UpdatedSearchableInput from "../../../Utilities/UpdatedSearchableInput";

type Props = { updateMode?: boolean };
type LineRent = {
    name: string;
    amount: string;
    district: string;
    village: string;
    hamlet: string;
    powerstation: string;
};

const AddLineRent = ({ updateMode = false }: Props) => {
    const [lineRent, setLineRent] = useState({
        name: "",
        amount: "",
        district: "",
        village: "",
        hamlet: "",
        powerstation: "",
    });
    const [feedback, setFeedback] = useState("");
    const location = useLocation();
    const [id, setId] = useState("");
    const [clear, setClear] = useState({
        district: false,
        village: false,
        hamlet: false,
        powerstation: false,
    });
    const [defaultRegions, setDefaultRegions] = useState({
        district: "",
        village: "",
        hamlet: "",
        powerstation: "",
    });

    const getLineRentById = () => {
        return GET(`/line-rent?id=${id}`);
    };
    const { data } = useQuery([`line-rent-${id}`], getLineRentById, {
        enabled: Boolean(id),
        onSuccess(response) {
            if (response.data.rows.length > 0) {
                const _lr = response.data.rows[0];
                setDefaultRegions({
                    district: _lr.district?.id ?? "",
                    village: _lr.village?.id ?? "",
                    hamlet: _lr.hamlet?.id ?? "",
                    powerstation: _lr.powerstation?.id ?? "",
                });

                setLineRent({
                    name: _lr.name,
                    amount: _lr.amount,
                    district: _lr.district?.id ?? "",
                    village: _lr.village?.id ?? "",
                    hamlet: _lr.hamlet?.id ?? "",
                    powerstation: _lr.powerstation?.id ?? "",
                });
            }
        },
        refetchOnMount: true,
    });

    const handleSubmit = (ev: any) => {
        ev.preventDefault();
        {
            !updateMode ? addMutation.mutate() : updateMutation.mutate();
        }
    };

    const cleanLineRent = (lineRent: LineRent) =>
        Object.fromEntries(
            Object.entries(lineRent)
                .map(([k, v]) =>
                    ["district", "village", "hamlet", "powerstation"].includes(
                        k
                    )
                        ? v && v !== "all"
                            ? [k, v]
                            : [k, null]
                        : [k, v]
                )
                .filter((item) => Boolean(item[0]))
        );

    const addMutation = useMutation(
        () => POST("/line-rent", cleanLineRent(lineRent)),
        {
            onSuccess(res) {
                setFeedback(res.data.message);
            },

            onError(err: any) {
                setFeedback(err.response.data.message);
            },
        }
    );

    const updateMutation = useMutation(
        () => PATCH("/line-rent", cleanLineRent(lineRent), { id }),
        {
            onSuccess(res) {
                setFeedback(res.data.message);
            },

            onError(err: any) {
                setFeedback(err.response.data.message);
            },
        }
    );

    useEffect(() => {
        if (updateMode) {
            const params = new URLSearchParams(location.search);
            const _id = params.get("id");

            if (_id) {
                setId(_id);
            }
        }
    }, [updateMode]);

    useEffect(() => {
        if (lineRent.district === "all") {
            setClear({
                district: true,
                village: true,
                hamlet: true,
                powerstation: true,
            });
            setLineRent({
                ...lineRent,
                district: "",
                village: "",
                hamlet: "",
                powerstation: "",
            });
        }

        if (lineRent.village === "all") {
            setClear({ ...clear, village: true, hamlet: true });
            setLineRent({ ...lineRent, village: "", hamlet: "" });
        }

        if (lineRent.hamlet === "all") {
            setClear({ ...clear, hamlet: true });
            setLineRent({ ...lineRent, hamlet: "" });
        }

        if (defaultRegions.district !== lineRent.district) {
            setClear({
                ...clear,
                village: true,
                hamlet: true,
                powerstation: true,
            });

            setDefaultRegions({
                ...defaultRegions,
                district: lineRent.district as any,
            });

            setLineRent({
                ...lineRent,
                village: "",
                hamlet: "",
                powerstation: "",
            });
        }

        return () => {
            setClear({
                district: false,
                village: false,
                hamlet: false,
                powerstation: false,
            });
        };
    }, [lineRent.district, lineRent.village, lineRent.hamlet]);
    return (
        <>
            <Card
                elevation={0}
                sx={{ bgcolor: theme.palette.common.white, mb: "1rem" }}
            >
                <CardHeader
                    title={updateMode ? "update line rent" : "add line rent"}
                    sx={{
                        textTransform: "capitalize",
                        bgcolor: theme.palette.common.white,
                    }}
                />
                <CardContent>
                    <Divider sx={{ mb: 5 }} />

                    <Grid
                        container
                        component="form"
                        onSubmit={handleSubmit}
                        spacing={2}
                    >
                        <Grid item xs={12} md={6} lg={3}>
                            <TextField
                                size="small"
                                name="name"
                                value={lineRent.name}
                                label="Line Rent Name"
                                onChange={(ev) =>
                                    setLineRent({
                                        ...lineRent,
                                        name: ev.target.value,
                                    })
                                }
                                fullWidth
                                type="text"
                                required
                            />
                        </Grid>

                        <Grid item xs={12} md={6} lg={3}>
                            <TextField
                                size="small"
                                name="name"
                                value={lineRent.amount}
                                label="Line Rent Charges"
                                onChange={(ev) =>
                                    setLineRent({
                                        ...lineRent,
                                        amount: ev.target.value,
                                    })
                                }
                                fullWidth
                                type="number"
                                inputProps={{ min: 0 }}
                                required
                            />
                        </Grid>

                        <Grid xs={12} md={6} lg={3} item>
                            <UpdatedSearchableInput
                                api="/region/district"
                                label="District"
                                filter={lineRent}
                                setFilter={setLineRent}
                                clearAll={clear.district}
                                defaultValue={
                                    updateMode &&
                                    (defaultRegions.district as any)
                                }
                            />
                        </Grid>
                        <Grid xs={12} md={6} lg={3} item>
                            <UpdatedSearchableInput
                                api="/region/village"
                                label="Village"
                                filter={lineRent}
                                setFilter={setLineRent}
                                dep={lineRent.district}
                                params={{ district: lineRent.district }}
                                clearAll={clear.village}
                                defaultValue={
                                    updateMode &&
                                    (defaultRegions.village as any)
                                }
                            />
                        </Grid>
                        <Grid xs={12} md={6} lg={3} item>
                            <UpdatedSearchableInput
                                api="/region/hamlet"
                                label="Hamlet"
                                filter={lineRent}
                                setFilter={setLineRent}
                                dep={lineRent.village}
                                params={
                                    lineRent.village &&
                                    lineRent.village !== "all"
                                        ? { village: [lineRent.village] }
                                        : { village: [0] }
                                }
                                clearAll={clear.hamlet}
                                defaultValue={
                                    updateMode && (defaultRegions.hamlet as any)
                                }
                            />
                        </Grid>
                        <Grid xs={12} md={6} lg={3} item>
                            <UpdatedSearchableInput
                                api="/powerstation"
                                label="Powerstation"
                                filter={lineRent}
                                setFilter={setLineRent}
                                dep={lineRent.district}
                                params={{ district: lineRent.district }}
                                clearAll={clear.powerstation}
                                defaultValue={
                                    updateMode &&
                                    (defaultRegions.powerstation as any)
                                }
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                startIcon={<Add />}
                                endIcon={
                                    addMutation.isLoading ? (
                                        <CircularProgress
                                            color="secondary"
                                            size="1rem"
                                        />
                                    ) : (
                                        ""
                                    )
                                }
                                color="secondary"
                                variant="outlined"
                                size="small"
                                type="submit"
                            >
                                {updateMode
                                    ? updateMutation.isLoading
                                        ? "updating line rent..."
                                        : "update line rent"
                                    : addMutation.isLoading
                                    ? "adding line rent..."
                                    : "add line rent"}
                            </Button>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>

            {addMutation.isSuccess || updateMutation.isSuccess ? (
                <Alert severity="success">{feedback}.</Alert>
            ) : addMutation.isError || updateMutation.isError ? (
                <Alert severity="error">{feedback}.</Alert>
            ) : null}
        </>
    );
};

export default AddLineRent;
