import { InfoOutlined } from "@mui/icons-material";
import {
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    useTheme,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useMemo, useState } from "react";
import { GET } from "../../../Utilities/BaseService";
import { currencyFormatter } from "../../Reading/Bill/Cells/Last12Summary";
import { dateFormatter } from "../../Reading/Bill/SingleBill";

const settingsToBillObj = new Map<string, string>(
    Object.entries({
        "additional-charges": "additionalCharges",
        "connection-charges": "connectionCharges",
        lineRent: "lineRent",
        units: "unitsAmount",
        surcharge: "payedSurcharge",
    })
);

const settingsToLabelObj = new Map<string, string>(
    Object.entries({
        "additional-charges": "Additional Charges",
        "connection-charges": "Connection Charges",
        lineRent: "Line Rent",
        units: "Units Amount",
        surcharge: "Surcharge",
    })
);

const ReportBody = ({
    reportData,
    reportDetails,
    selectedReportDetails,
    calculationStrategy,
    printMode = false,
}: any) => {
    const [activeRow, setActiveRow] = useState<any>(null);
    const [settings, setSettings] = useState<any>([]);
    const theme = useTheme();

    const activeRowAmounts = useMemo(() => {
        const initialVal: any = {
            lineRent: 0,
            connectionCharges: 0,
            unitsAmount: 0,
            additionalCharges: 0,
        };
        if (activeRow) {
            let paidAmount = activeRow.paidAmount;
            for (const source of settings) {
                if (paidAmount === 0) break;

                const amount = activeRow[settingsToBillObj.get(source)!];

                if (paidAmount - amount < 0) {
                    initialVal[settingsToBillObj.get(source)!] = paidAmount;
                    paidAmount = 0;
                } else {
                    initialVal[settingsToBillObj.get(source)!] = amount;
                    paidAmount -= amount;
                }
            }
            return initialVal;
        } else {
            return initialVal;
        }
    }, [activeRow]);

    const { data: _settings } = useQuery(["settings"], () => GET("/settings"), {
        onSuccess: (res) => {
            const _collectionOrder = res.data.settings?.find(
                (row: any) => row.key === "collection-order"
            );

            setSettings([...JSON.parse(_collectionOrder?.value), "surcharge"]);
        },
    });

    const columnTotal = (data: any, column: any) => {
        if (column === "arrears") {
            return data.slice(-1)[0]?.arrears ?? 0;
        }

        const columns = data
            .map((row: any) => row[column])
            .reduce((prev: any, curr: any) => prev + curr, 0);

        return columns;
    };

    const showDetails = (row: any) => {
        console.log(row);
        setActiveRow(row);
    };

    const calculateTotalSurcharge = (surcharge: number, row: any): number => {
        let surchargeCompsSum = 0;

        for (const item of calculationStrategy) {
            if (item === "units") {
                surchargeCompsSum += row.units;
            }

            if (item === "lr") {
                surchargeCompsSum += row.lineRent;
            }

            if (item === "acs") {
                surchargeCompsSum += row.additionalCharges;
            }

            if (item === "connCharges") {
                surchargeCompsSum += row.connectionCharges.amount ?? 0;
            }

            if (item === "arrears") {
                surchargeCompsSum += row.arrears;
            }
        }

        return surchargeCompsSum;
    };

    return (
        <>
            {/* <TableHead>
                <TableRow>
                    <TableCell
                        colSpan={selectedReportDetails.length + 1}
                    ></TableCell>
                </TableRow>

                <TableRow>
                    <TableCell sx={{ px: 1, py: 0.5 }}>
                        <Typography fontWeight="medium">Date</Typography>
                    </TableCell>

                    {selectedReportDetails.map((col: any, key: number) => (
                        <TableCell key={key} sx={{ px: 1, py: 0.5 }}>
                            <Typography fontWeight="medium">
                                {reportDetails[col]}
                            </Typography>
                        </TableCell>
                    ))}
                    <TableCell></TableCell>
                </TableRow>
            </TableHead> */}

            <TableBody>
                {reportData.map((row: any, idx: number) => (
                    <TableRow key={idx}>
                        <TableCell sx={{ px: 1, py: 0.5 }}>
                            {row.date}
                        </TableCell>
                        {selectedReportDetails.map((col: any, key: number) => (
                            <TableCell
                                key={key}
                                sx={{
                                    px: 1,
                                    py: 0.5,
                                    color: "grey",
                                    border:
                                        "1px solid " +
                                        theme.palette.common.white,
                                }}
                            >
                                {col === "units" ? (
                                    row[col]
                                ) : col === "paidAmount" ? (
                                    <span
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                        }}
                                    >
                                        {currencyFormatter.format(row[col])}

                                        {!printMode && (
                                            <IconButton
                                                size="small"
                                                onClick={() => showDetails(row)}
                                            >
                                                <InfoOutlined fontSize="small" />
                                            </IconButton>
                                        )}
                                    </span> /* : col === "surcharge" ? (
                                    calculateTotalSurcharge(row[col], row)
                                ) */
                                ) : (
                                    currencyFormatter.format(row[col])
                                )}
                            </TableCell>
                        ))}
                    </TableRow>
                ))}

                <TableRow>
                    <TableCell sx={{ px: 1 }}>
                        <Typography fontWeight="bolder">Total</Typography>
                    </TableCell>
                    {selectedReportDetails.map((col: any, key: number) => (
                        <TableCell
                            key={key}
                            sx={{ px: 1, py: 2, fontWeight: "bolder" }}
                        >
                            {col === "units"
                                ? columnTotal(reportData, col)
                                : currencyFormatter.format(
                                      columnTotal(reportData, col)
                                  )}
                        </TableCell>
                    ))}
                </TableRow>
            </TableBody>

            <Dialog
                open={Boolean(activeRow)}
                onClose={() => setActiveRow(null)}
                fullWidth
            >
                <DialogTitle>Collections</DialogTitle>

                <DialogContent>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Date</TableCell>
                                <TableCell>Amount</TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {activeRow?.collections.map((coll: any) => (
                                <TableRow key={coll.id}>
                                    <TableCell>
                                        {coll?.collectionDate
                                            ? dateFormatter.format(
                                                  new Date(coll.collectionDate)
                                              )
                                            : "Unknown"}
                                    </TableCell>
                                    <TableCell>
                                        {currencyFormatter.format(coll?.amount)}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default ReportBody;
