import { ExpandMoreOutlined } from "@mui/icons-material";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import { useMemo } from "react";
import { numberFormatter } from "../../Reading/Bill/Cells/Last12Summary";

type Props = {
    selectedConnTypes: "all" | number[];
    meterReport?: MeterCountReport;
};

export const ConnTypeCounts = ({ selectedConnTypes, meterReport }: Props) => {
    const total = useMemo(
        () =>
            meterReport
                ? meterReport
                      .filter(
                          (connType) =>
                              selectedConnTypes === "all" ||
                              selectedConnTypes.includes(connType.typeId)
                      )
                      .reduce(
                          (prev, connType) =>
                              prev + parseInt(connType.typeCount),
                          0
                      )
                : 0,
        [selectedConnTypes, meterReport]
    );

    if (meterReport) {
        return (
            <Accordion
                elevation={0}
                variant="outlined"
                sx={{ my: 2 }}
                TransitionProps={{ unmountOnExit: true }}
            >
                <AccordionSummary expandIcon={<ExpandMoreOutlined />}>
                    <Box display="flex" gap={1} alignItems="center">
                        <Typography>Total No. Of Connection: </Typography>
                        <Typography>{numberFormatter.format(total)}</Typography>
                    </Box>
                </AccordionSummary>
                <AccordionDetails>
                    <Table size="small">
                        <TableHead>
                            <TableRow
                                sx={{ "& > *": { bgcolor: "common.white" } }}
                            >
                                <TableCell>Connection Type</TableCell>
                                <TableCell>No. Of Connections</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {meterReport
                                .filter(
                                    (connType) =>
                                        selectedConnTypes === "all" ||
                                        selectedConnTypes.includes(
                                            connType.typeId
                                        )
                                )
                                .map((connType) => (
                                    <TableRow key={connType.typeId}>
                                        <TableCell>
                                            {connType.typeName}
                                        </TableCell>
                                        <TableCell>
                                            {numberFormatter.format(
                                                parseInt(connType.typeCount)
                                            )}
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </AccordionDetails>
            </Accordion>
        );
    } else {
        return null;
    }
};
