import { Add } from "@mui/icons-material";
import {
    Alert,
    Button,
    Card,
    CardContent,
    CardHeader,
    Checkbox,
    CircularProgress,
    Divider,
    FormControlLabel,
    Grid,
    MenuItem,
    TextField,
} from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { theme } from "../../../Theme/theme";
import { AppContext } from "../../../Utilities/AppContext";
import { GET, PATCH, POST } from "../../../Utilities/BaseService";
import UpdatedSearchableInput from "../../../Utilities/UpdatedSearchableInput";

type DateConstraintVariant =
    | "collection-back"
    | "collection-forward"
    | "reading-back"
    | "reading-forward"
    | "connection-back"
    | "connection-forward";

type DateConstraint = { key: DateConstraintVariant; value: string };

const addMeter = (meter: any) => POST("/customer/meter", meter);

const updateMeter = (meter: any, id: number) => {
    return PATCH("/customer/meter", meter, { id });
};

const AddMeter = ({ updateMode }: any) => {
    const [id, setId] = useState("");
    const [meter, setMeter] = useState({
        meterNo: "",
        connectionCharges: 0,
        connectionDate: "",
        property: "",
        connectionType: "",
        powerstation: "",
        district: "",
        village: "",
        priceType: "",
        hamlet: "",
        status: "active",
        lineRentEditable: false,
    });
    const [feedback, setFeedback] = useState();
    const [details, setDetails] = useState<any>({ property: { id: "" } });
    const [clearAll, setClearAll] = useState({
        district: false,
        village: false,
        hamlet: false,
        powerstation: false,
    });
    const [defaultRegions, setDefaultRegions] = useState({
        district: "",
        village: "",
        hamlet: "",
        powerstation: "",
    });
    const { user } = useContext(AppContext);
    const location = useLocation();

    const getMeterById = () => {
        return GET(`/customer/meter?id=${id}`);
    };

    const handleChange = (ev: any) => {
        const value = ev.target.value;

        setMeter({
            ...meter,
            [ev.target.name]: value,
        });
    };

    const { data: meterById } = useQuery([`Meter`], getMeterById, {
        enabled: Boolean(id),
        onSuccess(response) {
            if (response.data.rows.length > 0) {
                const _m = response.data.rows[0];

                setDefaultRegions({
                    district: _m.district.id,
                    village: _m.village.id,
                    hamlet: _m.hamlet.id,
                    powerstation: _m.powerstation.id,
                });

                setMeter({
                    meterNo: _m.meterNo,
                    connectionCharges: _m.connectionCharges,
                    connectionDate: _m.connectionDate,
                    priceType: _m.priceType.id,
                    lineRentEditable: _m.lineRentEditable,
                    property: _m.property.id,
                    district: _m.district.id,
                    village: _m.village.id,
                    hamlet: _m.hamlet.id,
                    status: _m.status,
                    connectionType: _m.connectionType.id,
                    powerstation: _m.powerstation.id,
                });
            }
        },
        refetchOnMount: true,
    });

    const handleSubmit = (ev: any) => {
        ev.preventDefault();

        if (!updateMode) {
            addMutation.mutate({
                ...Object.fromEntries(
                    Object.entries(meter).filter(([k, v]) => v && v !== "all")
                ),
                lineRentEditable: meter.lineRentEditable,
                connectionCharges: meter.connectionCharges,
            });
        } else {
            updateMutation.mutate();
        }
    };

    const addMutation = useMutation(addMeter, {
        onSuccess(res) {
            return setFeedback(res.data.message);
        },
        onError(err: any) {
            return setFeedback(err.response.data.message);
        },
    });

    const updateMutation = useMutation(
        () =>
            updateMeter(
                {
                    ...Object.fromEntries(
                        Object.entries(meter).filter(
                            ([k, v]) => v && v !== "all"
                        )
                    ),
                    lineRentEditable: meter.lineRentEditable,
                },
                id as any
            ),
        {
            onSuccess(res) {
                setFeedback(res.data.message);
            },
            onError(err: any) {
                return setFeedback(err.response.data.message);
            },
        }
    );

    function manipulateDate(
        currentDate: Date,
        daysToAddOrSubtract: number,
        op: "add" | "sub"
    ) {
        const dateObj = new Date(currentDate);

        const millisecondsInADay = 24 * 60 * 60 * 1000;

        let newDate: Date;
        switch (op) {
            case "add":
                newDate = new Date(
                    dateObj.getTime() + daysToAddOrSubtract * millisecondsInADay
                );
                break;

            case "sub":
                newDate = new Date(
                    dateObj.getTime() - daysToAddOrSubtract * millisecondsInADay
                );
                break;
        }

        return newDate;
    }

    function getRestrictedDate(target: DateConstraintVariant) {
        const hasDateRestriction = Boolean(
            user.constraints.find((c: DateConstraint) => c.key === target)
        );

        if (hasDateRestriction) {
            console.log("has date constraints: ", hasDateRestriction);
            const days: number = user.constraints.find(
                (c: DateConstraint) => c.key === target
            ).value;

            switch (target) {
                case "connection-back":
                    const backDate = manipulateDate(new Date(), days, "sub")
                        .toISOString()
                        .slice(0, 10);

                    console.log(backDate, target);

                    return backDate;

                case "connection-forward":
                    const forwardDate = manipulateDate(new Date(), days, "add")
                        .toISOString()
                        .slice(0, 10);

                    console.log(target);

                    return forwardDate;
            }
        } else return undefined;
    }

    useEffect(() => {
        if (updateMode) {
            const params = new URLSearchParams(location.search);
            const _id = params.get("id");

            if (_id) {
                setId(_id);
            }
        }
    }, [updateMode]);

    useEffect(() => {
        if (meter.district === "all") {
            setClearAll({
                district: true,
                village: true,
                hamlet: true,
                powerstation: true,
            });
        }

        if (meter.village === "all") {
            setClearAll({ ...clearAll, village: true, hamlet: true });
        }

        if (meter.hamlet === "all") {
            setClearAll({ ...clearAll, hamlet: true });
        }

        if (defaultRegions.district !== meter.district) {
            setClearAll({
                ...clearAll,
                village: true,
                hamlet: true,
                powerstation: true,
            });

            setDefaultRegions({ ...defaultRegions, district: meter.district });
        }

        return () => {
            setClearAll({
                district: false,
                village: false,
                hamlet: false,
                powerstation: false,
            });
        };
    }, [meter.district, meter.village, meter.hamlet]);

    useEffect(() => {
        setMeter({
            ...meter,
            property: details.property?.id ?? "",
            district: details.property?.district?.id ?? "",
            village: details.property?.village?.id ?? "",
            hamlet: details.property?.hamlet?.id ?? "",
        });
    }, [details.property]);

    return (
        <>
            <Card
                elevation={0}
                sx={{ bgcolor: theme.palette.common.white, mb: "1rem" }}
            >
                <CardHeader
                    title={updateMode ? "update meter " : "add meter"}
                    titleTypographyProps={{ color: "gray" }}
                    sx={{
                        textTransform: "capitalize",
                        bgcolor: theme.palette.common.white,
                    }}
                />

                <CardContent>
                    <Divider sx={{ mb: 5 }} />

                    <Grid
                        container
                        component="form"
                        onSubmit={handleSubmit}
                        spacing={1}
                    >
                        <Grid item xs={12} md={6} lg={4}>
                            <TextField
                                size="small"
                                name="meterNo"
                                value={meter.meterNo}
                                label="Meter No."
                                onChange={handleChange}
                                fullWidth
                                required
                                disabled={updateMode}
                            />
                        </Grid>

                        <Grid item xs={12} md={6} lg={4}>
                            <TextField
                                label="Connection Charges"
                                size="small"
                                type="number"
                                value={meter.connectionCharges}
                                onChange={(e) =>
                                    setMeter({
                                        ...meter,
                                        connectionCharges: parseInt(
                                            e.target.value
                                        ) as any,
                                    })
                                }
                                fullWidth
                            />
                        </Grid>

                        <Grid item xs={12} md={6} lg={4}>
                            {/* <MultiPermissionAuthorize
                                ops={
                                    updateMode
                                        ? ["UPDATE CONNECTION_DATE"]
                                        : ["CREATE CONNECTION_DATE"]
                                }
                            > */}
                            <TextField
                                size="small"
                                name="connectionDate"
                                value={meter.connectionDate}
                                label="Connection Date"
                                onChange={handleChange}
                                fullWidth
                                type="date"
                                required
                                InputLabelProps={{ shrink: true }}
                                disabled={updateMode}
                                inputProps={{
                                    min: getRestrictedDate("connection-back"),
                                    max: getRestrictedDate(
                                        "connection-forward"
                                    ),
                                }}
                            />
                            {/* </MultiPermissionAuthorize> */}
                        </Grid>

                        <Grid item xs={12} md={6} lg={4}>
                            <UpdatedSearchableInput
                                label="Property"
                                api="/customer/property"
                                filter={details}
                                setFilter={setDetails}
                                defaultValue={
                                    updateMode &&
                                    meterById?.data.rows[0].property?.id
                                }
                                disabled={updateMode}
                                getFullRecord
                            />
                        </Grid>

                        <Grid item xs={12} md={6} lg={4}>
                            <UpdatedSearchableInput
                                label="Connection Type"
                                _name="connectionType"
                                api="/connection"
                                filter={meter}
                                setFilter={setMeter}
                                defaultValue={
                                    updateMode &&
                                    meterById?.data.rows[0].connectionType?.id
                                }
                            />
                        </Grid>

                        <Grid item xs={12} md={6} lg={4}>
                            <UpdatedSearchableInput
                                label="Price Type"
                                _name="priceType"
                                api="/priceType"
                                filter={meter}
                                setFilter={setMeter}
                                required
                                defaultValue={
                                    updateMode &&
                                    meterById?.data.rows[0].priceType?.id
                                }
                            />
                        </Grid>

                        {updateMode && (
                            <>
                                <Grid item xs={12} md={6} lg={4}>
                                    <UpdatedSearchableInput
                                        api="/region/district"
                                        filter={meter}
                                        setFilter={setMeter}
                                        label="District"
                                        defaultValue={
                                            updateMode &&
                                            defaultRegions.district
                                        }
                                        clearAll={clearAll.district}
                                        required
                                    />
                                </Grid>

                                <Grid item xs={12} md={6} lg={4}>
                                    <UpdatedSearchableInput
                                        api="/region/village"
                                        filter={meter}
                                        setFilter={setMeter}
                                        label="Village"
                                        defaultValue={
                                            (updateMode &&
                                                defaultRegions.village) ||
                                            undefined
                                        }
                                        dep={meter.district}
                                        params={{
                                            district: meter.district,
                                        }}
                                        clearAll={clearAll.village}
                                        required
                                    />
                                </Grid>

                                <Grid item xs={12} md={6} lg={4}>
                                    <UpdatedSearchableInput
                                        api="/region/hamlet"
                                        filter={meter}
                                        setFilter={setMeter}
                                        label="Hamlet"
                                        defaultValue={
                                            (updateMode &&
                                                defaultRegions.hamlet) ||
                                            undefined
                                        }
                                        dep={meter.village}
                                        params={
                                            meter.village &&
                                            meter.village !== "all"
                                                ? {
                                                      village: [meter.village],
                                                  }
                                                : { village: [0] }
                                        }
                                        clearAll={clearAll.hamlet}
                                        required
                                    />
                                </Grid>

                                <Grid item xs={12} md={6} lg={4}>
                                    <TextField
                                        select
                                        size="small"
                                        fullWidth
                                        value={meter.status}
                                        label="Status"
                                        onChange={(e) =>
                                            setMeter({
                                                ...meter,
                                                status: e.target.value,
                                            })
                                        }
                                    >
                                        <MenuItem value="active">
                                            Active
                                        </MenuItem>

                                        <MenuItem value="inactive">
                                            Inactive
                                        </MenuItem>

                                        {/* <MenuItem value="replaced">
                                            Replaced
                                        </MenuItem> */}
                                    </TextField>
                                </Grid>
                            </>
                        )}

                        <Grid item xs={12} md={6} lg={4}>
                            <UpdatedSearchableInput
                                label="Powerstation"
                                api="/powerstation"
                                filter={meter}
                                setFilter={setMeter}
                                defaultValue={
                                    updateMode && defaultRegions.powerstation
                                }
                                clearAll={clearAll.powerstation}
                                required
                                dep={meter.district}
                                params={{
                                    district: meter.district ?? undefined,
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} md={6} lg={4}>
                            <FormControlLabel
                                label="Is line rent editable?"
                                control={
                                    <Checkbox
                                        checked={meter.lineRentEditable}
                                        onChange={(e) =>
                                            setMeter({
                                                ...meter,
                                                lineRentEditable:
                                                    e.target.checked,
                                            })
                                        }
                                    />
                                }
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Button
                                startIcon={<Add />}
                                endIcon={
                                    addMutation.isLoading ? (
                                        <CircularProgress
                                            color="secondary"
                                            size="1rem"
                                        />
                                    ) : (
                                        ""
                                    )
                                }
                                color="secondary"
                                variant="outlined"
                                size="small"
                                type="submit"
                            >
                                {updateMode ? "update" : "add"} meter
                            </Button>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>

            {addMutation.isSuccess || updateMutation.isSuccess ? (
                <Alert severity="success">{feedback}.</Alert>
            ) : addMutation.isError || updateMutation.isError ? (
                <Alert severity="error">{feedback}.</Alert>
            ) : null}
        </>
    );
};

export default AddMeter;
