import axios from "axios";
import { baseAPI, notConnectedMessage } from "./constants";

export class S3Service {
    static api = `${baseAPI}/s3`;

    static async getS3ByKey(Key: string) {
        try {
            const presignedURL = await axios.post(
                this.api + "/psurl",
                { Key, command: "getObject" },
                { withCredentials: true }
            );
            return [presignedURL.data, null];
        } catch (err: any) {
            return [null, err?.response?.data?.message || notConnectedMessage];
        }
    }

    static async uploadToS3(Key: string, body: File | Blob) {
        const newFile = new File([body], Key, { type: body.type });
        try {
            const presigned = await axios.post(
                this.api + "/psurl",
                {
                    Key,
                    command: "putObject",
                    Body: newFile,
                },
                { withCredentials: true }
            );
            const url = presigned.data.url;

            const response = await axios.put(url, body, {
                headers: { "Content-Type": body.type },
            });

            return ["File uploaded successfully", null];
        } catch (err: any) {
            return [
                null,
                err.response?.data?.message || "Something went wrong",
            ];
        }
    }

    static async deleteS3ByKey(key: string, clientSpecific = true) {
        try {
            const response = await axios.delete(this.api + "/by-key", {
                withCredentials: true,
                params: { key, clientSpecific },
            });
            return [response.data, null];
        } catch (err: any) {
            return [null, err.response?.data?.message || notConnectedMessage];
        }
    }

    static async deleteS3ByKeys(keys: string[]) {
        try {
            for (const key of keys) {
                await this.deleteS3ByKey(key);
            }
            return ["Delete successfull", null];
        } catch (err) {
            return [null, "Delete failed"];
        }
    }

    static constructKey(folders: string[], fname: string): string {
        return (folders.join("/") + "/" + fname).slice(1, -1);
    }
}
