import { Add } from "@mui/icons-material";
import {
    Alert,
    Button,
    Card,
    CardContent,
    CardHeader,
    CircularProgress,
    Divider,
    Grid,
    MenuItem,
    Snackbar,
    TextField,
    useTheme,
} from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { GET, PATCH, POST } from "../../Utilities/BaseService";
import SearchableInput from "../Dashboard/Components/SearchableInput";

const addPowerStation = (powerstation: any) =>
    POST("/powerstation", powerstation);

const updatePowerStation = ({ powerstation, id }: any) => {
    return PATCH("/powerstation", powerstation, { id });
};

const AddPowerStation = ({ updateMode }: any) => {
    const [id, setId] = useState("");
    const [powerstation, setPowerstation] = useState({
        name: "",
        district: "",
    });
    const [filter, setFilter] = useState({ district: "" });
    const [feedback, setFeedback] = useState();
    const location = useLocation();
    const theme = useTheme();

    const getPowerStationById = () => {
        return GET(`/powerstation?id=${id}`);
    };

    const { data: powerstationById } = useQuery(
        [`powerStation`],
        getPowerStationById,
        {
            enabled: Boolean(id),
            onSuccess(response) {
                if (response.data.rows.length > 0) {
                    const _ps = response.data.rows[0];

                    setPowerstation({ name: _ps.name, district: _ps.district });
                }
            },
            refetchOnMount: true,
        }
    );

    const handleSubmit = (ev: any) => {
        ev.preventDefault();
        {
            !updateMode
                ? addMutation.mutate(powerstation)
                : updateMutation.mutate({ powerstation, id });
        }
    };

    const addMutation = useMutation(addPowerStation, {
        onSuccess(res) {
            return setFeedback(res.data.message);
        },
        onError(err: any) {
            return setFeedback(err.response.data.message);
        },
    });

    const updateMutation = useMutation(updatePowerStation, {
        onSuccess(res) {
            setFeedback(res.data.message);
        },
        onError(err: any) {
            return setFeedback(err.response.data.message);
        },
    });

    useEffect(() => {
        if (updateMode) {
            const params = new URLSearchParams(location.search);
            const _id = params.get("id");

            if (_id) {
                setId(_id);
            }
        }
    }, [updateMode]);

    useEffect(() => {
        setPowerstation({ ...powerstation, district: filter.district });
    }, [filter]);

    return (
        <>
            <Card
                elevation={0}
                sx={{ bgcolor: theme.palette.common.white, mb: "1rem" }}
            >
                <CardHeader
                    title={
                        updateMode
                            ? "update power station"
                            : "add power station"
                    }
                    titleTypographyProps={{ color: "gray" }}
                    sx={{
                        textTransform: "capitalize",
                        bgcolor: theme.palette.common.white,
                    }}
                />

                <CardContent>
                    <Divider sx={{ mb: 5 }} />

                    <Grid
                        container
                        component="form"
                        onSubmit={handleSubmit}
                        spacing={3}
                    >
                        <Grid item xs={12} md={6}>
                            <TextField
                                size="small"
                                name="name"
                                value={powerstation.name}
                                label="Power Station Name"
                                onChange={(ev) =>
                                    setPowerstation({
                                        ...powerstation,
                                        name: ev.target.value,
                                    })
                                }
                                fullWidth
                                type="text"
                                required
                                //   color={addMutation.isSuccess ? "success" : "primary"}
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <SearchableInput
                                label="District"
                                api="/region/district"
                                filter={filter}
                                setFilter={setFilter}
                                multiple={false}
                                defaultValue={
                                    updateMode &&
                                    powerstationById?.data.rows[0].district
                                }
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Button
                                startIcon={<Add />}
                                endIcon={
                                    addMutation.isLoading ? (
                                        <CircularProgress
                                            color="secondary"
                                            size="1rem"
                                        />
                                    ) : (
                                        ""
                                    )
                                }
                                color="secondary"
                                variant="outlined"
                                size="small"
                                type="submit"
                            >
                                {updateMode ? "update" : "add"} power station
                            </Button>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>

            {addMutation.isSuccess || updateMutation.isSuccess ? (
                <Alert severity="success">{feedback}.</Alert>
            ) : addMutation.isError || updateMutation.isError ? (
                <Alert severity="error">{feedback}.</Alert>
            ) : null}
        </>
    );
};

export default AddPowerStation;
