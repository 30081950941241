import {
    Add,
    Close,
    CloseOutlined,
    EditOffOutlined,
    EditOutlined,
    FilterAltOutlined,
} from "@mui/icons-material";
import {
    Alert,
    Box,
    Button,
    Card,
    Chip,
    CircularProgress,
    Grid,
    IconButton,
    MenuItem,
    TextField,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { FormEvent, useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../../../Utilities/AppContext";
import { GET } from "../../../Utilities/BaseService";
import { MultiPermissionAuthorize } from "../../../Utilities/MultiPermissionAuthorize";
import UpdatedSearchableInput from "../../../Utilities/UpdatedSearchableInput";
import { getSelectedFilters } from "../../Customer/Property/Property";
import { getReportTemplates } from "../../Payment/Payment";
import BaseTable from "../../Table/BaseTable";
import SearchBar from "../../Table/Components/SearchBar";
import {
    currencyFormatter,
    numberFormatter,
} from "../Bill/Cells/Last12Summary";
import { dateFormatter, monthFormatter } from "../Bill/SingleBill";

export const readings = ({ pagination, filter }: any) => {
    return GET("/reading", {
        page: pagination.page + 1,
        limit: pagination.limit,
        ...Object.fromEntries(
            Object.entries(filter).filter(
                (entry) => entry[1] && entry[1] !== "all"
            )
        ),
    });
};

type ReportTemplate = {
    id: number;
    key: string;
    value: string[];
};

const initialClearAll = {
    district: false,
    village: false,
    hamlet: false,
};

const initialDefaultRegions = {
    district: "",
    village: "",
    hamlet: "",
};

const Reading = () => {
    const [rowsCount, setRowsCount] = useState(0);
    const [pagination, setPagination] = useState<any>({ page: 0, limit: 100 });
    const [intermediateFilter, setIntermediateFilter] = useState<any>({});
    const [filter, setFilter] = useState<any>({});
    const [billingMonth, setBillingMonth] = useState("");
    const [feedback, setFeedback] = useState<any>("");
    const [showFilterSelectionInfo, setShowFilterSelectionInfo] =
        useState(true);
    const [reportTemplates, setReportTemplates] = useState<ReportTemplate[]>(
        []
    );
    const [selectedFilters, setSelectedFilters] = useState({});
    const [clearAll, setClearAll] = useState(initialClearAll);
    const [defaultRegions, setDefaultRegions] = useState(initialDefaultRegions);
    const { user } = useContext(AppContext);
    const theme = useTheme();
    const mdDown = useMediaQuery(theme.breakpoints.down("md"));

    const { data, isFetching: isLoading } = useQuery(
        ["readings", pagination.page, pagination.limit, filter],
        () => readings({ pagination, filter }),
        {
            enabled: Object.values(filter).filter((x) => Boolean(x)).length > 0,
            onSuccess(res) {
                if (res.data) {
                    setRowsCount(res.data.count);

                    const _selectedFills = getSelectedFilters(
                        res.data.rows[0],
                        ["district", "village", "hamlet", "powerstation"],
                        filter,
                        {
                            district: { label: "District", valueKey: "name" },
                            village: { label: "Village", valueKey: "name" },
                            hamlet: { label: "Hamlet", valueKey: "name" },
                            powerstation: {
                                label: "Power Station",
                                valueKey: "name",
                            },
                        }
                    );

                    setSelectedFilters(() => _selectedFills);
                }
            },
            onError(res: any) {
                setFeedback(res.response.data.message);
            },
        }
    );

    const { data: reportTemps } = useQuery(
        ["report-templates"],
        () => getReportTemplates(user.id, "Readings Report"),
        {
            onSuccess(res) {
                setReportTemplates(() =>
                    res.data.rows.map((item: any) => ({
                        id: item.id,
                        key: item.key,
                        value: JSON.parse(item.value),
                    }))
                );
            },
        }
    );

    const handleSubmit = (ev: FormEvent<HTMLFormElement>) => {
        ev.preventDefault();
        setPagination((p: any) => ({ ...p, page: 0 }));
        const [year, month] = intermediateFilter.billingMonth?.split("-") ?? [
            "",
            "",
        ];
        setFilter({
            ...intermediateFilter,
            ...(intermediateFilter.billingMonth
                ? { month, year, billingMonth: undefined }
                : {}),
        });
    };

    useEffect(() => {
        if (billingMonth) {
            const [year, month] = billingMonth.split("-") ?? ["", ""];

            setIntermediateFilter({
                ...intermediateFilter,
                year,
                month: parseInt(month),
            });
        }
    }, [billingMonth]);

    useEffect(() => {
        if (intermediateFilter.district === "all") {
            setClearAll({
                district: true,
                village: true,
                hamlet: true,
            });

            setIntermediateFilter({
                district: "",
                village: "",
                hamlet: "",
            });
        }

        if (intermediateFilter.village === "all") {
            setClearAll({ ...clearAll, village: true, hamlet: true });
        }

        if (intermediateFilter.hamlet === "all") {
            setClearAll({ ...clearAll, hamlet: true });
        }

        if (defaultRegions.district !== intermediateFilter.district) {
            setClearAll({
                ...clearAll,
                village: true,
                hamlet: true,
            });

            setDefaultRegions({
                ...defaultRegions,
                district: intermediateFilter.district,
            });
        }

        return () => {
            setClearAll({
                district: false,
                village: false,
                hamlet: false,
            });
        };
    }, [
        intermediateFilter.district,
        intermediateFilter.village,
        intermediateFilter.hamlet,
    ]);

    const createSummary = (cols: string[]) => {
        const summaryRow = ["Total"];

        let consumedUnits = 0;
        let lr = 0;
        let totalAmount = 0;
        let surcharge = 0;

        for (const row of data?.data.rows) {
            consumedUnits += row["currentUnits"] - row["prevUnits"];
            lr += row["lineRent"];
            totalAmount += row["totalAmount"];
            surcharge += row["applicableSurcharge"];
        }

        for (const col of cols) {
            switch (col) {
                case "consumedUnits":
                    summaryRow.push(numberFormatter.format(consumedUnits));
                    break;

                case "lineRent":
                    summaryRow.push(currencyFormatter.format(lr));
                    break;

                case "totalAmount":
                    summaryRow.push(currencyFormatter.format(totalAmount));
                    break;

                case "applicableSurcharge":
                    summaryRow.push(currencyFormatter.format(surcharge));
                    break;

                default:
                    summaryRow.push("");
            }
        }

        if (user.ops.includes("DELETE READING")) {
            summaryRow.push("");
        }

        return summaryRow;
    };

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                height: mdDown ? "auto" : `calc(100vh - 96px)`,
            }}
        >
            <Box>
                <Card
                    elevation={0}
                    sx={{
                        bgcolor: theme.palette.common.white,
                        p: 2,
                        mb: "1rem",
                    }}
                >
                    <Grid
                        container
                        alignItems="center"
                        justifyContent="space-between"
                        spacing={1.5}
                        mb={1.5}
                    >
                        <Grid item xs={12} md="auto">
                            <Typography variant="h5" color="GrayText">
                                Readings
                            </Typography>
                        </Grid>

                        <Grid
                            item
                            xs={12}
                            md={6}
                            container
                            spacing={1.5}
                            justifyContent="flex-end"
                        >
                            <Grid item flex={1}>
                                <SearchBar
                                    filter={intermediateFilter}
                                    setFilter={setIntermediateFilter}
                                />
                            </Grid>

                            <Grid item xs={12} md="auto">
                                <MultiPermissionAuthorize
                                    ops={["CREATE READING"]}
                                >
                                    <Link
                                        to="/bills/readings/add"
                                        style={{ textDecoration: "none" }}
                                    >
                                        <Button
                                            sx={{ height: "100%" }}
                                            variant="outlined"
                                            color="secondary"
                                            startIcon={<Add />}
                                            fullWidth
                                        >
                                            add reading
                                        </Button>
                                    </Link>
                                </MultiPermissionAuthorize>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        spacing={1.5}
                        component="form"
                        onSubmit={handleSubmit}
                        mb={2}
                    >
                        <MultiPermissionAuthorize ops={["READ DISTRICT"]}>
                            <Grid item xs={12} md={6} lg={3}>
                                <UpdatedSearchableInput
                                    label="District"
                                    api="/region/district"
                                    filter={intermediateFilter}
                                    setFilter={setIntermediateFilter}
                                    clearAll={clearAll.district}
                                />
                            </Grid>
                        </MultiPermissionAuthorize>

                        <MultiPermissionAuthorize ops={["READ VILLAGE"]}>
                            <Grid item xs={12} md={6} lg={3}>
                                <UpdatedSearchableInput
                                    label="Village"
                                    api="/region/village"
                                    filter={intermediateFilter}
                                    setFilter={setIntermediateFilter}
                                    dep={intermediateFilter.district}
                                    params={{
                                        district: intermediateFilter.district,
                                    }}
                                    clearAll={clearAll.village}
                                />
                            </Grid>
                        </MultiPermissionAuthorize>

                        <MultiPermissionAuthorize ops={["READ HAMLET"]}>
                            <Grid item xs={12} md={6} lg={3}>
                                <UpdatedSearchableInput
                                    label="Hamlet"
                                    api="/region/hamlet"
                                    filter={intermediateFilter}
                                    setFilter={setIntermediateFilter}
                                    dep={intermediateFilter.village}
                                    params={
                                        intermediateFilter.village &&
                                        intermediateFilter.village !== "all"
                                            ? {
                                                  village: [
                                                      intermediateFilter.village,
                                                  ],
                                              }
                                            : { village: [0] }
                                    }
                                    multiple={false}
                                    clearAll={clearAll.hamlet}
                                />
                            </Grid>
                        </MultiPermissionAuthorize>

                        <MultiPermissionAuthorize ops={["READ METER"]}>
                            <Grid item xs={12} md={6} lg={3}>
                                <TextField
                                    size="small"
                                    name="meterNo"
                                    label="Meter no."
                                    fullWidth
                                    value={intermediateFilter.meterNo}
                                    onChange={(e) =>
                                        setIntermediateFilter({
                                            ...intermediateFilter,
                                            meterNo: e.target.value,
                                        })
                                    }
                                />
                            </Grid>
                        </MultiPermissionAuthorize>

                        <Grid item xs={12} md={6} lg={3}>
                            <TextField
                                size="small"
                                name="billingMonth"
                                label="Billing month"
                                type="month"
                                fullWidth
                                value={billingMonth}
                                onChange={(e) =>
                                    setBillingMonth(e.target.value)
                                }
                                InputLabelProps={{ shrink: true }}
                                InputProps={{
                                    endAdornment:
                                        intermediateFilter?.billingMonth ? (
                                            <IconButton
                                                size="small"
                                                onClick={() =>
                                                    setBillingMonth("")
                                                }
                                            >
                                                <CloseOutlined />
                                            </IconButton>
                                        ) : null,
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} md={6} lg={3}>
                            <TextField
                                select
                                size="small"
                                label="Status"
                                fullWidth
                                value={intermediateFilter?.completed ?? ""}
                                InputProps={
                                    intermediateFilter.completed && {
                                        endAdornment: (
                                            <IconButton
                                                size="small"
                                                sx={{ mr: theme.spacing(2) }}
                                                onClick={() =>
                                                    setIntermediateFilter({
                                                        ...intermediateFilter,
                                                        completed: "",
                                                    })
                                                }
                                            >
                                                <Close fontSize="small" />
                                            </IconButton>
                                        ),
                                    }
                                }
                                onChange={(e) =>
                                    setIntermediateFilter({
                                        ...intermediateFilter,
                                        completed: e.target.value,
                                    })
                                }
                            >
                                <MenuItem value="1">Completed</MenuItem>
                                <MenuItem value="0">Pending</MenuItem>
                            </TextField>
                        </Grid>

                        <MultiPermissionAuthorize ops={["READ PROPERTY"]}>
                            <Grid item xs={12} md={6} lg={3}>
                                <UpdatedSearchableInput
                                    label="Property"
                                    api="/customer/property"
                                    filter={intermediateFilter}
                                    setFilter={setIntermediateFilter}
                                    dep={[
                                        intermediateFilter?.district,
                                        intermediateFilter?.village,
                                        intermediateFilter?.hamlet,
                                    ]}
                                    params={Object.fromEntries(
                                        Object.entries(
                                            intermediateFilter
                                        ).filter(
                                            ([k, v]) =>
                                                [
                                                    "district",
                                                    "village",
                                                    "hamlet",
                                                ].includes(k) &&
                                                v &&
                                                v !== "all"
                                        )
                                    )}
                                />
                            </Grid>
                        </MultiPermissionAuthorize>

                        <Grid item xs={12} md={6} lg={3}>
                            <Button
                                fullWidth
                                color="secondary"
                                variant="outlined"
                                sx={{ height: "100%" }}
                                type="submit"
                                startIcon={
                                    isLoading ? (
                                        <CircularProgress size="1rem" />
                                    ) : (
                                        <FilterAltOutlined />
                                    )
                                }
                            >
                                filter
                            </Button>
                        </Grid>
                    </Grid>
                </Card>

                {!Boolean(
                    Object.values(filter).filter((x) => Boolean(x)).length
                ) && <Alert severity="info">Please select a filter.</Alert>}

                {data?.data.rows.length == 0 && (
                    <Alert severity="info">Found 0 readings.</Alert>
                )}
            </Box>

            <Box sx={{ height: "55%", mt: 2 }}>
                <BaseTable
                    headers={{
                        id: "Ref. No.",
                        meter: "Meter",
                        customer: "Customer",
                        property: "Property",
                        prevUnits: "Prev. Units",
                        currentUnits: "Curr. Units",
                        consumedUnits: "Consumed Units",
                        issueDate: "Issue Date",
                        dueDate: "Due Date",
                        readingDate: "Reading Date",
                        completed: "Status",
                        district: "District",
                        village: "Village",
                        hamlet: "Hamlet",
                        // powerstation: "Powerstation",
                        additionalCharges: "Additional Charges",
                        connectionCharges: "Connection Charges",
                        payedSurcharge: "Paid Surcharge",
                        totalAmount: "Total Amount",
                        lineRent: "Line Rent",
                        applicableSurcharge: "Surcharge",
                        billMonth: "Billing Month",
                        edit: "Edit",
                        origin: "Origin",
                    }}
                    defaultSelectedHeaders={[
                        "id",
                        "meter",
                        "billMonth",
                        "prevUnits",
                        "currentUnits",
                        "consumedUnits",
                        "lineRent",
                        "applicableSurcharge",
                        "totalAmount",
                        "dueDate",
                        "surcharge",
                        "completed",
                        "edit",
                    ]}
                    load
                    data={data?.data.rows.map((row: any, serialNo: number) => ({
                        ...row,
                        billingMonth: monthFormatter.format(
                            new Date(row.year, row.month, 1)
                        ),
                        issueDate: dateFormatter.format(
                            new Date(row.issueDate)
                        ),
                        dueDate: dateFormatter.format(new Date(row.dueDate)),
                        readingDate: dateFormatter.format(
                            new Date(row.readingDate)
                        ),
                        applicableSurcharge: currencyFormatter.format(
                            row.applicableSurcharge
                        ),
                        meter: row.meter?.meterNo,
                        additionalCharges: row?.additionalCharges.length ? (
                            <AdditionalChargesCell
                                charges={row.additionalCharges}
                            />
                        ) : (
                            0
                        ),
                        connectionCharges: currencyFormatter.format(
                            row?.meter?.connectionCharges ?? 0
                        ),
                        property: row.property?.name,
                        completed: row.completed ? (
                            <Chip
                                sx={{ width: "10ch" }}
                                label="Done"
                                size="small"
                                color="success"
                                variant="outlined"
                            />
                        ) : (
                            <Chip
                                sx={{ width: "10ch" }}
                                label="Pending"
                                size="small"
                                color="warning"
                                variant="outlined"
                            />
                        ),

                        origin:
                            row.origin == "web-app" ? (
                                <Chip
                                    sx={{ width: "10ch" }}
                                    label="Desktop"
                                    size="small"
                                    variant="outlined"
                                />
                            ) : (
                                <Chip
                                    sx={{ width: "10ch" }}
                                    label="Mobile app"
                                    size="small"
                                    variant="outlined"
                                />
                            ),

                        district: row?.district?.name ?? "",
                        village: row?.village?.name ?? "",
                        hamlet: row?.hamlet?.name ?? "",
                        // powerstation: row?.powerstation?.name ?? "",

                        payedSurcharge: currencyFormatter.format(
                            row.payedSurcharge
                        ),
                        consumedUnits: row.currentUnits - row.prevUnits,
                        totalAmount: currencyFormatter.format(row.totalAmount),
                        customer: row.property?.customer?.name,
                        lineRent: currencyFormatter.format(row.lineRent),
                        billMonth: monthFormatter.format(
                            new Date(row.year ?? 0, row.month - 1 ?? 0, 1)
                        ),
                        edit: row.completed ? (
                            <IconButton size="small" disabled>
                                <EditOffOutlined fontSize="small" />
                            </IconButton>
                        ) : (
                            <MultiPermissionAuthorize ops={["UPDATE READING"]}>
                                <Link
                                    to={`/bills/readings/update?id=${row.id}`}
                                    state={{
                                        ...row,
                                        bulkUpadte: false,
                                    }}
                                >
                                    <IconButton size="small">
                                        <EditOutlined fontSize="small" />
                                    </IconButton>
                                </Link>
                            </MultiPermissionAuthorize>
                        ),
                    }))}
                    _printables={[
                        "id",
                        "meter",
                        "customer",
                        "property",
                        "prevUnits",
                        "currentUnits",
                        "consumedUnits",
                        "issueDate",
                        "dueDate",
                        "readingDate",
                        "completed",
                        "district",
                        "village",
                        "hamlet",
                        "additionalCharges",
                        "connectionCharges",
                        "payedSurcharge",
                        "totalAmount",
                        "lineRent",
                        "applicableSurcharge",
                        "billMonth",
                    ]}
                    delEndPoint="/reading"
                    reportName="Readings Report"
                    feedback={feedback}
                    setFeedback={setFeedback}
                    rowsCount={rowsCount}
                    pagination={pagination}
                    setPagination={setPagination}
                    isLoading={isLoading}
                    permissions={{
                        edit: [""],
                        delete: ["DELETE READING"],
                    }}
                    queryKey={[
                        "readings",
                        pagination.page,
                        pagination.limit,
                        intermediateFilter,
                    ]}
                    reportTemplates={reportTemplates}
                    hasReportTemplates
                    width={1920}
                    reportDetails={selectedFilters}
                    summaryRow={createSummary}
                />
            </Box>
        </Box>
    );
};

export default Reading;

type ACCellProps = {
    charges: { amount: number; head: { editable: boolean; amount: number } }[];
};

const AdditionalChargesCell = ({ charges }: ACCellProps) => {
    const targetCharges = charges.map((charge) =>
        charge.head.editable ? charge.amount : charge.head.amount
    );

    return (
        <span style={{ display: "flex" }}>
            {targetCharges.map((charge, idx) => (
                <div key={idx}>
                    {charge}&nbsp;
                    {idx === targetCharges.length - 1 ? "" : " + "}&nbsp;
                </div>
            ))}
            &nbsp;= &nbsp;{targetCharges.reduce((prev, curr) => prev + curr, 0)}
        </span>
    );
};
