import { VisibilityOutlined } from "@mui/icons-material";
import {
    Box,
    Dialog,
    DialogContent,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from "@mui/material";
import { useEffect, useState } from "react";

type Props = { amounts: string[]; components: string[]; adjs: string[] };

const labels: { [key: string]: string } = {
    lineRent: "Line Rent",
    unitsAmount: "Units Amount",
};

const CollectionDetails = ({ amounts, components, adjs }: Props) => {
    const [open, setOpen] = useState(false);
    const [details, setDetails] = useState({});

    const calculateDetails = (
        amounts: string[],
        adjs: string[],
        components: string[]
    ) => {
        const newDetails = new Map();

        for (const [i, comp] of Object.entries(components)) {
            if (newDetails.has(comp)) {
                newDetails.set(comp, {
                    amount:
                        newDetails.get(comp)["amount"] +
                        parseFloat(amounts[parseInt(i)]),
                    adj:
                        newDetails.get(comp)["adj"] +
                        parseFloat(adjs[parseInt(i)]),
                });
            } else {
                newDetails.set(comp, {
                    amount: parseFloat(amounts[parseInt(i)]),
                    adj: parseFloat(adjs[parseInt(i)]),
                });
            }
        }

        setDetails(Object.fromEntries(Array.from(newDetails.entries())));
    };

    useEffect(() => {
        calculateDetails(amounts, adjs, components);
    }, [amounts, adjs, components]);

    return (
        <Box>
            <IconButton size="small" onClick={() => setOpen(true)}>
                <VisibilityOutlined fontSize="small" />
            </IconButton>

            <Dialog open={open} onClose={() => setOpen(false)} fullWidth>
                <DialogContent>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>Component</TableCell>
                                <TableCell>Amount</TableCell>
                                <TableCell>Adjustment</TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {Object.entries(details).map(([k, v]: any[]) => (
                                <TableRow key={k}>
                                    <TableCell>{labels[k]}</TableCell>

                                    <TableCell>{v.amount ?? 0}</TableCell>

                                    <TableCell>{v.adj ?? 0}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </DialogContent>
            </Dialog>
        </Box>
    );
};

export default CollectionDetails;
