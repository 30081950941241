import { Add, FilterAltOutlined } from "@mui/icons-material";
import {
    Alert,
    Button,
    CircularProgress,
    Grid,
    Paper,
    TextField,
    Typography,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { FormEvent, useState } from "react";
import { Link } from "react-router-dom";
import { GET } from "../../Utilities/BaseService";
import {
    filterSelectionMessage,
    hasValue,
    noDataMessage,
} from "../../Utilities/constants";
import { MultiPermissionAuthorize } from "../../Utilities/MultiPermissionAuthorize";
import UpdatedSearchableInput from "../../Utilities/UpdatedSearchableInput";
import BaseTable from "../Table/BaseTable";

type UnitConsumption = {
    id: number;
    powerstation: { id: number; name: string };
    generatedUnits: number;
    consumedUnits: number;
    month: number;
    year: number;
};

type UnitConsumptionFilter = {
    powerstation?: number | string;
    month?: number | string;
    year?: number | string;
};

const useUnitConsumption = (
    filter: UnitConsumptionFilter,
    fetchTryCount: number
) => {
    const [data, setData] = useState<UnitConsumption[]>([]);
    const [count, setCount] = useState(0);
    const [pagination, setPagination] = useState({ page: 0, limit: 100 });
    const [status, setStatus] = useState<
        "idle" | "loading" | "success" | "error"
    >("idle");
    const [message, setMessage] = useState("");

    const { isFetching: isLoading } = useQuery(
        [
            "unit-consumption",
            ...Object.values(filter),
            ...Object.values(pagination),
            fetchTryCount,
        ],
        () =>
            GET("/unit-consumption", {
                ...filter,
                page: pagination.page + 1,
                limit: pagination.limit,
            }),
        {
            enabled: fetchTryCount > 0,
            onSuccess({ data }) {
                setData(
                    data.rows.map((x: any) => ({
                        ...x,
                        powerstationName: x.powerstation.name,
                        deletionId: x.id,
                    }))
                );
                setCount(data.count);
                setStatus("success");
                setMessage(data.message);

                console.log(data.message);
            },
            onError({
                response: {
                    data: { message },
                },
            }) {
                setCount(0);
                setData([]);
                setMessage(message);

                setStatus("error");

                console.log(message);
            },
        }
    );

    const changePage = (page: number) => {
        setPagination((pg) => ({ ...pg, page }));
    };

    const changeLimit = (limit: number) => {
        setPagination((pg) => ({ ...pg, limit }));
    };

    return {
        data,
        count,
        pagination,
        message,
        status,
        isLoading,
        changePage,
        changeLimit,
    };
};

export const UnitConsumptionList = () => {
    const [fetchTryCount, setFetchTryCount] = useState(0);
    const [filter, setFilter] = useState<UnitConsumptionFilter>({
        powerstation: "",
        month: "",
        year: "",
    });
    const [finalFilter, setFinalFilter] = useState<UnitConsumptionFilter>({});
    const { data, count, pagination, changePage, changeLimit, isLoading } =
        useUnitConsumption(finalFilter, fetchTryCount);

    const handleFilter = (ev: FormEvent<HTMLFormElement>) => {
        ev.preventDefault();

        setFetchTryCount((n) => n + 1);
        setFinalFilter(
            Object.fromEntries(
                Object.entries(filter).filter((entry: any[]) =>
                    Boolean(entry[1])
                )
            )
        );
    };

    return (
        <Grid container spacing={1} component="form" onSubmit={handleFilter}>
            <Grid
                item
                container
                xs={12}
                spacing={1}
                component={Paper}
                sx={{ bgcolor: "common.white", my: 1, ml: 1, pb: 1, pr: 1.5 }}
                variant="outlined"
            >
                <Grid item xs={12} md={8}>
                    <Typography color="textSecondary" variant="h5">
                        Unit consumption
                    </Typography>
                </Grid>

                <Grid item xs={12} md={4}>
                    <MultiPermissionAuthorize ops={["CREATE UNIT_CONSUMPTION"]}>
                        <Link
                            to="/unit-consumption/add"
                            style={{ textDecoration: "none" }}
                        >
                            <Button
                                fullWidth
                                variant="outlined"
                                color="secondary"
                                endIcon={<Add />}
                            >
                                Add Consumption
                            </Button>
                        </Link>
                    </MultiPermissionAuthorize>
                </Grid>

                <MultiPermissionAuthorize ops={["READ POWERSTATION"]}>
                    <Grid item xs={12} md={4}>
                        <UpdatedSearchableInput
                            api="/powerstation"
                            filter={filter}
                            setFilter={setFilter}
                            label="Powerstation"
                        />
                    </Grid>
                </MultiPermissionAuthorize>

                <Grid item xs={12} md={4}>
                    <TextField
                        size="small"
                        fullWidth
                        label="Month"
                        type="month"
                        InputLabelProps={{ shrink: true }}
                        onChange={(ev) => {
                            const [year, month] = ev.target.value.split("-");
                            setFilter({ ...filter, month, year });
                        }}
                    />
                </Grid>

                <Grid item xs={12} md={4}>
                    <Button
                        type="submit"
                        sx={{ height: "100%" }}
                        fullWidth
                        variant="outlined"
                        color="secondary"
                        // disabled={isLoading}
                        endIcon={
                            isLoading ? (
                                <CircularProgress
                                    size="1rem"
                                    color="secondary"
                                />
                            ) : (
                                <FilterAltOutlined />
                            )
                        }
                    >
                        Filter
                    </Button>
                </Grid>
            </Grid>

            {data.length > 0 ? (
                <Grid item xs={12}>
                    <BaseTable
                        data={data}
                        rowsCount={count}
                        headers={{
                            id: "ID",
                            powerstationName: "Powerstation",
                            generatedUnits: "Generated Units",
                            consumedUnits: "Consumed Units",
                        }}
                        defaultSelectedHeaders={[
                            "id",
                            "powerstationName",
                            "generatedUnits",
                            "consumedUnits",
                        ]}
                        delEndPoint="/unit-consumption"
                        isLoading={isLoading}
                        pagination={pagination}
                        permissions={{
                            edit: [""],
                            delete: ["DELETE UNIT_CONSUMPTION"],
                        }}
                        queryKey={[
                            "unit-consumption",
                            ...Object.values(finalFilter),
                            ...Object.values(pagination),
                            fetchTryCount,
                        ]}
                        reportName="Unit consumption report"
                        setPagination={(newPagination) => {
                            changePage(newPagination.page);
                            changeLimit(newPagination.limit);
                        }}
                        load
                    />
                </Grid>
            ) : hasValue(filter) ? (
                <Alert severity="info">{noDataMessage}</Alert>
            ) : (
                <Alert severity="info">{filterSelectionMessage}</Alert>
            )}
        </Grid>
    );
};
