import { Grid } from "@mui/material";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import UpdatedSearchableInput from "../../../Utilities/UpdatedSearchableInput";

const initialClearAll = {
    district: false,
    village: false,
    hamlet: false,
    powerstation: false,
};

const initialRegions = {
    district: "",
    village: "",
    hamlet: "",
    powerstation: "",
};

const Regions = ({
    regionsData,
    setRegionsData,
}: {
    regionsData: any;
    setRegionsData: Dispatch<SetStateAction<any>>;
}) => {
    const [regions, setRegions] = useState(initialRegions);
    const [defaultRegions, setDefaultRegions] = useState(initialRegions);
    const [clearAll, setClearAll] = useState(initialClearAll);

    useEffect(() => {
        if (regions.district === "all") {
            setClearAll({
                district: true,
                village: true,
                hamlet: true,
                powerstation: true,
            });

            setRegions({
                district: "",
                village: "",
                hamlet: "",
                powerstation: "",
            });
        }

        if (regions.village === "all") {
            setClearAll({ ...clearAll, village: true, hamlet: true });
        }

        if (regions.hamlet === "all") {
            setClearAll({ ...clearAll, hamlet: true });
        }

        if (defaultRegions.district !== regions.district) {
            setClearAll({
                ...clearAll,
                village: true,
                hamlet: true,
                powerstation: true,
            });

            setDefaultRegions({
                ...defaultRegions,
                district: regions.district,
            });
        }

        return () => {
            setClearAll({
                district: false,
                village: false,
                hamlet: false,
                powerstation: false,
            });
        };
    }, [regions.district, regions.village, regions.hamlet]);

    useEffect(() => {
        setRegionsData(regions);
    }, [regions]);

    return (
        <Grid item container xs={12} spacing={2}>
            <Grid item xs={12} sm={6} md={3}>
                <UpdatedSearchableInput
                    label="District"
                    api="/region/district"
                    filter={regions}
                    setFilter={setRegions}
                    clearAll={clearAll.district}
                />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
                <UpdatedSearchableInput
                    label="Village"
                    api="/region/village"
                    dep={regions.district}
                    params={{ district: regions.district }}
                    filter={regions}
                    setFilter={setRegions}
                    clearAll={clearAll.village}
                />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
                <UpdatedSearchableInput
                    label="Hamlet"
                    api="/region/hamlet"
                    filter={regions}
                    setFilter={setRegions}
                    dep={regions.village}
                    params={
                        regions.village && regions.village !== "all"
                            ? { village: [regions.village] }
                            : { village: [0] }
                    }
                    clearAll={clearAll.hamlet}
                />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
                <UpdatedSearchableInput
                    label="Powerstation"
                    api="/powerstation"
                    filter={regions}
                    setFilter={setRegions}
                    dep={regions.district}
                    params={{ district: regions.district }}
                    clearAll={clearAll.powerstation}
                />
            </Grid>
        </Grid>
    );
};

export default Regions;
