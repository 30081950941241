import { Add, FilterAltOutlined } from "@mui/icons-material";
import {
    Alert,
    Box,
    Button,
    Card,
    Grid,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { AppContext } from "../../../Utilities/AppContext";
import { GET } from "../../../Utilities/BaseService";
import {
    filterSelectionMessage,
    hasValue,
    noDataMessage,
} from "../../../Utilities/constants";
import { MultiPermissionAuthorize } from "../../../Utilities/MultiPermissionAuthorize";
import UpdatedSearchableInput from "../../../Utilities/UpdatedSearchableInput";
import { getReportTemplates } from "../../Payment/Payment";
import BaseTable from "../../Table/BaseTable";
import SearchBar from "../../Table/Components/SearchBar";

type Filter = {
    search: string;
    customer: number | "" | "all";
    district: number | "" | "all";
    village: number | "" | "all";
    hamlet: number | "" | "all";
    powerstation: number | "" | "all";
    id?: number;
};

const initialFilter: Filter = {
    search: "",
    customer: "",
    district: "",
    village: "",
    hamlet: "",
    powerstation: "",
};

const initialClear = {
    district: false,
    village: false,
    hamlet: false,
    powerstation: false,
};

export const getSelectedFilters = (
    firstRow: { [key: string]: any } = {},
    keysToSelect: string[],
    filter: { [key: string]: any } = {},
    filterLabelsNValueKey: {
        [key: string]: { label: string; valueKey: string };
    } = {}
) => {
    if (firstRow) {
        const selectedFilters = Object.fromEntries(
            Object.entries(filter).filter(
                ([k, v]) => v && v !== "all" && keysToSelect.includes(k)
            )
        );

        const targetFilters = Object.keys(selectedFilters).reduce(
            (prev, curr) => ({
                ...prev,
                [curr]: {
                    label: filterLabelsNValueKey[curr].label,
                    value: firstRow[curr][filterLabelsNValueKey[curr].valueKey],
                },
            }),
            {}
        );

        return targetFilters;
    } else return {};
};

const Property = () => {
    const [rowsCount, setRowsCount] = useState(0);
    const [pagination, setPagination] = useState<any>({ page: 0, limit: 100 });
    const [filter, setFilter] = useState(initialFilter);
    const [selectedFilters, setSelectedFilters] = useState({});
    const [feedback, setFeedback] = useState("");
    const [reportTemplates, setReportTemplates] = useState<string[]>([]);
    const { user } = useContext(AppContext);
    const [clearAll, setClearAll] = useState(initialClear);
    const [changeCount, setChangeCount] = useState(0);
    const [propertyId, setPropertyId] = useState<number | null>(null);
    const [intermediateFilter, setIntermediateFilter] =
        useState<Filter>(initialFilter);
    const theme = useTheme();
    const mdDown = useMediaQuery(theme.breakpoints.down("md"));
    const location = useLocation();

    const getProperty = () => {
        return GET("/customer/property", {
            page: pagination.page + 1,
            limit: pagination.limit,
            ...Object.fromEntries(
                Object.entries(filter).filter(([k, v]) => v && v !== "all")
            ),
        });
    };

    const { data, isLoading } = useQuery(
        ["property", pagination.page, pagination.limit, filter],
        getProperty,
        {
            enabled: Boolean(changeCount),
            onSuccess(res) {
                if (res.data) {
                    setRowsCount(res.data.count);

                    const _selectedFills = getSelectedFilters(
                        res.data.rows[0],
                        ["district", "village", "hamlet", "powerstation"],
                        filter,
                        {
                            district: { label: "District", valueKey: "name" },
                            village: { label: "Village", valueKey: "name" },
                            hamlet: { label: "Hamlet", valueKey: "name" },
                            powerstation: {
                                label: "Power Station",
                                valueKey: "name",
                            },
                        }
                    );

                    console.log(_selectedFills);

                    setSelectedFilters(() => _selectedFills);
                }
            },
        }
    );

    const { data: reportTemps } = useQuery(
        ["report-templates", rowsCount],
        () => getReportTemplates(user.id, "Properties Report"),
        {
            onSuccess(res) {
                setReportTemplates(() =>
                    res.data.rows.map((item: any) => ({
                        key: item.key,
                        value: JSON.parse(item.value),
                    }))
                );
            },
        }
    );

    useEffect(() => {
        if (intermediateFilter.district === "all") {
            setClearAll({
                district: true,
                village: true,
                hamlet: true,
                powerstation: true,
            });
            setIntermediateFilter(initialFilter);
        }

        if (intermediateFilter.village === "all") {
            setClearAll({ ...clearAll, village: true, hamlet: true });
            setIntermediateFilter({
                ...intermediateFilter,
                village: "",
                hamlet: "",
            });
        }

        if (intermediateFilter.hamlet === "all") {
            setClearAll({ ...clearAll, hamlet: true });
            setIntermediateFilter({ ...intermediateFilter, hamlet: "" });
        }

        return () => {
            setClearAll({
                district: false,
                village: false,
                hamlet: false,
                powerstation: false,
            });
        };
    }, [
        intermediateFilter.district,
        intermediateFilter.village,
        intermediateFilter.hamlet,
    ]);

    useEffect(() => {
        if (changeCount) {
            setFilter({ ...filter, ...intermediateFilter });
        }
    }, [changeCount]);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const _id = params.get("id");

        if (_id) {
            setPropertyId(parseInt(_id));

            setIntermediateFilter({
                ...intermediateFilter,
                id: parseInt(_id),
            });

            setChangeCount(changeCount + 1);
        }
    }, [location.pathname]);

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                height: mdDown ? "auto" : `calc(100vh - 96px)`,
            }}
        >
            <Card
                elevation={0}
                sx={{
                    bgcolor: theme.palette.common.white,
                    p: 2,
                    mb: "1rem",
                }}
            >
                <Grid
                    container
                    mb={2}
                    justifyContent="space-between"
                    spacing={1.5}
                >
                    <Grid item xs={12} md="auto">
                        <Typography variant="h5" color="GrayText">
                            Properties
                        </Typography>
                    </Grid>

                    <Grid
                        item
                        container
                        flex={1}
                        justifyContent="flex-end"
                        spacing={1.5}
                        xs={12}
                        md={6}
                    >
                        <Grid item flex={1}>
                            <SearchBar
                                filter={intermediateFilter}
                                setFilter={setIntermediateFilter}
                            />
                        </Grid>

                        <Grid item xs={12} md="auto">
                            <MultiPermissionAuthorize ops={["CREATE PROPERTY"]}>
                                <Link
                                    to="/customer/property/add"
                                    style={{ textDecoration: "none" }}
                                >
                                    <Button
                                        fullWidth
                                        sx={{ height: "100%" }}
                                        variant="outlined"
                                        color="secondary"
                                        startIcon={<Add />}
                                    >
                                        add property
                                    </Button>
                                </Link>
                            </MultiPermissionAuthorize>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container spacing={1.5}>
                    <MultiPermissionAuthorize ops={["READ DISTRICT"]}>
                        <Grid item xs={12} sm={6} md={6} lg={3}>
                            <UpdatedSearchableInput
                                label="District"
                                api="/region/district"
                                filter={intermediateFilter}
                                setFilter={setIntermediateFilter}
                                clearAll={clearAll.district}
                            />
                        </Grid>
                    </MultiPermissionAuthorize>

                    <MultiPermissionAuthorize ops={["READ VILLAGE"]}>
                        <Grid item xs={12} sm={6} md={6} lg={3}>
                            <UpdatedSearchableInput
                                label="Village"
                                api="/region/village"
                                filter={intermediateFilter}
                                setFilter={setIntermediateFilter}
                                dep={intermediateFilter.district}
                                params={{
                                    district: intermediateFilter.district,
                                }}
                                clearAll={clearAll.village}
                            />
                        </Grid>
                    </MultiPermissionAuthorize>

                    <MultiPermissionAuthorize ops={["READ HAMLET"]}>
                        <Grid item xs={12} sm={6} lg={3}>
                            <UpdatedSearchableInput
                                label="Hamlet"
                                api="/region/hamlet"
                                filter={intermediateFilter}
                                setFilter={setIntermediateFilter}
                                dep={intermediateFilter.village}
                                params={
                                    intermediateFilter.village &&
                                    intermediateFilter.village !== "all"
                                        ? {
                                              village: [
                                                  intermediateFilter.village,
                                              ],
                                          }
                                        : {
                                              village: [
                                                  intermediateFilter.village,
                                              ],
                                          }
                                }
                                clearAll={clearAll.hamlet}
                            />
                        </Grid>
                    </MultiPermissionAuthorize>

                    <MultiPermissionAuthorize ops={["READ POWERSTATION"]}>
                        <Grid item xs={12} sm={6} md={6} lg={3}>
                            <UpdatedSearchableInput
                                label="Powerstation"
                                api="/powerstation"
                                filter={intermediateFilter}
                                setFilter={setIntermediateFilter}
                                dep={intermediateFilter.district}
                                params={{
                                    district: intermediateFilter.district,
                                }}
                                clearAll={clearAll.powerstation}
                            />
                        </Grid>
                    </MultiPermissionAuthorize>

                    <MultiPermissionAuthorize ops={["READ PROPERTY_TYPE"]}>
                        <Grid item xs={12} sm={6} lg={3}>
                            <UpdatedSearchableInput
                                label="Property Type"
                                _name="type"
                                api="/customer/property/type"
                                filter={intermediateFilter}
                                setFilter={setIntermediateFilter}
                            />
                        </Grid>
                    </MultiPermissionAuthorize>

                    <MultiPermissionAuthorize ops={["READ CUSTOMER"]}>
                        <Grid item xs={12} sm={6} lg={3}>
                            <UpdatedSearchableInput
                                label="Customer"
                                api="/customer"
                                filter={intermediateFilter}
                                setFilter={setIntermediateFilter}
                            />
                        </Grid>
                    </MultiPermissionAuthorize>

                    <Grid item xs={12} sm={6} lg={3}>
                        <Button
                            color="secondary"
                            variant="outlined"
                            onClick={() => setChangeCount(changeCount + 1)}
                            sx={{ minWidth: "22ch", height: "100%" }}
                            startIcon={<FilterAltOutlined />}
                            fullWidth
                        >
                            filter
                        </Button>
                    </Grid>
                </Grid>
            </Card>

            {data?.data?.rows.length > 0 ? (
                <Box sx={{ height: "65%" }}>
                    <BaseTable
                        headers={{
                            id: "ID",
                            name: "Name",
                            address: "Address",
                            customerName: "Customer",
                            customerCnic: "CNIC",
                            districtName: "District ",
                            villageName: "Village",
                            hamletName: "Hamlet",
                            powerstationName: "Powerstation",
                            propertyName: "Property Type",
                        }}
                        defaultSelectedHeaders={[
                            "id",
                            "name",
                            "address",
                            "customerName",
                            "customerCnic",
                            "propertyName",
                        ]}
                        data={data?.data.rows.map((row: any) => ({
                            ...row,
                            customerName: row.customer.name,
                            customerCnic: row.customer.cnic,
                            districtName: row.district.name,
                            villageName: row.village.name,
                            hamletName: row.hamlet.name,
                            powerstationName: row.powerstation.name,
                            propertyName: row.type.name,
                        }))}
                        load={Boolean(changeCount)}
                        reportName="Properties Report"
                        delEndPoint="/customer/property"
                        feedback={feedback}
                        setFeedback={setFeedback}
                        rowsCount={rowsCount}
                        pagination={pagination}
                        setPagination={setPagination}
                        isLoading={isLoading}
                        permissions={{
                            edit: ["UPDATE PROPERTY"],
                            delete: ["DELETE PROPERTY"],
                        }}
                        queryKey={[
                            "property",
                            pagination.page,
                            pagination.limit,
                            filter,
                        ]}
                        hasReportTemplates
                        reportTemplates={reportTemplates}
                        reportDetails={selectedFilters}
                    />
                </Box>
            ) : hasValue(filter) ? (
                <Alert severity="info">{noDataMessage}</Alert>
            ) : (
                <Alert severity="info">{filterSelectionMessage}</Alert>
            )}
        </Box>
    );
};

export default Property;
