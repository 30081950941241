import { AddCircleOutline, Delete, Upload } from "@mui/icons-material";
import {
    Alert,
    Box,
    Button,
    Checkbox,
    Chip,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControlLabel,
    IconButton,
    Paper,
    TextField,
    Typography,
} from "@mui/material";
import {
    ChangeEvent,
    Dispatch,
    SetStateAction,
    createContext,
    useContext,
    useEffect,
    useRef,
    useState,
} from "react";
import { DELETE, GET, POST } from "../../../Utilities/BaseService";
import { S3Service } from "../../../Utilities/S3Service";
import { awsDirStruct } from "../../../Utilities/constants";

type NewLogo = {
    name: string;
    isDefault: boolean;
    src: string;
};

type Logo = {
    id: number;
    key: string;
    value: string;
    isDefault: boolean;
    src: string;
};

type CtxType = {
    logos: Logo[];
    setLogos: Dispatch<SetStateAction<Logo[]>>;
    openNewLogoDialog: boolean;
    setOpenNewLogoDialog: Dispatch<SetStateAction<boolean>>;
    setNewLogoN: Dispatch<SetStateAction<number>>;
};

const Ctx = createContext({} as CtxType);

const LogoPreviewSmall = ({ logo }: { logo: Logo }) => {
    const { setNewLogoN } = useContext(Ctx);
    const [status, setStatus] = useState("idle");

    const handleDelete = async () => {
        setStatus("loading");
        try {
            await DELETE("/settings/hard", {}, { id: logo.id });
            await S3Service.deleteS3ByKey(`${awsDirStruct.logos}/${logo.id}`);
            setNewLogoN((n) => n + 1);
            setStatus("success");
        } catch (err) {
            setStatus("error");
        }
    };

    return (
        <Box
            sx={{
                minWidth: "4rem",
                borderRadius: "4px",
                borderColor: "grey.500",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "8px",
            }}
        >
            <img
                src={logo.src}
                alt={logo.value}
                style={{
                    maxHeight: "4rem",
                    borderRadius: "4px",
                }}
            />
            <Divider flexItem />
            <div style={{ display: "flex", alignItems: "center" }}>
                <Typography
                    sx={{ ml: 1, mr: 0.5 }}
                    variant="body2"
                    color="textSecondary"
                    // textAlign="center"
                >
                    {logo.value}
                </Typography>
                {/* <CircularProgress size=".8rem" /> */}
                {status === "loading" ? (
                    <CircularProgress size=".8rem" color="secondary" />
                ) : (
                    <IconButton
                        size="small"
                        color="error"
                        // style={{ fontSize: "1rem" }}
                        onClick={handleDelete}
                    >
                        <Delete style={{ fontSize: "1rem" }} />
                    </IconButton>
                )}
            </div>
        </Box>
    );
};

const ShowLogos = () => {
    const { logos, setOpenNewLogoDialog } = useContext(Ctx);

    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{ overflowX: "auto" }}
        >
            {logos.map((logo) => (
                <>
                    <LogoPreviewSmall logo={logo} />
                    <Divider flexItem orientation="vertical" />
                </>
            ))}

            <IconButton
                color="secondary"
                onClick={() => setOpenNewLogoDialog(true)}
            >
                <AddCircleOutline />
            </IconButton>
        </Box>
    );
};

const AddNewLogo = ({ isOpen, close }: any) => {
    const { setNewLogoN } = useContext(Ctx);
    const fileInputRef = useRef<HTMLInputElement | null>(null);
    const [newLogo, setNewLogo] = useState<NewLogo>({
        name: "",
        isDefault: false,
        src: "",
    });
    const [feedback, setFeedback] = useState<any>({
        status: "idle",
        message: "",
    });
    const [file, setFile] = useState<File | null>(null);

    const handleFile = (ev: ChangeEvent<HTMLInputElement>) => {
        const files = ev.target.files;

        if (files && files.length > 0) {
            const targetFile = files[0];

            setFile(targetFile);
            const src = URL.createObjectURL(targetFile);
            setNewLogo({ ...newLogo, src });
        }
    };

    const handleRemoveFile = () => {
        if (newLogo.src) {
            URL.revokeObjectURL(newLogo.src);
            setFile(null);
            setNewLogo({ ...newLogo, src: "" });
        }
    };

    const handleAdd = async () => {
        if (!newLogo.name || !file) {
            return setFeedback({
                status: "error",
                message: "Must provide a name and an image",
            });
        }

        setFeedback({ status: "loading", message: "" });

        const newSetting = {
            key: "logo-" + new Date().getTime(),
            value: newLogo.name,
            isDefault: newLogo.isDefault,
            label: "Logo",
        };

        try {
            const response = await POST("/settings", newSetting);
            const settingId = response.data.entity.id;

            setFeedback({
                status: "info",
                message: (
                    <div
                        style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            gap: "8px",
                        }}
                    >
                        <div>Uploading File</div>

                        <CircularProgress size="1rem" />
                    </div>
                ),
            });

            const key = `${awsDirStruct.logos}/${settingId}`;
            await S3Service.uploadToS3(key, file);

            setFeedback({
                status: "success",
                message: "Logo added successfully",
            });
            setNewLogoN((n) => n + 1);
        } catch (err) {
            setFeedback({ status: "error", message: "Failed to add the logo" });
        }
    };

    return (
        <Dialog fullWidth open={isOpen} onClose={close}>
            <DialogTitle>Add new logo</DialogTitle>
            <DialogContent>
                {feedback.status === "idle" ||
                feedback.status === "loading" ? null : (
                    <Alert
                        severity={feedback.status as any}
                        sx={{ mb: 1, "& .MuiAlert-message": { width: "100%" } }}
                    >
                        {feedback.message}
                    </Alert>
                )}

                <TextField
                    sx={{ my: 1 }}
                    size="small"
                    fullWidth
                    variant="outlined"
                    label="Name"
                    required
                    value={newLogo.name}
                    onChange={(ev) =>
                        setNewLogo({ ...newLogo, name: ev.target.value })
                    }
                />

                <FormControlLabel
                    label="Default?"
                    control={
                        <Checkbox
                            checked={newLogo.isDefault}
                            onChange={(ev, checked) =>
                                setNewLogo({ ...newLogo, isDefault: checked })
                            }
                        />
                    }
                />

                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        mt: 3,
                    }}
                >
                    {file ? (
                        <img
                            src={newLogo.src}
                            alt={newLogo.name}
                            style={{
                                borderRadius: "8px",
                                maxWidth: "80%",
                                maxHeight: "10rem",
                            }}
                        />
                    ) : (
                        <Button
                            variant="contained"
                            color="secondary"
                            endIcon={<Upload />}
                            sx={{ minWidth: "13rem", borderRadius: 5 }}
                            onClick={() => fileInputRef.current?.click()}
                        >
                            <input
                                hidden
                                ref={fileInputRef}
                                onChange={handleFile}
                                type="file"
                                accept="image/png"
                            />
                            Upload File
                        </Button>
                    )}

                    <div
                        style={{
                            display: "flex",
                            gap: "4px",
                            alignItems: "center",
                            marginTop: "8px",
                        }}
                    >
                        <Typography variant="body2" color="textSecondary">
                            {file ? file.name : "No file selected"}
                        </Typography>

                        {file && (
                            <Chip
                                size="small"
                                label="Delete"
                                color="error"
                                clickable
                                onClick={handleRemoveFile}
                            />
                        )}
                    </div>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button color="inherit" onClick={close}>
                    Cancel
                </Button>
                <Button
                    onClick={handleAdd}
                    variant="outlined"
                    color="secondary"
                >
                    Add
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export const MultipleLogos = () => {
    const [logos, setLogos] = useState<Logo[]>([]);
    const [openNewLogoDialog, setOpenNewLogoDialog] = useState(false);
    const [newLogoN, setNewLogoN] = useState(0);

    const getLogos = async () => {
        try {
            const response = await GET("/settings/logos");

            const _logos = response.data.rows;

            const proms = _logos.map((l: any) =>
                S3Service.getS3ByKey(`${awsDirStruct.logos}/${l.id}`)
            );

            const logoSrcs = await Promise.all(proms);

            const logoSrcsUrl = logoSrcs.map((s: any) => s[0]?.url);

            const logosWithSrc = _logos.map((l: any, i: number) => ({
                ...l,
                src: logoSrcsUrl[i],
            }));

            setLogos(logosWithSrc);
        } catch (err) {}
    };

    useEffect(() => {
        getLogos();
    }, [newLogoN]);

    return (
        <Ctx.Provider
            value={{
                logos,
                setLogos,
                openNewLogoDialog,
                setOpenNewLogoDialog,
                setNewLogoN,
            }}
        >
            <Paper
                variant="outlined"
                sx={{
                    p: 3,
                    px: 1.5,
                    bgcolor: "common.white",
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                }}
            >
                <div>
                    <Typography variant="body1">Multiple Logos</Typography>
                </div>

                <div>
                    <ShowLogos />
                </div>
            </Paper>

            <AddNewLogo
                isOpen={openNewLogoDialog}
                close={() => setOpenNewLogoDialog(false)}
            />
        </Ctx.Provider>
    );
};
