import { Add } from "@mui/icons-material";
import {
    Alert,
    Button,
    Card,
    CardContent,
    CardHeader,
    Checkbox,
    CircularProgress,
    Divider,
    FormControlLabel,
    Grid,
    MenuItem,
    TextField,
    useTheme,
} from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { GET, PATCH, POST } from "../../../Utilities/BaseService";

// /payment/head

const targetTypes = [
    { id: 1, label: "Reading", value: "reading" },
    { id: 2, label: "Meter", value: "meter" },
    { id: 3, label: "Property", value: "property" },
    { id: 4, label: "Customer", value: "customer" },
];

const addPaymentHead = (paymentHead: PaymentHeadType) => {
    return POST("/payment/head", paymentHead);
};

const updatePaymentHead = (paymentHead: PaymentHeadType, id: number) => {
    return PATCH("/payment/head", paymentHead, { id });
};

type Props = { updateMode?: boolean };

type PaymentHeadType = {
    name: string;
    amount: number;
    editable: boolean;
    applySurcharge: boolean;
    targetType: "reading" | "meter" | "property" | "customer" | "";
};

export const AddPaymentHead = ({ updateMode = false }: Props) => {
    const [paymentHead, setPaymentHead] = useState<PaymentHeadType>({
        name: "",
        amount: 0,
        editable: false,
        applySurcharge: true,
        targetType: "",
    });
    const [rowId, setRowId] = useState<number>();
    const [feedback, setFeedback] = useState("");
    const theme = useTheme();
    const location = useLocation();

    const getPaymentHeadById = () => {
        return GET(`/payment/head?id=${rowId}`);
    };
    const { data: _paymentHead } = useQuery(
        [`payment-head-${rowId}`],
        getPaymentHeadById,
        {
            enabled: Boolean(rowId),
            onSuccess(response) {
                if (response.data.rows.length > 0) {
                    const _ph = response.data.rows[0];
                    setPaymentHead(_ph);
                }
            },
            refetchOnMount: true,
        }
    );

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setPaymentHead({
            ...paymentHead,
            [e.target.name]:
                e.target.name === "editable"
                    ? !paymentHead.editable
                    : e.target.value,
        });
    };

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        updateMode ? updateMutation.mutate() : addMutation.mutate();
    };

    const addMutation = useMutation(() => addPaymentHead(paymentHead), {
        onSuccess(res) {
            setFeedback(res.data.message);
        },

        onError(err: any) {
            setFeedback(err.response.data.message);
        },
    });

    const updateMutation = useMutation(
        () => updatePaymentHead(paymentHead, rowId as number),
        {
            onSuccess(res) {
                setFeedback(res.data.message);
            },

            onError(err: any) {
                setFeedback(err.response.data.message);
            },
        }
    );

    useEffect(() => {
        if (updateMode) {
            const params = new URLSearchParams(location.search);
            const _id = params.get("id");

            if (_id) {
                setRowId(parseInt(_id));
            }
        }
    }, [updateMode]);

    return (
        <>
            <Card
                elevation={0}
                sx={{ bgcolor: theme.palette.common.white, mb: "1rem" }}
            >
                <CardHeader
                    title={
                        updateMode ? "update Payment Head" : "Add Payment Head"
                    }
                    sx={{
                        textTransform: "capitalize",
                        bgcolor: theme.palette.common.white,
                    }}
                />
                <CardContent>
                    <Divider sx={{ mb: 5 }} />

                    <Grid
                        container
                        component="form"
                        onSubmit={handleSubmit}
                        spacing={2}
                    >
                        <Grid item xs={12} md={4}>
                            <TextField
                                size="small"
                                name="name"
                                value={paymentHead?.name}
                                label="Name"
                                onChange={handleChange}
                                fullWidth
                                type="text"
                                required
                            />
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <TextField
                                size="small"
                                name="amount"
                                value={paymentHead?.amount}
                                label="Amount"
                                onChange={handleChange}
                                fullWidth
                                type="number"
                                inputProps={{ min: 0 }}
                                required
                            />
                        </Grid>

                        {!updateMode && (
                            <Grid item xs={12} md={4}>
                                <TextField
                                    size="small"
                                    select
                                    name="targetType"
                                    value={paymentHead?.targetType}
                                    label="Target Type"
                                    onChange={handleChange}
                                    fullWidth
                                    type="text"
                                    required
                                >
                                    {targetTypes.map((target) => (
                                        <MenuItem
                                            key={target.id}
                                            value={target.value}
                                        >
                                            {target.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                        )}

                        <Grid item xs={12} md={4}>
                            <FormControlLabel
                                label="Editable?"
                                control={
                                    <Checkbox
                                        checked={paymentHead?.editable}
                                        name="editable"
                                        onChange={handleChange}
                                    />
                                }
                            />
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <FormControlLabel
                                label="Apply Surcharge?"
                                control={
                                    <Checkbox
                                        checked={paymentHead?.applySurcharge}
                                        name="applySurcharge"
                                        onChange={(ev, checked) =>
                                            setPaymentHead({
                                                ...paymentHead,
                                                applySurcharge: checked,
                                            })
                                        }
                                    />
                                }
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Button
                                startIcon={<Add />}
                                endIcon={
                                    addMutation.isLoading ||
                                    updateMutation.isLoading ? (
                                        <CircularProgress
                                            color="secondary"
                                            size="1rem"
                                        />
                                    ) : (
                                        ""
                                    )
                                }
                                color="secondary"
                                variant="outlined"
                                size="small"
                                type="submit"
                            >
                                {updateMode ? "update" : "add"} Payment Head
                            </Button>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>

            {addMutation.isSuccess || updateMutation.isSuccess ? (
                <Alert severity="success">{feedback}.</Alert>
            ) : addMutation.isError || updateMutation.isError ? (
                <Alert severity="error">{feedback}.</Alert>
            ) : null}
        </>
    );
};
