export const basePath =
    process.env.NODE_ENV === "development" ? "http://localhost:3000" : "";
export const baseAPI =
    process.env.NODE_ENV === "development"
        ? "http://localhost:8080/api"
        : "/api";

export const notConnectedMessage =
    "Unable to perform the task at this moment, make sure you're connected to the internet";

export const awsDirStruct = {
    logos: "logos",
    logs: "logs",
    readings: "readings",
};

export const SESSION_TIME = 60;

export function hasValue(obj: any) {
    return Object.values(obj).some(Boolean);
}

export const filterSelectionMessage =
    "Please select a filter in order to see data";

export const noDataMessage = "No data found";
