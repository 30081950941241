import { Download } from "@mui/icons-material";
import { Color, IconButton, Modal, Typography, useTheme } from "@mui/material";
import { green, orange, red } from "@mui/material/colors";
import {
    Document,
    Image,
    PDFDownloadLink,
    Page,
    StyleSheet,
    Text,
    View,
} from "@react-pdf/renderer";
import {
    Dispatch,
    Fragment,
    ReactNode,
    SetStateAction,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import { theme } from "../../../Theme/theme";
import { GET } from "../../../Utilities/BaseService";
import { S3Service } from "../../../Utilities/S3Service";
import { awsDirStruct } from "../../../Utilities/constants";
import { currencyFormatter, numberFormatter } from "./Cells/Last12Summary";
import { dateFormatter, monthFormatter } from "./SingleBill";

type Pagination = { page: number; limit: number; count: number };

type BillsPdfProps = {
    logoSrc: string;
    groupBy: "village" | "powerstation";
    bills: Bill[];
    showBills: boolean;
};

const initialReadings = {
    get: false,
    page: 1,
    ids: [] as number[],
};

const initialBills = {
    get: false,
    page: 1,
    rows: [] as Bill[],
};

const BillsPdf = ({
    bills = [],
    logoSrc,
    groupBy,
    showBills,
}: BillsPdfProps) => {
    const downloadBtnRef = useRef<HTMLButtonElement | null>(null);
    const createDocument = (sectionWiseBills: any) => {
        return (
            <Document>
                {sectionWiseBills ? (
                    groupBy === "village" ? (
                        Object.entries(sectionWiseBills).map(
                            ([districtName, districtBills]: any, districtIdx) =>
                                Object.entries(districtBills).map(
                                    (
                                        [villageName, villageBills]: any,
                                        villageIdx
                                    ) =>
                                        Object.entries(villageBills).map(
                                            (
                                                [hamletName, hamletBills]: any,
                                                hamletIdx
                                            ) =>
                                                hamletBills.map(
                                                    (
                                                        bill: Bill,
                                                        index: number
                                                    ) => (
                                                        <Fragment key={bill.id}>
                                                            {index === 0 && (
                                                                <Page
                                                                    style={{
                                                                        width: "100%",
                                                                        display:
                                                                            "flex",
                                                                        justifyContent:
                                                                            "center",
                                                                        alignItems:
                                                                            "center",
                                                                        textTransform:
                                                                            "uppercase",
                                                                    }}
                                                                >
                                                                    <View>
                                                                        <Text
                                                                            style={{
                                                                                letterSpacing: 3,
                                                                            }}
                                                                        >
                                                                            District
                                                                        </Text>
                                                                        <Text>
                                                                            {
                                                                                districtName
                                                                            }
                                                                        </Text>

                                                                        <Text
                                                                            style={{
                                                                                marginTop:
                                                                                    theme.spacing(
                                                                                        2
                                                                                    ),
                                                                                letterSpacing: 3,
                                                                            }}
                                                                        >
                                                                            VILLAGE
                                                                        </Text>

                                                                        <Text
                                                                            style={{
                                                                                marginBottom:
                                                                                    theme.spacing(
                                                                                        2
                                                                                    ),
                                                                            }}
                                                                        >
                                                                            {
                                                                                villageName
                                                                            }
                                                                        </Text>

                                                                        <Text
                                                                            style={{
                                                                                letterSpacing: 3,
                                                                            }}
                                                                        >
                                                                            HAMLET
                                                                        </Text>

                                                                        <Text>
                                                                            {
                                                                                hamletName
                                                                            }
                                                                        </Text>
                                                                    </View>
                                                                </Page>
                                                            )}

                                                            <SinglePdfBill
                                                                key={bill.id}
                                                                bill={bill}
                                                                logoSrc={
                                                                    logoSrc
                                                                }
                                                            />
                                                        </Fragment>
                                                    )
                                                )
                                        )
                                )
                        )
                    ) : (
                        Object.entries(sectionWiseBills).map(
                            ([districtName, districtBills]: any, districtIdx) =>
                                Object.entries(districtBills).map(
                                    ([
                                        powerstationName,
                                        powerstationBills,
                                    ]: any) =>
                                        powerstationBills.map(
                                            (bill: Bill, index: number) => (
                                                <Fragment key={bill.id}>
                                                    {index === 0 && (
                                                        <Page
                                                            style={{
                                                                width: "100%",
                                                                display: "flex",
                                                                justifyContent:
                                                                    "center",
                                                                alignItems:
                                                                    "center",
                                                                textTransform:
                                                                    "uppercase",
                                                            }}
                                                        >
                                                            <View>
                                                                <Text
                                                                    style={{
                                                                        letterSpacing: 3,
                                                                    }}
                                                                >
                                                                    District
                                                                </Text>
                                                                <Text>
                                                                    {
                                                                        districtName
                                                                    }
                                                                </Text>

                                                                <Text
                                                                    style={{
                                                                        marginTop:
                                                                            theme.spacing(
                                                                                2
                                                                            ),
                                                                        letterSpacing: 3,
                                                                    }}
                                                                >
                                                                    POWERSTATION
                                                                </Text>
                                                                <Text
                                                                    style={{
                                                                        marginBottom:
                                                                            theme.spacing(
                                                                                2
                                                                            ),
                                                                    }}
                                                                >
                                                                    {
                                                                        powerstationName
                                                                    }
                                                                </Text>
                                                            </View>
                                                        </Page>
                                                    )}

                                                    <SinglePdfBill
                                                        key={bill.id}
                                                        bill={bill}
                                                        logoSrc={logoSrc}
                                                    />
                                                </Fragment>
                                            )
                                        )
                                )
                        )
                    )
                ) : (
                    <Page>
                        <Text>no bills to print</Text>
                    </Page>
                )}
            </Document>
        );
    };

    const sectionWiseBills = useMemo(() => {
        if (bills.length) {
            return bills.reduce((acc: any, bill: Bill) => {
                const districtName = bill.meter.district.name;
                const districtId = bill.meter.district.id;

                acc[districtName] =
                    groupBy === "village"
                        ? bills
                              .filter(
                                  (b: any) => b.meter.district.id === districtId
                              )
                              .reduce((distAcc: any, distWiseBill: any) => {
                                  const villageId =
                                      distWiseBill.meter.village.id;
                                  const villageName =
                                      distWiseBill.meter.village.name;

                                  distAcc[villageName] = bills
                                      .filter(
                                          (b: any) =>
                                              b.meter.village.id === villageId
                                      )
                                      .reduce(
                                          (
                                              villAcc: any,
                                              villageWiseBill: any
                                          ) => {
                                              const hamletId =
                                                  villageWiseBill.meter.hamlet
                                                      .id;
                                              const hamletName =
                                                  villageWiseBill.meter.hamlet
                                                      .name;

                                              villAcc[hamletName] =
                                                  bills.filter(
                                                      (b: any) =>
                                                          b.meter.hamlet.id ===
                                                          hamletId
                                                  );

                                              return villAcc;
                                          },
                                          {}
                                      );

                                  console.log(distAcc);

                                  return distAcc;
                              }, {})
                        : bills
                              .filter(
                                  (b: any) => b.meter.district.id === districtId
                              )
                              .reduce((distAcc: any, distWiseBill: any) => {
                                  const powerstationId =
                                      distWiseBill.meter.powerstation.id;
                                  const powerstaionName =
                                      distWiseBill.meter.powerstation.name;

                                  distAcc[powerstaionName] = bills.filter(
                                      (b: any) =>
                                          b.meter.powerstation.id ===
                                          powerstationId
                                  );

                                  return distAcc;
                              }, {});

                return acc;
            }, {});
        }
    }, [groupBy, bills]);

    return (
        <>
            <PDFDownloadLink
                document={createDocument(sectionWiseBills)}
                style={{
                    textDecoration: "none",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                {({ blob, url, loading, error }) => (
                    <>
                        {loading ? (
                            <Typography variant="body2" color="primary">
                                Generating pdf...
                            </Typography>
                        ) : error ? (
                            <Typography variant="body2" color="error">
                                Error while generating pdf.
                            </Typography>
                        ) : (
                            <>
                                {blob && downloadBtnRef.current?.click()}

                                <IconButton size="small" ref={downloadBtnRef}>
                                    <Download
                                        fontSize="small"
                                        htmlColor="inherit"
                                    />
                                </IconButton>
                            </>
                        )}
                    </>
                )}
            </PDFDownloadLink>
        </>
    );
};

export default BillsPdf;

type AdditionalCharge = {
    title: string;
    collections: { amount: number }[];
    amount: number;
    head: { name: string };
};

type MeterAttribute = {
    type: "collection" | string;
    id: number;
    createdAt: string;
    value: string;
};

type Customer = { name: string; fatherName: string; cnic: string };

type Property = {
    id: number;
    name: string;
    address: string;
    customer: Customer;
};

type Meter = {
    property: Property;
    connectionDate: string;
    meterNo: string;
    district: { id: number; name: string };
    village: { id: number; name: string };
    hamlet: { id: number; name: string };
    powerstation: { name: string };
    connectionType: { name: string };
    attributes: MeterAttribute[];
};

type Bill = {
    lineRent: number;
    unitsAmount: number;
    connectionChargesAmount: number;
    additionalCharges: AdditionalCharge[] | [];
    surchargeAmount: number;
    payableWithinDueDate: number;
    payableAfterDueDate: number;
    unitPrice: number | any[];
    arrears: number;
    id: number;
    month: number;
    year: number;
    prevUnits: number;
    currentUnits: number;
    readingDate: string;
    issueDate: string;
    dueDate: string;
    meter: Meter;
    totalAmount: number;
};

export const SinglePdfBill = ({
    logoSrc,
    bill,
}: {
    logoSrc: string;
    bill: Bill;
}) => {
    const [readingImage, setReadingImage] = useState("");

    //
    const collectionAttributes = useMemo(
        () =>
            bill.meter.attributes.filter(
                (x) =>
                    x.type === "collection" &&
                    new Date(x.createdAt) < new Date(bill.readingDate)
            ),
        [bill]
    );

    const getReadingImage = async (readingId: number | string) => {
        const readingKey = `readings/${readingId}`;

        // S3Service.constructKey(
        //     ["/" + awsDirStruct.readings],
        //     reading.id.toString()
        // );

        const [data, err] = await S3Service.getS3ByKey(readingKey);

        if (data) {
            try {
                const response = await fetch(data.url);

                console.log(data.url);

                const blob = await response.blob();

                if (response.status === 200) {
                    setReadingImage(data.url);
                }
            } catch (err) {}
        } else {
        }
    };

    useEffect(() => {
        if (bill.id) getReadingImage(bill.id);
    }, [bill.id]);

    return (
        <Page style={styles.wrapper} size="A4">
            <BillHeader logoSrc={logoSrc} copyVariant="Consumer Copy" />

            <View
                style={{
                    ...styles.billRow,
                    marginTop: 24,
                }}
            >
                <BillCell label="reference no." value={bill.id} />

                <BillCell
                    label="connection date"
                    value={dateFormatter.format(
                        new Date(bill.meter.connectionDate)
                    )}
                />

                <BillCell
                    label="billing month"
                    value={monthFormatter.format(
                        new Date(bill.year, bill.month - 1, 1)
                    )}
                />

                <BillCell
                    label="reading date"
                    value={dateFormatter.format(new Date(bill.readingDate))}
                />

                <BillCell
                    label="issue date"
                    value={dateFormatter.format(new Date(bill.issueDate))}
                />

                <BillCell
                    label="due date"
                    value={dateFormatter.format(new Date(bill.dueDate))}
                    color={red}
                />
            </View>

            <View style={{ ...styles.billRow, marginTop: 8 }}>
                <CustomerCell consumerDetails={bill.meter.property} />

                <View
                    style={{
                        ...styles.billRow,
                        flexDirection: "column",
                        flex: 1,
                        // width: "40%",
                    }}
                >
                    <BillCell>
                        <View style={{ margin: "auto" }}>
                            <Text
                                style={{
                                    ...styles.cellLabel,
                                    fontSize: 14,
                                    marginBottom: 4,
                                }}
                            >
                                {bill.meter.powerstation.name}
                            </Text>

                            <Text style={{ ...styles.cellValue, fontSize: 12 }}>
                                {bill.meter.connectionType.name}
                            </Text>
                        </View>
                    </BillCell>

                    <BillCell>
                        {readingImage && (
                            <Image
                                src={readingImage}
                                style={{
                                    objectFit: "cover",
                                    width: "80px",
                                    height: "60px",
                                    margin: "auto",
                                }}
                            />
                        )}
                    </BillCell>
                </View>

                <View style={{ ...styles.billRow, flex: 1 }}>
                    <View
                        style={{
                            flex: 1,
                            display: "flex",
                            flexDirection: "column",
                            gap: 8,
                        }}
                    >
                        <BillCell
                            label="meter no."
                            value={bill.meter.meterNo}
                        />

                        <BillCell
                            label="prev. reading"
                            value={bill.prevUnits + ""}
                        />
                    </View>

                    <View
                        style={{
                            flex: 1,
                            display: "flex",
                            flexDirection: "column",
                            gap: 8,
                        }}
                    >
                        <BillCell
                            label="current reading"
                            value={bill.currentUnits}
                        />

                        <BillCell
                            label="units consumed"
                            value={bill.currentUnits - bill.prevUnits}
                        />
                    </View>
                </View>
            </View>

            <View style={{ ...styles.billRow, marginTop: 8 }}>
                <PreviousRecords attributes={collectionAttributes} />

                <BillCalculations bill={bill} />
            </View>

            {/* Office Copy */}
            <View style={{ margin: "12px auto", width: "100%" }}>
                <View
                    style={{
                        margin: "auto",
                        width: "100%",
                        height: "1px",
                        border: "2px dashed grey",
                    }}
                />
            </View>

            <BillHeader logoSrc={logoSrc} copyVariant="Office Copy" />

            <View
                style={{
                    ...styles.billRow,
                    marginTop: 12,
                    marginBottom: 8,
                }}
            >
                <BillCell label="customer details">
                    <View style={{ ...styles.billRow, ...styles.cellRow }}>
                        <Text style={styles.customerCellLabel}>name</Text>
                        <Text style={styles.customerCellValue}>
                            {bill.meter.property.customer.name}
                        </Text>
                    </View>

                    <View
                        style={{
                            ...styles.cellSeperator,
                            ...styles.cellRowSeperator,
                        }}
                    />

                    <View style={{ ...styles.billRow, ...styles.cellRow }}>
                        <Text style={styles.customerCellLabel}>
                            father name
                        </Text>
                        <Text style={styles.customerCellValue}>
                            {bill.meter.property.customer.fatherName}
                        </Text>
                    </View>

                    <View
                        style={{
                            ...styles.cellSeperator,
                            ...styles.cellRowSeperator,
                        }}
                    />

                    <View style={{ ...styles.billRow, ...styles.cellRow }}>
                        <Text style={styles.customerCellLabel}>meter no.</Text>
                        <Text style={styles.customerCellValue}>
                            {bill.meter.meterNo}
                        </Text>
                    </View>

                    <View
                        style={{
                            ...styles.cellSeperator,
                            ...styles.cellRowSeperator,
                        }}
                    />

                    <View style={{ ...styles.billRow, ...styles.cellRow }}>
                        <Text style={styles.customerCellLabel}>
                            property name
                        </Text>
                        <Text style={styles.customerCellValue}>
                            {bill.meter.property.name}
                        </Text>
                    </View>
                </BillCell>

                <View
                    style={{
                        width: "40%",
                        border: `2px dashed ${theme.palette.grey[300]}`,
                        borderRadius: 4,
                    }}
                >
                    <View style={{ margin: "auto" }}>
                        <Text
                            style={{
                                ...styles.cellLabel,
                                fontSize: 16,
                                fontWeight: "bold",
                                color: theme.palette.grey[300],
                                marginBottom: 4,
                                textTransform: "uppercase",
                            }}
                        >
                            stamp
                        </Text>
                    </View>
                </View>
            </View>

            <View style={styles.billRow}>
                <BillCell
                    label="bill month"
                    value={monthFormatter.format(
                        new Date(bill.year, bill.month - 1, 1)
                    )}
                />

                <BillCell
                    label="due date"
                    value={dateFormatter.format(new Date(bill.dueDate))}
                    color={red}
                />

                <BillCell label="reference no." value={bill.id} />

                <BillCell
                    label="bill within due date"
                    value={currencyFormatter.format(bill.payableWithinDueDate)}
                    color={green}
                />

                <BillCell
                    label="bill after due date"
                    value={currencyFormatter.format(bill.payableAfterDueDate)}
                    color={orange}
                />
            </View>
        </Page>
    );
};

const BillHeader = ({
    logoSrc,
    copyVariant,
}: {
    logoSrc: string;
    copyVariant: string;
}) => {
    const [secondaryLogos, setSecondaryLogos] = useState<string[]>([]);

    const getDataInRange = (index: number, limit: number, data: any[]) => {
        const output: any[] = [];

        for (index; index < limit; index++) {
            if (data[index]) {
                output.push(data[index]);
            }
        }

        return output;
    };

    const getSecondaryLogos = async () => {
        try {
            const response = await GET("/settings/logos", {
                default: true,
            });

            const second_third_Logo = getDataInRange(
                1,
                3,
                response.data.rows || []
            );

            let secondaryLogosUrls: Promise<any>[] = second_third_Logo.map(
                async (l) => {
                    try {
                        const [{ url }] = await S3Service.getS3ByKey(
                            `${awsDirStruct.logos}/${l.id}`
                        );

                        return url;
                    } catch (error) {
                        return "";
                    }
                }
            );

            const res = await Promise.all(secondaryLogosUrls);

            setSecondaryLogos(res);
        } catch (error) {
            console.log(error);
        }
    };
    useEffect(() => {
        getSecondaryLogos();
    }, []);

    return (
        <View style={styles.header}>
            <View>
                <Image src={logoSrc || ""} style={styles.logo} />
            </View>

            <View style={{ margin: "0 auto" }}>
                <Text style={styles.title}>Sarhad Rural Support Programme</Text>
                <Text style={styles.subTitle}>
                    Electricity Bill ({copyVariant})
                </Text>
            </View>

            <View
                style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: theme.spacing(3),
                }}
            >
                {secondaryLogos.map((src, id) => (
                    <Image src={src} style={styles.logo} key={id} />
                ))}
            </View>
        </View>
    );
};

const BillCell = ({
    label,
    value,
    children,
    color,
}: {
    label?: string;
    value?: number | string;
    children?: ReactNode;
    color?: Color;
}) => {
    const theme = useTheme();

    return (
        <View
            style={
                color
                    ? {
                          ...styles.billCell,
                          backgroundColor: color[50],
                          borderColor: color[100],
                      }
                    : styles.billCell
            }
        >
            {label && (
                <>
                    <Text
                        style={
                            color
                                ? { ...styles.cellLabel, color: color[600] }
                                : styles.cellLabel
                        }
                    >
                        {label}
                    </Text>
                    <View
                        style={
                            color
                                ? {
                                      ...styles.cellSeperator,
                                      backgroundColor: color[100],
                                  }
                                : styles.cellSeperator
                        }
                    />
                </>
            )}

            {value && (
                <Text
                    style={
                        color
                            ? { ...styles.cellValue, color: color[500] }
                            : styles.cellValue
                    }
                >
                    {value}
                </Text>
            )}

            {children && children}
        </View>
    );
};

const CustomerCell = ({ consumerDetails }: { consumerDetails: Property }) => {
    return (
        <BillCell label="customer details">
            <View style={{ ...styles.billRow, ...styles.cellRow }}>
                <Text style={styles.customerCellLabel}>name</Text>
                <Text style={styles.customerCellValue}>
                    {consumerDetails.customer.name}
                </Text>
            </View>

            <View
                style={{
                    ...styles.cellSeperator,
                    ...styles.cellRowSeperator,
                }}
            />

            <View style={{ ...styles.billRow, ...styles.cellRow }}>
                <Text style={styles.customerCellLabel}>father name</Text>
                <Text style={styles.customerCellValue}>
                    {consumerDetails.customer.fatherName}
                </Text>
            </View>

            <View
                style={{
                    ...styles.cellSeperator,
                    ...styles.cellRowSeperator,
                }}
            />

            <View style={{ ...styles.billRow, ...styles.cellRow }}>
                <Text
                    style={{
                        ...styles.customerCellLabel,
                        textTransform: "uppercase",
                    }}
                >
                    cnic
                </Text>
                <Text style={styles.customerCellValue}>
                    {consumerDetails.customer.cnic}
                </Text>
            </View>

            <View
                style={{
                    ...styles.cellSeperator,
                    ...styles.cellRowSeperator,
                }}
            />

            <View style={{ ...styles.billRow, ...styles.cellRow }}>
                <Text style={styles.customerCellLabel}>property name</Text>
                <Text style={styles.customerCellValue}>
                    {consumerDetails.name}
                </Text>
            </View>

            <View
                style={{
                    ...styles.cellSeperator,
                    ...styles.cellRowSeperator,
                }}
            />

            <View style={{ ...styles.billRow, ...styles.cellRow }}>
                <Text style={styles.customerCellLabel}>property address</Text>
                <Text style={styles.customerCellValue}>
                    {consumerDetails.address}
                </Text>
            </View>
        </BillCell>
    );
};

const PreviousRecords = ({ attributes }: { attributes: MeterAttribute[] }) => {
    const collections: {
        amount: number;
        adjustment: number;
        collectionDate: string;
    }[] = useMemo(
        () => attributes.map((c) => JSON.parse(c.value)),
        [attributes]
    );

    return (
        <BillCell>
            <View style={{ ...styles.billRow, ...styles.cellRow }}>
                <Text
                    style={{
                        ...styles.cellLabel,
                        flex: 1,
                        textAlign: "center",
                    }}
                >
                    date
                </Text>

                <Text
                    style={{
                        ...styles.cellLabel,
                        flex: 1,
                        textAlign: "center",
                    }}
                >
                    amount
                </Text>

                <Text
                    style={{
                        ...styles.cellLabel,
                        flex: 1,
                        textAlign: "center",
                    }}
                >
                    adjustment
                </Text>
            </View>

            <View style={styles.cellSeperator} />

            {collections.map((c) => (
                <View style={{ ...styles.cellRow, ...styles.billRow }}>
                    <Text
                        style={{
                            ...styles.cellLabel,
                            flex: 1,
                            textAlign: "center",
                        }}
                    >
                        {c.collectionDate}
                    </Text>

                    <Text
                        style={{
                            ...styles.cellLabel,
                            flex: 1,
                            textAlign: "center",
                        }}
                    >
                        {currencyFormatter.format(c.amount)}
                    </Text>

                    <Text
                        style={{
                            ...styles.cellLabel,
                            flex: 1,
                            textAlign: "center",
                        }}
                    >
                        {c.adjustment}
                    </Text>
                </View>
            ))}
        </BillCell>
    );
};

const BillCalculations = ({ bill }: { bill: Bill }) => {
    const unitsAmount = useMemo(() => {
        if (bill) {
            if (typeof bill.unitPrice === "number") {
                return bill.unitsAmount;
            } else {
                const unitsAmount = [];
                let unitsRemaining = bill.currentUnits - bill.prevUnits;
                let idx = 0;

                for (const row of bill.unitPrice) {
                    const [upperRange, price] = Object.entries(row)[0];
                    let unitsInRange = 0;

                    if (idx === 0) {
                        unitsInRange = parseInt(upperRange);
                    } else if (parseInt(upperRange) === -1) {
                        unitsInRange = unitsRemaining;
                    } else {
                        unitsInRange =
                            parseInt(upperRange) -
                            parseInt(
                                Object.keys(bill.unitPrice[idx - 1])[idx - 1]
                            );
                    }

                    if (unitsRemaining - unitsInRange < 0) {
                        unitsInRange = unitsRemaining;
                        unitsRemaining = 0;
                    } else {
                        unitsRemaining -= unitsInRange;
                    }

                    unitsAmount.push({
                        units: unitsInRange,
                        price,
                        amount: (price as number) * unitsInRange,
                    });
                    idx++;
                }
                return unitsAmount;
            }
        } else {
            return 0;
        }
    }, [bill]);

    const totalUnitsAmount = useMemo(() => {
        if (Array.isArray(unitsAmount)) {
            return unitsAmount.reduce((prev, curr) => prev + curr.amount, 0);
        } else {
            return unitsAmount;
        }
    }, [unitsAmount]);
    return (
        <BillCell label="bill calculations">
            <View style={styles.cellRow}>
                {Array.isArray(unitsAmount) ? (
                    <>
                        <Text
                            style={{
                                ...styles.cellLabel,
                                margin: 0,
                                marginBottom: 4,
                            }}
                        >
                            units x rate = total
                        </Text>

                        {unitsAmount.map((row, idx) => (
                            <Text
                                key={idx}
                                style={{ ...styles.cellLabel, margin: 0 }}
                            >
                                {`${row.units} x ${
                                    row.price
                                } = ${numberFormatter.format(row.amount)}`}
                            </Text>
                        ))}

                        <Text style={styles.cellLabel}>
                            {`Total: ${currencyFormatter.format(
                                totalUnitsAmount
                            )}`}
                        </Text>
                    </>
                ) : (
                    <>
                        <Text
                            style={{
                                ...styles.cellLabel,
                                margin: 0,
                                marginBottom: 4,
                            }}
                        >
                            Units x Rate = Total
                        </Text>

                        <Text style={{ ...styles.cellValue, margin: 0 }}>
                            {`${bill.currentUnits - bill.prevUnits} x ${
                                bill.unitPrice
                            } = ${
                                (bill.currentUnits - bill.prevUnits) *
                                (bill.unitPrice as number)
                            }`}
                        </Text>
                    </>
                )}
            </View>

            <View
                style={{
                    ...styles.cellSeperator,
                    ...styles.cellRowSeperator,
                }}
            />

            <View
                style={{
                    ...styles.billRow,
                    ...styles.cellRow,
                }}
            >
                <Text style={{ ...styles.cellLabel, margin: 0 }}>
                    current bill
                </Text>
                <Text
                    style={{
                        ...styles.cellLabel,
                        margin: 0,
                        marginLeft: "auto",
                    }}
                >
                    {currencyFormatter.format(totalUnitsAmount)}
                </Text>
            </View>

            <View
                style={{
                    ...styles.cellSeperator,
                    ...styles.cellRowSeperator,
                }}
            />

            <View
                style={{
                    ...styles.billRow,
                    ...styles.cellRow,
                }}
            >
                <Text style={{ ...styles.cellLabel, margin: 0 }}>
                    line rent
                </Text>

                <Text
                    style={{
                        ...styles.cellLabel,
                        margin: 0,
                        marginLeft: "auto",
                    }}
                >
                    {currencyFormatter.format(bill.lineRent)}
                </Text>
            </View>

            <View
                style={{
                    ...styles.cellSeperator,
                    ...styles.cellRowSeperator,
                }}
            />

            <View
                style={{
                    ...styles.billRow,
                    ...styles.cellRow,
                }}
            >
                <Text style={{ ...styles.cellLabel, margin: 0 }}>arrears</Text>

                <Text
                    style={{
                        ...styles.cellLabel,
                        margin: 0,
                        marginLeft: "auto",
                    }}
                >
                    {currencyFormatter.format(bill.arrears)}
                </Text>
            </View>

            <View
                style={{
                    ...styles.cellSeperator,
                    ...styles.cellRowSeperator,
                }}
            />

            {bill.additionalCharges.length && (
                <>
                    <AdditionalChargesDescription
                        additionalCharges={bill.additionalCharges}
                    />

                    <View
                        style={{
                            ...styles.cellSeperator,
                            ...styles.cellRowSeperator,
                        }}
                    />
                </>
            )}

            <View
                style={{
                    ...styles.billRow,
                    ...styles.cellRow,
                }}
            >
                <Text style={{ ...styles.cellLabel, margin: 0 }}>
                    connection charges
                </Text>

                <Text
                    style={{
                        ...styles.cellLabel,
                        margin: 0,
                        marginLeft: "auto",
                    }}
                >
                    {currencyFormatter.format(bill.connectionChargesAmount)}
                </Text>
            </View>

            <View
                style={{
                    ...styles.cellSeperator,
                    ...styles.cellRowSeperator,
                }}
            />

            <View
                style={{
                    ...styles.billRow,
                    ...styles.cellRow,
                    // ...styles.calculationRow,
                }}
            >
                <Text style={{ ...styles.cellLabel, margin: 0 }}>
                    payable within due date
                </Text>

                <Text
                    style={{
                        ...styles.cellLabel,
                        margin: 0,
                        marginLeft: "auto",
                    }}
                >
                    {currencyFormatter.format(bill.totalAmount)}
                </Text>
            </View>

            <View
                style={{
                    ...styles.cellSeperator,
                    ...styles.cellRowSeperator,
                }}
            />

            <View
                style={{
                    ...styles.billRow,
                    ...styles.cellRow,
                    // ...styles.calculationRow,
                }}
            >
                <Text style={{ ...styles.cellLabel, margin: 0 }}>
                    L.P. surcharge
                </Text>

                <Text
                    style={{
                        ...styles.cellLabel,
                        margin: 0,
                        marginLeft: "auto",
                    }}
                >
                    {currencyFormatter.format(bill.surchargeAmount)}
                </Text>
            </View>

            <View
                style={{
                    ...styles.cellSeperator,
                    ...styles.cellRowSeperator,
                }}
            />

            <View
                style={{
                    ...styles.billRow,
                    ...styles.cellRow,
                }}
            >
                <Text style={{ ...styles.cellLabel, margin: 0 }}>
                    payable after due date
                </Text>

                <Text
                    style={{
                        ...styles.cellLabel,
                        margin: 0,
                        marginLeft: "auto",
                    }}
                >
                    {currencyFormatter.format(bill.payableAfterDueDate)}
                </Text>
            </View>
        </BillCell>
    );
};

const AdditionalChargeRow = ({ charge }: { charge: AdditionalCharge }) => {
    const collected = useMemo(() => {
        if (charge.collections) {
            return charge.collections.reduce((p, c) => p + c.amount, 0);
        } else {
            return 0;
        }
    }, [charge]);

    return (
        <>
            <View
                style={{
                    ...styles.billRow,
                    ...styles.cellRow,
                }}
            >
                <Text style={{ ...styles.cellLabel, margin: 0 }}>
                    {charge.head.name}
                </Text>
                <Text
                    style={{
                        ...styles.cellLabel,
                        margin: 0,
                        marginLeft: "auto",
                    }}
                >
                    {currencyFormatter.format(charge.amount - collected)}
                </Text>
            </View>
        </>
    );
};

const AdditionalChargesDescription = ({
    additionalCharges = [],
}: {
    additionalCharges: AdditionalCharge[];
}) => {
    return (
        <>
            {additionalCharges.map((ac) => (
                <AdditionalChargeRow key={ac.title + ac.amount} charge={ac} />
            ))}
        </>
    );
};

type PdfFeedbackProps = {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    bills: Bill[];
    logoSrc: string;
};

const PdfFeedbackModel = ({ open, setOpen }: PdfFeedbackProps) => {
    return (
        <Modal
            open={open}
            onClose={() => setOpen(false)}
            sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <>
                {/*  {bills.length > 0 && (
                    <BlobProvider
                        document={
                            <Document>
                                {bills.map((b) => (
                                    <Page key={b.id}>
                                        <SinglePdfBill
                                            logoSrc={logoSrc}
                                            bill={b}
                                        />
                                    </Page>
                                ))}
                            </Document>
                        }
                    >
                        {({ blob, loading, error }) => {
                            blob && handleBlob(blob);
                            return (
                                <>
                                    {error ? (
                                        <Alert severity="error">
                                            {error.message}
                                        </Alert>
                                    ) : loading ? (
                                        <CircularProgress />
                                    ) : (
                                        "not loading."
                                    )}
                                </>
                            );
                        }}
                    </BlobProvider>
                )} */}
            </>
        </Modal>
    );
};

const styles = StyleSheet.create({
    wrapper: { padding: 12 },

    header: { display: "flex", flexDirection: "row", alignItems: "center" },

    logo: { width: 48 },

    title: { fontSize: 16 },

    subTitle: { fontSize: 14, margin: "0 auto", marginTop: 4 },

    billRow: { display: "flex", flexDirection: "row", gap: 8 },

    billCell: {
        flex: 1,
        border: `1px solid ${theme.palette.grey[300]}`,
        borderRadius: 4,
        padding: "8px 0",
        // backgroundColor: theme.palette.grey[100],
    },

    cellLabel: {
        fontSize: 10,
        textTransform: "capitalize",
        margin: "0 auto",
    },

    cellValue: { fontSize: 10, margin: "0 auto" },

    cellRow: {
        width: "90%",
        marginRight: "auto",
        marginLeft: "auto",
    },

    cellRowSeperator: {
        backgroundColor: theme.palette.grey[300],
        margin: "4px auto",
    },

    customerCellLabel: {
        fontSize: 10,
        textTransform: "capitalize",
        flex: 1,
    },

    customerCellValue: {
        fontSize: 10,
        flex: 1,
        textTransform: "capitalize",
    },

    cellSeperator: {
        width: "90%",
        height: 1,
        margin: "8px auto",
        backgroundColor: theme.palette.grey[400],
    },

    prevRecords: {
        flex: undefined,
        width: "60%",
    },

    calculationRow: {
        borderTop: `1px solid ${theme.palette.grey[300]}`,
        // borderBottom: `1px solid ${theme.palette.grey[300]}`,
    },
});
