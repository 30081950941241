import { Add, FilterAltOutlined } from "@mui/icons-material";
import {
    Alert,
    Button,
    Card,
    Grid,
    TextField,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import { Box } from "@mui/system";
import { useQuery } from "@tanstack/react-query";
import { FormEvent, useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../../../Utilities/AppContext";
import { GET } from "../../../Utilities/BaseService";
import { MultiPermissionAuthorize } from "../../../Utilities/MultiPermissionAuthorize";
import { getSelectedFilters } from "../../Customer/Property/Property";
import SearchableInput from "../../Dashboard/Components/SearchableInput";
import { getReportTemplates } from "../../Payment/Payment";
import BaseTable from "../../Table/BaseTable";
import SearchBar from "../../Table/Components/SearchBar";
import { currencyFormatter } from "../Bill/Cells/Last12Summary";
import { dateFormatter, monthFormatter } from "../Bill/SingleBill";
import CollectionDetails from "./CollectionDetails";
type Filter = {
    search: string;
    district: number | "" | "all";
    village: number | "" | "all";
    hamlet: number | "" | "all";
    property: number | "" | "all";
    meterNo: string;
    collectionDate: string;
    startDate: string;
    endDate: string;
    paymentMethod: number | "" | "all";
};

const initialFilter: Filter = {
    search: "",
    district: "",
    village: "",
    hamlet: "",
    property: "",
    meterNo: "",
    collectionDate: "",
    startDate: "",
    endDate: "",
    paymentMethod: "",
};

const initialClear = {
    district: false,
    village: false,
    hamlet: false,
};

export const getAllCollections = (pagination: any, filter: any) => {
    return GET("/reading/collection", {
        ...Object.fromEntries(
            Object.entries(filter).filter((entry) => Boolean(entry[1]))
        ),
        page: pagination.page + 1,
        limit: pagination.limit,
    });
};

const Collection = () => {
    const [pagination, setPagination] = useState<any>({
        page: 0,
        limit: 100,
        count: 0,
    });
    const [filter, setFilter] = useState(initialFilter);
    const [intermediateFilter, setIntermediateFilter] = useState(initialFilter);
    const [feedback, setFeedback] = useState("");
    const [reportTemplates, setReportTemplates] = useState<string[]>([]);
    const [clearAll, setClearAll] = useState(initialClear);
    const [changeCount, setChangeCount] = useState(0);
    const [selectedFilters, setSelectedFilters] = useState({});
    const [isRequiredFiltersSelected, setIsRequiredFiltersSelected] =
        useState(false);

    const { user } = useContext(AppContext);
    const theme = useTheme();
    const mdDown = useMediaQuery(theme.breakpoints.down("md"));

    const { data, isLoading } = useQuery(
        ["collection", pagination.page, pagination.limit, filter],
        () => getAllCollections(pagination, filter),
        {
            enabled: Boolean(changeCount),
            onSuccess(res) {
                if (res.data) {
                    setPagination({
                        ...pagination,
                        count: res.data.count,
                    });

                    const firstRow = res.data.rows[0] ?? {};

                    const _selectedFills = getSelectedFilters(
                        {
                            district: firstRow.reading?.district,
                            village: firstRow.reading?.village,
                            hamlet: firstRow.reading?.hamlet,
                            powerstation: firstRow.reading?.powerstation,
                            startDate: { value: filter.startDate },
                            endDate: { value: filter.endDate },
                        },
                        [
                            "district",
                            "village",
                            "hamlet",
                            "startDate",
                            "endDate",
                        ],
                        filter,
                        {
                            district: { label: "District", valueKey: "name" },
                            village: { label: "Village", valueKey: "name" },
                            hamlet: { label: "Hamlet", valueKey: "name" },
                            powerstation: {
                                label: "Power Station",
                                valueKey: "name",
                            },
                            startDate: {
                                label: "Start date",
                                valueKey: "value",
                            },
                            endDate: {
                                label: "End date",
                                valueKey: "value",
                            },
                        }
                    );

                    setSelectedFilters(() => _selectedFills);
                }
            },
        }
    );

    const { data: reportTemps } = useQuery(
        ["report-templates"],
        () => getReportTemplates(user.id, "Collections Report"),
        {
            onSuccess(res) {
                setReportTemplates(() =>
                    res.data.rows.map((item: any) => ({
                        key: item.key,
                        value: JSON.parse(item.value),
                    }))
                );
            },
        }
    );

    const handleApplyFilter = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const requiredFilters = {
            district: Boolean(
                intermediateFilter.district &&
                    intermediateFilter.district !== "all"
            ),
            village: Boolean(
                intermediateFilter.village &&
                    intermediateFilter.village !== "all"
            ),
            hamlet: Boolean(
                intermediateFilter.hamlet && intermediateFilter.hamlet !== "all"
            ),
            meterNo: intermediateFilter.meterNo,
        };

        if (
            (requiredFilters.district &&
                requiredFilters.village &&
                requiredFilters.hamlet) ||
            requiredFilters.meterNo
        ) {
            setIsRequiredFiltersSelected(true);
            setChangeCount((c) => c + 1);
        } else {
            setIsRequiredFiltersSelected(false);
            setChangeCount(0);
        }
    };

    useEffect(() => {
        if (intermediateFilter.district === "all") {
            setClearAll({
                district: true,
                village: true,
                hamlet: true,
            });
            setIntermediateFilter({
                ...intermediateFilter,
                district: "",
                village: "",
                hamlet: "",
            });
        }

        if (intermediateFilter.village === "all") {
            setClearAll({ ...clearAll, village: true, hamlet: true });
            setIntermediateFilter({
                ...intermediateFilter,
                village: "",
                hamlet: "",
            });
        }

        if (intermediateFilter.hamlet === "all") {
            setClearAll({ ...clearAll, hamlet: true });
            setIntermediateFilter({ ...intermediateFilter, hamlet: "" });
        }

        return () => {
            setClearAll({
                district: false,
                village: false,
                hamlet: false,
            });
        };
    }, [
        intermediateFilter.district,
        intermediateFilter.village,
        intermediateFilter.hamlet,
    ]);

    useEffect(() => {
        if (changeCount) {
            setFilter({ ...filter, ...intermediateFilter });
        }
    }, [changeCount]);

    const createSummary = (cols: string[]) => {
        const summaryRow = ["Total"];

        if (
            !data ||
            !data.data ||
            !data.data.rows ||
            !Array.isArray(data.data.rows)
        ) {
            return summaryRow;
        }

        let amount = 0;
        let adjustment = 0;
        let totalAmount = 0;

        for (const row of data?.data.rows) {
            amount += row["amounts"]
                .split(",")
                .reduce(
                    (prev: number, curr: string) => prev + parseFloat(curr),
                    0
                );
            adjustment += row["adjs"]
                .split(",")
                .reduce(
                    (prev: number, curr: string) => prev + parseFloat(curr),
                    0
                );
            totalAmount += row["reading"]["totalAmount"];
        }

        for (const col of cols) {
            switch (col) {
                case "amount":
                    summaryRow.push(currencyFormatter.format(amount));
                    break;

                case "adjustment":
                    summaryRow.push(currencyFormatter.format(adjustment));
                    break;

                case "totalAmount":
                    summaryRow.push(currencyFormatter.format(totalAmount));
                    break;

                default:
                    summaryRow.push("");
            }
        }

        if (user.ops.includes("DELETE COLLECTION")) {
            summaryRow.push("");
        }

        return summaryRow;
    };

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                height: mdDown ? "auto" : `calc(100vh - 96px)`,
            }}
        >
            <Card
                elevation={0}
                sx={{
                    bgcolor: theme.palette.common.white,
                    p: 2,
                    mb: "1rem",
                }}
            >
                <Grid
                    container
                    alignItems="center"
                    spacing={1.5}
                    justifyContent="space-between"
                >
                    <Grid item xs={12} md={4}>
                        <Typography variant="h5" color="GrayText">
                            Collections
                        </Typography>
                    </Grid>

                    <Grid
                        item
                        xs={12}
                        md={6}
                        container
                        spacing={1.5}
                        justifyContent="flex-end"
                    >
                        <Grid item flex={1}>
                            <SearchBar
                                filter={intermediateFilter}
                                setFilter={setIntermediateFilter}
                            />
                        </Grid>

                        <Grid item xs={12} md="auto">
                            <MultiPermissionAuthorize ops={["COLLECT BILL"]}>
                                <Link
                                    to="/bills/collect"
                                    style={{ textDecoration: "none" }}
                                >
                                    <Button
                                        sx={{ height: "100%" }}
                                        variant="outlined"
                                        color="secondary"
                                        startIcon={<Add />}
                                        fullWidth
                                    >
                                        Add Collection
                                    </Button>
                                </Link>
                            </MultiPermissionAuthorize>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid
                    item
                    container
                    spacing={1.5}
                    mt={0.5}
                    component="form"
                    onSubmit={handleApplyFilter}
                >
                    <MultiPermissionAuthorize ops={["READ DISTRICT"]}>
                        <Grid item xs={12} sm={6} lg={3}>
                            <SearchableInput
                                api="/region/district"
                                label="District"
                                multiple={false}
                                filter={intermediateFilter}
                                setFilter={setIntermediateFilter}
                                clearAll={clearAll.district}
                            />
                        </Grid>
                    </MultiPermissionAuthorize>

                    <MultiPermissionAuthorize ops={["READ VILLAGE"]}>
                        <Grid item xs={12} sm={6} lg={3}>
                            <SearchableInput
                                api="/region/village"
                                label="Village"
                                multiple={false}
                                filter={intermediateFilter}
                                setFilter={setIntermediateFilter}
                                dep={{
                                    district: intermediateFilter.district,
                                }}
                                clearAll={clearAll.village}
                                // required={!intermediateFilter.meterNo}
                            />
                        </Grid>
                    </MultiPermissionAuthorize>

                    <MultiPermissionAuthorize ops={["READ HAMLET"]}>
                        <Grid item xs={12} sm={6} lg={3}>
                            <SearchableInput
                                api="/region/hamlet"
                                label="Hamlet"
                                multiple={false}
                                filter={intermediateFilter}
                                setFilter={setIntermediateFilter}
                                dep={
                                    intermediateFilter.village &&
                                    intermediateFilter.village !== "all"
                                        ? {
                                              village: [
                                                  intermediateFilter.village,
                                              ],
                                          }
                                        : { village: [0] }
                                }
                                clearAll={clearAll.hamlet}
                                // required={!intermediateFilter.meterNo}
                            />
                        </Grid>
                    </MultiPermissionAuthorize>

                    <MultiPermissionAuthorize ops={["READ METER"]}>
                        <Grid item xs={12} sm={6} lg={3}>
                            <TextField
                                fullWidth
                                label="Meter No."
                                size="small"
                                type="text"
                                value={intermediateFilter.meterNo}
                                name="meterNo"
                                onChange={(e) =>
                                    setIntermediateFilter({
                                        ...intermediateFilter,
                                        meterNo: e.target.value,
                                    })
                                }
                            />
                        </Grid>
                    </MultiPermissionAuthorize>

                    <Grid item xs={12} sm={6} lg={3}>
                        <TextField
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            label="Star Date"
                            size="small"
                            type="date"
                            name="startDate"
                            value={intermediateFilter.startDate}
                            onChange={(e) =>
                                setIntermediateFilter({
                                    ...intermediateFilter,
                                    startDate: e.target.value,
                                })
                            }
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} lg={3}>
                        <TextField
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            label="End Date"
                            size="small"
                            type="date"
                            name="endDate"
                            value={intermediateFilter.endDate}
                            required={Boolean(intermediateFilter.startDate)}
                            onChange={(e) =>
                                setIntermediateFilter({
                                    ...intermediateFilter,
                                    endDate: e.target.value,
                                })
                            }
                        />
                    </Grid>

                    <MultiPermissionAuthorize ops={["READ PAYMENT_METHOD"]}>
                        <Grid item xs={12} sm={6} lg={3}>
                            <SearchableInput
                                api="/reading/payment-method"
                                label="Payment Method"
                                multiple={false}
                                filter={intermediateFilter}
                                setFilter={setIntermediateFilter}
                                clearAll={clearAll.district}
                            />
                        </Grid>
                    </MultiPermissionAuthorize>

                    <Grid item xs={12} sm={6} lg={3}>
                        <Button
                            color="secondary"
                            variant="outlined"
                            type="submit"
                            sx={{
                                height: "100%",
                            }}
                            startIcon={<FilterAltOutlined />}
                            fullWidth
                        >
                            filter
                        </Button>
                    </Grid>
                </Grid>
            </Card>

            <Box sx={{ height: "55%" }}>
                {!isRequiredFiltersSelected && (
                    <Alert severity="info">
                        Please select "District", "Village", "Hamlet" or "Meter
                        Number"
                    </Alert>
                )}
                <BaseTable
                    load={Boolean(changeCount)}
                    headers={{
                        id: "ID",
                        meter: "Meter No.",
                        billMonth: "Bill Month",
                        collectionDate: "Collection Date",
                        totalAmount: "Total Amount",
                        amount: "Paid Amount",
                        adjustment: "Adjustment",
                        paymentMethod: "Payment Method",
                        description: "Description",
                        details: "Details",
                    }}
                    defaultSelectedHeaders={[
                        "meter",
                        "billMonth",
                        "totalAmount",
                        "amount",
                        "adjustment",
                        "collectionDate",
                        "description",
                        "details",
                    ]}
                    data={data?.data.rows.map((row: any) => ({
                        ...row,
                        meter: row.reading?.meter?.meterNo,
                        billMonth: monthFormatter.format(
                            new Date(
                                row.reading?.year ?? 0,
                                row.reading?.month - 1 ?? 0,
                                1
                            )
                        ),
                        collectionDate: dateFormatter.format(
                            new Date(row.collectionDate)
                        ),
                        paymentMethod: row.paymentMethod.name,
                        totalAmount: currencyFormatter.format(
                            row.reading?.totalAmount ?? 0
                        ),

                        details: (
                            <CollectionDetails
                                amounts={row.amounts.split(",") ?? []}
                                components={row.components.split(",") ?? []}
                                adjs={row.adjs.split(",") ?? []}
                            />
                        ),

                        amount: currencyFormatter.format(
                            row.amounts
                                .split(",")
                                .map((x: string) => parseFloat(x))
                                .reduce((acc: number, x: number) => acc + x, 0)
                        ),
                        adjustment: currencyFormatter.format(
                            row.adjs
                                .split(",")
                                .map((x: string) => parseFloat(x))
                                .reduce((acc: number, x: number) => acc + x, 0)
                        ),
                    }))}
                    reportName="Collections List"
                    delEndPoint="/reading/collection/many"
                    deletionProp="colls"
                    feedback={feedback}
                    setFeedback={setFeedback}
                    rowsCount={pagination.count}
                    pagination={pagination}
                    setPagination={setPagination}
                    isLoading={isLoading}
                    reportDetails={selectedFilters}
                    permissions={{
                        edit: [""],
                        delete: ["DELETE COLLECTION"],
                    }}
                    queryKey={[
                        "collection",
                        pagination.page,
                        pagination.limit,
                        filter,
                    ]}
                    hasReportTemplates
                    reportTemplates={reportTemplates}
                    summaryRow={createSummary}
                />
            </Box>
        </Box>
    );
};

export default Collection;
