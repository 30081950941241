import { CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { GET } from "./BaseService";

export default function Authenticate({ children, ...props }) {
    const [content, setContent] = useState(
        <div
            style={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <CircularProgress />
        </div>
    );
    const location = useLocation();
    const navigate = useNavigate();

    const loginOrRedirect = async () => {
        try {
            const sessionExpiry = localStorage.getItem("sessionExpiry");

            if (!sessionExpiry) {
                const res = await GET("/user/is-logged");
                if (res.status === 200) {
                    setContent(children);
                } else navigate("/login", { replace: true });
            } else {
                const expiryTime = parseInt(sessionExpiry);

                if (new Date().getTime() < expiryTime) {
                    setContent(children);
                } else {
                    localStorage.removeItem("sessionExpiry");
                    navigate("/login", { replace: true });
                }
            }
        } catch (err) {
            navigate("/login", { replace: true });
        }
    };

    useEffect(() => {
        let isMounted = true;
        loginOrRedirect();
        return () => {
            isMounted = false;
        };
    }, [location.pathname]);

    return content;
}
