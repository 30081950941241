import { createContext, Dispatch, SetStateAction } from "react";

type context = {
    user: any | null;
    setUser: Dispatch<SetStateAction<any>>;

    store: any;
    setStore: Dispatch<SetStateAction<any>>;
};

export const AppContext = createContext<context>({} as context);
