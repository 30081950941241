import { Add, VisibilityOutlined } from "@mui/icons-material";
import {
    Box,
    Button,
    Card,
    Grid,
    IconButton,
    Typography,
    useTheme,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { Link } from "react-router-dom";
import { GET } from "../../../Utilities/BaseService";
import LoadViewButton from "../../../Utilities/LoadViewButton";
import { MultiPermissionAuthorize } from "../../../Utilities/MultiPermissionAuthorize";
import BaseTable from "../../Table/BaseTable";
import SearchBar from "../../Table/Components/SearchBar";
import PaymentView from "../Components/PaymentView";

const getPaymentMethods = (filter: any, pagination: any) => {
    return GET("/reading/payment-method", {
        ...pagination,
        search: filter.search ? filter.search : undefined,
    });
};

export const PaymentMethods = () => {
    const [rowsCount, setRowsCount] = useState(0);
    const [pagination, setPagination] = useState<any>({ page: 0, limit: 100 });
    const [filter, setFilter] = useState({ search: "" });
    const [feedback, setFeedback] = useState("");
    const [activeRow, setActiveRow] = useState(false);
    const [load, setLoad] = useState(false);
    const theme = useTheme();

    const { data, isLoading } = useQuery(
        ["Payment Method", pagination.page, pagination.limit, filter.search],
        () =>
            getPaymentMethods(filter, {
                ...pagination,
                page: pagination.page + 1,
            })
    );

    const handleViewPayment = (row: any) => {
        setActiveRow(row);
    };

    return (
        <Box>
            <Card
                elevation={0}
                sx={{
                    bgcolor: theme.palette.common.white,
                    p: 2,
                    mb: "1rem",
                }}
            >
                <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Grid item xs={12} md={4}>
                        <Typography variant="h5" color="GrayText">
                            Payment Methods
                        </Typography>
                    </Grid>

                    <Grid
                        item
                        xs={12}
                        md={6}
                        container
                        spacing={1}
                        justifyContent="flex-end"
                    >
                        <Grid item flex={1}>
                            <SearchBar
                                filter={filter}
                                setFilter={setFilter}
                                debounceChange
                            />
                        </Grid>

                        <Grid item xs={12} md="auto">
                            <MultiPermissionAuthorize
                                ops={["CREATE PAYMENT_METHOD"]}
                            >
                                <Link
                                    to="/payment/method/add"
                                    style={{ textDecoration: "none" }}
                                >
                                    <Button
                                        sx={{ height: "100%" }}
                                        variant="outlined"
                                        color="secondary"
                                        startIcon={<Add />}
                                    >
                                        add payment method
                                    </Button>
                                </Link>
                            </MultiPermissionAuthorize>
                        </Grid>
                    </Grid>
                </Grid>
            </Card>

            {!load && (
                <LoadViewButton
                    label="view payment methods"
                    load={load}
                    setLoad={setLoad}
                />
            )}

            <BaseTable
                headers={{ id: "ID", name: "Name", view: "View" }}
                _printables={["id", "name"]}
                defaultSelectedHeaders={["id", "name", "view"]}
                data={data?.data.rows.map((row: any) => ({
                    ...row,
                    view: (
                        <IconButton
                            onClick={() => handleViewPayment(row)}
                            size="small"
                        >
                            <VisibilityOutlined fontSize="small" />
                        </IconButton>
                    ),
                }))}
                load={load}
                reportName="Payment Methods List"
                delEndPoint="/reading/payment-method"
                feedback={feedback}
                setFeedback={setFeedback}
                rowsCount={rowsCount}
                pagination={pagination}
                setPagination={setPagination}
                isLoading={isLoading}
                permissions={{
                    edit: [""],
                    delete: ["DELETE PAYMENT_METHOD"],
                }}
                queryKey={[
                    "Payment Method",
                    pagination.page,
                    pagination.limit,
                    filter.search,
                ]}
            />

            {Boolean(activeRow) && (
                <PaymentView
                    activeRow={activeRow}
                    setActiveRow={setActiveRow}
                />
            )}
        </Box>
    );
};
