import { Close, Done, InfoOutlined } from "@mui/icons-material";
import {
    Checkbox,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    TableCell,
    TableRow,
    TextField,
} from "@mui/material";
import {
    ChangeEvent,
    Dispatch,
    SetStateAction,
    useEffect,
    useMemo,
    useState,
} from "react";
import { MultiPermissionAuthorize } from "../../../Utilities/MultiPermissionAuthorize";

type Props = {
    collectee: Collectee;
    collections: { [key: number]: RawCollection };
    setCollections: Dispatch<SetStateAction<{ [key: number]: RawCollection }>>;
    collectionDate: string;
    paymentMethodRes: any[];
    collectionResults: any;
    setActiveResult: Dispatch<SetStateAction<any>>;
    count: number;
    checkAll: boolean;
    // checkedRows: { [key: number]: boolean };
    // setCheckedRows: Dispatch<SetStateAction<{ [key: number]: boolean }>>;
};

const calculateSurcharge = (
    collectee: Collectee,
    collectionDate: string
): number => {
    let surchargeAmount = 0;
    const _collDate = new Date(collectionDate).getTime();

    for (const reading of collectee.readings) {
        const dueDate = new Date(reading.dueDate).getTime();

        if (dueDate < _collDate) {
            surchargeAmount += reading.applicableSurcharge;
        }
    }

    return surchargeAmount;
};

export const CollectionRow = ({
    collectee,
    collections,
    setCollections,
    collectionDate,
    paymentMethodRes,
    collectionResults,
    setActiveResult,
    count,
    checkAll,
}: // checkedRows,
// setCheckedRows,
Props) => {
    const [isChecked, setIsChecked] = useState(false);

    const handleChange = (ev: ChangeEvent<any>, id: number) => {
        setIsChecked(true);
        setCollections(() => ({
            ...collections,
            [id]: { ...collections[id], [ev.target.name]: ev.target.value },
        }));
    };

    const surcharge = useMemo(() => {
        if (collectee) {
            return calculateSurcharge(collectee, collectionDate);
        } else {
            return 0;
        }
    }, [collectionDate, collectee]);

    const totalAmount = useMemo(
        () =>
            collectee.readings.reduce(
                (prev, curr) => prev + curr.totalAmount,
                0
            ) +
            collectee.readings.reduce(
                (prev: number, r: any) => prev + r.payedSurcharge,
                0
            ) -
            collectee.readings.reduce(
                (prev, curr) =>
                    prev +
                    curr.collections.reduce(
                        (p: any, c: any) => p + c.amount + c.adjustment,
                        0
                    ),
                0
            ) +
            surcharge,
        [surcharge]
    );

    useEffect(() => {
        setIsChecked(checkAll);
    }, [checkAll]);

    useEffect(() => {
        setCollections({
            ...collections,
            [collectee.id]: {
                ...collections[collectee.id],
                checked: isChecked,
            },
        });
    }, [isChecked]);

    return (
        <TableRow>
            <TableCell>
                <FormControl>
                    <Checkbox
                        checked={isChecked}
                        onChange={(e, check) => setIsChecked(check)}
                    />
                </FormControl>
            </TableCell>

            {collectionResults[collectee.id] ? (
                <TableCell>
                    <IconButton onClick={() => setActiveResult(collectee.id)}>
                        {collectionResults[collectee.id].success ? (
                            <Done color="success" />
                        ) : (
                            <Close color="error" />
                        )}
                    </IconButton>
                </TableCell>
            ) : (
                <TableCell>
                    <IconButton disabled>
                        <InfoOutlined color="disabled" />
                    </IconButton>
                </TableCell>
            )}
            <TableCell>{count}</TableCell>
            <TableCell sx={{ whiteSpace: "nowrap" }}>
                {collectee.meterNo}
            </TableCell>
            <TableCell>{collectee.property.customer.name}</TableCell>
            <TableCell>{collectee.property.name}</TableCell>
            <TableCell>{totalAmount}</TableCell>

            <TableCell>
                <TextField
                    sx={{ minWidth: "20ch" }}
                    size="small"
                    name="amount"
                    label="Amount"
                    value={collections[collectee.id]?.amount}
                    onChange={(ev) => handleChange(ev, collectee.id)}
                    type="number"
                    inputProps={{ min: 0, step: "any" }}
                />
            </TableCell>

            <MultiPermissionAuthorize ops={["CREATE READING_ADJUSTMENT"]}>
                <TableCell>
                    <TextField
                        sx={{ minWidth: "20ch" }}
                        size="small"
                        name="adjustment"
                        label="Adjustment"
                        value={collections[collectee.id]?.adjustment}
                        onChange={(ev) => handleChange(ev, collectee.id)}
                        type="number"
                        inputProps={{ min: 0 }}
                    />
                </TableCell>
            </MultiPermissionAuthorize>

            <TableCell style={{ minWidth: "256px" }}>
                {/* <UpdatedSearchableInput
                        api="/reading/payment-method"
                        label="Payment Method"
                        _name={collectee.id}
                        multiple={false}
                        // filter={collections}
                        // setFilter={setCollections}
                        clearAll={!collections[collectee.id]?.paymentMethod}
                        defaultValue={
                            collections[collectee.id]?.paymentMethod?.id
                        }
                        setOutput={(val) => {
                            setCollections((c) => ({
                                ...c,
                                [collectee.id]: {
                                    ...c[collectee.id],
                                    paymentMethod: val ? val.id : "",
                                },
                            }));
                        }}
                    /> */}
                <FormControl fullWidth size="small">
                    <InputLabel htmlFor="payment-method">
                        Payment Method
                    </InputLabel>

                    <Select
                        name="payment-method"
                        label="Payment Method"
                        value={collections[collectee.id]?.paymentMethod ?? ""}
                        onChange={(event) =>
                            setCollections((c) => ({
                                ...c,
                                [collectee.id]: {
                                    ...c[collectee.id],
                                    paymentMethod:
                                        (event.target.value as any) ?? "",
                                },
                            }))
                        }
                    >
                        {paymentMethodRes?.map((method) => (
                            <MenuItem key={method.id} value={method.id}>
                                {method.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                {/* <TextField
                     select
                    sx={{ minWidth: "30ch" }}
                    size="small"
                    name="paymentMethod"
                    label="Payment Method"
                    value={
                        collections[collectee.id]?.paymentMethod
                            ? collections[collectee.id].paymentMethod.id
                            : ""
                    }
                    onChange={(ev) => handleChange(ev, collectee.id)}
                    required
                >
                    {paymentMethodRes?.map((method) => (
                        <MenuItem key={method.id} value={method.id}>
                            {method.name}
                        </MenuItem>
                    ))}
                </TextField> */}
            </TableCell>

            <TableCell>
                <TextField
                    sx={{ minWidth: "50ch" }}
                    multiline
                    minRows={1}
                    maxRows={5}
                    size="small"
                    name="description"
                    label="Description"
                    value={collections[collectee.id]?.description}
                    onChange={(ev) => handleChange(ev, collectee.id)}
                    placeholder={"Optional"}
                />
            </TableCell>
        </TableRow>
    );
};
