import { Add } from "@mui/icons-material";
import {
    Alert,
    Button,
    Card,
    CardContent,
    CardHeader,
    CircularProgress,
    Divider,
    Grid,
    MenuItem,
    Snackbar,
    TextField,
} from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { theme } from "../../../Theme/theme";
import { GET, PATCH, POST } from "../../../Utilities/BaseService";
import SearchableInput from "../../Dashboard/Components/SearchableInput";

const getDistricts = () => {
    return GET("/region/district/", { limit: 0 });
};

const addVillage = (village: any) => POST("/region/village", village);

const updateVillage = ({ village, id }: any) => {
    return PATCH("/region/village", village, { id });
};

const AddVillage = ({ updateMode }: any) => {
    const [id, setId] = useState("");
    const [village, setVillage] = useState({ name: "", district: "" });
    const [filter, setFilter] = useState({ district: "" });
    const [feedback, setFeedback] = useState();

    const location = useLocation();
    const navigate = useNavigate();

    const { data } = useQuery(["districts"], getDistricts);

    const getVillageById = () => {
        return GET(`/region/village?id=${id}`);
    };

    const { data: villageById } = useQuery([`village`], getVillageById, {
        enabled: Boolean(id),
        onSuccess(response) {
            if (response.data.rows.length > 0) {
                const _v = response.data.rows[0];
                setVillage({ name: _v.name, district: _v.district.id });
            }
        },
        refetchOnMount: true,
    });

    const handleSubmit = (ev: any) => {
        ev.preventDefault();
        {
            !updateMode
                ? addMutation.mutate(village)
                : updateMutation.mutate({ village, id });
        }
    };

    const addMutation = useMutation(addVillage, {
        onSuccess(res) {
            return setFeedback(res.data.message);
        },
        onError(err: any) {
            return setFeedback(err.response.data.message);
        },
    });

    const updateMutation = useMutation(updateVillage, {
        onSuccess(res) {
            setFeedback(res.data.message);
        },
        onError(err: any) {
            return setFeedback(err.response.data.message);
        },
    });

    useEffect(() => {
        if (updateMode) {
            const params = new URLSearchParams(location.search);
            const _id = params.get("id");

            if (_id) {
                setId(_id);
            }
        }
    }, [updateMode]);

    useEffect(() => {
        setVillage({ ...village, district: filter.district });
    }, [filter]);

    return (
        <>
            <Card
                elevation={0}
                sx={{ bgcolor: theme.palette.common.white, mb: "1rem" }}
            >
                <CardHeader
                    title={updateMode ? "update village" : "add village"}
                    titleTypographyProps={{ color: "gray" }}
                    sx={{
                        textTransform: "capitalize",
                        bgcolor: theme.palette.common.white,
                    }}
                />

                <CardContent>
                    <Divider sx={{ mb: 5 }} />

                    <Grid
                        container
                        component="form"
                        onSubmit={handleSubmit}
                        spacing={3}
                    >
                        <Grid item xs={12} md={6}>
                            <TextField
                                size="small"
                                name="name"
                                value={village.name}
                                label="Village Name"
                                onChange={(ev) =>
                                    setVillage({
                                        ...village,
                                        name: ev.target.value,
                                    })
                                }
                                fullWidth
                                type="text"
                                required
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <SearchableInput
                                label="District"
                                api="/region/district"
                                filter={filter}
                                setFilter={setFilter}
                                multiple={false}
                                defaultValue={
                                    updateMode &&
                                    villageById?.data.rows[0].district
                                }
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Button
                                startIcon={<Add />}
                                endIcon={
                                    addMutation.isLoading ? (
                                        <CircularProgress
                                            color="secondary"
                                            size="1rem"
                                        />
                                    ) : (
                                        ""
                                    )
                                }
                                color="secondary"
                                variant="outlined"
                                size="small"
                                type="submit"
                            >
                                {updateMode ? "update" : "add"} village
                            </Button>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>

            {addMutation.isSuccess || updateMutation.isSuccess ? (
                <Alert severity="success">{feedback}.</Alert>
            ) : addMutation.isError || updateMutation.isError ? (
                <Alert severity="error">{feedback}.</Alert>
            ) : null}
        </>
    );
};

export default AddVillage;
