import { Box } from "@mui/material";
import { useContext } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { AppContext } from "../../Utilities/AppContext";
import Authenticate from "../../Utilities/Authenticate";
import { MultiPermissionAuthorize } from "../../Utilities/MultiPermissionAuthorize";
import WithTitle from "../../Utilities/WithTitle";
import AddConnectionType from "../Connection/AddConnectionType";
import AddConnectionCharge from "../Connection/ConnectionCharges/AddConnectionCharge";
import ConnectionCharge from "../Connection/ConnectionCharges/ConnectionCharge";
import ConnectionTypes from "../Connection/ConnectionTypes";
import AddLineRent from "../Connection/LineRent/AddLineRent";
import LineRent from "../Connection/LineRent/LineRent";
import AddPriceType from "../Connection/PriceType/AddPriceType";
import PriceType from "../Connection/PriceType/PriceType";
import AddSurcharge from "../Connection/Surcharge/AddSurcharge";
import Surcharge from "../Connection/Surcharge/Surcharge";
import AddUnitChargeRule from "../Connection/UnitChargeRule/AddUnitChargeRule";
import UnitChargeRule from "../Connection/UnitChargeRule/UnitChargeRule";
import AddCustomer from "../Customer/AddCustomer";
import CombinedView from "../Customer/CombinedView";
import Customer from "../Customer/Customer";
import AddProperty from "../Customer/Property/AddProperty";
import Property from "../Customer/Property/Property";
import AddPropertyType from "../Customer/PropertyType/AddPropertyType";
import PropertyType from "../Customer/PropertyType/PropertyType";
import CustomerTimeline from "../Customer/Timeline/CustomerTimeline";
import AddMeter from "../Customer/meter/AddMeter";
import Meter from "../Customer/meter/Meter";
import Dashboard from "../Dashboard/Dashboard";
import { Footer } from "../Header/Footer";
import { Header } from "../Header/Header";
import Login from "../Login/Login";
import { Logs } from "../Logsystem/Logs";
import { AddPayment } from "../Payment/AddPayment";
import { AddPaymentCollection } from "../Payment/Collection/AddPaymentCollection";
import { PaymentCollection } from "../Payment/Collection/PaymentCollection";
import { Payment } from "../Payment/Payment";
import { AddPaymentHead } from "../Payment/PaymentHead/AddPaymentHead";
import { PaymentHead } from "../Payment/PaymentHead/PaymentHead";
import { AddPaymentMethod } from "../Payment/PaymentMethod/AddPaymentMethod";
import { PaymentMethods } from "../Payment/PaymentMethod/PaymentMethods";
import AddPowerStation from "../Power-station/AddPowerStation";
import PowerStation from "../Power-station/PowerStation";
import { Bills } from "../Reading/Bill/Bills";
import { SingleBill } from "../Reading/Bill/SingleBill";
import { CollectBills } from "../Reading/Collection/CollectBills";
import Collection from "../Reading/Collection/Collection";
import { AddReadings } from "../Reading/Reading/AddReadings";
import Reading from "../Reading/Reading/Reading";
import AddDistrict from "../Region/District/AddDistrict";
import District from "../Region/District/District";
import AddHamlet from "../Region/Hamlet/AddHamlet";
import Hamlet from "../Region/Hamlet/Hamlet";
import AddVillage from "../Region/Village/AddVillage";
import Villages from "../Region/Village/Villages";
import CustomerReport from "../Report/CustomerReport";
import AddRole from "../Role/AddRole";
import Roles from "../Role/Roles";
import AddSettings from "../Settings/AddSettings";
import Settings from "../Settings/Settings";
import AddArrearsSlip from "../Slip/Arrears/AddArrearsSlip";
import ArrearsSlip from "../Slip/Arrears/ArrearsSlip";
import AddConnectionSlip from "../Slip/Connection/AddConnectionSlip";
import ConnectionSlip from "../Slip/Connection/ConnectionSlip";
import AddMeterReplacementSlip from "../Slip/MeterReplacement/AddMeterReplacementSlip";
import MeterReplacementSlip from "../Slip/MeterReplacement/MeterReplacementSlip";
import { CreateConsumption } from "../UnitConsumption/CreateConsumption";
import { UnitConsumptionList } from "../UnitConsumption/UnitConsumptionList";
import AddUser from "../User/AddUser";
import Users from "../User/Users";
import FourOFourPage from "./FourOFourPage";
import { OfflinePage } from "./OfflinePage";

const Mainview = ({ open }: any) => {
    const location = useLocation();
    const { user } = useContext(AppContext);

    return (
        <Box
            sx={
                location.pathname !== "/login"
                    ? {
                          width: !user
                              ? "100%"
                              : open
                              ? "calc(100% - 240px)"
                              : "100%",
                          mt: "75px",
                          ml: "auto",
                          px: 2,
                      }
                    : null
            }
        >
            <Routes>
                <Route
                    path="/unit-consumption"
                    element={
                        <Authenticate>
                            <MultiPermissionAuthorize
                                ops={["READ UNIT_CONSUMPTION"]}
                            >
                                <UnitConsumptionList />
                            </MultiPermissionAuthorize>
                        </Authenticate>
                    }
                />

                <Route
                    path="/unit-consumption/add"
                    element={
                        <Authenticate>
                            <MultiPermissionAuthorize
                                ops={["CREATE UNIT_CONSUMPTION"]}
                            >
                                <CreateConsumption />
                            </MultiPermissionAuthorize>
                        </Authenticate>
                    }
                />

                <Route
                    path="/login"
                    element={
                        <WithTitle title="SRSP | Login">
                            <Login />
                        </WithTitle>
                    }
                />
                {/*============================================= DASHBOARD =============================================*/}

                <Route
                    path={"/"}
                    element={
                        <Authenticate>
                            <MultiPermissionAuthorize
                                ops={[
                                    "READ DASHBOARD",
                                    "READ SIMPLE_DASHBOARD",
                                ]}
                                strategy="some"
                            >
                                <Dashboard />
                            </MultiPermissionAuthorize>
                        </Authenticate>
                    }
                />

                <Route
                    path={"/dashboard"}
                    element={
                        <Authenticate>
                            <MultiPermissionAuthorize
                                ops={[
                                    "READ DASHBOARD",
                                    "READ SIMPLE_DASHBOARD",
                                ]}
                                strategy="some"
                            >
                                <Dashboard />
                            </MultiPermissionAuthorize>
                        </Authenticate>
                    }
                />

                {/*=============================================== USERS ===============================================*/}
                <Route
                    path="/users"
                    element={
                        <Authenticate>
                            <MultiPermissionAuthorize ops={["READ USER"]}>
                                <Users />
                            </MultiPermissionAuthorize>
                        </Authenticate>
                    }
                />
                <Route
                    path="/users/add"
                    element={
                        <Authenticate>
                            <MultiPermissionAuthorize ops={["CREATE USER"]}>
                                <AddUser />
                            </MultiPermissionAuthorize>
                        </Authenticate>
                    }
                />
                <Route
                    path="/users/update"
                    element={
                        <Authenticate>
                            <AddUser updateMode />
                        </Authenticate>
                    }
                />
                {/*=============================================== ROLES ===============================================*/}
                <Route
                    path="/roles"
                    element={
                        <Authenticate>
                            <MultiPermissionAuthorize ops={["READ ROLE"]}>
                                <Roles />
                            </MultiPermissionAuthorize>
                        </Authenticate>
                    }
                />
                <Route
                    path="/roles/add"
                    element={
                        <Authenticate>
                            <MultiPermissionAuthorize ops={["CREATE ROLE"]}>
                                <AddRole />
                            </MultiPermissionAuthorize>
                        </Authenticate>
                    }
                />
                <Route
                    path="/roles/update"
                    element={
                        <Authenticate>
                            <MultiPermissionAuthorize ops={["UPDATE ROLE"]}>
                                <AddRole updateMode />
                            </MultiPermissionAuthorize>
                        </Authenticate>
                    }
                />
                {/*============================================== REGIONS ==============================================*/}
                <Route
                    path="/regions/districts"
                    element={
                        <Authenticate>
                            <MultiPermissionAuthorize ops={["READ DISTRICT"]}>
                                <District />
                            </MultiPermissionAuthorize>
                        </Authenticate>
                    }
                />
                <Route
                    path="/regions/districts/add"
                    element={
                        <Authenticate>
                            <MultiPermissionAuthorize ops={["CREATE DISTRICT"]}>
                                <AddDistrict />
                            </MultiPermissionAuthorize>
                        </Authenticate>
                    }
                />
                <Route
                    path="/regions/districts/update"
                    element={
                        <Authenticate>
                            <MultiPermissionAuthorize ops={["UPDATE DISTRICT"]}>
                                <AddDistrict updateMode />
                            </MultiPermissionAuthorize>
                        </Authenticate>
                    }
                />
                <Route
                    path="/regions/villages"
                    element={
                        <Authenticate>
                            <MultiPermissionAuthorize ops={["READ VILLAGE"]}>
                                <Villages />
                            </MultiPermissionAuthorize>
                        </Authenticate>
                    }
                />
                <Route
                    path="/regions/villages/add"
                    element={
                        <Authenticate>
                            <MultiPermissionAuthorize ops={["CREATE VILLAGE"]}>
                                <AddVillage />
                            </MultiPermissionAuthorize>
                        </Authenticate>
                    }
                />
                <Route
                    path="/regions/villages/update"
                    element={
                        <Authenticate>
                            <MultiPermissionAuthorize ops={["UPDATE VILLAGE"]}>
                                <AddVillage updateMode />
                            </MultiPermissionAuthorize>
                        </Authenticate>
                    }
                />
                <Route
                    path="/regions/hamlets"
                    element={
                        <Authenticate>
                            <MultiPermissionAuthorize ops={["READ HAMLET"]}>
                                <Hamlet />
                            </MultiPermissionAuthorize>
                        </Authenticate>
                    }
                />
                <Route
                    path="/regions/hamlets/add"
                    element={
                        <Authenticate>
                            <MultiPermissionAuthorize ops={["CREATE HAMLET"]}>
                                <AddHamlet />
                            </MultiPermissionAuthorize>
                        </Authenticate>
                    }
                />
                <Route
                    path="/regions/hamlets/update"
                    element={
                        <Authenticate>
                            <MultiPermissionAuthorize ops={["UPDATE HAMLET"]}>
                                <AddHamlet updateMode />
                            </MultiPermissionAuthorize>
                        </Authenticate>
                    }
                />
                {/*============================================ POWERSTATION ===========================================*/}
                <Route
                    path="/powerstations"
                    element={
                        <Authenticate>
                            <MultiPermissionAuthorize
                                ops={["READ POWERSTATION"]}
                            >
                                <PowerStation />
                            </MultiPermissionAuthorize>
                        </Authenticate>
                    }
                />
                <Route
                    path="/powerstations/add"
                    element={
                        <Authenticate>
                            <MultiPermissionAuthorize
                                ops={["CREATE POWERSTATION"]}
                            >
                                <AddPowerStation />
                            </MultiPermissionAuthorize>
                        </Authenticate>
                    }
                />
                <Route
                    path="/powerstations/update"
                    element={
                        <Authenticate>
                            <MultiPermissionAuthorize
                                ops={["UPDATE POWERSTATION"]}
                            >
                                <AddPowerStation updateMode />
                            </MultiPermissionAuthorize>
                        </Authenticate>
                    }
                />
                {/*============================================ CONNECTIONS ============================================*/}
                <Route
                    path="/connections/unit-charges"
                    element={
                        <Authenticate>
                            <MultiPermissionAuthorize ops={["READ RULE"]}>
                                <UnitChargeRule />
                            </MultiPermissionAuthorize>
                        </Authenticate>
                    }
                />

                <Route
                    path="/connections/unit-charges/add"
                    element={
                        <Authenticate>
                            <MultiPermissionAuthorize ops={["CREATE RULE"]}>
                                <AddUnitChargeRule />
                            </MultiPermissionAuthorize>
                        </Authenticate>
                    }
                />

                <Route
                    path="/connections/surcharge"
                    element={
                        <Authenticate>
                            <MultiPermissionAuthorize ops={["READ SURCHARGE"]}>
                                <Surcharge />
                            </MultiPermissionAuthorize>
                        </Authenticate>
                    }
                />

                <Route
                    path="/connections/surcharge/add"
                    element={
                        <Authenticate>
                            <MultiPermissionAuthorize
                                ops={["CREATE SURCHARGE"]}
                            >
                                <AddSurcharge />
                            </MultiPermissionAuthorize>
                        </Authenticate>
                    }
                />

                <Route
                    path="/connections/surcharge/update"
                    element={
                        <Authenticate>
                            <MultiPermissionAuthorize
                                ops={["UPDATE SURCHARGE"]}
                            >
                                <AddSurcharge updateMode />
                            </MultiPermissionAuthorize>
                        </Authenticate>
                    }
                />

                <Route
                    path="/connections/line-rent"
                    element={
                        <Authenticate>
                            <MultiPermissionAuthorize ops={["READ LINE_RENT"]}>
                                <LineRent />
                            </MultiPermissionAuthorize>
                        </Authenticate>
                    }
                />

                <Route
                    path="/connections/line-rent/add"
                    element={
                        <Authenticate>
                            <MultiPermissionAuthorize
                                ops={["CREATE LINE_RENT"]}
                            >
                                <AddLineRent />
                            </MultiPermissionAuthorize>
                        </Authenticate>
                    }
                />

                <Route
                    path="/connections/line-rent/update"
                    element={
                        <Authenticate>
                            <MultiPermissionAuthorize
                                ops={["UPDATE LINE_RENT"]}
                            >
                                <AddLineRent updateMode />
                            </MultiPermissionAuthorize>
                        </Authenticate>
                    }
                />

                <Route
                    path="/connections/charges"
                    element={
                        <Authenticate>
                            <MultiPermissionAuthorize
                                ops={["READ CONNECTION_CHARGES"]}
                            >
                                <ConnectionCharge />
                            </MultiPermissionAuthorize>
                        </Authenticate>
                    }
                />

                <Route
                    path="/connections/charges/add"
                    element={
                        <Authenticate>
                            <MultiPermissionAuthorize
                                ops={["CREATE CONNECTION_CHARGES"]}
                            >
                                <AddConnectionCharge />
                            </MultiPermissionAuthorize>
                        </Authenticate>
                    }
                />

                <Route
                    path="/connections/charges/update"
                    element={
                        <Authenticate>
                            <MultiPermissionAuthorize
                                ops={["UPDATE CONNECTION_CHARGES"]}
                            >
                                <AddConnectionCharge updateMode />
                            </MultiPermissionAuthorize>
                        </Authenticate>
                    }
                />

                <Route
                    path="/connections/types"
                    element={
                        <Authenticate>
                            <MultiPermissionAuthorize
                                ops={["READ CONNECTION_TYPE"]}
                            >
                                <ConnectionTypes />
                            </MultiPermissionAuthorize>
                        </Authenticate>
                    }
                />

                <Route
                    path="/connections/types/add"
                    element={
                        <Authenticate>
                            <MultiPermissionAuthorize
                                ops={["CREATE CONNECTION_TYPE"]}
                            >
                                <AddConnectionType />
                            </MultiPermissionAuthorize>
                        </Authenticate>
                    }
                />

                <Route
                    path="/connections/types/update"
                    element={
                        <Authenticate>
                            <MultiPermissionAuthorize
                                ops={["UPDATE CONNECTION_TYPE"]}
                            >
                                <AddConnectionType updateMode />
                            </MultiPermissionAuthorize>
                        </Authenticate>
                    }
                />

                <Route
                    path="/connections/price-types"
                    element={
                        <Authenticate>
                            <MultiPermissionAuthorize ops={["READ PRICE_TYPE"]}>
                                <PriceType />
                            </MultiPermissionAuthorize>
                        </Authenticate>
                    }
                />

                <Route
                    path="/connections/price-types/add"
                    element={
                        <Authenticate>
                            <MultiPermissionAuthorize
                                ops={["CREATE PRICE_TYPE"]}
                            >
                                <AddPriceType />
                            </MultiPermissionAuthorize>
                        </Authenticate>
                    }
                />

                <Route
                    path="/connections/price-types/update"
                    element={
                        <Authenticate>
                            <MultiPermissionAuthorize ops={["EDIT PRICE_TYPE"]}>
                                <AddPriceType updateMode />
                            </MultiPermissionAuthorize>
                        </Authenticate>
                    }
                />

                {/*============================================= CUSTOMERS =============================================*/}
                <Route
                    path="/customer/consumers"
                    element={
                        <Authenticate>
                            <MultiPermissionAuthorize ops={["READ CUSTOMER"]}>
                                <Customer />
                            </MultiPermissionAuthorize>
                        </Authenticate>
                    }
                />

                <Route
                    path="/customer/consumers/add"
                    element={
                        <Authenticate>
                            <MultiPermissionAuthorize ops={["CREATE CUSTOMER"]}>
                                <AddCustomer />
                            </MultiPermissionAuthorize>
                        </Authenticate>
                    }
                />

                <Route
                    path="/customer/consumers/update"
                    element={
                        <Authenticate>
                            <MultiPermissionAuthorize ops={["UPDATE CUSTOMER"]}>
                                <AddCustomer updateMode />
                            </MultiPermissionAuthorize>
                        </Authenticate>
                    }
                />

                <Route
                    path="/customer/property-type"
                    element={
                        <Authenticate>
                            <MultiPermissionAuthorize
                                ops={["READ PROPERTY_TYPE"]}
                            >
                                <PropertyType />
                            </MultiPermissionAuthorize>
                        </Authenticate>
                    }
                />

                <Route
                    path="/customer/property-type/add"
                    element={
                        <Authenticate>
                            <MultiPermissionAuthorize
                                ops={["CREATE PROPERTY_TYPE"]}
                            >
                                <AddPropertyType />
                            </MultiPermissionAuthorize>
                        </Authenticate>
                    }
                />

                <Route
                    path="/customer/property-type/update"
                    element={
                        <Authenticate>
                            <MultiPermissionAuthorize
                                ops={["UPDATE PROPERTY_TYPE"]}
                            >
                                <AddPropertyType updateMode />
                            </MultiPermissionAuthorize>
                        </Authenticate>
                    }
                />

                <Route
                    path="/customer/property"
                    element={
                        <Authenticate>
                            <MultiPermissionAuthorize ops={["READ PROPERTY"]}>
                                <Property />
                            </MultiPermissionAuthorize>
                        </Authenticate>
                    }
                />

                <Route
                    path="/customer/property/add"
                    element={
                        <Authenticate>
                            <MultiPermissionAuthorize ops={["CREATE PROPERTY"]}>
                                <AddProperty />
                            </MultiPermissionAuthorize>
                        </Authenticate>
                    }
                />

                <Route
                    path="/customer/property/update"
                    element={
                        <Authenticate>
                            <MultiPermissionAuthorize ops={["UPDATE PROPERTY"]}>
                                <AddProperty updateMode />
                            </MultiPermissionAuthorize>
                        </Authenticate>
                    }
                />

                <Route
                    path="/customer/meters"
                    element={
                        <Authenticate>
                            <MultiPermissionAuthorize ops={["READ METER"]}>
                                <Meter />
                            </MultiPermissionAuthorize>
                        </Authenticate>
                    }
                />

                <Route
                    path="/customer/meters/add"
                    element={
                        <Authenticate>
                            <MultiPermissionAuthorize ops={["CREATE METER"]}>
                                <AddMeter />
                            </MultiPermissionAuthorize>
                        </Authenticate>
                    }
                />

                <Route
                    path="/customer/meters/update"
                    element={
                        <Authenticate>
                            <MultiPermissionAuthorize ops={["UPDATE METER"]}>
                                <AddMeter updateMode />
                            </MultiPermissionAuthorize>
                        </Authenticate>
                    }
                />

                <Route
                    path="/customer/connection"
                    element={
                        <Authenticate>
                            <MultiPermissionAuthorize ops={["CREATE CUSTOMER"]}>
                                <CombinedView />
                            </MultiPermissionAuthorize>
                        </Authenticate>
                    }
                />
                <Route
                    path="/customer/timeline"
                    element={
                        <Authenticate>
                            <MultiPermissionAuthorize ops={["READ CUSTOMER"]}>
                                <CustomerTimeline />
                            </MultiPermissionAuthorize>
                        </Authenticate>
                    }
                />

                {/*=============================================== BILLS ===============================================*/}

                <Route
                    path="/bills/readings"
                    element={
                        <Authenticate>
                            <MultiPermissionAuthorize ops={["READ READING"]}>
                                <Reading />
                            </MultiPermissionAuthorize>
                        </Authenticate>
                    }
                />

                <Route
                    path="/bills/readings/add"
                    element={
                        <Authenticate>
                            <MultiPermissionAuthorize ops={["CREATE READING"]}>
                                <AddReadings />
                            </MultiPermissionAuthorize>
                        </Authenticate>
                    }
                />

                <Route
                    path="/bills/readings/update"
                    element={
                        <Authenticate>
                            <MultiPermissionAuthorize ops={["UPDATE READING"]}>
                                <AddReadings updateMode />
                            </MultiPermissionAuthorize>
                        </Authenticate>
                    }
                />

                <Route
                    path="/bills"
                    element={
                        <Authenticate>
                            <MultiPermissionAuthorize ops={["GENERATE BILL"]}>
                                <Bills />
                            </MultiPermissionAuthorize>
                        </Authenticate>
                    }
                />

                <Route
                    path="/bill/single"
                    element={
                        <Authenticate>
                            <SingleBill
                                gridProps={{ flex: "1" }}
                                includeOfficeCopy
                            />
                        </Authenticate>
                    }
                />

                <Route
                    path="/bills/collection"
                    element={
                        <Authenticate>
                            <MultiPermissionAuthorize ops={["READ COLLECTION"]}>
                                <Collection />
                            </MultiPermissionAuthorize>
                        </Authenticate>
                    }
                />

                <Route
                    path="/bills/collect"
                    element={
                        <Authenticate>
                            <MultiPermissionAuthorize ops={["COLLECT BILL"]}>
                                <CollectBills />
                            </MultiPermissionAuthorize>
                        </Authenticate>
                    }
                />

                <Route
                    path="/settings"
                    element={
                        <Authenticate>
                            <MultiPermissionAuthorize ops={["READ SETTING"]}>
                                <Settings />
                            </MultiPermissionAuthorize>
                        </Authenticate>
                    }
                />
                <Route
                    path="/settings/add"
                    element={
                        <Authenticate>
                            <MultiPermissionAuthorize ops={["READ SETTING"]}>
                                <AddSettings />
                            </MultiPermissionAuthorize>
                        </Authenticate>
                    }
                />

                <Route
                    path="/logs"
                    element={
                        <Authenticate>
                            <Logs />
                        </Authenticate>
                    }
                />
                <Route
                    path="/payment"
                    element={
                        <Authenticate>
                            <MultiPermissionAuthorize ops={["READ PAYMENT"]}>
                                <Payment />
                            </MultiPermissionAuthorize>
                        </Authenticate>
                    }
                />
                <Route
                    path="/payment/add"
                    element={
                        <Authenticate>
                            <MultiPermissionAuthorize ops={["CREATE PAYMENT"]}>
                                <AddPayment />
                            </MultiPermissionAuthorize>
                        </Authenticate>
                    }
                />
                <Route
                    path="/payment/method"
                    element={
                        <Authenticate>
                            <MultiPermissionAuthorize
                                ops={["READ PAYMENT_METHOD"]}
                            >
                                <PaymentMethods />
                            </MultiPermissionAuthorize>
                        </Authenticate>
                    }
                />
                <Route
                    path="/payment/method/add"
                    element={
                        <Authenticate>
                            <MultiPermissionAuthorize
                                ops={["CREATE PAYMENT_METHOD"]}
                            >
                                <AddPaymentMethod />
                            </MultiPermissionAuthorize>
                        </Authenticate>
                    }
                />
                <Route
                    path="/payment/head"
                    element={
                        <Authenticate>
                            <MultiPermissionAuthorize
                                ops={["READ PAYMENT_HEAD"]}
                            >
                                <PaymentHead />
                            </MultiPermissionAuthorize>
                        </Authenticate>
                    }
                />
                <Route
                    path="/payment/head/add"
                    element={
                        <Authenticate>
                            <MultiPermissionAuthorize
                                ops={["CREATE PAYMENT_HEAD"]}
                            >
                                <AddPaymentHead />
                            </MultiPermissionAuthorize>
                        </Authenticate>
                    }
                />
                <Route
                    path="/payment/head/update"
                    element={
                        <Authenticate>
                            <MultiPermissionAuthorize
                                ops={["UPDATE PAYMENT_HEAD"]}
                            >
                                <AddPaymentHead updateMode />
                            </MultiPermissionAuthorize>
                        </Authenticate>
                    }
                />
                <Route
                    path="/payment/collection"
                    element={
                        <Authenticate>
                            <MultiPermissionAuthorize
                                ops={["READ PAYMENT_COLLECTION"]}
                            >
                                <PaymentCollection />
                            </MultiPermissionAuthorize>
                        </Authenticate>
                    }
                />
                <Route
                    path="/payment/collection/add"
                    element={
                        <Authenticate>
                            <MultiPermissionAuthorize
                                ops={["UPDATE PAYMENT_COLLECTION"]}
                            >
                                <AddPaymentCollection />
                            </MultiPermissionAuthorize>
                        </Authenticate>
                    }
                />

                <Route
                    path="/slips/connection"
                    element={
                        <Authenticate>
                            <MultiPermissionAuthorize ops={["READ SLIP"]}>
                                <ConnectionSlip />
                            </MultiPermissionAuthorize>
                        </Authenticate>
                    }
                />

                <Route
                    path="/slips/connection/add"
                    element={
                        <Authenticate>
                            <MultiPermissionAuthorize ops={["CREATE SLIP"]}>
                                <AddConnectionSlip />
                            </MultiPermissionAuthorize>
                        </Authenticate>
                    }
                />

                <Route
                    path="/slips/meter-replacement"
                    element={
                        <Authenticate>
                            <MultiPermissionAuthorize ops={["READ SLIP"]}>
                                <MeterReplacementSlip />
                            </MultiPermissionAuthorize>
                        </Authenticate>
                    }
                />

                <Route
                    path="/slips/meter-replacement/add"
                    element={
                        <Authenticate>
                            <MultiPermissionAuthorize ops={["CREATE SLIP"]}>
                                <AddMeterReplacementSlip />
                            </MultiPermissionAuthorize>
                        </Authenticate>
                    }
                />

                <Route
                    path="/slips/arrears"
                    element={
                        <Authenticate>
                            <MultiPermissionAuthorize ops={["READ SLIP"]}>
                                <ArrearsSlip />
                            </MultiPermissionAuthorize>
                        </Authenticate>
                    }
                />

                <Route
                    path="/slips/arrears/add"
                    element={
                        <Authenticate>
                            <MultiPermissionAuthorize ops={["CREATE SLIP"]}>
                                <AddArrearsSlip />
                            </MultiPermissionAuthorize>
                        </Authenticate>
                    }
                />

                <Route
                    path="/report/customer"
                    element={
                        <Authenticate>
                            <MultiPermissionAuthorize
                                ops={["READ CUSTOMER_REPORT"]}
                            >
                                <CustomerReport />
                            </MultiPermissionAuthorize>
                        </Authenticate>
                    }
                />
                <Route
                    path="/header"
                    element={
                        <>
                            <Header name="Collection Report" />
                            <Footer />
                        </>
                    }
                />
                <Route path="/no-internet" element={<OfflinePage />} />

                <Route path="*" element={<FourOFourPage />} />
            </Routes>
        </Box>
    );
};

export default Mainview;
