import {
    Dialog,
    DialogContent,
    DialogTitle,
    Table,
    TableBody,
    TableCell,
    TableRow,
} from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import { currencyFormatter } from "../../Reading/Bill/Cells/Last12Summary";

type Props = {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    meter: any;
};

const PreviousMeter = ({ open = false, setOpen, meter }: Props) => {
    return (
        <Dialog open={open} onClose={() => setOpen(false)} fullWidth>
            <DialogTitle>Previouse Meter Info</DialogTitle>

            <DialogContent>
                <Table size="small">
                    <TableBody>
                        <TableRow>
                            <TableCell>Meter No.</TableCell>
                            <TableCell>{meter.meterNo}</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell>Meter Status</TableCell>
                            <TableCell sx={{ textTransform: "capitalize" }}>
                                {meter.status}
                            </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell>Connection Charges</TableCell>
                            <TableCell>
                                {currencyFormatter.format(
                                    meter.connectionCharges
                                )}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </DialogContent>
        </Dialog>
    );
};

export default PreviousMeter;
