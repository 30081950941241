import { ReceiptOutlined } from "@mui/icons-material";
import {
    Box,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableRow,
} from "@mui/material";
import { ReactNode, useRef, useState } from "react";
import { Print } from "../../Utilities/Print";
import { Footer } from "../Header/Footer";
import { Header } from "../Header/Header";
import { Slip } from "./types";

type Props = { data: Slip };

const SingleSlip = ({ data }: Props) => {
    const [open, setOpen] = useState(false);
    const [printMode, setPrintMode] = useState(false);
    const slipRef = useRef<ReactNode | null>(null);

    return (
        <Box>
            <IconButton size="small" onClick={() => setOpen(true)}>
                <ReceiptOutlined fontSize="small" />
            </IconButton>

            <Dialog open={open} onClose={() => setOpen(false)} fullWidth>
                <DialogTitle>
                    <Print componentRef={slipRef} setPrintMode={setPrintMode} />
                </DialogTitle>

                <DialogContent ref={slipRef}>
                    <Header
                        name={
                            data.type === "arrears"
                                ? "Arrears Slip"
                                : data.type === "connection"
                                ? "Connection Slip"
                                : "Meter Replacement Slip"
                        }
                    />

                    <Table size="small" padding="checkbox">
                        <TableBody>
                            {Object.entries(data).map(
                                ([key, value]) =>
                                    key !== "type" && (
                                        <TableRow key={key}>
                                            <TableCell>{key}</TableCell>
                                            <TableCell>{value}</TableCell>
                                        </TableRow>
                                    )
                            )}
                        </TableBody>

                        {printMode && <Footer span={2} />}
                    </Table>
                </DialogContent>
            </Dialog>
        </Box>
    );
};

export default SingleSlip;
