import { Grid, Typography, useTheme } from "@mui/material";

const ReportHeader = ({
    customerData,
    customerDetails,
    selectedCustomerDetails,
}: any) => {
    const theme = useTheme();
    return (
        <Grid
            container
            justifyContent="space-between"
            sx={{
                p: 1,
                bgcolor: theme.palette.common.white,
                borderRadius: 1,
            }}
        >
            {selectedCustomerDetails.map((col: string, key: number) => (
                <Grid item key={key}>
                    <Typography
                        variant="caption"
                        fontWeight="bold"
                        color="gray"
                    >
                        {customerDetails[col]}
                    </Typography>
                    <Typography variant="body2">{customerData[col]}</Typography>
                </Grid>
            ))}
        </Grid>
    );
};

export default ReportHeader;
