import { useEffect, useState } from "react";
import { S3Service } from "../../../../Utilities/S3Service";

export const ReadingImage = ({ reading }: any) => {
    const [content, setContent] = useState<any>(null);

    const getReadingImage = async () => {
        const readingKey = `readings/${reading.id}`;

        // S3Service.constructKey(
        //     ["/" + awsDirStruct.readings],
        //     reading.id.toString()
        // );

        const [data, err] = await S3Service.getS3ByKey(readingKey);

        if (data) {
            try {
                const response = await fetch(data.url);

                console.log(data.url);

                const blob = await response.blob();

                if (response.status === 200) {
                    setContent(
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "100%",
                                width: "100%",
                            }}
                        >
                            <img
                                src={URL.createObjectURL(blob)}
                                alt="reading"
                                style={{
                                    aspectRatio: "4/3",
                                    maxHeight: "200px",
                                    margin: "auto",
                                }}
                            />
                        </div>
                    );
                }
            } catch (err) {
                setContent(null);
            }
        } else {
        }
    };

    useEffect(() => {
        if (reading) {
            getReadingImage();
        }
    }, [reading]);

    return content;
};
