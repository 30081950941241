import {
    Alert,
    Button,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    TextField,
} from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
    Dispatch,
    FormEvent,
    SetStateAction,
    useEffect,
    useState,
} from "react";
import { PATCH } from "../../Utilities/BaseService";

type Props = {
    open: any;
    setOpen: Dispatch<SetStateAction<boolean>>;
    row: { id: number; amount: number };
};

const updatePayment = (id: number, updatedAmount: number) => {
    return PATCH("/payment", { amount: updatedAmount }, { id });
};

const EditPayment = ({ open, setOpen, row }: Props) => {
    const [updatedAmount, setUpdatedAmount] = useState(row.amount);
    const [feedback, setFeedback] = useState({
        show: false,
        message: "",
        severity: "warning",
        loading: false,
    });

    const queryClient = useQueryClient();

    function handleSubmit(e: FormEvent<HTMLFormElement>) {
        e.preventDefault();

        setFeedback({ ...feedback, loading: true });

        updateMutation.mutate();
    }

    const updateMutation = useMutation(
        () => updatePayment(row.id, updatedAmount),
        {
            onSuccess(res) {
                console.log("Hurrah!");
                setFeedback({
                    loading: false,
                    message: res.data.message,
                    severity: "success",
                    show: true,
                });

                queryClient.invalidateQueries(["payment"]);
            },
        }
    );

    useEffect(() => {
        return setFeedback({
            show: false,
            message: "",
            severity: "warning",
            loading: false,
        });
    }, []);
    return (
        <Dialog
            open={open[row.id] ?? false}
            onClose={() => setOpen({ ...open, [row.id]: false })}
        >
            <DialogTitle>Edit Payment</DialogTitle>

            <DialogContent>
                {feedback.show && (
                    <Grid item xs={12} mb={1}>
                        <Alert severity={feedback.severity as any}>
                            {feedback.message}
                        </Alert>
                    </Grid>
                )}

                <Grid
                    container
                    spacing={1.5}
                    pt={1}
                    component="form"
                    onSubmit={handleSubmit}
                >
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            size="small"
                            label="Amount"
                            value={updatedAmount}
                            type="number"
                            onChange={(e) =>
                                setUpdatedAmount(parseInt(e.target.value))
                            }
                        />
                    </Grid>

                    <Grid item xs={12} mt={1}>
                        <Button
                            type="submit"
                            variant="contained"
                            disableElevation
                            disabled={feedback.loading}
                            endIcon={
                                feedback.loading ? (
                                    <CircularProgress size="small" />
                                ) : null
                            }
                        >
                            {feedback.loading
                                ? "updating payment..."
                                : " update payment"}
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

export default EditPayment;
