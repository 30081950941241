import { Visibility, VisibilityOutlined } from "@mui/icons-material";
import {
    Avatar,
    Box,
    Chip,
    ChipProps,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    useTheme,
} from "@mui/material";
import DescriptionIcon from "@mui/icons-material/Description";
import { Dispatch, SetStateAction, useState } from "react";

type Props = {
    log: Log;
};

type LogDetailsProps = {
    open: boolean;
    setOpen: Dispatch<SetStateAction<null | number>>;
    log: Log;
};

export enum ActionResponse {
    Success = "success",
    Failure = "failure",
    Unknown = "unknown",
}

const timeFormatter = Intl.DateTimeFormat("en", {
    dateStyle: "short",
    timeStyle: "short",
});

const parseActionText = (text: string, payload: any) => {
    const regex = /#[a-zA-Z]+/g;
    const matches = Array.from(text.matchAll(regex)).map((exp) => exp[0]);

    payload = JSON.parse(payload);

    for (const match of matches) {
        text = text.replaceAll(match, payload[match.slice(1)]);
    }

    return text;
};

const getAgentString = (agent: string) => {
    let regex = /[^)]*/;
    const match = agent.match(regex);

    if (match) {
        if (match[0].includes("(") && match[0].includes(";")) {
            return match[0]?.split("(")[1].split(";")[0] ?? agent;
        }
    }
    return agent;
};

const chipDefaults: ChipProps = {
    size: "small",
};

const ResponseIndicator = ({ response }: { response: ActionResponse }) => {
    if (response === ActionResponse.Success) {
        return <Chip color="success" label="Success" {...chipDefaults} />;
    } else if (response === ActionResponse.Failure) {
        return <Chip color="error" label="Failed" {...chipDefaults} />;
    } else {
        return <Chip label="Unknown" {...chipDefaults} />;
    }
};

const LogDetails = ({ open, setOpen, log }: LogDetailsProps) => {
    const theme = useTheme();
    const payload = JSON.parse(log.payload);

    return (
        <Dialog open={open} onClose={() => setOpen(null)} fullWidth>
            <DialogContent>
                <Grid container flexDirection="column">
                    <Grid
                        item
                        mb={theme.spacing(2)}
                        display="flex"
                        alignItems="center"
                        gap={theme.spacing(1)}
                    >
                        <Avatar />

                        <Box>
                            <Typography>{log.name}</Typography>
                        </Box>
                    </Grid>

                    <Grid item>
                        <Typography></Typography>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell colSpan={2}>{`${
                                        log?.name
                                    } ${parseActionText(
                                        log.actionText,
                                        log.payload
                                    )}`}</TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {Object.entries(payload).map(([k, v]: any) => (
                                    <TableRow key={k}>
                                        <TableCell
                                            sx={{ textTransform: "capitalize" }}
                                        >
                                            {k}
                                        </TableCell>
                                        <TableCell>{v}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

export const SingleLog = ({ log }: Props) => {
    const [open, setOpen] = useState<null | number>(null);

    return (
        <TableRow>
            <TableCell size="small">
                {timeFormatter.format(new Date(log.createdAt))}
            </TableCell>

            <TableCell>{log.operator.name}</TableCell>

            <TableCell>
                {parseActionText(log.actionText, log.payload)}
            </TableCell>

            <TableCell sx={{ textAlign: "center" }}>
                <LogDetails
                    open={open ? open === log.id : false}
                    setOpen={setOpen}
                    log={log}
                />
                <IconButton size="small" onClick={() => setOpen(log.id)}>
                    <DescriptionIcon fontSize="small" />
                </IconButton>
            </TableCell>

            <TableCell>
                <ResponseIndicator response={log.response} />
            </TableCell>
        </TableRow>
    );
};
