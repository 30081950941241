import { CloseOutlined, DoneOutlined, InfoOutlined } from "@mui/icons-material";
import {
    Alert,
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogContent,
    Grid,
    IconButton,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
    Typography,
    useTheme,
} from "@mui/material";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { ChangeEvent, FormEvent, useEffect, useRef, useState } from "react";
import { GET, POST } from "../../../Utilities/BaseService";
import UpdatedSearchableInput from "../../../Utilities/UpdatedSearchableInput";
import { currencyFormatter } from "../../Reading/Bill/Cells/Last12Summary";

const addPaymentCollection = (collections: {
    collections: { amount: number; payment: number }[];
}) => {
    return POST("/payment/collection/bulk", collections);
};

type Props = { updateMode?: boolean };

type Collection = {
    amount: number;
    payment: number;
    id: number;
    targetType: "reading" | "customer" | "property" | "meter";
    collection: number;
    paymentHead: string;
};

type Collections = {
    [key: number]: Collection;
};

const initialClearAll = {
    district: false,
    village: false,
    hamlet: false,
    powerstation: false,
    reading: false,
    property: false,
    customer: false,
    meter: false,
};

export const AddPaymentCollection = ({ updateMode = false }: Props) => {
    const [feedback, setFeedback] = useState<{
        [key: number]: {
            status: "success" | "failed";
            message: string;
            open: boolean;
        };
    }>();
    const [openFilters, setOpenFilters] = useState(false);
    const [filter, setFilter] = useState({
        district: "",
        village: "",
        hamlet: "",
        powerstation: "",
        targetType: "",
        targetId: "",
        meterNo: "",
    });
    const [pagination, setPagination] = useState({
        page: 0,
        limit: 10,
        count: 0,
    });
    const [collections, setCollections] = useState<Collections>();
    const [clearAll, setClearAll] = useState(initialClearAll);
    const [defaultRegions, setDefaultRegions] = useState({
        district: "",
        village: "",
        hamlet: "",
        powerstation: "",
    });
    const client = useQueryClient();

    const theme = useTheme();
    const collectionRef = useRef<HTMLDivElement | null>(null);

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        addMutation.mutate(
            {
                collections: Object.values(collections!)
                    .filter((item) => item.collection)
                    .map((item) => ({
                        amount: item.collection,
                        payment: item.id,
                    })),
            },

            {
                onSuccess(res) {
                    setFeedback(() => ({
                        ...res.data?.successes.reduce(
                            (prev: any, curr: any) => ({
                                ...prev,
                                [curr.id]: {
                                    status: "success",
                                    message: curr.message,
                                    open: false,
                                },
                            }),
                            {}
                        ),
                        ...res.data?.failures.reduce(
                            (prev: any, curr: any) => ({
                                ...prev,
                                [curr.id]: {
                                    status: "failed",
                                    message: curr.message,
                                    open: false,
                                },
                            }),
                            {}
                        ),
                    }));

                    setTimeout(() => {
                        setFeedback(
                            Object.fromEntries(
                                Object.entries(feedback!).map(([k, v]: any) => [
                                    k,
                                    { status: "", message: "" },
                                ])
                            )
                        );
                    }, 3500);
                },

                onError(err: any) {
                    console.log(err);
                    setFeedback(() => ({
                        ...err.response?.data?.successes.reduce(
                            (prev: any, curr: any) => ({
                                ...prev,
                                [curr.id]: {
                                    status: "success",
                                    message: curr.message,
                                    open: false,
                                },
                            }),
                            {}
                        ),
                        ...err.response?.data?.failures.reduce(
                            (prev: any, curr: any) => ({
                                ...prev,
                                [curr.id]: {
                                    status: "failed",
                                    message: curr.message,
                                    open: false,
                                },
                            }),
                            {}
                        ),
                    }));
                },
            }
        );
    };

    const { data: targetTypes } = useQuery(["target-types"], () =>
        GET("/payment/head/targetType")
    );

    const addMutation = useMutation(addPaymentCollection);

    const { data: payments, isLoading } = useQuery(
        [
            "payments",
            filter.targetType,
            filter.targetId,
            pagination.page,
            pagination.limit,
            feedback,
        ],
        () =>
            GET("/payment", {
                targetType: filter.targetType,
                targetId: filter.targetId ? filter.targetId : undefined,
                page: pagination.page + 1,
                limit: pagination.limit,
                completed: 0,
            }),
        {
            onSuccess(res) {
                setCollections(
                    res.data.rows.reduce((prev: any, curr: any) => {
                        const amount =
                            curr.amount > 0 ? curr?.amount : curr?.head?.amount;
                        return {
                            ...prev,
                            [curr.id]: {
                                id: curr.id,
                                amount,
                                targetType: curr.targetType,
                                collection: "",
                                paymentHead: curr?.head?.name,
                                remainingAmount:
                                    amount -
                                    curr?.collections.reduce(
                                        (prev: any, curr: any) =>
                                            prev + curr?.amount,
                                        0
                                    ),
                            },
                        };
                    }, {})
                );

                setPagination({ ...pagination, count: res.data.count });
            },
        }
    );

    const handleChange = (
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        collectionId: number
    ) => {
        setCollections({
            ...collections,
            [collectionId]: {
                ...collections![collectionId],
                collection: parseInt(e.target.value),
            },
        });
    };

    useEffect(() => {
        if (filter.district === "all") {
            setClearAll({
                district: true,
                village: true,
                hamlet: true,
                powerstation: true,
                reading: true,
                property: true,
                meter: true,
                customer: true,
            });
            setFilter({
                ...filter,
                district: "",
                village: "",
                hamlet: "",
                powerstation: "",
            });
        }

        if (filter.village === "all") {
            setClearAll({ ...clearAll, village: true, hamlet: true });
            setFilter({
                ...filter,
                village: "",
                hamlet: "",
            });
        }

        if (filter.hamlet === "all") {
            setClearAll({ ...clearAll, hamlet: true });
            setFilter({ ...filter, hamlet: "" });
        }

        if (defaultRegions.district !== filter.district) {
            setClearAll({
                ...clearAll,
                village: true,
                hamlet: true,
                powerstation: true,
            });

            setDefaultRegions({
                ...defaultRegions,
                district: filter.district as any,
            });

            setFilter({
                ...filter,
                village: "",
                hamlet: "",
                powerstation: "",
            });
        }

        if (filter.targetType) {
            setClearAll({
                ...clearAll,
                [filter.targetType]: true,
            });
        }

        return () => {
            setClearAll(initialClearAll);
        };
    }, [filter.district, filter.village, filter.hamlet, filter.targetType]);

    return (
        <Box>
            <Grid container justifyContent="space-between">
                <Grid item>
                    <Typography variant="h5">Payment Collection</Typography>
                </Grid>

                <Grid item container spacing={1.5} mt={2} xs={12}>
                    <Grid item xs={12} md={3}>
                        <UpdatedSearchableInput
                            label="District"
                            api="/region/district"
                            filter={filter}
                            setFilter={setFilter}
                            clearAll={clearAll.district}
                        />
                    </Grid>

                    <Grid item xs={12} md={3}>
                        <UpdatedSearchableInput
                            label="Village"
                            api="/region/village"
                            dep={filter.district}
                            params={{ district: filter.district }}
                            filter={filter}
                            setFilter={setFilter}
                            clearAll={clearAll.village}
                        />
                    </Grid>

                    <Grid item xs={12} md={3}>
                        <UpdatedSearchableInput
                            label="Hamlet"
                            api="/region/hamlet"
                            filter={filter}
                            setFilter={setFilter}
                            dep={filter.village}
                            params={
                                filter.village && filter.village !== "all"
                                    ? { village: [filter.village] }
                                    : { village: [0] }
                            }
                            clearAll={clearAll.hamlet}
                        />
                    </Grid>

                    <Grid item xs={12} md={3}>
                        <UpdatedSearchableInput
                            label="Powerstation"
                            api="/powerstation"
                            dep={filter.district}
                            params={{ district: filter.district }}
                            filter={filter}
                            setFilter={setFilter}
                            clearAll={clearAll.powerstation}
                        />
                    </Grid>

                    <Grid item xs={12} md={3}>
                        <TextField
                            select
                            label="Target Type"
                            fullWidth
                            size="small"
                            onChange={(e) =>
                                setFilter({
                                    ...filter,
                                    targetType: e.target.value,
                                })
                            }
                        >
                            {targetTypes?.data.rows.length > 0 ? (
                                targetTypes?.data.rows
                                    .filter(
                                        (target: {
                                            type:
                                                | "reading"
                                                | "property"
                                                | "customer"
                                                | "meter";
                                        }) => target.type !== "reading"
                                    )
                                    .map(({ type, label }: any) => (
                                        <MenuItem value={type} key={type}>
                                            {label}
                                        </MenuItem>
                                    ))
                            ) : (
                                <MenuItem>No options</MenuItem>
                            )}
                        </TextField>
                    </Grid>

                    <Grid item xs={12} md={9}>
                        {filter.targetType && (
                            <UpdatedSearchableInput
                                api={
                                    filter.targetType === "reading"
                                        ? "/reading"
                                        : filter.targetType === "property"
                                        ? "/customer/property"
                                        : filter.targetType === "meter"
                                        ? "/customer/meter"
                                        : "/customer"
                                }
                                filter={filter}
                                _name="targetId"
                                setFilter={setFilter}
                                label={
                                    filter.targetType === "reading"
                                        ? "Reading"
                                        : filter.targetType === "property"
                                        ? "Property"
                                        : filter.targetType === "meter"
                                        ? "Meter"
                                        : "Customer"
                                }
                                dep={
                                    filter.targetType === "customer"
                                        ? undefined
                                        : [
                                              filter.targetType,
                                              filter.district,
                                              filter.village,
                                              filter.hamlet,
                                          ]
                                }
                                params={
                                    filter.targetType === "customer"
                                        ? undefined
                                        : {
                                              district:
                                                  filter.district &&
                                                  filter.district !== "all"
                                                      ? filter.district
                                                      : undefined,
                                              village:
                                                  filter.village &&
                                                  filter.village !== "all"
                                                      ? filter.village
                                                      : undefined,
                                              hamlet:
                                                  filter.hamlet &&
                                                  filter.hamlet !== "all"
                                                      ? filter.hamlet
                                                      : undefined,
                                          }
                                }
                                optionsPreprocessor={(opt: any) =>
                                    filter.targetType === "reading"
                                        ? { ...opt, name: opt.property?.name }
                                        : filter.targetType === "meter"
                                        ? { ...opt, name: opt.meterNo }
                                        : {
                                              ...opt,
                                              name: `${opt.name} - ${opt.customer.name}`,
                                          }
                                }
                                clearAll={
                                    filter.targetType === "reading"
                                        ? clearAll.reading
                                        : filter.targetType === "property"
                                        ? clearAll.property
                                        : filter.targetType === "meter"
                                        ? clearAll.meter
                                        : clearAll.customer
                                }
                            />
                        )}
                    </Grid>
                </Grid>
            </Grid>

            {!filter.targetType && (
                <Alert severity="info" sx={{ mt: 2 }}>
                    Please select a target type first.
                </Alert>
            )}

            <>
                <TablePagination
                    sx={{ mt: 3 }}
                    size="small"
                    component="div"
                    count={pagination.count}
                    page={pagination.page}
                    onPageChange={(ev, page) =>
                        setPagination({ ...pagination, page: page })
                    }
                    rowsPerPage={pagination.limit}
                    onRowsPerPageChange={(ev) =>
                        setPagination({
                            ...pagination,
                            limit: parseInt(ev.target.value),
                            page: 0,
                        })
                    }
                />

                <TableContainer
                    sx={{ mt: 3 }}
                    component="form"
                    onSubmit={handleSubmit}
                >
                    <Table size="small" padding="checkbox">
                        <TableHead>
                            <TableRow>
                                <TableCell>Status</TableCell>
                                <TableCell>ID</TableCell>
                                <TableCell>Target Type</TableCell>
                                <TableCell>Payment Head</TableCell>
                                <TableCell>Total Amount</TableCell>
                                <TableCell>Remianing Amount</TableCell>
                                <TableCell>Collection</TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {collections &&
                                Object.entries(collections).map(
                                    ([key, value]: any) => (
                                        <TableRow key={value.id}>
                                            <TableCell>
                                                {feedback ? (
                                                    feedback[key]?.status ===
                                                    "success" ? (
                                                        <>
                                                            <IconButton
                                                                onClick={() =>
                                                                    setFeedback(
                                                                        {
                                                                            ...feedback,
                                                                            [key]: {
                                                                                ...feedback[
                                                                                    key
                                                                                ],
                                                                                open: true,
                                                                            },
                                                                        }
                                                                    )
                                                                }
                                                            >
                                                                <DoneOutlined color="success" />
                                                            </IconButton>

                                                            <Dialog
                                                                open={
                                                                    feedback[
                                                                        key
                                                                    ].open
                                                                }
                                                                onClose={() =>
                                                                    setFeedback(
                                                                        {
                                                                            ...feedback,
                                                                            [key]: {
                                                                                ...feedback[
                                                                                    key
                                                                                ],
                                                                                open: false,
                                                                            },
                                                                        }
                                                                    )
                                                                }
                                                            >
                                                                <DialogContent>
                                                                    {
                                                                        feedback[
                                                                            key
                                                                        ]
                                                                            .message
                                                                    }
                                                                </DialogContent>
                                                            </Dialog>
                                                        </>
                                                    ) : feedback[key]
                                                          ?.status ===
                                                      "failed" ? (
                                                        <>
                                                            <IconButton
                                                                onClick={() =>
                                                                    setFeedback(
                                                                        {
                                                                            ...feedback,
                                                                            [key]: {
                                                                                ...feedback[
                                                                                    key
                                                                                ],
                                                                                open: true,
                                                                            },
                                                                        }
                                                                    )
                                                                }
                                                            >
                                                                <CloseOutlined color="error" />
                                                            </IconButton>

                                                            <Dialog
                                                                open={
                                                                    feedback[
                                                                        key
                                                                    ].open
                                                                }
                                                                onClose={() =>
                                                                    setFeedback(
                                                                        {
                                                                            ...feedback,
                                                                            [key]: {
                                                                                ...feedback[
                                                                                    key
                                                                                ],
                                                                                open: false,
                                                                            },
                                                                        }
                                                                    )
                                                                }
                                                            >
                                                                <DialogContent>
                                                                    {
                                                                        feedback[
                                                                            key
                                                                        ]
                                                                            .message
                                                                    }
                                                                </DialogContent>
                                                            </Dialog>
                                                        </>
                                                    ) : (
                                                        <IconButton disabled>
                                                            <InfoOutlined />
                                                        </IconButton>
                                                    )
                                                ) : (
                                                    <IconButton disabled>
                                                        <InfoOutlined />
                                                    </IconButton>
                                                )}
                                            </TableCell>
                                            <TableCell>{value?.id}</TableCell>
                                            <TableCell>
                                                {value?.targetType}
                                            </TableCell>
                                            <TableCell>
                                                {value?.paymentHead}
                                            </TableCell>
                                            <TableCell>
                                                {currencyFormatter.format(
                                                    value?.amount
                                                )}
                                            </TableCell>
                                            <TableCell>
                                                {value?.remainingAmount}
                                            </TableCell>
                                            <TableCell>
                                                <TextField
                                                    ref={collectionRef}
                                                    size="small"
                                                    fullWidth
                                                    value={
                                                        value.collection ?? ""
                                                    }
                                                    onChange={(e) =>
                                                        handleChange(e, key)
                                                    }
                                                    variant="standard"
                                                    sx={{
                                                        width: "20ch",
                                                        mt: 1.5,
                                                    }}
                                                    placeholder="Collection"
                                                    type="number"
                                                    InputProps={{
                                                        startAdornment: (
                                                            <Typography
                                                                color="grey"
                                                                mr={1}
                                                            >
                                                                Rs.
                                                            </Typography>
                                                        ),
                                                    }}
                                                    inputProps={{
                                                        max: value.remainingAmount,
                                                        min: 0,
                                                    }}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    )
                                )}
                        </TableBody>
                    </Table>

                    <Button
                        fullWidth
                        variant="outlined"
                        color="secondary"
                        type="submit"
                        disabled={
                            !filter.targetType ||
                            addMutation.isLoading ||
                            !Object.values(collections!).some(
                                (item) => item.collection
                            )
                        }
                        endIcon={
                            addMutation.isLoading ? (
                                <CircularProgress size={20} color="secondary" />
                            ) : undefined
                        }
                        sx={{ mt: 2 }}
                    >
                        {addMutation.isLoading ? "collecting..." : "collect"}
                    </Button>
                </TableContainer>
            </>
        </Box>
    );
};
