import { RestoreOutlined } from "@mui/icons-material";
import {
    Box,
    Card,
    Chip,
    CircularProgress,
    TextField,
    Typography,
} from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { POST } from "../../../Utilities/BaseService";

type Props = {
    setting: any;
    updatedSettings: any;
    setUpdatedSettings: Dispatch<SetStateAction<any>>;
};

export const TextSetting = ({
    setting,
    updatedSettings,
    setUpdatedSettings,
}: Props) => {
    const [localSett, setLocalSett] = useState({
        key: "",
        label: "",
        value: "",
    });

    const queryClient = useQueryClient();

    const isGlobal = useMemo(() => {
        if (setting) {
            const regions = Object.fromEntries(
                Object.entries(setting).filter(
                    ([k, v]) =>
                        k === "district" || k === "village" || k === "hamlet"
                )
            );

            if (regions.district) return false;
        }

        return true;
    }, [setting]);

    const restoreMut = useMutation(
        (sett: any) => POST("/settings/add-single", sett),
        {
            onSuccess(res) {
                window.location.reload();
            },
        }
    );

    useEffect(() => {
        if (setting?.value)
            setLocalSett({
                key: setting.key,
                value: setting.value,
                label: setting.label,
            });
    }, [setting]);

    return (
        <Card
            elevation={0}
            sx={{
                bgcolor: "common.white",
                p: 2,
                mb: 1,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
            }}
        >
            <Box display="flex" alignItems="center" gap={1}>
                <Typography>{localSett?.label}</Typography>
                {/* {!setting?.IsDefault && (
                    <Chip
                        label={
                            restoreMut.isLoading ? "Restoring..." : "Restore"
                        }
                        size="small"
                        color="info"
                        variant="outlined"
                        deleteIcon={
                            restoreMut.isLoading ? (
                                <CircularProgress size={15} />
                            ) : (
                                <RestoreOutlined />
                            )
                        }
                        onDelete={() =>
                            restoreMut.mutate({
                                id: setting.id,
                                key: setting.key,
                                isGlobal,
                            })
                        }
                    />
                )} */}
            </Box>

            <TextField
                size="small"
                placeholder={setting?.label}
                onChange={(ev) => {
                    setUpdatedSettings({
                        ...updatedSettings,
                        [setting?.key]: ev.target.value,
                    });

                    setLocalSett({ ...localSett, value: ev.target.value });
                }}
                value={localSett.value}
            />
        </Card>
    );
};
