import { Box } from "@mui/material";
import Donut from "../Components/Donut";

type Props = {
    selectedConnTypes: "all" | number[];
    meterReport?: MeterCountReport;
};

export const DistributionDonut = ({
    selectedConnTypes,
    meterReport,
}: Props) => {
    if (meterReport) {
        return (
            <Box>
                <Donut
                    labels={meterReport
                        .filter(
                            (connType) =>
                                selectedConnTypes === "all" ||
                                selectedConnTypes.includes(connType.typeId)
                        )
                        .map((connType) => connType.typeName)}
                    series={meterReport
                        .filter(
                            (connType) =>
                                selectedConnTypes === "all" ||
                                selectedConnTypes.includes(connType.typeId)
                        )
                        .map((connType) => parseInt(connType.typeCount) ?? 0)}
                />
            </Box>
        );
    } else {
        return null;
    }
};
