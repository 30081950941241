import {
    Alert,
    Box,
    Button,
    Chip,
    CircularProgress,
    Divider,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
    Typography,
    useTheme,
} from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
    ChangeEvent,
    Dispatch,
    FormEvent,
    SetStateAction,
    createContext,
    useContext,
    useEffect,
    useMemo,
    useState,
} from "react";
import { useLocation } from "react-router-dom";
import { AppContext } from "../../../Utilities/AppContext";
import { GET, PATCH, POST } from "../../../Utilities/BaseService";
import UpdatedSearchableInput from "../../../Utilities/UpdatedSearchableInput";
import { AddAcs } from "./Components/AddAcs";
import { ReadingRow } from "./Components/ReadingRow";

type DateConstraintVariant =
    | "collection-back"
    | "collection-forward"
    | "reading-back"
    | "reading-forward";

type DateConstraint = { key: DateConstraintVariant; value: string };

type Props = {
    updateMode?: boolean;
};

type ReadingTarget = {
    id: number;
    meterNo: string;
    lastReading: number;
};

type AdditionalCharge = {
    head: number;
    amount: number;
};

type CreateReadingData = {
    select: boolean;
    units: number;
    lineRent: number;
    additionalCharges: AdditionalCharge[];
    arrears: number;
    feedback: { message: string; status: "success" | "error" | null };
};

type ReadingCtxType = {
    readingsData: { [meterId: string]: CreateReadingData };
    setReadingsData: Dispatch<
        SetStateAction<{ [meterId: string]: CreateReadingData }>
    >;
    activeMeter: number | null;
    setActiveMeter: Dispatch<SetStateAction<number | null>>;
    mode: boolean;
};

const fetchMeters = (params: any = {}, updateMode: boolean) => {
    const cleanParams = Object.fromEntries(
        Object.entries(params).filter((e) => e[1] !== "all" && e[1])
    );

    const api = updateMode
        ? "/reading/update-targets"
        : "/customer/meter/reading-targets";

    return GET(api, cleanParams);
};

const createReadings = (
    indivisualReadings: { [key: string]: any },
    commonData: any,
    readingTargets: any[]
) => {
    const selectedMeters = readingTargets.map((m: any) => m.id + "");
    const actualIndvisualReadings = Object.entries(indivisualReadings)
        .filter(
            (entry: any[]) =>
                selectedMeters.includes(entry[0]) && entry[1].select
        )
        .map((entry) => ({ ...entry[1], meterId: entry[0] }));

    return POST("/reading/bulk", {
        ...commonData,
        meters: actualIndvisualReadings,
    });
};

const updateReadings = (
    indivisualReadings: { [key: string]: any },
    commonData: any
) => {
    const actualIndvisualReadings = Object.entries(indivisualReadings)
        .filter((entry: any[]) => entry[1].select)
        .map((entry) => ({
            ...entry[1],
            meterId: parseInt(entry[0]),
            units: parseInt(entry[1].units),
        }));

    return PATCH("/reading/bulk", {
        ...Object.fromEntries(
            Object.entries(commonData).filter((entry) => entry[1])
        ),
        meters: actualIndvisualReadings,
    });
};

const createReadingTableCols = [
    "",
    "Status",
    "Serial no.",
    "Meter no.",
    "Customer name",
    "Previous reading",
    "Curr. reading",
    "Line rent",
    "Additional charges",
    "Calculations",
    "Surcharge",
    "Connection charges",
];

const initialCommonData = {
    month: "",
    year: "",
    readingDate: "",
    issueDate: "",
    dueDate: "",
};

const initialClearAll = {
    district: false,
    village: false,
    hamlet: false,
    powerstation: false,
};

const initialDefaultRegions = {
    district: "",
    village: "",
    hamlet: "",
};

export const ReadingCtx = createContext({} as ReadingCtxType);

export const AddReadings = ({ updateMode = false }: Props) => {
    const [activeMeter, setActiveMeter] = useState<number | null>(null);
    const [mode, setMode] = useState(updateMode);
    const [id, setId] = useState("");
    const [filter, setFilter] = useState({
        district: "",
        village: "",
        hamlet: "",
        meterNo: "",
        month: "",
        year: "",
    });
    const [pagination, setPagination] = useState({
        page: 0,
        limit: 50,
        count: 0,
    });
    const [date, setDate] = useState("");
    const [commonData, setCommonData] = useState<any>(initialCommonData);
    const [feedback, setFeedback] = useState("");
    const [clearAll, setClearAll] = useState(initialClearAll);
    const [defaultRegions, setDefaultRegions] = useState(initialDefaultRegions);
    const [fetchTargets, setFetchTargets] = useState(0);
    const { user } = useContext(AppContext);

    const location = useLocation();
    const theme = useTheme();

    const handleCommonDataChange = (ev: ChangeEvent<HTMLInputElement>) => {
        setCommonData({ ...commonData, [ev.target.name]: ev.target.value });
    };

    const handleReadingTargetOnSuccess = (res: any) => {
        setPagination({ ...pagination, count: parseInt(res.data.count) });
        setReadingsData(
            res.data.rows.reduce(
                (prev: any, curr: any) => ({
                    ...prev,
                    [curr.id]: {
                        units: updateMode
                            ? curr.initialReading
                            : readingsData[curr.id]?.units || 0,
                        lineRent: curr.priceType.lineRent.amount,
                        additionalCharges: curr.acs || [],
                        feedback: { message: "", status: null },
                        select: Boolean(readingsData[curr.id]?.units),
                    },
                }),
                {}
            )
        );

        setPagination({ ...pagination, count: res.data.count });
    };

    const { data: readingTargets, isFetching } = useQuery(
        ["reading-targets", pagination.page, pagination.limit, fetchTargets],
        () => {
            return fetchMeters(
                {
                    ...filter,
                    page: pagination.page + 1,
                    limit: pagination.limit,
                    month: filter.month,
                    year: filter.year,
                    id,
                },
                updateMode
            );
        },

        {
            enabled: Boolean(filter.month) && Boolean(filter.year),
            onSuccess: handleReadingTargetOnSuccess,
        }
    );

    const oldestConnectionDate = useMemo(() => {
        if (readingTargets?.data.rows.length) {
            return readingTargets?.data.rows[0].connectionDate;
        }
    }, [readingTargets]);

    const [readingsData, setReadingsData] = useState<{
        [meterId: string]: CreateReadingData;
    }>({});

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (updateMode) {
            updateMutation.mutate();
        } else {
            addMutation.mutate();
        }
    };

    const addMutation = useMutation(
        () =>
            createReadings(
                readingsData,
                {
                    ...commonData,
                    month: parseInt(commonData.month),
                    year: parseInt(commonData.year),
                },
                readingTargets.data.rows
            ),
        {
            onSuccess(res) {
                const targetResponse = res.data.successes.reduce(
                    (prev: any, curr: any) => ({
                        ...prev,
                        [curr.id]: { ...curr, status: "success" },
                    }),
                    {}
                );
                const readingsDataWithFeedback = Object.fromEntries(
                    Object.entries(readingsData).map(([k, v]) => [
                        k,
                        { ...v, feedback: targetResponse[k] },
                    ])
                );

                setReadingsData(readingsDataWithFeedback);

                const numberOfSuccesses = res.data.successes.length;

                setFeedback(
                    `${numberOfSuccesses}/${numberOfSuccesses} readings have recorded successfully.`
                );
            },

            onError(err: any) {
                if (!err.response.data.message) {
                    const targetResponse = {
                        ...err.response.data.successes.reduce(
                            (prev: any, curr: any) => ({
                                ...prev,
                                [curr.id]: { ...curr, status: "success" },
                            }),
                            {}
                        ),
                        ...err.response.data.failures.reduce(
                            (prev: any, curr: any) => ({
                                ...prev,
                                [curr.id]: {
                                    message: curr.message,
                                    status: "error",
                                },
                            }),
                            {}
                        ),
                    };

                    const readingsDataWithFeedback = Object.fromEntries(
                        Object.entries(readingsData).map(([k, v]) => [
                            k,
                            { ...v, feedback: targetResponse[k] },
                        ])
                    );

                    setReadingsData(readingsDataWithFeedback);

                    const numberOfSuccesses =
                        err.response.data.successes.length;
                    const numberOfFailures = err.response.data.failures.length;

                    setFeedback(
                        `${numberOfSuccesses}/${
                            numberOfSuccesses + numberOfFailures
                        } readings have recorded successfully. ${numberOfFailures} records have failed.`
                    );
                } else {
                    setFeedback(err.response.data.message);
                }
            },
        }
    );

    const updateMutation = useMutation(
        () =>
            updateReadings(readingsData, {
                ...commonData,
                month: parseInt(commonData.month),
                year: parseInt(commonData.year),
            }),
        {
            onSuccess(res) {
                const targetResponse = res.data.successes.reduce(
                    (prev: any, curr: any) => ({
                        ...prev,
                        [curr.id]: { ...curr, status: "success" },
                    }),
                    {}
                );
                const readingsDataWithFeedback = Object.fromEntries(
                    Object.entries(readingsData).map(([k, v]) => [
                        k,
                        { ...v, feedback: targetResponse[k] },
                    ])
                );

                setReadingsData(readingsDataWithFeedback);

                const numberOfSuccesses = res.data.successes.length;

                setFeedback(
                    `${numberOfSuccesses}/${numberOfSuccesses} readings have recorded successfully.`
                );
            },

            onError(err: any) {
                if (!err.response.data.message) {
                    const targetResponse = {
                        ...err.response.data.successes.reduce(
                            (prev: any, curr: any) => ({
                                ...prev,
                                [curr.id]: { ...curr, status: "success" },
                            }),
                            {}
                        ),
                        ...err.response.data.failures.reduce(
                            (prev: any, curr: any) => ({
                                ...prev,
                                [curr.id]: {
                                    message: curr.message,
                                    status: "error",
                                },
                            }),
                            {}
                        ),
                    };

                    const readingsDataWithFeedback = Object.fromEntries(
                        Object.entries(readingsData).map(([k, v]) => [
                            k,
                            { ...v, feedback: targetResponse[k] },
                        ])
                    );

                    setReadingsData(readingsDataWithFeedback);

                    const numberOfSuccesses =
                        err.response.data.successes.length;
                    const numberOfFailures = err.response.data.failures.length;

                    setFeedback(
                        `${numberOfSuccesses}/${
                            numberOfSuccesses + numberOfFailures
                        } readings have recorded successfully. ${numberOfFailures} records have failed.`
                    );
                } else {
                    setFeedback(err.response.data.message);
                }
            },
        }
    );

    function manipulateDate(
        currentDate: Date,
        daysToAddOrSubtract: number,
        op: "add" | "sub"
    ) {
        const dateObj = new Date(currentDate);

        const millisecondsInADay = 24 * 60 * 60 * 1000;

        let newDate: Date;
        switch (op) {
            case "add":
                newDate = new Date(
                    dateObj.getTime() + daysToAddOrSubtract * millisecondsInADay
                );
                break;

            case "sub":
                newDate = new Date(
                    dateObj.getTime() - daysToAddOrSubtract * millisecondsInADay
                );
                break;
        }

        return newDate;
    }

    function getRestrictedDate(target: DateConstraintVariant) {
        const hasDateRestriction = Boolean(
            user.constraints.find((c: DateConstraint) => c.key === target)
        );

        if (hasDateRestriction) {
            console.log("has date constraints: ", hasDateRestriction);
            const days: number = user.constraints.find(
                (c: DateConstraint) => c.key === target
            ).value;

            switch (target) {
                case "reading-back":
                    const backDate = manipulateDate(new Date(), days, "sub")
                        .toISOString()
                        .slice(0, 10);

                    console.log(backDate, target);

                    return backDate;

                case "reading-forward":
                    const forwardDate = manipulateDate(new Date(), days, "add")
                        .toISOString()
                        .slice(0, 10);

                    console.log(target);

                    return forwardDate;
            }
        } else return undefined;
    }

    const handleApplyFilter = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const [year, month] = date.split("-");
        setCommonData({ ...commonData, year, month });

        setFilter({ ...filter, month, year });

        setFetchTargets(fetchTargets + 1);
    };

    useEffect(() => {
        if (location.state?.id) {
            setFilter({ ...filter, meterNo: location.state.meter.meterNo });
            setDate(
                `${location.state.year}-${
                    location.state.month <= 9
                        ? "0" + location.state.month
                        : location.state.month
                }`
            );

            setCommonData({
                readingDate: location.state.readingDate.split("T")[0],
                issueDate: location.state.issueDate.split("T")[0],
                dueDate: location.state.dueDate.split("T")[0],
                year: location.state.year + "",
                month:
                    location.state.month <= 9
                        ? "0" + location.state.month
                        : location.state.month,
            });
        }
    }, [location.state]);

    const handleDateChange = (ev: any) => {
        setDate(ev.target.value);
    };

    useEffect(() => {
        setMode(updateMode);

        if (updateMode) {
            const params = new URLSearchParams(location.search);
            const _id = params.get("id") ?? "";

            setId(_id);
        }
    }, [updateMode]);

    useEffect(() => {
        if (filter.district === "all") {
            setClearAll({
                district: true,
                village: true,
                hamlet: true,
                powerstation: true,
            });

            setFilter({ ...filter, district: "", village: "", hamlet: "" });
        }

        if (filter.village === "all") {
            setClearAll({ ...clearAll, village: true, hamlet: true });
        }

        if (filter.hamlet === "all") {
            setClearAll({ ...clearAll, hamlet: true });
        }

        if (defaultRegions.district !== filter.district) {
            setClearAll({
                ...clearAll,
                village: true,
                hamlet: true,
                powerstation: true,
            });

            setDefaultRegions({
                ...defaultRegions,
                district: filter.district,
            });
        }

        return () => {
            setClearAll({
                district: false,
                village: false,
                hamlet: false,
                powerstation: false,
            });
        };
    }, [filter.district, filter.village, filter.hamlet]);

    return (
        <ReadingCtx.Provider
            value={{
                readingsData,
                setReadingsData,
                activeMeter,
                setActiveMeter,
                mode,
            }}
        >
            <AddAcs />

            <Typography variant="h5" sx={{ my: theme.spacing(3) }}>
                {updateMode ? "Update Readings" : "Add Readings"}
            </Typography>

            <Grid container justifyContent="space-between" spacing={1}>
                <Grid item xs={12} md={6} lg={3}>
                    <UpdatedSearchableInput
                        label="District"
                        api="/region/district"
                        filter={filter}
                        setFilter={setFilter}
                        clearAll={clearAll.district}
                    />
                </Grid>

                <Grid item xs={12} md={6} lg={3}>
                    <UpdatedSearchableInput
                        label="Village"
                        api="/region/village"
                        filter={filter}
                        setFilter={setFilter}
                        dep={filter.district}
                        params={{ district: filter.district }}
                        clearAll={clearAll.village}
                    />
                </Grid>

                <Grid item xs={12} md={6} lg={3}>
                    <UpdatedSearchableInput
                        label="Hamlet"
                        api="/region/hamlet"
                        filter={filter}
                        setFilter={setFilter}
                        dep={filter.village}
                        params={
                            filter.village && filter.village !== "all"
                                ? { village: [filter.village] }
                                : { village: [0] }
                        }
                        multiple={false}
                        clearAll={clearAll.hamlet}
                    />
                </Grid>

                <Grid item xs={12} md={6} lg={3}>
                    <TextField
                        size="small"
                        name="meterNo"
                        label="Meter no."
                        fullWidth
                        value={filter.meterNo}
                        onChange={(e) =>
                            setFilter({ ...filter, meterNo: e.target.value })
                        }
                    />
                </Grid>

                <Grid
                    container
                    item
                    xs={12}
                    component="form"
                    onSubmit={handleApplyFilter}
                    spacing={1}
                >
                    <Grid item xs={12} mb={2}>
                        <Divider>
                            <Chip label="Date" size="small" />
                        </Divider>
                    </Grid>

                    <Grid item xs={12} md={3}>
                        {/* <MultiPermissionAuthorize
                            ops={
                                updateMode
                                    ? ["UPDATE DATE_YEAR"]
                                    : ["CREATE DATE_YEAR"]
                            }
                        > */}
                        <TextField
                            fullWidth
                            size="small"
                            name="date"
                            label="Billing Month"
                            value={date}
                            onChange={handleDateChange}
                            required
                            type="month"
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        {/* </MultiPermissionAuthorize> */}
                    </Grid>

                    <Grid item xs={12} md={3}>
                        {/* <MultiPermissionAuthorize
                            ops={
                                updateMode
                                    ? ["UPDATE READING_DATE"]
                                    : ["CREATE READING_DATE"]
                            }
                        > */}
                        <TextField
                            name="readingDate"
                            value={commonData.readingDate}
                            label="Reading Date"
                            fullWidth
                            size="small"
                            type="date"
                            inputProps={
                                // oldestConnectionDate
                                //     ? {
                                //           min: oldestConnectionDate,
                                //       }
                                //     : undefined
                                {
                                    min: getRestrictedDate("reading-back"),
                                    max: getRestrictedDate("reading-forward"),
                                }
                            }
                            onChange={handleCommonDataChange}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        {/* </MultiPermissionAuthorize> */}
                    </Grid>

                    <Grid item xs={12} md={3}>
                        {/* <MultiPermissionAuthorize
                            ops={
                                updateMode
                                    ? ["UPDATE ISSUE_DATE"]
                                    : ["CREATE ISSUE_DATE"]
                            }
                        > */}
                        <TextField
                            name="issueDate"
                            value={commonData.issueDate}
                            label="Issue Date"
                            fullWidth
                            size="small"
                            type="date"
                            onChange={handleCommonDataChange}
                            inputProps={
                                date
                                    ? {
                                          min: new Date(date)
                                              .toISOString()
                                              .split("T")[0],
                                      }
                                    : undefined
                            }
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        {/* </MultiPermissionAuthorize> */}
                    </Grid>

                    <Grid item xs={12} md={3}>
                        {/* <MultiPermissionAuthorize
                            ops={
                                updateMode
                                    ? ["UPDATE DUE_DATE"]
                                    : ["CREATE DUE_DATE"]
                            }
                        > */}
                        <TextField
                            name="dueDate"
                            value={commonData.dueDate}
                            label="Due Date"
                            fullWidth
                            size="small"
                            type="date"
                            onChange={handleCommonDataChange}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        {/* </MultiPermissionAuthorize> */}
                    </Grid>

                    {/* {!Boolean(date) && (
                        <Grid item xs={12} mt={1}>
                            <Alert severity="info">
                                Please select a month!
                            </Alert>
                        </Grid>
                    )} 

                    <Grid item xs={12} mt={2}>
                        <Divider>
                            <Chip label="Pagination" size="small" />
                        </Divider>
                    </Grid> */}

                    <Grid item xs={12}>
                        <Button
                            variant="outlined"
                            color="secondary"
                            type="submit"
                            fullWidth
                        >
                            view readings
                        </Button>
                    </Grid>
                </Grid>
            </Grid>

            <TablePagination
                rowsPerPageOptions={[10, 50, 100, 500]}
                component="div"
                onPageChange={(ev, page) =>
                    setPagination({ ...pagination, page: page })
                }
                onRowsPerPageChange={(ev) =>
                    setPagination({
                        ...pagination,
                        limit: parseInt(ev.target.value),
                        page: 0,
                    })
                }
                count={pagination.count}
                page={pagination.page}
                rowsPerPage={pagination.limit}
            />

            {Boolean(date) && isFetching ? (
                <Box display="flex" justifyContent="center" alignItems="center">
                    <CircularProgress />
                </Box>
            ) : fetchTargets > 0 ? (
                <Box component="form" onSubmit={handleSubmit}>
                    <TableContainer>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    {createReadingTableCols.map((col) => (
                                        <TableCell key={col}>{col}</TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {readingTargets?.data.rows.map(
                                    (target: ReadingTarget, idx: number) => (
                                        <ReadingRow
                                            key={target.id}
                                            target={target}
                                            updateMode={updateMode}
                                            count={
                                                idx +
                                                1 +
                                                pagination.limit *
                                                    pagination.page
                                            }
                                        />
                                    )
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    {readingTargets?.data.rows.length == 0 && (
                        <Alert severity="info"> No readings found.</Alert>
                    )}

                    {addMutation.isSuccess ||
                        (addMutation.isError && (
                            <Alert
                                sx={{ mt: 2 }}
                                severity={
                                    addMutation.isSuccess ? "success" : "error"
                                }
                            >
                                {feedback}
                            </Alert>
                        ))}

                    <Button
                        variant="outlined"
                        fullWidth
                        color="secondary"
                        sx={{ my: 2 }}
                        type="submit"
                        disabled={
                            updateMutation.isLoading || addMutation.isLoading
                        }
                        endIcon={
                            updateMutation.isLoading ||
                            addMutation.isLoading ? (
                                <CircularProgress size={20} />
                            ) : undefined
                        }
                    >
                        {updateMode
                            ? updateMutation.isLoading
                                ? "updating readings..."
                                : "update readings"
                            : addMutation.isLoading
                            ? "adding readings..."
                            : "add readings"}
                    </Button>
                </Box>
            ) : null}
        </ReadingCtx.Provider>
    );
};
