import React, { useEffect } from "react";

type Props = {
    children: JSX.Element | null;
    title: string;
};

const WithTitle = ({ children = null, title = "SRSP" }: Props) => {
    useEffect(() => {
        const titleTag = document.querySelector<HTMLTitleElement>("title");
        if (titleTag) titleTag.innerText = title;

        return () => {
            if (titleTag) {
                titleTag.innerText = "SRSP";
            }
        };
    }, []);

    return children;
};

export default WithTitle;
