import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { baseAPI } from "./constants";

export const GET = (url: string, params: any = {}) => {
    return axios.get(baseAPI + url, {
        withCredentials: true,
        params,
    });
};

export const POST = (url: string, data: any = null, params: any = {}) => {
    return axios.post(baseAPI + url, data, {
        withCredentials: true,
        params,
    });
};

export const PATCH = async (
    url: string,
    data: any = null,
    params: any = {}
) => {
    return axios.patch(baseAPI + url, data, {
        withCredentials: true,
        params,
    });
};
export const DELETE = async (
    url: string,
    data: any = null,
    params: any = {}
) => {
    return axios.delete(baseAPI + url, {
        withCredentials: true,
        params,
    });
};
