import { Add, FilterAltOutlined } from "@mui/icons-material";
import {
    Alert,
    Box,
    Button,
    Card,
    Chip,
    Grid,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { GET } from "../../../Utilities/BaseService";
import {
    filterSelectionMessage,
    hasValue,
    noDataMessage,
} from "../../../Utilities/constants";
import { MultiPermissionAuthorize } from "../../../Utilities/MultiPermissionAuthorize";
import UpdatedSearchableInput from "../../../Utilities/UpdatedSearchableInput";
import { currencyFormatter } from "../../Reading/Bill/Cells/Last12Summary";
import BaseTable from "../../Table/BaseTable";
import SearchBar from "../../Table/Components/SearchBar";

const getSurcharges = (page: number, limit: number, filter: any) => {
    return GET("/connection/surcharge", {
        page,
        limit,
        search: filter.search ? filter.search : undefined,
        district:
            filter.district && filter.district !== "all"
                ? filter.district
                : undefined,
        village:
            filter.village && filter.village !== "all"
                ? filter.village
                : undefined,
        hamlet:
            filter.hamlet && filter.hamlet !== "all"
                ? filter.hamlet
                : undefined,
        powerstation:
            filter.powerstation && filter.powerstation !== "all"
                ? filter.powerstation
                : undefined,
    });
};

const calculationStrategyMap = new Map();
calculationStrategyMap.set("acs", "Additiona Charges");
calculationStrategyMap.set("arrears", "Arrears");
calculationStrategyMap.set("connCharges", "Connection Charges");
calculationStrategyMap.set("lr", "Line Rent");
calculationStrategyMap.set("units", "Units");

type Filter = {
    search: string;
    district: number | "" | "all";
    village: number | "" | "all";
    hamlet: number | "" | "all";
    powerstation: number | "" | "all";
};

const initialFilter: Filter = {
    search: "",
    district: "",
    village: "",
    hamlet: "",
    powerstation: "",
};

const initialClearAll = {
    district: false,
    village: false,
    hamlet: false,
    powerstation: false,
};

const Surcharge = () => {
    const [filter, setFilter] = useState(initialFilter);
    const [rowsCount, setRowsCount] = useState(0);
    const [pagination, setPagination] = useState({ page: 0, limit: 100 });
    const [feedback, setFeedback] = useState("");
    const [load, setLoad] = useState(false);
    const [clearAll, setClearAll] = useState(initialClearAll);
    const [changeCount, setChangeCount] = useState(0);
    const [intermediateFilter, setIntermediateFilter] =
        useState<Filter>(initialFilter);

    const theme = useTheme();
    const mdDown = useMediaQuery(theme.breakpoints.down("md"));

    const { data, isLoading } = useQuery(
        ["surcharges", pagination.page, pagination.limit, filter],
        () => getSurcharges(pagination.page + 1, pagination.limit, filter),
        {
            enabled: Boolean(changeCount),
            onSuccess(res) {
                setRowsCount(res.data.count);
            },
        }
    );

    useEffect(() => {
        if (intermediateFilter.district === "all") {
            setClearAll({
                district: true,
                village: true,
                hamlet: true,
                powerstation: true,
            });
            setIntermediateFilter({
                ...intermediateFilter,
                district: "",
                village: "",
                hamlet: "",
                powerstation: "",
            });
        }

        if (intermediateFilter.village === "all") {
            setClearAll({ ...clearAll, village: true, hamlet: true });
            setIntermediateFilter({
                ...intermediateFilter,
                village: "",
                hamlet: "",
            });
        }

        if (intermediateFilter.hamlet === "all") {
            setClearAll({ ...clearAll, hamlet: true });
            setIntermediateFilter({ ...intermediateFilter, hamlet: "" });
        }

        if (intermediateFilter.powerstation === "all") {
            setClearAll({ ...clearAll, powerstation: true });
            setIntermediateFilter({ ...intermediateFilter, powerstation: "" });
        }

        return () => {
            setClearAll({
                district: false,
                village: false,
                hamlet: false,
                powerstation: false,
            });
        };
    }, [
        intermediateFilter.district,
        intermediateFilter.village,
        intermediateFilter.hamlet,
        intermediateFilter.powerstation,
    ]);

    useEffect(() => {
        if (changeCount) {
            setFilter({ ...filter, ...intermediateFilter });
        }
    }, [changeCount]);

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                height: mdDown ? "auto" : `calc(100vh - 96px)`,
            }}
        >
            <Card
                elevation={0}
                sx={{
                    bgcolor: theme.palette.common.white,
                    p: 2,
                    mb: "1rem",
                }}
            >
                <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                    spacing={1.5}
                >
                    <Grid item xs={12} md={4}>
                        <Typography variant="h5" color="GrayText">
                            Surcharges
                        </Typography>
                    </Grid>

                    <Grid item xs={12} md={8} lg={6} container spacing={1.5}>
                        <Grid item flex={1}>
                            <SearchBar
                                filter={intermediateFilter}
                                setFilter={setIntermediateFilter}
                            />
                        </Grid>

                        <Grid item xs={12} md="auto">
                            <MultiPermissionAuthorize
                                ops={["CREATE SURCHARGE"]}
                            >
                                <Link
                                    to="/connections/surcharge/add"
                                    style={{ textDecoration: "none" }}
                                >
                                    <Button
                                        sx={{ height: "100%" }}
                                        variant="outlined"
                                        color="secondary"
                                        startIcon={<Add />}
                                        fullWidth
                                    >
                                        add surcharge
                                    </Button>
                                </Link>
                            </MultiPermissionAuthorize>
                        </Grid>
                    </Grid>

                    <Grid item container xs={12} spacing={1.5}>
                        <MultiPermissionAuthorize ops={["READ DISTRICT"]}>
                            <Grid item xs={12} sm={6} lg={3}>
                                <UpdatedSearchableInput
                                    api="/region/district"
                                    label="District"
                                    filter={intermediateFilter}
                                    setFilter={setIntermediateFilter}
                                    clearAll={clearAll.district}
                                />
                            </Grid>
                        </MultiPermissionAuthorize>

                        <MultiPermissionAuthorize ops={["READ VILLAGE"]}>
                            <Grid item xs={12} sm={6} lg={3}>
                                <UpdatedSearchableInput
                                    api="/region/village"
                                    label="Village"
                                    filter={intermediateFilter}
                                    setFilter={setIntermediateFilter}
                                    dep={intermediateFilter.district}
                                    params={{
                                        district: intermediateFilter.district,
                                    }}
                                    clearAll={clearAll.village}
                                />
                            </Grid>
                        </MultiPermissionAuthorize>

                        <MultiPermissionAuthorize ops={["READ HAMLET"]}>
                            <Grid item xs={12} sm={6} lg={3}>
                                <UpdatedSearchableInput
                                    api="/region/hamlet"
                                    label="Hamlet"
                                    filter={intermediateFilter}
                                    setFilter={setIntermediateFilter}
                                    dep={intermediateFilter.village}
                                    params={Object.fromEntries(
                                        Object.entries(intermediateFilter)
                                            .filter(
                                                ([k, v]) =>
                                                    [
                                                        "district",
                                                        "village",
                                                    ].includes(k) &&
                                                    v &&
                                                    v !== "all"
                                            )
                                            .map(([k, v]) => [k, [v]])
                                    )}
                                    clearAll={clearAll.hamlet}
                                />
                            </Grid>
                        </MultiPermissionAuthorize>

                        <MultiPermissionAuthorize ops={["READ POWERSTATION"]}>
                            <Grid item xs={12} sm={6} lg={3}>
                                <UpdatedSearchableInput
                                    api="/powerstation"
                                    label="Powerstation"
                                    filter={intermediateFilter}
                                    setFilter={setIntermediateFilter}
                                    dep={intermediateFilter.district}
                                    params={{
                                        district: intermediateFilter.district,
                                    }}
                                    clearAll={clearAll.powerstation}
                                />
                            </Grid>
                        </MultiPermissionAuthorize>

                        <Grid item xs={12} sm={6} lg={3}>
                            <Button
                                color="secondary"
                                variant="outlined"
                                onClick={() => setChangeCount(changeCount + 1)}
                                sx={{ height: "100%" }}
                                startIcon={<FilterAltOutlined />}
                                fullWidth
                            >
                                filter
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Card>

            {data?.data?.rows.length > 0 ? (
                <Box sx={{ height: "60%" }}>
                    <BaseTable
                        headers={{
                            id: "ID",
                            name: "Name",
                            amount: "Amount",
                            calculationStrategy: "Calc. Strategy",
                            district: "District",
                            village: "Village",
                            hamlet: "Hamlet",
                            powerstation: "Powerstation",
                        }}
                        load={Boolean(changeCount)}
                        defaultSelectedHeaders={["id", "name", "amount"]}
                        _printables={[
                            "id",
                            "name",
                            "amount",
                            "calculationStrategy",
                            "district",
                            "village",
                            "hamlet",
                            "powerstation",
                        ]}
                        data={data?.data.rows.map((row: any) => ({
                            ...row,
                            amount: row.isSurchargePct ? (
                                <Typography variant="body2">
                                    {row.value} %
                                </Typography>
                            ) : (
                                <Typography variant="body2">
                                    {currencyFormatter.format(row.value)}
                                </Typography>
                            ),
                            calculationStrategy: JSON.parse(
                                row.calculationStrategy
                            ).map((item: string, index: number) => (
                                <Chip
                                    size="small"
                                    label={calculationStrategyMap.get(item)}
                                    key={index}
                                />
                            )),
                            district: row?.district?.name ?? "Global",
                            village: row?.village?.name ?? "Global",
                            hamlet: row?.hamlet?.name ?? "Global",
                            powerstation: row?.powerstation?.name ?? "Global",
                        }))}
                        delEndPoint="/connection/surcharge"
                        feedback={feedback}
                        setFeedback={setFeedback}
                        rowsCount={rowsCount}
                        pagination={pagination}
                        setPagination={setPagination}
                        isLoading={isLoading}
                        permissions={{
                            edit: ["UPDATE SURCHARGE"],
                            delete: ["DELETE SURCHARGE"],
                        }}
                        reportName="Surcharges List"
                        queryKey={[
                            "surcharges",
                            pagination.page,
                            pagination.limit,
                            filter,
                        ]}
                    />
                </Box>
            ) : hasValue(filter) ? (
                <Alert severity="info">{noDataMessage}</Alert>
            ) : (
                <Alert severity="info">{filterSelectionMessage}</Alert>
            )}
        </Box>
    );
};

export default Surcharge;
