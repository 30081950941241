import { Box, Grid, useTheme } from "@mui/material";
import { RangeInput } from "./RangeInput";
import { AllInclusiveOutlined } from "@mui/icons-material";
import { Dispatch, SetStateAction } from "react";

type LastRangeProps = {
    range: { start: number; cost: number };
    isAdaptive: boolean;
    index: number;
    consumption: number;
    unitChargeRules: UnitChargeRules;
    setUnitChargeRules: Dispatch<SetStateAction<UnitChargeRules>>;
    minCost: number;
};

export const LastRange = ({
    range,
    isAdaptive,
    index,
    consumption,
    unitChargeRules,
    setUnitChargeRules,
    minCost,
}: LastRangeProps) => {
    const theme = useTheme();

    return (
        <>
            <Grid item xs={3}>
                <RangeInput
                    label="Start"
                    name="start"
                    value={range.start}
                    isAdaptive={isAdaptive}
                    index={index}
                    consumption={consumption}
                    mainState={unitChargeRules}
                    setMainState={setUnitChargeRules}
                    disabled
                />
            </Grid>

            <Grid item xs={3}>
                <Box
                    border={`1px solid ${theme.palette.action.disabled}`}
                    borderRadius={theme.spacing(0.5)}
                    height={theme.spacing(5)}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                >
                    <AllInclusiveOutlined
                        htmlColor={theme.palette.action.disabled}
                    />
                </Box>
            </Grid>

            <Grid item xs={3}>
                <RangeInput
                    label="Cost After Last Rule"
                    name="cost"
                    value={range.cost}
                    isAdaptive={isAdaptive}
                    index={index}
                    consumption={consumption}
                    mainState={unitChargeRules}
                    setMainState={setUnitChargeRules}
                    min={minCost}
                    required
                />
            </Grid>

            <Grid item xs={3}></Grid>
        </>
    );
};
