import { Grid, Typography, useTheme } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../Utilities/AppContext";
import { GET } from "../../../Utilities/BaseService";
import { S3Service } from "../../../Utilities/S3Service";
import { awsDirStruct } from "../../../Utilities/constants";

type Props = {
    copyName: string;
};

export const Header = ({ copyName }: Props) => {
    const { store, setStore } = useContext(AppContext);
    const [logoSrc, setLogoSrc] = useState("");
    const [secondaryLogos, setSecondaryLogos] = useState<string[]>([]);
    const theme = useTheme();

    const getDataInRange = (index: number, limit: number, data: any[]) => {
        const output: any[] = [];

        for (index; index < limit; index++) {
            if (data[index]) {
                output.push(data[index]);
            }
        }

        return output;
    };

    const getLogo = async () => {
        if (store.logoURL) {
            setLogoSrc(store.logoURL);

            try {
                const response = await GET("/settings/logos", {
                    default: true,
                });

                const second_third_Logo = getDataInRange(
                    1,
                    3,
                    response.data.rows || []
                );

                let secondaryLogosUrls: Promise<any>[] = second_third_Logo.map(
                    async (l) => {
                        try {
                            const [{ url }] = await S3Service.getS3ByKey(
                                `${awsDirStruct.logos}/${l.id}`
                            );

                            return url;
                        } catch (error) {
                            return "";
                        }
                    }
                );

                const res = await Promise.all(secondaryLogosUrls);

                setSecondaryLogos(res);
            } catch (error) {
                console.log(error);
            }
        } else {
            try {
                const response = await GET("/settings/logos", {
                    default: true,
                });
                const firstDefault = response.data.rows[0];
                const [{ url }, err] = await S3Service.getS3ByKey(
                    `${awsDirStruct.logos}/${firstDefault.id}`
                );

                const _response = await fetch(url);
                const blob = await _response.blob();
                const blobURL = URL.createObjectURL(blob);
                setLogoSrc(blobURL);
                setStore({ ...store, logoURL: blobURL });
            } catch (err) {}
        }
    };

    useEffect(() => {
        getLogo();
    }, []);

    return (
        <Grid item container xs={12} alignItems="center">
            <Grid item xs={2}>
                <img src={logoSrc} alt="logo" width="60px" height="60px" />
            </Grid>

            <Grid
                item
                xs={8}
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "center",
                }}
            >
                <Typography variant="h6">
                    Sarhad Rural Support Programme
                </Typography>
                <Typography>
                    Electricity Bill <span>{`(${copyName})`}</span>
                </Typography>
                {/* <Typography variant="caption">{`(${copyName})`}</Typography> */}
            </Grid>

            <Grid
                item
                xs={2}
                display="flex"
                justifyContent="flex-end"
                gap={theme.spacing(3)}
            >
                {secondaryLogos.map((l, id) => (
                    <img
                        key={id}
                        src={l}
                        alt="logo"
                        width="60px"
                        height="60px"
                    />
                ))}
                {/* {secondaryLogos.map((l) => (
                    // <div>
                    //     <img src={l} alt="logo" width="60px" height="60px" />
                    // </div>

                    <Typography>hello</Typography>
                ))} */}
            </Grid>
        </Grid>
    );
};
