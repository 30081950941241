import {
    Alert,
    Button,
    CircularProgress,
    Grid,
    Hidden,
    TextField,
    Typography,
    useTheme,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { ChangeEvent, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../Utilities/AppContext";
import { POST } from "../../Utilities/BaseService";
import { S3Service } from "../../Utilities/S3Service";

const logInUser = (creds: any) => POST("/user/login", creds);

const Login = () => {
    const [user, setuser] = useState({
        username: "",
        password: "",
    });

    const { store, setStore } = useContext(AppContext);

    const [logoSrc, setLogoSrc] = useState("");
    const [feedback, setFeedback] = useState("");
    const theme = useTheme();
    const { user: _user, setUser } = useContext(AppContext);

    const history = useNavigate();

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setuser({ ...user, [e.target.name]: value });
    };

    const handleSubmit = (ev: any) => {
        ev.preventDefault();

        mutation.mutate(user);
    };

    const mutation = useMutation(logInUser, {
        onSuccess(res) {
            if (res.data.message == "Already logged in") {
                history("/dashboard");
            } else {
                setUser(res.data.user);
                history("/dashboard");
                const date = new Date();
                date.setTime(date.getTime() + res.data.expiry);
                localStorage.setItem(
                    "sessionExpiry",
                    date.getTime().toString()
                );
                localStorage.setItem("user", JSON.stringify(res.data.user));
            }
        },
        onError(err: any) {
            setFeedback(err.response.data.message);
        },
    });

    const getLogo = async () => {
        if (store.logo) {
            setLogoSrc(URL.createObjectURL(store.logo));
        } else {
            const [data, err] = await S3Service.getS3ByKey("logo");

            if (data) {
                const url = data.url;

                try {
                    const response = await fetch(url);
                    const blob = await response.blob();
                    setLogoSrc(url);
                    setStore({ ...store, logo: blob });
                } catch (err) {}
            }
        }
    };

    useEffect(() => {
        getLogo();
    }, []);

    return (
        <Grid
            container
            style={{
                backgroundImage: "url(/login-bg.jpg)",
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                position: "relative",
            }}
        >
            <span
                style={{
                    position: "absolute",
                    top: "0",
                    left: "0",
                    right: "0",
                    bottom: "0",
                    backgroundColor: "white",
                    opacity: "0.85",
                }}
            />
            <Hidden smDown>
                <Grid item xs={12} lg={7} px={10} py={5}>
                    <img
                        src="./logo.png"
                        alt="SRSP Logo"
                        width="200"
                        style={{ position: "relative" }}
                    />
                </Grid>
            </Hidden>

            <Grid
                item
                xs={12}
                lg={5}
                component="form"
                bgcolor={theme.palette.primary.main}
                height="100vh"
                px={10}
                py={5}
                method="post"
                position="relative"
                onSubmit={handleSubmit}
            >
                <Typography
                    variant="h3"
                    color="secondary"
                    mb={10}
                    textTransform="capitalize"
                >
                    login
                </Typography>

                <TextField
                    color="secondary"
                    fullWidth
                    label="Username"
                    name="username"
                    onChange={handleChange}
                    sx={{ marginBottom: 3 }}
                    type="text"
                    value={user.username}
                    variant="outlined"
                />

                <TextField
                    color="secondary"
                    fullWidth
                    label="Password"
                    name="password"
                    onChange={handleChange}
                    sx={{ marginBottom: 6 }}
                    type="password"
                    value={user.password}
                    variant="outlined"
                />

                <Button
                    endIcon={
                        mutation.isLoading ? (
                            <CircularProgress size="1rem" />
                        ) : null
                    }
                    sx={{ mb: "1rem" }}
                    variant="contained"
                    color="secondary"
                    size="large"
                    type="submit"
                    fullWidth
                >
                    {mutation.isLoading ? `loggin in...` : "Log in"}
                </Button>

                {mutation.isError && <Alert severity="error">{feedback}</Alert>}
            </Grid>
        </Grid>
    );
};

export default Login;
