import {
    Alert,
    Box,
    Button,
    Checkbox,
    CircularProgress,
    Divider,
    FormControlLabel,
    Grid,
    TextField,
    Typography,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { ChangeEvent, FormEvent, useContext, useEffect, useState } from "react";
import { AppContext } from "../../Utilities/AppContext";
import { POST } from "../../Utilities/BaseService";
import UpdatedSearchableInput from "../../Utilities/UpdatedSearchableInput";

type DateConstraintVariant =
    | "collection-back"
    | "collection-forward"
    | "reading-back"
    | "reading-forward"
    | "connection-back"
    | "connection-forward";

type DateConstraint = { key: DateConstraintVariant; value: string };

const create_customer_property_meter = (data: {
    customer: Customer;
    propertyType: string;
    property: Property;
    meter: Meter;
}) => POST("/customer/merge", data);

type Customer = {
    name: string;
    fatherName: string;
    cnic: number | "";
    mobileNo: number | "";
};

type Property = {
    name: string;
    address: string;
    district: string;
    village: string;
    hamlet: string;
    powerstation: string;
};

type Meter = {
    meterNo: number | string;
    connectionCharges: string;
    connectionDate: string;
    connectionType: number | string;
    powerstation: number | string;
    lineRentEditable: boolean;
};

const CombinedView = () => {
    const [regions, setRegions] = useState({
        district: "",
        village: "",
        hamlet: "",
        powerstation: "",
    });
    const [customer, setCustomer] = useState<Customer>({
        name: "",
        fatherName: "",
        cnic: "",
        mobileNo: "",
    });
    const [property, setProperty] = useState({
        name: "",
        address: "",
        type: "",
    });
    const [meter, setMeter] = useState({
        meterNo: "",
        connectionCharges: "",
        connectionDate: "",
        connectionType: "",
        lineRentEditable: false,
    });
    const [feedback, setFeedback] = useState("");
    const [clear, setClear] = useState({
        district: false,
        village: false,
        hamlet: false,
        powerstation: false,
    });
    const [defaultRegions, setDefaultRegions] = useState({
        district: "",
        village: "",
        hamlet: "",
        powerstation: "",
    });
    const { user } = useContext(AppContext);

    const handleCnicChange = (ev: any) => {
        const cnic = ev.target.value;

        let _cnic = cnic;
        if (_cnic.length === 5 || _cnic.length === 13) {
            _cnic += "-";
        } else if (_cnic.length > 15) {
            return;
        }

        setCustomer({ ...customer, cnic: _cnic });
    };

    const handleMobileChange = (ev: any) => {
        const mbl = ev.target.value;
        let _mbl = mbl;

        if (_mbl.length === 4) {
            _mbl += "-";
        } else if (_mbl.length > 12) {
            return;
        }

        setCustomer({ ...customer, mobileNo: _mbl });
    };

    const handleChange = (
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        target: "customer" | "property" | "meter"
    ) => {
        const name = e.target.name;

        switch (target) {
            case "customer":
                setCustomer({ ...customer, [name]: e.target.value });
                return;

            case "property":
                setProperty({ ...property, [name]: e.target.value });
                return;

            case "meter":
                setMeter({ ...meter, [name]: e.target.value });
                return;

            default:
                return;
        }
    };

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        addMutation.mutate({
            customer,
            propertyType: property.type,
            property: {
                name: property.name,
                address: property.address,
                district: regions.district,
                village: regions.village,
                hamlet: regions.hamlet,
                powerstation: regions.powerstation,
            },
            meter: { ...meter, powerstation: regions.powerstation },
        });
    };

    const addMutation = useMutation(create_customer_property_meter, {
        onSuccess(res) {
            setFeedback(res.data.message);
        },

        onError(res: any) {
            setFeedback(res.response.data.message);
        },
    });

    function manipulateDate(
        currentDate: Date,
        daysToAddOrSubtract: number,
        op: "add" | "sub"
    ) {
        const dateObj = new Date(currentDate);

        const millisecondsInADay = 24 * 60 * 60 * 1000;

        let newDate: Date;
        switch (op) {
            case "add":
                newDate = new Date(
                    dateObj.getTime() + daysToAddOrSubtract * millisecondsInADay
                );
                break;

            case "sub":
                newDate = new Date(
                    dateObj.getTime() - daysToAddOrSubtract * millisecondsInADay
                );
                break;
        }

        return newDate;
    }

    function getRestrictedDate(target: DateConstraintVariant) {
        const hasDateRestriction = Boolean(
            user.constraints.find((c: DateConstraint) => c.key === target)
        );

        if (hasDateRestriction) {
            console.log("has date constraints: ", hasDateRestriction);
            const days: number = user.constraints.find(
                (c: DateConstraint) => c.key === target
            ).value;

            switch (target) {
                case "connection-back":
                    const backDate = manipulateDate(new Date(), days, "sub")
                        .toISOString()
                        .slice(0, 10);

                    console.log(backDate, target);

                    return backDate;

                case "connection-forward":
                    const forwardDate = manipulateDate(new Date(), days, "add")
                        .toISOString()
                        .slice(0, 10);

                    console.log(target);

                    return forwardDate;
            }
        } else return undefined;
    }

    useEffect(() => {
        if (regions.district === "all") {
            setClear({
                district: true,
                village: true,
                hamlet: true,
                powerstation: true,
            });
            setRegions({
                district: "",
                village: "",
                hamlet: "",
                powerstation: "",
            });
        }

        if (regions.village === "all") {
            setClear({ ...clear, village: true, hamlet: true });
            setRegions({
                ...regions,
                village: "",
                hamlet: "",
                powerstation: "",
            });
        }

        if (regions.hamlet === "all") {
            setClear({ ...clear, hamlet: true });
            setRegions({ ...regions, hamlet: "" });
        }

        if (defaultRegions.district !== regions.district) {
            setClear({
                ...clear,
                village: true,
                hamlet: true,
            });

            setDefaultRegions({
                ...defaultRegions,
                district: regions.district as any,
            });

            setRegions({
                ...regions,
                village: "",
                hamlet: "",
                powerstation: "",
            });
        }

        return () => {
            setClear({
                district: false,
                village: false,
                hamlet: false,
                powerstation: false,
            });
        };
    }, [regions.district, regions.village, regions.hamlet]);

    return (
        <Box>
            <Grid
                container
                spacing={2}
                component="form"
                onSubmit={handleSubmit}
                mb={2}
            >
                <Grid item xs={12} mb={2}>
                    <Typography variant="h5">Add New Connection</Typography>
                </Grid>

                <Grid item xs={12}>
                    <Divider>Customer Details</Divider>
                </Grid>

                <Grid item xs={12} md={6} lg={3}>
                    <TextField
                        required
                        fullWidth
                        label="Name"
                        name="name"
                        size="small"
                        value={customer.name}
                        onChange={(e) => handleChange(e, "customer")}
                    />
                </Grid>

                <Grid item xs={12} md={6} lg={3} spacing={2}>
                    <TextField
                        required
                        fullWidth
                        label="Father Name"
                        name="fatherName"
                        size="small"
                        value={customer.fatherName}
                        onChange={(e) => handleChange(e, "customer")}
                    />
                </Grid>

                <Grid item xs={12} md={6} lg={3} spacing={2}>
                    <TextField
                        required
                        fullWidth
                        label="CNIC"
                        name="cnic"
                        size="small"
                        value={customer.cnic}
                        onChange={handleCnicChange}
                    />
                </Grid>

                <Grid item xs={12} md={6} lg={3} spacing={2}>
                    <TextField
                        required
                        fullWidth
                        label="Mobile No."
                        name="mobileNo"
                        size="small"
                        value={customer.mobileNo}
                        onChange={handleMobileChange}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Divider>Property Details</Divider>
                </Grid>

                <Grid item xs={12} md={6} lg={3} spacing={2}>
                    <UpdatedSearchableInput
                        api="/customer/property/type"
                        _name="type"
                        filter={property}
                        setFilter={setProperty}
                        label="Property Type"
                    />
                </Grid>

                <Grid item xs={12} md={6} lg={3} spacing={2}>
                    <TextField
                        required
                        fullWidth
                        label="Property Name"
                        name="name"
                        size="small"
                        value={property.name}
                        onChange={(e) => handleChange(e, "property")}
                    />
                </Grid>

                <Grid item xs={12} md={6} lg={3} spacing={2}>
                    <TextField
                        required
                        fullWidth
                        label="Property Address"
                        name="address"
                        size="small"
                        value={property.address}
                        onChange={(e) => handleChange(e, "property")}
                    />
                </Grid>

                <Grid item xs={12} md={6} lg={3}>
                    <UpdatedSearchableInput
                        required
                        api="/region/district"
                        label="District"
                        filter={regions}
                        setFilter={setRegions}
                        clearAll={clear.district}
                    />
                </Grid>

                <Grid item xs={12} md={6} lg={3}>
                    <UpdatedSearchableInput
                        required
                        api="/region/village"
                        label="Village"
                        filter={regions}
                        setFilter={setRegions}
                        dep={regions.district}
                        clearAll={clear.village}
                        params={{ district: regions.district }}
                    />
                </Grid>

                <Grid item xs={12} md={6} lg={3}>
                    <UpdatedSearchableInput
                        required
                        api="/region/hamlet"
                        label="Hamlet"
                        filter={regions}
                        setFilter={setRegions}
                        dep={regions.village}
                        clearAll={clear.hamlet}
                        params={
                            regions.village && regions.village !== "all"
                                ? { village: [regions.village] }
                                : { village: [0] }
                        }
                    />
                </Grid>

                <Grid item xs={12} md={6} lg={3}>
                    <UpdatedSearchableInput
                        required
                        api="/powerstation"
                        label="Powerstation"
                        filter={regions}
                        setFilter={setRegions}
                        dep={regions.district}
                        params={{ district: regions.district }}
                        clearAll={clear.powerstation}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Divider>Meter Details</Divider>
                </Grid>

                <Grid item xs={12} md={6} lg={3} spacing={2}>
                    <TextField
                        required
                        fullWidth
                        label="Meter No."
                        name="meterNo"
                        size="small"
                        value={meter.meterNo}
                        onChange={(e) => handleChange(e, "meter")}
                    />
                </Grid>

                <Grid item xs={12} md={6} lg={3} spacing={2}>
                    <TextField
                        required
                        fullWidth
                        label="Connection Date"
                        name="connectionDate"
                        size="small"
                        type="date"
                        InputLabelProps={{ shrink: true }}
                        value={meter.connectionDate}
                        onChange={(e) => handleChange(e, "meter")}
                        inputProps={{
                            min: getRestrictedDate("connection-back"),
                            max: getRestrictedDate("connection-forward"),
                        }}
                    />
                </Grid>

                <Grid item xs={12} md={6} lg={3} spacing={2}>
                    <TextField
                        label="Connection Charges"
                        size="small"
                        type="number"
                        value={meter.connectionCharges}
                        onChange={(e) =>
                            setMeter({
                                ...meter,
                                connectionCharges: e.target.value,
                            })
                        }
                        fullWidth
                    />
                </Grid>

                <Grid item xs={12} md={6} lg={3} spacing={2}>
                    <UpdatedSearchableInput
                        required
                        label="Connection Type"
                        _name="connectionType"
                        api="/connection"
                        filter={meter}
                        setFilter={setMeter}
                    />
                </Grid>

                <Grid item xs={12} md={6} lg={3}>
                    <UpdatedSearchableInput
                        label="Price Type"
                        _name="priceType"
                        api="/priceType"
                        filter={meter}
                        setFilter={setMeter}
                        required
                    />
                </Grid>

                <Grid item xs={12} md={6} lg={4}>
                    <FormControlLabel
                        label="Is line rent editable?"
                        control={
                            <Checkbox
                                checked={meter.lineRentEditable}
                                onChange={(e) =>
                                    setMeter({
                                        ...meter,
                                        lineRentEditable: e.target.checked,
                                    })
                                }
                            />
                        }
                    />
                </Grid>

                <Grid item xs={12}>
                    <Button
                        variant="outlined"
                        color="secondary"
                        size="small"
                        type="submit"
                        disabled={addMutation.isLoading}
                        endIcon={
                            addMutation.isLoading ? (
                                <CircularProgress size={20} />
                            ) : undefined
                        }
                    >
                        {addMutation.isLoading
                            ? "creating connection..."
                            : "create connection"}
                    </Button>
                </Grid>
            </Grid>

            {addMutation.isSuccess && (
                <Alert severity="success">{feedback}</Alert>
            )}

            {addMutation.isError && <Alert severity="error">{feedback}</Alert>}
        </Box>
    );
};

export default CombinedView;
