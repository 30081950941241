import { Grid, IconButton, Tooltip, useTheme } from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import { RangeInput } from "./RangeInput";
import { CloseOutlined } from "@mui/icons-material";

type RuleRangeProps = {
    range: RuleRange;
    index: number;
    isAdaptive: boolean;
    unitChargeRules: UnitChargeRules;
    setUnitChargeRules: Dispatch<SetStateAction<UnitChargeRules>>;
    consumption: number;
};

export const RuleRange = ({
    range,
    unitChargeRules,
    setUnitChargeRules,
    index,
    isAdaptive,
    consumption,
}: RuleRangeProps) => {
    const theme = useTheme();

    const handleDeleteRange = (idx: number) => {
        if (isAdaptive) {
            const targetRanges = Object.fromEntries(
                Object.entries(unitChargeRules[consumption].components).filter(
                    (range) => parseInt(range[0]) !== idx
                )
            );

            setUnitChargeRules({
                ...unitChargeRules,
                [consumption]: {
                    ...unitChargeRules[consumption],
                    components: targetRanges,
                },
            });
        } else {
            const targetRanges = Object.fromEntries(
                Object.entries(unitChargeRules[-1].components).filter(
                    (range) => parseInt(range[0]) !== idx
                )
            );

            setUnitChargeRules({
                ...unitChargeRules,
                [-1]: { ...unitChargeRules[-1], components: targetRanges },
            });
        }
    };

    return (
        <>
            <Grid item xs={3}>
                <RangeInput
                    label="Start"
                    name="start"
                    value={range.start}
                    isAdaptive={isAdaptive}
                    index={index}
                    consumption={consumption}
                    mainState={unitChargeRules}
                    setMainState={setUnitChargeRules}
                    min={1}
                    disabled
                />
            </Grid>

            <Grid item xs={3}>
                <RangeInput
                    label="End"
                    name="end"
                    value={range.end}
                    isAdaptive={isAdaptive}
                    index={index}
                    consumption={consumption}
                    mainState={unitChargeRules}
                    setMainState={setUnitChargeRules}
                    max={
                        isAdaptive && consumption !== -1
                            ? unitChargeRules[consumption].consumption
                            : undefined
                    }
                />
            </Grid>

            <Grid item xs={3}>
                <RangeInput
                    label="Cost"
                    name="cost"
                    value={range.cost}
                    isAdaptive={isAdaptive}
                    index={index}
                    consumption={consumption}
                    mainState={unitChargeRules}
                    setMainState={setUnitChargeRules}
                    required
                />
            </Grid>

            <Grid item xs={3}>
                <Tooltip title="Delete Range">
                    <IconButton
                        size="small"
                        color="error"
                        onClick={() => handleDeleteRange(index)}
                    >
                        <CloseOutlined fontSize="small" />
                    </IconButton>
                </Tooltip>
            </Grid>
        </>
    );
};
