import { Add } from "@mui/icons-material";
import {
    Card,
    CardHeader,
    CardContent,
    Divider,
    Grid,
    TextField,
    Button,
    CircularProgress,
    Alert,
} from "@mui/material";
import { useQuery, useMutation } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { theme } from "../../../Theme/theme";
import { GET, PATCH, POST } from "../../../Utilities/BaseService";
import UpdatedSearchableInput from "../../../Utilities/UpdatedSearchableInput";

type Props = { updateMode?: boolean };
type ConnectionCharge = {
    name: string;
    amount: string;
    district: string;
    village: string;
    hamlet: string;
    powerstation: string;
};

const AddConnectionCharge = ({ updateMode = false }: Props) => {
    const [connectionCharge, setConnectionCharge] = useState({
        name: "",
        amount: "",
        district: "",
        village: "",
        hamlet: "",
        powerstation: "",
    });
    const [feedback, setFeedback] = useState("");
    const location = useLocation();
    const [id, setId] = useState("");
    const [clear, setClear] = useState({
        district: false,
        village: false,
        hamlet: false,
        powerstation: false,
    });
    const [defaultRegions, setDefaultRegions] = useState({
        district: "",
        village: "",
        hamlet: "",
        powerstation: "",
    });

    const { data } = useQuery(
        [`connection-charges-${id}`],
        () => GET(`/connection-charges?id=${id}`),
        {
            enabled: Boolean(id),
            onSuccess(response) {
                if (response.data.rows.length > 0) {
                    const connCharge = response.data.rows[0];

                    setDefaultRegions({
                        district: connCharge.district?.id ?? "",
                        village: connCharge.village?.id ?? "",
                        hamlet: connCharge.hamlet?.id ?? "",
                        powerstation: connCharge.powerstation?.id ?? "",
                    });

                    setConnectionCharge({
                        name: connCharge.name,
                        amount: connCharge.amount,
                        district: connCharge.district?.id ?? "",
                        village: connCharge.village?.id ?? "",
                        hamlet: connCharge.hamlet?.id ?? "",
                        powerstation: connCharge.powerstation?.id ?? "",
                    });
                }
            },
            refetchOnMount: true,
        }
    );

    const handleSubmit = (ev: any) => {
        ev.preventDefault();
        {
            !updateMode ? addMutation.mutate() : updateMutation.mutate();
        }
    };

    const cleanConnectionCharge = (connectionCharge: ConnectionCharge) =>
        Object.fromEntries(
            Object.entries(connectionCharge)
                .map(([k, v]) =>
                    ["district", "village", "hamlet", "powerstation"].includes(
                        k
                    )
                        ? v && v !== "all"
                            ? [k, v]
                            : [k, null]
                        : [k, v]
                )
                .filter((item) => Boolean(item[0]))
        );

    const addMutation = useMutation(
        () =>
            POST(
                "/connection-charges",
                cleanConnectionCharge(connectionCharge)
            ),
        {
            onSuccess(res) {
                setFeedback(res.data.message);
            },

            onError(err: any) {
                setFeedback(err.response.data.message);
            },
        }
    );

    const updateMutation = useMutation(
        () =>
            PATCH(
                "/connection-charges",
                cleanConnectionCharge(connectionCharge),
                {
                    id,
                }
            ),
        {
            onSuccess(res) {
                setFeedback(res.data.message);
            },

            onError(err: any) {
                setFeedback(err.response.data.message);
            },
        }
    );

    useEffect(() => {
        if (updateMode) {
            const params = new URLSearchParams(location.search);
            const _id = params.get("id");

            if (_id) {
                setId(_id);
            }
        }
    }, [updateMode]);

    useEffect(() => {
        if (connectionCharge.district === "all") {
            setClear({
                district: true,
                village: true,
                hamlet: true,
                powerstation: true,
            });
            setConnectionCharge({
                ...connectionCharge,
                district: "",
                village: "",
                hamlet: "",
                powerstation: "",
            });
        }

        if (connectionCharge.village === "all") {
            setClear({ ...clear, village: true, hamlet: true });
            setConnectionCharge({
                ...connectionCharge,
                village: "",
                hamlet: "",
            });
        }

        if (connectionCharge.hamlet === "all") {
            setClear({ ...clear, hamlet: true });
            setConnectionCharge({ ...connectionCharge, hamlet: "" });
        }

        if (defaultRegions.district !== connectionCharge.district) {
            setClear({
                ...clear,
                village: true,
                hamlet: true,
                powerstation: true,
            });

            setDefaultRegions({
                ...defaultRegions,
                district: connectionCharge.district as any,
            });

            setConnectionCharge({
                ...connectionCharge,
                village: "",
                hamlet: "",
                powerstation: "",
            });
        }

        return () => {
            setClear({
                district: false,
                village: false,
                hamlet: false,
                powerstation: false,
            });
        };
    }, [
        connectionCharge.district,
        connectionCharge.village,
        connectionCharge.hamlet,
    ]);
    return (
        <>
            <Card
                elevation={0}
                sx={{ bgcolor: theme.palette.common.white, mb: "1rem" }}
            >
                <CardHeader
                    title={
                        updateMode
                            ? "update connection charges"
                            : "add connection charges"
                    }
                    sx={{
                        textTransform: "capitalize",
                        bgcolor: theme.palette.common.white,
                    }}
                />
                <CardContent>
                    <Divider sx={{ mb: 5 }} />

                    <Grid
                        container
                        component="form"
                        onSubmit={handleSubmit}
                        spacing={2}
                    >
                        <Grid item xs={12} md={6} lg={3}>
                            <TextField
                                size="small"
                                name="name"
                                value={connectionCharge.name}
                                label="Connection Charge Name"
                                onChange={(ev) =>
                                    setConnectionCharge({
                                        ...connectionCharge,
                                        name: ev.target.value,
                                    })
                                }
                                fullWidth
                                type="text"
                                required
                            />
                        </Grid>

                        <Grid item xs={12} md={6} lg={3}>
                            <TextField
                                size="small"
                                name="name"
                                value={connectionCharge.amount}
                                label="Connection Charges"
                                onChange={(ev) =>
                                    setConnectionCharge({
                                        ...connectionCharge,
                                        amount: ev.target.value,
                                    })
                                }
                                fullWidth
                                type="number"
                                inputProps={{ min: 0 }}
                                required
                            />
                        </Grid>

                        <Grid xs={12} md={6} lg={3} item>
                            <UpdatedSearchableInput
                                api="/region/district"
                                label="District"
                                filter={connectionCharge}
                                setFilter={setConnectionCharge}
                                clearAll={clear.district}
                                defaultValue={
                                    updateMode &&
                                    (defaultRegions.district as any)
                                }
                            />
                        </Grid>
                        <Grid xs={12} md={6} lg={3} item>
                            <UpdatedSearchableInput
                                api="/region/village"
                                label="Village"
                                filter={connectionCharge}
                                setFilter={setConnectionCharge}
                                dep={connectionCharge.district}
                                params={{ district: connectionCharge.district }}
                                clearAll={clear.village}
                                defaultValue={
                                    updateMode &&
                                    (defaultRegions.village as any)
                                }
                            />
                        </Grid>
                        <Grid xs={12} md={6} lg={3} item>
                            <UpdatedSearchableInput
                                api="/region/hamlet"
                                label="Hamlet"
                                filter={connectionCharge}
                                setFilter={setConnectionCharge}
                                dep={connectionCharge.village}
                                params={{ village: connectionCharge.village }}
                                clearAll={clear.hamlet}
                                defaultValue={
                                    updateMode && (defaultRegions.hamlet as any)
                                }
                            />
                        </Grid>
                        <Grid xs={12} md={6} lg={3} item>
                            <UpdatedSearchableInput
                                api="/powerstation"
                                label="Powerstation"
                                filter={connectionCharge}
                                setFilter={setConnectionCharge}
                                dep={connectionCharge.district}
                                params={{ district: connectionCharge.district }}
                                clearAll={clear.powerstation}
                                defaultValue={
                                    updateMode &&
                                    (defaultRegions.powerstation as any)
                                }
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                startIcon={<Add />}
                                endIcon={
                                    addMutation.isLoading ? (
                                        <CircularProgress
                                            color="secondary"
                                            size="1rem"
                                        />
                                    ) : (
                                        ""
                                    )
                                }
                                color="secondary"
                                variant="outlined"
                                size="small"
                                type="submit"
                            >
                                {updateMode
                                    ? updateMutation.isLoading
                                        ? "updating connection charge..."
                                        : "update connection charge"
                                    : addMutation.isLoading
                                    ? "adding connection charge..."
                                    : "add connection charge"}
                            </Button>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>

            {addMutation.isSuccess || updateMutation.isSuccess ? (
                <Alert severity="success">{feedback}.</Alert>
            ) : addMutation.isError || updateMutation.isError ? (
                <Alert severity="error">{feedback}.</Alert>
            ) : null}
        </>
    );
};

export default AddConnectionCharge;
