import {
    Add,
    AddCircleOutline,
    RemoveCircleOutline,
} from "@mui/icons-material";
import {
    Alert,
    AlertTitle,
    Button,
    Card,
    CardContent,
    CardHeader,
    CircularProgress,
    Divider,
    Grid,
    IconButton,
    TextField,
    Typography,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { ChangeEvent, FormEvent, useState } from "react";
import { POST } from "../../../Utilities/BaseService";
import { notConnectedMessage } from "../../../Utilities/constants";

const createPaymentMethod = (data: any) => {
    const opts = Object.values(data.opts).filter(
        (opt: any) => opt.label.length > 0
    );
    return POST("/reading/payment-method", {
        name: data.name,
        opts: JSON.stringify(opts),
    });
};

export const AddPaymentMethod = () => {
    const [paymentMethod, setPaymentMethod] = useState<any>({
        name: "",
        opts: {
            0: { label: "", value: "" },
        },
    });

    const createMut = useMutation(["add-payment-method"], () =>
        createPaymentMethod(paymentMethod)
    );

    const handleCustomerOptsChange = (ev: ChangeEvent<any>, idx: number) => {
        setPaymentMethod({
            ...paymentMethod,
            opts: {
                ...paymentMethod.opts,
                [idx]: {
                    ...paymentMethod.opts[idx],
                    [ev.target.name]: ev.target.value,
                },
            },
        });
    };

    const handleSubmit = (ev: FormEvent<any>) => {
        ev.preventDefault();

        createMut.mutate();
    };

    return (
        <Card
            elevation={0}
            sx={{ mb: "1rem", bgcolor: "common.white" }}
            component="form"
            onSubmit={handleSubmit}
        >
            <CardHeader
                title="Add Payment Method"
                titleTypographyProps={{ color: "gray" }}
                sx={{
                    textTransform: "capitalize",
                }}
            />
            <CardContent component={Grid} container spacing={1}>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        name="name"
                        label="Method name"
                        value={paymentMethod.name}
                        onChange={(ev) =>
                            setPaymentMethod({
                                ...paymentMethod,
                                name: ev.target.value,
                            })
                        }
                        required
                    />
                </Grid>

                <Grid item xs={12} my={1}>
                    <Divider>
                        <Typography color="action.disabled" variant="caption">
                            Custom options
                        </Typography>
                    </Divider>
                </Grid>

                {Object.entries(paymentMethod.opts).map((entry: any) => (
                    <Grid
                        item
                        container
                        xs={12}
                        spacing={1}
                        alignItems="center"
                    >
                        <Grid item xs={5}>
                            <TextField
                                fullWidth
                                name="label"
                                label="Label"
                                value={paymentMethod.opts[entry[0]].label}
                                onChange={(ev) =>
                                    handleCustomerOptsChange(ev, entry[0])
                                }
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                name="value"
                                label="Value"
                                value={paymentMethod.opts[entry[0]].value}
                                onChange={(ev) =>
                                    handleCustomerOptsChange(ev, entry[0])
                                }
                            />
                        </Grid>

                        <Grid item xs={1}>
                            {Object.keys(paymentMethod.opts).length < 20 &&
                            Object.keys(paymentMethod.opts).slice(-1)[0] ==
                                entry[0] ? (
                                <IconButton
                                    color="success"
                                    onClick={() => {
                                        setPaymentMethod({
                                            ...paymentMethod,
                                            opts: {
                                                ...paymentMethod.opts,
                                                [Object.keys(
                                                    paymentMethod.opts
                                                ).slice(-1)[0] + 1]: {
                                                    label: "",
                                                    value: "",
                                                },
                                            },
                                        });
                                    }}
                                >
                                    <AddCircleOutline />
                                </IconButton>
                            ) : (
                                <IconButton
                                    color="error"
                                    onClick={() => {
                                        setPaymentMethod({
                                            ...paymentMethod,
                                            opts: Object.fromEntries(
                                                Object.entries(
                                                    paymentMethod.opts
                                                ).filter(
                                                    (e) => e[0] != entry[0]
                                                )
                                            ),
                                        });
                                    }}
                                >
                                    <RemoveCircleOutline />
                                </IconButton>
                            )}
                        </Grid>
                    </Grid>
                ))}

                <Grid item xs={12} mt={1}>
                    <Button
                        fullWidth
                        type="submit"
                        variant="outlined"
                        endIcon={
                            createMut.isLoading ? (
                                <CircularProgress size="1rem" />
                            ) : (
                                <Add />
                            )
                        }
                    >
                        Add Payment Method
                    </Button>
                </Grid>

                <Grid item xs={12}>
                    {(createMut.isSuccess || createMut.isError) && (
                        <Alert severity={createMut.status}>
                            {createMut.isSuccess
                                ? createMut?.data?.data?.message ||
                                  "Payment method created successfully"
                                : (createMut?.error as any)?.response?.data
                                      ?.message || notConnectedMessage}
                        </Alert>
                    )}
                </Grid>
            </CardContent>
        </Card>
    );
};
