import { ISlipService, Slip } from "../Features/Slip/types";
import { GET, POST } from "../Utilities/BaseService";

export class SlipService implements ISlipService {
    static instance: SlipService;
    static url = "/slip";

    static getInstance() {
        if (SlipService.instance) {
            return SlipService.instance;
        } else {
            SlipService.instance = new SlipService();
            return SlipService.instance;
        }
    }

    getSlips = async (pagination = { page: 1, limit: 10 }, filter = {}) => {
        try {
            const res: { data: { rows: Slip[] }; error: null } = (await GET(
                SlipService.url,
                { ...pagination, ...filter }
            )) as any;

            console.log(res);

            return res;
        } catch (error) {
            return error as { data: null; error: string };
        }
    };

    addSlip = async (newEntry: Slip) => {
        try {
            const response = (await POST(SlipService.url, newEntry)) as any;

            return response;
        } catch (error) {
            return error as { message: string };
        }
    };

    getArrears = async (meterId: number) => {
        try {
            const response = await GET(SlipService.url + "/arrears", {
                id: meterId,
            });

            return {
                message: response.data.message as string,
                arrears: response.data.arrears as number,
                arrearsWithoutSurcharge: response.data
                    .arrearsWithoutSurcharge as number,
            };
        } catch (error: any) {
            console.log(error);
            return {
                message: error.message as string,
                arrears: error.arrears as number,
                arrearsWithoutSurcharge:
                    error.arrearsWithoutSurcharge as number,
            };
        }
    };
}
