import { Add, ExpandMoreOutlined } from "@mui/icons-material";
import {
    Card,
    CardHeader,
    CardContent,
    Divider,
    Grid,
    TextField,
    Button,
    CircularProgress,
    Alert,
    useTheme,
    Accordion,
    Typography,
    AccordionSummary,
    AccordionDetails,
    FormControlLabel,
    Checkbox,
} from "@mui/material";
import { FormEvent, useEffect, useState } from "react";
import UpdatedSearchableInput from "../../../Utilities/UpdatedSearchableInput";
import { currencyFormatter } from "../../Reading/Bill/Cells/Last12Summary";
import { useMutation, useQuery } from "@tanstack/react-query";
import { GET, PATCH, POST } from "../../../Utilities/BaseService";
import { useLocation } from "react-router-dom";
import { UserRegions } from "../../User/UserRegions";

type PriceType = {
    name: string;
    lineRent: number | null;
    unitChargeRule: number | null;
    surcharge: number | null;
    district: number | null;
    village: number | null;
    hamlet: number | null;
    powerstation: number | null;
};

const AddPriceType = ({ updateMode = false }) => {
    const [priceType, setPriceType] = useState({
        name: "",
        lineRent: "",
        unitChargeRule: "",
        surcharge: "",
        connectionType: "",
        isDefault: false,
        regions: [],
        users: [],
    });
    const [feedback, setFeedback] = useState("");
    const [id, setId] = useState("");
    const [clearAll, setClearAll] = useState({
        district: false,
        village: false,
        hamlet: false,
        powerstation: false,
    });
    const [changes, setChanges] = useState({
        district: 0,
        village: 0,
        hamlet: 0,
        powerstation: 0,
    });

    const theme = useTheme();
    const location = useLocation();

    const { data: priceTypeById } = useQuery(
        [`price-type-${id}`],
        () => GET("/priceType", { id }),
        {
            enabled: Boolean(updateMode && id),

            onSuccess(res) {
                const _pt = res.data.rows[0];

                setPriceType({
                    name: _pt.name,
                    lineRent: _pt.lineRent.id,
                    unitChargeRule: _pt.unitChargeRule.id,
                    surcharge: _pt.surcharge.id,
                    regions: _pt.regions.map(
                        (r: {
                            district: { id: number } | null;
                            village: { id: number } | null;
                            hamlet: { id: number } | null;
                            powerstation: { id: number } | null;
                        }) => ({
                            district: r.district?.id || null,
                            village: r.village?.id || null,
                            hamlet: r.hamlet?.id || null,
                            powerstation: r.powerstation?.id || null,
                        })
                    ),
                    users: _pt.users.map((u: any) => u.id),
                    connectionType: _pt.connectionType.id,
                    isDefault: _pt.isDefault,
                });

                setChanges({
                    district: 1,
                    village: 1,
                    hamlet: 1,
                    powerstation: 1,
                });
            },
        }
    );

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!updateMode) {
            addMutation.mutate();
        } else {
            updateMutation.mutate();
        }
    };

    const addMutation = useMutation(
        () =>
            POST(
                "/priceType",
                Object.fromEntries(
                    Object.entries(priceType).map(([k, v]) => [
                        k,
                        v && v !== "all" ? v : null,
                    ])
                )
            ),
        {
            onSuccess(res) {
                setFeedback(res.data.message);
            },

            onError(err: any) {
                setFeedback(err.response.data.message);
            },
        }
    );
    const updateMutation = useMutation(
        () =>
            PATCH(
                "/priceType",
                Object.fromEntries(
                    Object.entries(priceType).map(([k, v]) => [
                        k,
                        v && v !== "all" ? v : null,
                    ])
                ),
                { id }
            ),
        {
            onSuccess(res) {
                setFeedback(res.data.message);
            },

            onError(err: any) {
                setFeedback(err.response.data.message);
            },
        }
    );

    useEffect(() => {
        if (updateMode) {
            const params = new URLSearchParams(location.search);
            const _id = params.get("id");

            if (_id) {
                setId(_id);
            }
        }
    }, [updateMode]);

    return (
        <>
            <Card
                elevation={0}
                sx={{ bgcolor: theme.palette.common.white, mb: "1rem" }}
            >
                <CardHeader
                    title={updateMode ? "update price type" : "Add price type"}
                    sx={{
                        textTransform: "capitalize",
                        bgcolor: theme.palette.common.white,
                    }}
                />
                <CardContent>
                    <Divider sx={{ mb: 5 }} />

                    <Grid
                        container
                        component="form"
                        onSubmit={handleSubmit}
                        spacing={2}
                    >
                        <Grid item xs={12} md={6} lg={3}>
                            <TextField
                                size="small"
                                value={priceType.name}
                                label="Price Type"
                                onChange={(ev) =>
                                    setPriceType({
                                        ...priceType,
                                        name: ev.target.value,
                                    })
                                }
                                fullWidth
                                type="text"
                                required
                            />
                        </Grid>

                        <Grid item xs={12} md={6} lg={3}>
                            <UpdatedSearchableInput
                                label="Surcharge"
                                api="/connection/surcharge"
                                filter={priceType}
                                setFilter={setPriceType}
                                defaultValue={
                                    updateMode
                                        ? parseInt(priceType.surcharge)
                                        : undefined
                                }
                            />
                        </Grid>

                        <Grid item xs={12} md={6} lg={3}>
                            <UpdatedSearchableInput
                                label="Line Rent"
                                _name="lineRent"
                                api="/line-rent"
                                filter={priceType}
                                setFilter={setPriceType}
                                required
                                defaultValue={
                                    updateMode
                                        ? parseInt(priceType.lineRent)
                                        : undefined
                                }
                                optionsPreprocessor={(opt: any) => ({
                                    ...opt,
                                    name: `${currencyFormatter.format(
                                        opt.amount
                                    )} - ${opt.name} `,
                                })}
                            />
                        </Grid>

                        <Grid item xs={12} md={6} lg={3}>
                            <UpdatedSearchableInput
                                label="Unit Charge Rule"
                                _name="unitChargeRule"
                                api="/connection/unit-charge-rule"
                                filter={priceType}
                                setFilter={setPriceType}
                                required
                                defaultValue={
                                    updateMode
                                        ? parseInt(priceType.unitChargeRule)
                                        : undefined
                                }
                            />
                        </Grid>

                        <Grid item xs={12} md={6} lg={3}>
                            <UpdatedSearchableInput
                                label="Connection Type"
                                _name="connectionType"
                                api="/connection"
                                filter={priceType}
                                setFilter={setPriceType}
                                required
                                defaultValue={
                                    updateMode
                                        ? parseInt(priceType.connectionType)
                                        : undefined
                                }
                            />
                        </Grid>

                        <Grid item xs={12} md={6} lg={3}>
                            <FormControlLabel
                                label="Is price type default?"
                                control={
                                    <Checkbox
                                        checked={priceType.isDefault}
                                        onChange={(e) =>
                                            setPriceType({
                                                ...priceType,
                                                isDefault: e.target.checked,
                                            })
                                        }
                                    />
                                }
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <UpdatedSearchableInput
                                api="/user"
                                label="Users"
                                filter={priceType}
                                setFilter={setPriceType}
                                defaultValue={
                                    updateMode ? priceType.users : undefined
                                }
                                multiple
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Accordion
                                elevation={0}
                                sx={{ bgcolor: "transparent" }}
                                variant="outlined"
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreOutlined />}
                                >
                                    <Typography>Regions</Typography>
                                </AccordionSummary>

                                <Divider />

                                <AccordionDetails>
                                    <UserRegions
                                        user={priceType}
                                        setUser={setPriceType}
                                        changes={changes}
                                        setChanges={setChanges}
                                        updateMode={updateMode}
                                    />
                                </AccordionDetails>
                            </Accordion>
                        </Grid>

                        <Grid item xs={12}>
                            <Button
                                startIcon={<Add />}
                                endIcon={
                                    addMutation.isLoading ? (
                                        <CircularProgress
                                            color="secondary"
                                            size="1rem"
                                        />
                                    ) : (
                                        ""
                                    )
                                }
                                color="secondary"
                                variant="outlined"
                                size="small"
                                type="submit"
                            >
                                {updateMode ? "update" : "add"} price type
                            </Button>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>

            {addMutation.isSuccess || updateMutation.isSuccess ? (
                <Alert severity="success">{feedback}.</Alert>
            ) : addMutation.isError || updateMutation.isError ? (
                <Alert severity="error">{feedback}.</Alert>
            ) : null}
        </>
    );
};

export default AddPriceType;
