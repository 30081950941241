import { Add, Download } from "@mui/icons-material";
import {
    Box,
    Button,
    Card,
    CircularProgress,
    Grid,
    IconButton,
    Tooltip,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { useQuery } from "@tanstack/react-query";
import { ReactNode, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { SlipService } from "../../../Services/SlipService";
import { MultiPermissionAuthorize } from "../../../Utilities/MultiPermissionAuthorize";
import BaseTable from "../../Table/BaseTable";
import SearchBar from "../../Table/Components/SearchBar";
import SingleSlip from "../SingleSlip";
import SlipsDocument from "../SlipsDocument";
import { Slip } from "../types";

type Filter = { search: string };

const ArrearsSlip = () => {
    const [feedback, setFeedback] = useState("");
    const [pagination, setPagination] = useState({ page: 0, limit: 10 });
    const [filter, setFilter] = useState<Filter>({
        search: "",
    });
    const [rowsCount, setRowsCount] = useState(0);
    const theme = useTheme();
    const mdDown = useMediaQuery(theme.breakpoints.down("md"));
    const [printMode, setPrintMode] = useState(false);

    const printRef = useRef<ReactNode | null>(null);

    const { data, isLoading } = useQuery(
        ["arrears-slips", pagination, filter],
        () =>
            SlipService.getInstance().getSlips(
                {
                    page: pagination.page + 1,
                    limit: pagination.limit,
                },

                { target: filter.search, type: "arrears" }
            ),
        {
            onSuccess(res: { data: { rows: Slip[]; count: number } }) {
                setRowsCount(res.data.count);
            },
        }
    );

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                height: mdDown ? "auto" : `calc(100vh - 96px)`,
            }}
        >
            <Card
                elevation={0}
                sx={{
                    bgcolor: theme.palette.common.white,
                    p: 2,
                    mb: "1rem",
                }}
            >
                <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Grid item xs={12} md="auto">
                        <Typography variant="h5" color="GrayText">
                            Arrears Slips
                        </Typography>
                    </Grid>

                    <Grid item xs={12} md={6} container spacing={1.5}>
                        <Grid item flex={1}>
                            <SearchBar
                                filter={filter}
                                setFilter={setFilter}
                                debounceChange
                            />
                        </Grid>

                        <Grid item xs={12} md="auto">
                            <MultiPermissionAuthorize ops={["CREATE SLIP"]}>
                                <Link
                                    to="/slips/arrears/add"
                                    style={{ textDecoration: "none" }}
                                >
                                    <Button
                                        sx={{ height: "100%" }}
                                        variant="outlined"
                                        color="secondary"
                                        startIcon={<Add />}
                                    >
                                        generate slip
                                    </Button>
                                </Link>
                            </MultiPermissionAuthorize>
                        </Grid>
                    </Grid>
                </Grid>
            </Card>

            <Box sx={{ height: "70%" }}>
                <BaseTable
                    features={{
                        download: (
                            <PDFDownloadLink
                                document={
                                    <SlipsDocument
                                        slips={
                                            data?.data.rows.map((row) =>
                                                JSON.parse(row.data)
                                            ) ?? []
                                        }
                                        name="Arrears Slips"
                                    />
                                }
                                fileName="Arrears Slips"
                            >
                                {({ blob, url, loading, error }) =>
                                    loading ? (
                                        <Button
                                            startIcon={
                                                <CircularProgress size="1rem" />
                                            }
                                        >
                                            generating slips...
                                        </Button>
                                    ) : (
                                        <Tooltip title="Download Slips">
                                            <IconButton size="small">
                                                <Download fontSize="small" />
                                            </IconButton>
                                        </Tooltip>
                                    )
                                }
                            </PDFDownloadLink>
                        ),
                    }}
                    headers={{
                        type: "Slip Type",
                        target: "Meter",
                        slip: "Slip",
                    }}
                    defaultSelectedHeaders={["type", "target", "slip"]}
                    data={
                        data?.data
                            ? data.data.rows.map((row) => ({
                                  ...row,
                                  type:
                                      row.type === "connection"
                                          ? "Connection Slip"
                                          : "Arrears Slip",

                                  slip: (
                                      <SingleSlip
                                          data={{
                                              ...JSON.parse(row.data),
                                              type: "arrears",
                                          }}
                                      />
                                  ),
                              }))
                            : []
                    }
                    delEndPoint="/slip"
                    feedback={feedback}
                    setFeedback={setFeedback}
                    rowsCount={rowsCount}
                    pagination={pagination}
                    setPagination={setPagination}
                    isLoading={isLoading}
                    permissions={{
                        edit: [""],
                        delete: ["DELETE SLIP"],
                    }}
                    load={true}
                    queryKey={["arrears-slips", pagination, filter]}
                    reportName="Connection Slips"
                />
            </Box>
        </Box>
    );
};

export default ArrearsSlip;
