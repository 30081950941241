import { grey } from "@mui/material/colors";
import {
    Document,
    Image,
    Page,
    StyleSheet,
    Text,
    View,
} from "@react-pdf/renderer";
import { useEffect, useState } from "react";
import { GET } from "../../Utilities/BaseService";
import { S3Service } from "../../Utilities/S3Service";
import { awsDirStruct } from "../../Utilities/constants";

const SlipsDocument = ({ slips, name }: { slips: any[]; name: string }) => {
    const [settings, setsettings] = useState({
        name: "",
        phone: "",
        mobile: "",
        address: "",
    });
    const [logoSrc, setLogoSrc] = useState("");

    const getLogo = async () => {
        try {
            const response = await GET("/settings/logos", {
                default: true,
            });
            const firstDefault = response.data.rows[0];
            const [{ url }, err] = await S3Service.getS3ByKey(
                `${awsDirStruct.logos}/${firstDefault.id}`
            );

            setLogoSrc(url);
        } catch (err) {}
    };

    const getSettings = async () => {
        try {
            const res = await GET("/settings");

            console.log(res);

            if (res.data.settings) {
                const targetSettings = res.data.settings
                    .filter((s: { key: string; value: string }) =>
                        ["name", "address", "phone", "mobile"].includes(s.key)
                    )
                    .reduce(
                        (prev: any, curr: { key: string; value: string }) => ({
                            ...prev,
                            [curr.key]: curr.value,
                        }),
                        {}
                    );

                setsettings(targetSettings);
            }
        } catch (error) {}
    };

    useEffect(() => {
        getLogo();
        getSettings();
    }, []);

    return (
        <Document>
            {slips.map((s) => (
                <Page
                    size="A5"
                    orientation="portrait"
                    style={slipDocStyles.page}
                >
                    <View style={slipDocStyles.header}>
                        <Image src={logoSrc} style={slipDocStyles.logo} />

                        <Text style={slipDocStyles.title}>{settings.name}</Text>

                        <Text style={slipDocStyles.title}>
                            {settings.address}
                        </Text>

                        <Text style={slipDocStyles.title}>
                            {settings.mobile}
                        </Text>

                        <Text style={slipDocStyles.title}>
                            {settings.phone}
                        </Text>

                        <Text
                            style={[
                                slipDocStyles.title,
                                { fontWeight: "bold", marginTop: 8 },
                            ]}
                        >
                            {name}
                        </Text>
                    </View>

                    {Object.entries(s).map(([k, v]: any) => (
                        <View key={k} style={slipDocStyles.row}>
                            <Text style={slipDocStyles.cell}>{k}</Text>
                            <Text style={slipDocStyles.cell}>{v}</Text>
                        </View>
                    ))}
                </Page>
            ))}
        </Document>
    );
};

export default SlipsDocument;

const slipDocStyles = StyleSheet.create({
    page: { padding: 16, color: grey[800] },
    header: { alignItems: "center", marginBottom: 16 },
    title: {
        fontSize: 10,
        textTransform: "uppercase",
        fontWeight: "black",
        letterSpacing: 1,
        marginBottom: 4,
    },
    logo: { width: 32, height: 32, marginBottom: 8 },
    body: { width: "50%" },
    row: {
        flexDirection: "row",
        justifyContent: "space-between",
        fontSize: 10,
        borderBottom: `1px solid ${grey[500]}`,
    },
    cell: { flex: 1, paddingHorizontal: 8, paddingVertical: 4 },
});
