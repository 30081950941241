import { Box, Divider, Typography } from "@mui/material";
import { useMemo } from "react";
import { AdditionalChargesDescription } from "./AdditionalChargesDescription";
import { Cell } from "./Cell";
import { currencyFormatter, numberFormatter } from "./Last12Summary";

export const BillCalculations = ({ reading }: any) => {
    const unitsAmount = useMemo(() => {
        if (reading) {
            if (typeof reading.unitPrice === "number") {
                return reading.unitsAmount;
            } else {
                const unitsAmount = [];
                let unitsRemaining = reading.currentUnits - reading.prevUnits;
                let idx = 0;

                for (const row of reading.unitPrice) {
                    const [upperRange, price] = Object.entries(row)[0];
                    let unitsInRange = 0;

                    if (idx === 0) {
                        unitsInRange = parseInt(upperRange);
                    } else if (parseInt(upperRange) === -1) {
                        unitsInRange = unitsRemaining;
                    } else {
                        unitsInRange =
                            parseInt(upperRange) -
                            parseInt(
                                Object.keys(reading.unitPrice[idx - 1])[0] + ""
                            );
                    }

                    if (unitsRemaining - unitsInRange < 0) {
                        unitsInRange = unitsRemaining;
                        unitsRemaining = 0;
                    } else {
                        unitsRemaining -= unitsInRange;
                    }

                    unitsAmount.push({
                        units: unitsInRange,
                        price,
                        amount: (price as number) * unitsInRange,
                    });
                    idx++;
                }
                return unitsAmount;
            }
        } else {
            return 0;
        }
    }, [reading]);

    const totalUnitsAmount = useMemo(() => {
        if (Array.isArray(unitsAmount)) {
            return unitsAmount.reduce((prev, curr) => prev + curr.amount, 0);
        } else {
            return unitsAmount;
        }
    }, [unitsAmount]);

    return (
        <>
            <Cell
                sx={{ padding: 0 }}
                xs={12}
                noLabel
                noTypography
                value={
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            borderBottom: "1px solid",
                        }}
                    >
                        {Array.isArray(unitsAmount) ? (
                            <>
                                <Typography
                                    variant="caption"
                                    fontWeight="bold"
                                    width="100%"
                                >
                                    Units x Rate = Total
                                </Typography>
                                {unitsAmount.map((row, idx) => (
                                    <Typography key={idx} variant="caption">{`${
                                        row.units
                                    } x ${row.price} = ${numberFormatter.format(
                                        row.amount
                                    )}`}</Typography>
                                ))}
                                <Typography variant="caption" fontWeight="bold">
                                    {`Total: ${currencyFormatter.format(
                                        totalUnitsAmount
                                    )}`}
                                </Typography>
                            </>
                        ) : (
                            <>
                                <Typography
                                    variant="caption"
                                    fontWeight="bold"
                                    width="100%"
                                >
                                    Units x Rate = Total
                                </Typography>
                                <Typography variant="caption">
                                    {`${
                                        reading.currentUnits - reading.prevUnits
                                    } x ${reading.unitPrice} = ${
                                        (reading.currentUnits -
                                            reading.prevUnits) *
                                        reading.unitPrice
                                    }`}
                                </Typography>
                            </>
                        )}
                    </div>
                }
            />

            <Divider />

            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    borderBottom: "1px solid",
                }}
            >
                <Typography variant="caption" fontWeight="bold">
                    Current bill موجوده بل
                </Typography>
                <Typography variant="caption">
                    {currencyFormatter.format(totalUnitsAmount)}
                </Typography>
            </Box>
            <Divider />

            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    borderBottom: "1px solid",
                }}
            >
                <Typography variant="caption" fontWeight="bold">
                    Line rent:
                </Typography>
                <Typography variant="caption">
                    {currencyFormatter.format(reading.lineRent)}
                </Typography>
            </Box>
            <Divider />

            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    borderBottom: "1px solid",
                }}
            >
                <Typography variant="caption" fontWeight="bold">
                    Arrears بقایاجات
                </Typography>
                <Typography variant="caption">
                    {currencyFormatter.format(reading.arrears)}
                </Typography>
            </Box>
            <Divider />
            {/* 
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    borderBottom: "1px solid",
                }}
            >
                <Typography variant="caption" fontWeight="bold">
                    Additional charges
                </Typography>
                <Typography variant="caption">
                    {currencyFormatter.format(
                        reading.additionalCharges.reduce(
                            (prev: number, curr: any) => prev + curr.amount,
                            0
                        )
                    )}
                </Typography>
            </Box> */}

            <Box>
                <AdditionalChargesDescription reading={reading} />
            </Box>
            <Divider />

            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    borderBottom: "1px solid",
                }}
            >
                <Typography variant="caption" fontWeight="bold">
                    Connection charges:
                </Typography>
                <Typography variant="caption">
                    {currencyFormatter.format(reading.connectionChargesAmount)}
                </Typography>
            </Box>
            <Divider />

            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    borderBottom: "1px solid",
                }}
            >
                <Typography variant="caption" fontWeight="bold">
                    Payable within due date
                    <br /> مقرره تاریخ تک واجب الادا
                </Typography>
                <Typography variant="caption">
                    {currencyFormatter.format(
                        reading.totalAmount + reading.arrears
                    )}
                </Typography>
            </Box>
            <Divider />

            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    borderBottom: "1px solid",
                }}
            >
                <Typography variant="caption" fontWeight="bold">
                    L.P. Surcharge
                    <br /> مقرره تاریخ تک بل نہ ادا کرنےکی صورت میں سرچارج
                </Typography>
                <Typography variant="caption">
                    {currencyFormatter.format(reading.applicableSurcharge)}
                </Typography>
            </Box>
            <Divider />

            <Box
                sx={{
                    justifyContent: "space-between",
                    display: "flex",
                    alignItems: "center",
                    // borderBottom: "1px solid",
                }}
            >
                <Typography variant="caption" fontWeight="bold">
                    Payable after due date
                    <br /> مقرره تاریخ کےبعد واجب الادا
                </Typography>

                <Typography variant="caption">
                    {currencyFormatter.format(
                        reading.totalAmount +
                            reading.arrears +
                            reading.applicableSurcharge
                    )}
                </Typography>
            </Box>
        </>
    );
};
