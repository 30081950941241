import { FilterAltOutlined } from "@mui/icons-material";
import {
    Button,
    CircularProgress,
    Divider,
    Grid,
    TextField,
} from "@mui/material";
import { ChangeEvent, FormEvent, useEffect, useState } from "react";
import UpdatedSearchableInput from "../../../Utilities/UpdatedSearchableInput";

const initialClearAll = {
    district: false,
    village: false,
    hamlet: false,
};

export const CollecteesFilter = ({
    filter,
    setFilter,
    billsFetchStatus,
}: any) => {
    const [intermediateFilter, setIntermediateFilter] = useState<any>(filter);
    const [clearAll, setClearAll] = useState(initialClearAll);

    const handleChange = (ev: ChangeEvent<any>) => {
        setIntermediateFilter((f: any) => ({
            ...f,
            [ev.target.name]: ev.target.value,
        }));
    };

    const handleFilter = (ev: FormEvent<any>) => {
        ev.preventDefault();
        setFilter(intermediateFilter);
    };

    useEffect(() => {
        if (intermediateFilter.district === "all") {
            setClearAll({
                district: true,
                village: true,
                hamlet: true,
            });
            setIntermediateFilter({
                ...intermediateFilter,
                district: "",
                village: "",
                hamlet: "",
            });
        }

        if (intermediateFilter.village === "all") {
            setClearAll({ ...clearAll, village: true, hamlet: true });
            setIntermediateFilter({
                ...intermediateFilter,
                village: "",
                hamlet: "",
            });
        }

        if (intermediateFilter.hamlet === "all") {
            setClearAll({ ...clearAll, hamlet: true });
            setIntermediateFilter({ ...intermediateFilter, hamlet: "" });
        }

        return () => {
            setClearAll({
                district: false,
                village: false,
                hamlet: false,
            });
        };
    }, [
        intermediateFilter.district,
        intermediateFilter.village,
        intermediateFilter.hamlet,
    ]);

    return (
        <Grid
            item
            container
            xs={12}
            spacing={1}
            component="form"
            onSubmit={handleFilter}
        >
            <Grid item xs={12} md={3}>
                <UpdatedSearchableInput
                    label="District"
                    api="/region/district"
                    filter={intermediateFilter}
                    setFilter={setIntermediateFilter}
                    clearAll={clearAll.district}
                />
            </Grid>

            <Grid item xs={12} md={3}>
                <UpdatedSearchableInput
                    label="Village"
                    api="/region/village"
                    filter={intermediateFilter}
                    setFilter={setIntermediateFilter}
                    dep={intermediateFilter.district}
                    params={{ district: intermediateFilter.district }}
                    clearAll={clearAll.village}
                />
            </Grid>

            <Grid item xs={12} md={3}>
                <UpdatedSearchableInput
                    label="Hamlet"
                    api="/region/hamlet"
                    filter={intermediateFilter}
                    setFilter={setIntermediateFilter}
                    dep={[
                        intermediateFilter.district,
                        intermediateFilter.village,
                    ]}
                    params={
                        intermediateFilter.village &&
                        intermediateFilter.village !== "all"
                            ? { village: [intermediateFilter.village] }
                            : { village: [0] }
                    }
                    clearAll={clearAll.hamlet}
                />
            </Grid>

            <Grid item xs={12} md={3}>
                <TextField
                    onChange={handleChange}
                    fullWidth
                    name="meterNo"
                    label="Meter no."
                    value={intermediateFilter.meterNo}
                    size="small"
                    // type="number"
                />
            </Grid>

            <Grid item xs={12} md={3}>
                <Button
                    type="submit"
                    sx={{ height: "100%" }}
                    color="secondary"
                    fullWidth
                    variant="outlined"
                    endIcon={
                        billsFetchStatus === "loading" ? (
                            <CircularProgress size="1rem" />
                        ) : (
                            <FilterAltOutlined />
                        )
                    }
                    disabled={billsFetchStatus === "loading"}
                >
                    Filter
                </Button>
            </Grid>

            <Grid item xs={12} mt={2}>
                <Divider />
            </Grid>
        </Grid>
    );
};
