import { Add } from "@mui/icons-material";
import { Box, Button, Card, Grid, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { Link } from "react-router-dom";
import { theme } from "../../../Theme/theme";
import { GET } from "../../../Utilities/BaseService";
import { MultiPermissionAuthorize } from "../../../Utilities/MultiPermissionAuthorize";
import BaseTable from "../../Table/BaseTable";
import SearchBar from "../../Table/Components/SearchBar";
import LoadViewButton from "../../../Utilities/LoadViewButton";

export const getAllPropertyType = (pagination: any, filter: any) => {
    return GET("/customer/property/type", {
        page: pagination.page === 0 ? pagination.page + 1 : pagination.page,
        limit: pagination.limit,
        search: filter.search ? filter.search : "",
    });
};

const PropertyType = () => {
    const [rowsCount, setRowsCount] = useState(0);
    const [pagination, setPagination] = useState<any>({ page: 0, limit: 100 });
    const [filter, setFilter] = useState<any>({ search: "" });
    const [feedback, setFeedback] = useState("");
    const [load, setLoad] = useState(false);

    const { data, isLoading } = useQuery(
        ["propertyType", pagination.page, pagination.limit, filter.search],
        () => getAllPropertyType(pagination, filter),
        {
            enabled: load,
            onSuccess(res) {
                if (res) setRowsCount(res?.data.count);
            },
        }
    );

    return (
        <Box>
            <Card
                elevation={0}
                sx={{
                    bgcolor: theme.palette.common.white,
                    p: 2,
                    mb: "1rem",
                }}
            >
                <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Grid item xs={12} md={4}>
                        <Typography variant="h5" color="GrayText">
                            Property Type
                        </Typography>
                    </Grid>

                    <Grid item xs={12} md={6} container spacing={1}>
                        <Grid item flex={1}>
                            <SearchBar filter={filter} setFilter={setFilter} />
                        </Grid>

                        <Grid item>
                            <MultiPermissionAuthorize
                                ops={["CREATE PROPERTY_TYPE"]}
                            >
                                <Link
                                    to="/customer/property-type/add"
                                    style={{ textDecoration: "none" }}
                                >
                                    <Button
                                        sx={{ height: "100%" }}
                                        variant="outlined"
                                        color="secondary"
                                        startIcon={<Add />}
                                    >
                                        add Property Type
                                    </Button>
                                </Link>
                            </MultiPermissionAuthorize>
                        </Grid>
                    </Grid>
                </Grid>
            </Card>

            {!load && (
                <LoadViewButton
                    label="view property types"
                    load={load}
                    setLoad={setLoad}
                />
            )}

            <BaseTable
                headers={{ id: "ID", name: "Name" }}
                load={load}
                defaultSelectedHeaders={["id", "name"]}
                data={data?.data.rows}
                delEndPoint="/customer/property/type"
                feedback={feedback}
                setFeedback={setFeedback}
                rowsCount={rowsCount}
                pagination={pagination}
                setPagination={setPagination}
                isLoading={isLoading}
                permissions={{
                    edit: ["UPDATE PROPERTY_TYPE"],
                    delete: ["DELETE PROPERTY_TYPE"],
                }}
                queryKey={[
                    "propertyType",
                    pagination.page,
                    pagination.limit,
                    filter.search,
                ]}
                reportName="Add PropertyType"
            />
        </Box>
    );
};

export default PropertyType;
