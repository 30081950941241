import { EditOutlined } from "@mui/icons-material";
import {
    Alert,
    Box,
    Button,
    Card,
    CircularProgress,
    Dialog,
    DialogContent,
    Grid,
    IconButton,
    LinearProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { theme } from "../../Theme/theme";
import { GET, POST } from "../../Utilities/BaseService";
import { MultiPermissionAuthorize } from "../../Utilities/MultiPermissionAuthorize";
import UpdatedSearchableInput from "../../Utilities/UpdatedSearchableInput";
import { Setting } from "./AddSettings";
import Range from "./Components/Range";
import { Reorder } from "./Components/ReorderSetting";
import SingleRange from "./Components/SingleRange";

const updateSetting = ({ url, data }: { url: string; data: any }) => {
    return POST(url, data);
};

const initialFilter = {
    district: null,
    village: null,
    hamlet: null,
    search: "",
};

const Settings = () => {
    const [pagination, setPagination] = useState<any>({ page: 0, limit: 10 });
    const [filter, setFilter] = useState<any>(initialFilter);
    const [feedback, setFeedback] = useState({
        loading: false,
        show: false,
        message: "",
        severity: "warning",
    });
    const [groupedSettings, setGroupedSettings] = useState({});
    const [activeSetting, setActiveSetting] = useState({
        open: false,
        data: [],
    });
    const [updatedCollectionOrder, setUpdatedCollectionOrder] = useState({});
    const [updatedAdjustmentOrder, setUpdatedAdjustmentOrder] = useState({});
    const [updatedTerminationTreshold, setUpdatedTerminationTreshold] =
        useState({});
    const [updatedReadingRange, setUpdatedReadingRange] = useState({});
    const [clearAll, setClearAll] = useState({
        district: false,
        village: false,
        hamlet: false,
    });

    const { data, isLoading } = useQuery(
        ["settings", pagination, filter],
        () =>
            GET("/settings/regional-settings", {
                page: pagination.page + 1,
                limit: pagination.limit,
                district:
                    filter.district && filter.district !== "all"
                        ? filter.district
                        : undefined,
                village:
                    filter.village && filter.village !== "all"
                        ? filter.village
                        : undefined,
                hamlet:
                    filter.hamlet && filter.hamlet !== "all"
                        ? filter.hamlet
                        : undefined,
            }),
        {
            onSuccess(res) {
                const rows: Setting[] = res.data.settings;

                const setts = rows.reduce((acc: any, curr: Setting) => {
                    if (curr.district) {
                        acc[curr.district.name] = rows
                            .filter(
                                (sett: Setting) =>
                                    sett.district?.id == curr.district?.id
                            )
                            .reduce((villAcc: any, currVill: Setting) => {
                                if (currVill.village) {
                                    villAcc[currVill.village.name] = rows
                                        .filter(
                                            (sett: Setting) =>
                                                sett.district?.id ==
                                                    currVill.district?.id &&
                                                sett.village?.id ==
                                                    currVill.village?.id
                                        )
                                        .reduce(
                                            (
                                                hamletAcc: any,
                                                currHamlet: Setting
                                            ) => {
                                                if (currHamlet.hamlet) {
                                                    hamletAcc[
                                                        currHamlet.hamlet.name
                                                    ] = rows.filter(
                                                        (sett: Setting) =>
                                                            currHamlet.district
                                                                ?.id ==
                                                                sett.district
                                                                    ?.id &&
                                                            currHamlet.village
                                                                ?.id ==
                                                                sett.village
                                                                    ?.id &&
                                                            currHamlet.hamlet
                                                                ?.id ==
                                                                sett.hamlet?.id
                                                    );
                                                } else {
                                                    hamletAcc["regionalLevel"] =
                                                        rows.filter(
                                                            (sett: Setting) =>
                                                                currHamlet
                                                                    .district
                                                                    ?.id ==
                                                                    sett
                                                                        .district
                                                                        ?.id &&
                                                                currHamlet
                                                                    .village
                                                                    ?.id ==
                                                                    sett.village
                                                                        ?.id &&
                                                                sett.hamlet ===
                                                                    null
                                                        );
                                                }
                                                return hamletAcc;
                                            },
                                            {}
                                        );
                                } else {
                                    villAcc["regionalLevel"] = rows.filter(
                                        (sett: Setting) =>
                                            currVill.district?.id ==
                                                sett.district?.id &&
                                            sett.village === null &&
                                            sett.hamlet === null
                                    );
                                }

                                return villAcc;
                            }, {});
                    }
                    return acc;
                }, {});

                console.log(setts);
                setGroupedSettings(setts);
            },
        }
    );

    const handleUpdate = () => {
        setFeedback({ ...feedback, loading: true });

        const activeSettingKeys = activeSetting.data.map(
            (sett: Setting) => sett.key
        );

        const transformedObject = (object: any) =>
            Object.fromEntries(
                Object.entries(object).map(([k, v]: any) =>
                    k === "id" || k === "key" || k === "isGlobal"
                        ? [k, v]
                        : ["value", JSON.parse(v)]
                )
            );

        const targetObj = [
            transformedObject(updatedAdjustmentOrder),
            transformedObject(updatedCollectionOrder),
            transformedObject(updatedReadingRange),
            transformedObject(updatedTerminationTreshold),
        ].filter((item: any) => activeSettingKeys.includes(item?.key));

        updateMutation.mutate({
            url: "/settings/bulk",
            data: { settings: targetObj },
        });
    };

    const updateMutation = useMutation(updateSetting, {
        onSuccess(res) {
            setFeedback({
                loading: false,
                show: true,
                message: "Updation successfull.",
                severity: "success",
            });
        },
    });

    useEffect(() => {
        if (filter.district === "all") {
            setClearAll({ district: true, village: true, hamlet: true });
            setFilter(initialFilter);
        }

        if (filter.village === "all") {
            setClearAll({ ...clearAll, village: true, hamlet: true });
            setFilter({ ...filter, village: null, hamlet: null });
        }

        if (filter.hamlet === "all") {
            setClearAll({ ...clearAll, hamlet: true });
            setFilter({ ...filter, hamlet: null });
        }

        return () => {
            setClearAll({ district: false, village: false, hamlet: false });
        };
    }, [filter.district, filter.village, filter.hamlet]);

    return (
        <Box>
            <Card
                elevation={0}
                sx={{
                    bgcolor: theme.palette.common.white,
                    p: 2,
                    mb: "1rem",
                }}
            >
                <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                    spacing={1}
                >
                    <Grid item xs={12} md="auto">
                        <Typography variant="h5" color="GrayText">
                            Settings
                        </Typography>
                    </Grid>

                    {/*  <Grid item xs={12} md={8} lg={4} container spacing={1}>
                        <Grid item flex={1}>
                             <SearchBar filter={filter} setFilter={setFilter} /> 
                        </Grid>

                        <Grid item>
                            <MultiPermissionAuthorize ops={["CREATE SETTING"]}>
                                <Link
                                    to="/settings/add"
                                    style={{ textDecoration: "none" }}
                                >
                                    <Button
                                        sx={{ height: "100%" }}
                                        variant="outlined"
                                        color="secondary"
                                        startIcon={<Add />}
                                    >
                                        add setting
                                    </Button>
                                </Link>
                            </MultiPermissionAuthorize>
                        </Grid>
                    </Grid> */}

                    <Grid item container xs={12} md={8} spacing={1}>
                        <MultiPermissionAuthorize ops={["READ DISTRICT"]}>
                            <Grid item xs={4}>
                                <UpdatedSearchableInput
                                    api="/region/district"
                                    label="District"
                                    filter={filter}
                                    setFilter={setFilter}
                                    clearAll={clearAll.district}
                                />
                            </Grid>
                        </MultiPermissionAuthorize>

                        <MultiPermissionAuthorize ops={["READ VILLAGE"]}>
                            <Grid item xs={4}>
                                <UpdatedSearchableInput
                                    api="/region/village"
                                    label="Village"
                                    filter={filter}
                                    setFilter={setFilter}
                                    dep={filter.district}
                                    params={{
                                        district: filter.district,
                                    }}
                                    clearAll={clearAll.village}
                                />
                            </Grid>
                        </MultiPermissionAuthorize>

                        <MultiPermissionAuthorize ops={["READ HAMLET"]}>
                            <Grid item xs={4}>
                                <UpdatedSearchableInput
                                    api="/region/hamlet"
                                    label="Hamlet"
                                    filter={filter}
                                    setFilter={setFilter}
                                    dep={filter.village}
                                    params={
                                        filter.village &&
                                        filter.village !== "all"
                                            ? {
                                                  village: [filter.village],
                                              }
                                            : { village: [0] }
                                    }
                                    clearAll={clearAll.hamlet}
                                />
                            </Grid>
                        </MultiPermissionAuthorize>
                    </Grid>
                </Grid>
            </Card>

            {isLoading && <LinearProgress />}

            <TableContainer>
                <Table padding="checkbox">
                    <TableHead>
                        <TableRow
                            sx={{
                                textTransform: "capitalize",
                                "& > *": {
                                    bgcolor: "common.white",
                                    fontWeight: "bold!important",
                                    fontSize: "1rem",
                                },
                            }}
                        >
                            <TableCell>District - Village - Hamlet</TableCell>

                            <TableCell>Edit</TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        <TableRow>
                            <TableCell>Global Settings</TableCell>
                            <TableCell>
                                <Link
                                    to="/settings/add"
                                    state={{ updateMode: true }}
                                >
                                    <IconButton size="small">
                                        <EditOutlined fontSize="small" />
                                    </IconButton>
                                </Link>
                            </TableCell>
                        </TableRow>
                        {Object.entries(groupedSettings).map(
                            ([districtName, districtSettings]: any) => (
                                <Fragment key={districtName}>
                                    {districtSettings?.regionalLevel?.length >
                                        0 && (
                                        <TableRow>
                                            <TableCell>
                                                {districtName}
                                            </TableCell>

                                            <TableCell>
                                                <Link
                                                    to="/settings/add"
                                                    state={{
                                                        district:
                                                            districtSettings
                                                                .regionalLevel[0]
                                                                .district.id,
                                                    }}
                                                >
                                                    <IconButton size="small">
                                                        <EditOutlined fontSize="small" />
                                                    </IconButton>
                                                </Link>
                                            </TableCell>
                                        </TableRow>
                                    )}

                                    {Object.entries(districtSettings).map(
                                        ([villageName, villageSettings]: any) =>
                                            villageName !== "regionalLevel" && (
                                                <Fragment key={villageName}>
                                                    {villageSettings
                                                        ?.regionalLevel
                                                        ?.length > 0 && (
                                                        <TableRow>
                                                            <TableCell>
                                                                {`${districtName} - ${villageName}`}
                                                            </TableCell>

                                                            <TableCell>
                                                                <Link
                                                                    to="/settings/add"
                                                                    state={{
                                                                        district:
                                                                            villageSettings
                                                                                .regionalLevel[0]
                                                                                .district
                                                                                .id,
                                                                        village:
                                                                            villageSettings
                                                                                .regionalLevel[0]
                                                                                .village
                                                                                .id,
                                                                    }}
                                                                >
                                                                    <IconButton size="small">
                                                                        <EditOutlined fontSize="small" />
                                                                    </IconButton>
                                                                </Link>
                                                            </TableCell>
                                                        </TableRow>
                                                    )}

                                                    {Object.entries(
                                                        villageSettings
                                                    ).map(
                                                        ([
                                                            hamletName,
                                                            hamletSettings,
                                                        ]: any) =>
                                                            hamletName !==
                                                                "regionalLevel" && (
                                                                <TableRow
                                                                    key={
                                                                        hamletName
                                                                    }
                                                                >
                                                                    <TableCell>
                                                                        {`${districtName} - ${villageName} - ${hamletName}`}
                                                                    </TableCell>

                                                                    <TableCell>
                                                                        <Link
                                                                            to="/settings/add"
                                                                            state={{
                                                                                district:
                                                                                    hamletSettings[0]
                                                                                        ?.district
                                                                                        .id,
                                                                                village:
                                                                                    hamletSettings[0]
                                                                                        ?.village
                                                                                        .id,
                                                                                hamlet: hamletSettings[0]
                                                                                    ?.hamlet
                                                                                    .id,
                                                                            }}
                                                                        >
                                                                            <IconButton size="small">
                                                                                <EditOutlined fontSize="small" />
                                                                            </IconButton>
                                                                        </Link>
                                                                    </TableCell>
                                                                </TableRow>
                                                            )
                                                    )}
                                                </Fragment>
                                            )
                                    )}
                                </Fragment>
                            )
                        )}
                    </TableBody>
                </Table>
            </TableContainer>

            {/*    {Object.entries(groupedSettings).length === 0 && (
                <Alert severity="info" sx={{ mt: 2 }}>
                    No data to show.
                </Alert>
            )} */}

            <Dialog
                open={activeSetting.open}
                onClose={() => {
                    setActiveSetting({ open: false, data: [] });
                    setUpdatedCollectionOrder({});
                    setUpdatedReadingRange({});
                    setUpdatedTerminationTreshold({});
                    setUpdatedAdjustmentOrder({});
                }}
                fullWidth
                maxWidth="md"
            >
                <DialogContent>
                    {feedback.show && (
                        <Alert
                            severity={feedback.severity as any}
                            sx={{ mb: 1 }}
                        >
                            {feedback.message}
                        </Alert>
                    )}

                    {activeSetting.data.map((sett: Setting) =>
                        sett.key === "reading-range" ? (
                            <Range
                                key={sett.id}
                                data={sett}
                                updatedSettings={{
                                    ...updatedReadingRange,
                                    id: sett.id,
                                    key: "reading-range",
                                    isGlobal: false,
                                }}
                                setUpdatedSettings={setUpdatedReadingRange}
                            />
                        ) : sett.key === "termination-threshold" ? (
                            <SingleRange
                                key={sett.id}
                                data={sett}
                                updatedSettings={{
                                    ...updatedTerminationTreshold,
                                    id: sett.id,
                                    key: "termination-threshold",
                                    isGlobal: false,
                                }}
                                setUpdatedSettings={
                                    setUpdatedTerminationTreshold
                                }
                            />
                        ) : sett.key === "adjustment-order" ? (
                            <Reorder
                                key={sett.id}
                                labels={{
                                    lineRent: "Line rent",
                                    units: "Units",
                                    "additional-charges": "Additional charges",
                                }}
                                setting={sett}
                                name="Adjustment order"
                                updatedSettings={{
                                    ...updatedAdjustmentOrder,
                                    id: sett.id,
                                    key: "adjustment-order",
                                    isGlobal: false,
                                }}
                                setUpdatedSettings={setUpdatedAdjustmentOrder}
                            />
                        ) : (
                            <Reorder
                                key={sett.id}
                                labels={{
                                    lineRent: "Line rent",
                                    units: "Units",
                                    "additional-charges": "Additional charges",
                                }}
                                setting={sett}
                                name="Collection order"
                                updatedSettings={{
                                    ...updatedCollectionOrder,
                                    id: sett.id,
                                    key: "collection-order",
                                    isGlobal: false,
                                }}
                                setUpdatedSettings={setUpdatedCollectionOrder}
                            />
                        )
                    )}

                    <Button
                        variant="contained"
                        disableElevation
                        onClick={handleUpdate}
                        disabled={feedback.loading}
                        endIcon={
                            feedback.loading ? (
                                <CircularProgress size={20} />
                            ) : undefined
                        }
                    >
                        update
                    </Button>
                </DialogContent>
            </Dialog>
        </Box>
    );
};

export default Settings;
