import { FilterAltOutlined } from "@mui/icons-material";
import { Button, Grid, TextField } from "@mui/material";
import { useState } from "react";
import { MultiPermissionAuthorize } from "../../../Utilities/MultiPermissionAuthorize";
import UpdatedSearchableInput from "../../../Utilities/UpdatedSearchableInput";

export const Filters = ({ filter, setFilter }: any) => {
    const [intermediateFilter, setIntermediateFilter] = useState(filter);

    const handleSubmit = () => {
        setFilter(intermediateFilter);
    };

    return (
        <>
            <MultiPermissionAuthorize ops={["READ DISTRICT"]}>
                <Grid item xs={12} md={3}>
                    <UpdatedSearchableInput
                        _name="district"
                        label="District"
                        api="/region/district"
                        filter={intermediateFilter}
                        setFilter={setIntermediateFilter}
                    />
                </Grid>
            </MultiPermissionAuthorize>

            <MultiPermissionAuthorize ops={["READ VILLAGE"]}>
                <Grid item xs={12} md={3}>
                    <UpdatedSearchableInput
                        _name="village"
                        label="Village"
                        api="/region/village"
                        filter={intermediateFilter}
                        setFilter={setIntermediateFilter}
                        dep={intermediateFilter.district}
                        params={{ district: intermediateFilter.district }}
                    />
                </Grid>
            </MultiPermissionAuthorize>

            <MultiPermissionAuthorize ops={["READ HAMLET"]}>
                <Grid item xs={12} md={3}>
                    <UpdatedSearchableInput
                        _name="hamlet"
                        label="Hamlet"
                        api="/region/hamlet"
                        filter={intermediateFilter}
                        setFilter={setIntermediateFilter}
                        dep={intermediateFilter.village}
                        params={{ village: [intermediateFilter.village] }}
                    />
                </Grid>
            </MultiPermissionAuthorize>

            <Grid item xs={12} md={3}>
                <UpdatedSearchableInput
                    _name="powerstation"
                    label="Powerstation"
                    api="/powerstation"
                    filter={intermediateFilter}
                    setFilter={setIntermediateFilter}
                    dep={intermediateFilter.district}
                    params={{ district: intermediateFilter.district }}
                />
            </Grid>

            <Grid item xs={12} md={3}>
                <TextField
                    type="date"
                    size="small"
                    fullWidth
                    label="From Date"
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) =>
                        setIntermediateFilter({
                            ...intermediateFilter,
                            startDate: e.target.value,
                        })
                    }
                />
            </Grid>

            <Grid item xs={12} md={3}>
                <TextField
                    type="date"
                    size="small"
                    fullWidth
                    label="To Date"
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) =>
                        setIntermediateFilter({
                            ...intermediateFilter,
                            endDate: e.target.value,
                        })
                    }
                />
            </Grid>

            <Grid item xs={12} md={3}>
                <Button
                    style={{ height: "100%" }}
                    variant="outlined"
                    color="secondary"
                    fullWidth
                    endIcon={<FilterAltOutlined />}
                    onClick={handleSubmit}
                >
                    Filter
                </Button>
            </Grid>
        </>
    );
};
