import { RestoreOutlined } from "@mui/icons-material";
import {
    Box,
    Card,
    Chip,
    CircularProgress,
    TextField,
    Typography,
} from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useEffect, useMemo, useState } from "react";
import { theme } from "../../../Theme/theme";
import { POST } from "../../../Utilities/BaseService";

type Props = any;
const Range = ({ data, updatedSettings, setUpdatedSettings }: Props) => {
    const [initialValue, setInitialValue] = useState<any>({
        key: "",
        label: "",
        value: "",
    });
    const [setting, setSetting] = useState<any>({
        key: "",
        label: "",
        value: "",
    });

    const compareValues = (initialValue: any, finalValue: any) => {
        const initVal = Object.values(initialValue.value);
        const finalVal = Object.values(finalValue.value);

        let _result = initVal.map((_, idx) => finalVal[idx] == initVal[idx]);
        return _result.every((x) => x);
    };

    const queryClient = useQueryClient();

    const handleChange = (e: any) => {
        let value = e.target.value;

        const sett = {
            ...setting,
            value: { ...setting.value, [e.target.name]: value },
        };
        setSetting(sett);

        const isSame = compareValues(initialValue, sett);

        if (!isSame) {
            setUpdatedSettings({
                ...updatedSettings,
                [data.key]: JSON.stringify(sett.value),
            });
        }
    };

    useEffect(() => {
        if (data) {
            setInitialValue({
                key: data.key,
                label: data.label,
                value: JSON.parse(data.value),
            });

            setSetting({
                key: data.key,
                label: data.label,
                value: JSON.parse(data.value),
            });
        }
    }, [data]);

    const isGlobal = useMemo(() => {
        const regions = Object.fromEntries(
            Object.entries(setting).filter(
                ([k, v]) =>
                    k === "district" || k === "village" || k === "hamlet"
            )
        );

        if (regions.district) return false;

        return true;
    }, [setting.district, setting.village, setting.hamlet]);

    const restoreMut = useMutation(
        (sett: any) => POST("/settings/add-single", sett),
        {
            onSuccess(res) {
                window.location.reload();
            },
        }
    );

    return (
        <Card
            elevation={0}
            sx={{
                bgcolor: theme.palette.common.white,
                p: 2,
                mb: 1,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
            }}
        >
            <Box display="flex" alignItems="center" gap={1}>
                <Typography display="flex" alignItems="center" gap={1}>
                    {data?.label}
                </Typography>

                {!data?.IsDefault && (
                    <Chip
                        label={
                            restoreMut.isLoading ? "Restoring..." : "Restore"
                        }
                        size="small"
                        color="info"
                        variant="outlined"
                        deleteIcon={
                            restoreMut.isLoading ? (
                                <CircularProgress size={15} />
                            ) : (
                                <RestoreOutlined />
                            )
                        }
                        onDelete={() =>
                            restoreMut.mutate({
                                id: data.id,
                                key: data.key,
                                isGlobal,
                            })
                        }
                    />
                )}
            </Box>

            <div style={{ display: "flex" }}>
                {Object.entries(setting?.value).map(([k, v]: any) => (
                    <TextField
                        key={k}
                        name={k}
                        value={setting?.value[k]}
                        label={k + " day"}
                        size="small"
                        sx={{ mx: 1 }}
                        type="number"
                        onChange={handleChange}
                    />
                ))}
            </div>
        </Card>
    );
};

export default Range;
