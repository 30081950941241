import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from "@mui/material";
import { useMemo } from "react";
import { dateFormatter, monthFormatter } from "../SingleBill";

type Props = {
    last12Readings: {
        id: number;
        value: string;
    }[];
};

type PreviousRecord = {
    amount: number;
    adjustment: number;
    collectionDate: string;
};

export const numberFormatter = Intl.NumberFormat("en");

export const currencyFormatter = Intl.NumberFormat("en-PK", {
    currency: "PKR",
    style: "currency",
});

const getFromattedDate = (month: number, year: number) => {
    const d = new Date(year, month, 1);
    return monthFormatter.format(d);
};

export const Last12Summary = ({ last12Readings }: Props) => {
    const collections: PreviousRecord[] = useMemo(
        () => last12Readings.map((c) => JSON.parse(c.value)),
        [last12Readings]
    );

    return (
        <Table size="small">
            <TableHead
                sx={{
                    "& > *": {
                        bgcolor: "action.selected",
                    },
                }}
            >
                <TableRow>
                    <TableCell>Date</TableCell>
                    <TableCell>Amount</TableCell>
                    <TableCell>Adjustment</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {collections.map((c, index: number) => (
                    <TableRow key={index}>
                        <TableCell>
                            {dateFormatter.format(new Date(c.collectionDate))}
                        </TableCell>

                        <TableCell>
                            {currencyFormatter.format(c.amount)}
                        </TableCell>

                        <TableCell>
                            {currencyFormatter.format(c.adjustment)}
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
};
