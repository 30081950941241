import {
    Box,
    Table,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import { Header } from "../Header";
import { dateFormatter, monthFormatter } from "../SingleBill";
import { Cell } from "./Cell";
import { currencyFormatter } from "./Last12Summary";

export const OfficeCopy = ({ reading }: any) => {
    return (
        <>
            <Header copyName="Office copy" />

            <Cell
                sx={{
                    borderLeft: "2px solid",
                    borderTop: "2px solid",
                    borderBottom: "2px solid",
                }}
                xs={9}
                noLabel
                noTypography
                value={
                    /*  <Box display="flex" flexDirection="column">
                        <Typography variant="body2" fontWeight="bold">
                            Name & Address
                        </Typography>
                        <Typography variant="caption">
                            {reading.meter.property.customer.name}
                        </Typography>
                        <Typography variant="caption">
                            {reading.meter.property.customer.fatherName}
                        </Typography>
                        <Typography variant="caption">
                            Meter no. {reading.meter.meterNo}
                        </Typography>
                        <Typography variant="caption">
                            {reading.meter.property.name}
                        </Typography>
                        <Typography variant="caption">
                            {reading.meter.property.address}
                        </Typography>
                    </Box> */
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell padding="checkbox">Name</TableCell>

                                <TableCell padding="checkbox">
                                    Father Name
                                </TableCell>

                                <TableCell padding="checkbox">
                                    Meter No.
                                </TableCell>

                                <TableCell padding="checkbox" width="0">
                                    Property
                                </TableCell>
                            </TableRow>
                        </TableHead>

                        <TableRow>
                            <TableCell padding="checkbox">
                                {reading.meter.property.customer.name}
                            </TableCell>

                            <TableCell padding="checkbox">
                                {reading.meter.property.customer.fatherName}
                            </TableCell>

                            <TableCell padding="checkbox">
                                {reading.meter.meterNo}
                            </TableCell>

                            <TableCell padding="checkbox">
                                {reading.meter.property.name}
                            </TableCell>
                        </TableRow>
                    </Table>
                }
            />

            <Cell
                sx={{
                    borderRight: "2px solid",
                    borderTop: "2px solid",
                    borderBottom: "2px solid",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
                xs={3}
                noLabel
                noTypography
                value={
                    <Box
                        sx={{
                            border: "2px dashed",
                            borderColor: "action.disabled",
                            px: 6,
                            py: 1.5,
                        }}
                    >
                        <Typography
                            fontWeight="bold"
                            sx={{ color: "action.disabled" }}
                        >
                            STAMP
                        </Typography>
                    </Box>
                }
            />

            <Cell
                sx={{
                    borderLeft: "2px solid",
                    borderBottom: "2px solid",
                }}
                xs={2}
                label="Bill month"
                labelUrdu="مہینہ بل"
                value={monthFormatter.format(
                    new Date(reading.year, reading.month - 1, 1)
                )}
            />

            <Cell
                sx={{
                    borderBottom: "2px solid",
                    borderLeft: "2px solid",
                }}
                xs={2}
                label="Due date"
                labelUrdu="مقرره تاریخ"
                value={dateFormatter.format(new Date(reading.dueDate))}
                labelSx={{ color: "red" }}
            />

            <Cell
                sx={{
                    borderBottom: "2px solid",
                    borderLeft: "2px solid",
                }}
                xs={2}
                label="Reference no."
                labelUrdu="حوالہ نمبر"
                value={reading.id}
            />

            <Cell
                sx={{
                    borderBottom: "2px solid",
                    borderLeft: "2px solid",
                }}
                xs={3}
                label="Payable within due date"
                labelUrdu="مقرره تاریخ تک واجب الادا"
                value={currencyFormatter.format(reading.payableWithinDueDate)}
                labelSx={{ color: "red" }}
            />

            <Cell
                sx={{
                    borderBottom: "2px solid",
                    borderLeft: "2px solid",
                    borderRight: "2px solid",
                }}
                xs={3}
                label="Payable after due date"
                labelUrdu="الادا واجب کےبعد تاریخ مقرره"
                value={currencyFormatter.format(reading.payableAfterDueDate)}
                labelSx={{ color: "red" }}
            />
        </>
    );
};
