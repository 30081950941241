import { Print as PrintIcon } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import { useRef, useState } from "react";
import ReactToPrint from "react-to-print";

export const Print = ({ componentRef, setPrintMode, sx }: any) => {
    const [open, setOpen] = useState(false);

    const triggerRef = useRef<any>(null);

    const handlePrint = () => {
        setPrintMode(true);
        setTimeout(() => {
            triggerRef.current?.click();
            setPrintMode(false);
        }, 1000);
    };

    return (
        <>
            <ReactToPrint
                trigger={() => <section hidden ref={triggerRef} />}
                content={() => componentRef.current}
            />

            <Tooltip title="Print">
                <IconButton onClick={handlePrint} sx={sx} size="small">
                    <PrintIcon fontSize="small" />
                </IconButton>
            </Tooltip>
        </>
    );
};
