import { Add } from "@mui/icons-material";
import {
    Alert,
    Box,
    Button,
    CircularProgress,
    Grid,
    Paper,
    TextField,
    Typography,
} from "@mui/material";
import { FormEvent, useState } from "react";
import { POST } from "../../Utilities/BaseService";
import UpdatedSearchableInput from "../../Utilities/UpdatedSearchableInput";
import { notConnectedMessage } from "../../Utilities/constants";

export const CreateConsumption = () => {
    const [powerstation, setPowerstation] = useState<any | null>(null);
    const [consumption, setConsumption] = useState({
        month: "",
        year: "",
        generatedUnits: 0,
        _month: "",
    });
    const [status, setStatus] = useState({ status: "idle", message: "" });

    const handleSubmit = async (ev: FormEvent<HTMLFormElement>) => {
        ev.preventDefault();
        setStatus({ status: "loading", message: "" });

        if (!powerstation.powerstation || !powerstation.powerstation.district) {
            return setStatus({
                status: "error",
                message:
                    "Must select a powerstation, month, and no. of units generated to record the entry.",
            });
        }

        try {
            const response = await POST("/unit-consumption", {
                month: parseInt(consumption.month) || null,
                year: parseInt(consumption.year) || null,
                generatedUnits: consumption.generatedUnits,
                powerstation: powerstation.powerstation.id,
                district: powerstation.powerstation.district.id,
            });
            setStatus({ status: "success", message: response.data.message });
        } catch (err: any) {
            console.log(err);
            setStatus({
                status: "error",
                message: err?.response?.data?.message || notConnectedMessage,
            });
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <Box
                sx={{ p: 1, bgcolor: "common.white" }}
                component={Paper}
                variant="outlined"
            >
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Typography
                            gutterBottom
                            variant="h5"
                            color="textSecondary"
                        >
                            Add Consumption
                        </Typography>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <UpdatedSearchableInput
                            api="/powerstation"
                            filter={powerstation}
                            setFilter={setPowerstation}
                            label="Powerstation"
                            getFullRecord
                            required
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <TextField
                            size="small"
                            fullWidth
                            type="month"
                            label="Month"
                            InputLabelProps={{ shrink: true }}
                            onChange={(ev) => {
                                const [year, month] =
                                    ev.target.value.split("-");
                                setConsumption({
                                    ...consumption,
                                    month,
                                    year,
                                    _month: ev.target.value,
                                });
                            }}
                            required
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <TextField
                            InputLabelProps={{ shrink: true }}
                            size="small"
                            fullWidth
                            label="Generated units"
                            value={consumption.generatedUnits}
                            onChange={(ev) => {
                                setConsumption({
                                    ...consumption,
                                    generatedUnits:
                                        parseInt(ev.target.value) || 0,
                                });
                            }}
                            required
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Button
                            type="submit"
                            variant="outlined"
                            color="secondary"
                            fullWidth
                            disabled={status.status === "loading"}
                            endIcon={
                                status.status === "loading" ? (
                                    <CircularProgress
                                        color="secondary"
                                        size="1rem"
                                    />
                                ) : (
                                    <Add />
                                )
                            }
                            sx={{ height: "100%" }}
                        >
                            Add
                        </Button>
                    </Grid>

                    <Grid item xs={12}>
                        {["success", "error"].includes(status.status) && (
                            <Alert severity={status.status as any}>
                                {status.message.split("\n").map((x) => (
                                    <div>{x}</div>
                                ))}
                            </Alert>
                        )}
                    </Grid>
                </Grid>
            </Box>
        </form>
    );
};
