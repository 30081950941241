import { useContext } from "react";
import { AppContext } from "./AppContext";

interface Props {
    children: any;
    ops: string[];
    fallback?: JSX.Element | null;
    strategy?: "some" | "all";
}

export function MultiPermissionAuthorize({
    children,
    ops,
    fallback = null,
    strategy = "all",
}: Props) {
    const appCtx = useContext(AppContext);

    if (ops.length === 0) {
        return children;
    } else if (
        appCtx.user?.ops
            ? strategy === "all"
                ? ops.every((op) => appCtx.user.ops.includes(op))
                : ops.some((op) => appCtx.user.ops.includes(op))
            : true
    ) {
        return children;
    } else {
        return fallback;
    }
}
