import { LinkOutlined } from "@mui/icons-material";
import {
    Box,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    CircularProgress,
    Divider,
    TablePagination,
    Typography,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { GET } from "../../../Utilities/BaseService";
import { BlindPagination } from "../../Table/Components/BlindPagination";
import { ComparisonBar } from "./ComparisionBar";
import { ConnTypeCounts } from "./ConnTypeCounts";
import { DistributionDonut } from "./DistributionDonut";
import { SelectConnTypes } from "./SelectConnTypes";

const getMeterCountReport = (filter: any, page: any, limit: any) => {
    return GET("/reports/dashboard/no-of-meters", {
        page,
        limit,
        ...Object.fromEntries(
            Object.entries(filter).filter((entry) =>
                ["district", "village", "hamlet"].includes(entry[0])
            )
        ),
    });
};

export const MeterCountReport = ({ filter = {} }: any) => {
    const [pagination, setPagination] = useState<any>({ page: 0, limit: 10 });
    const [selectedConnTypes, setSelectedConnTypes] = useState<
        "all" | number[]
    >("all");
    const { data: reportRes, isFetching } = useQuery(
        ["meter-count-report", filter, pagination.page, pagination.limit],
        () => getMeterCountReport(filter, pagination.page + 1, pagination.limit)
    );

    return (
        <Card variant="outlined" sx={{ mb: "200px" }}>
            <CardActions sx={{ display: "flex", justifyContent: "flex-end" }}>
                <BlindPagination
                    pagination={pagination}
                    setPagination={setPagination}
                    rowsPerPageOpts={[10]}
                />
            </CardActions>
            <CardHeader
                subheader={
                    <Box display="flex" alignItems="center" gap={1}>
                        <LinkOutlined color="primary" />
                        <Typography>No. of connections</Typography>
                        {isFetching && <CircularProgress size="1rem" />}
                    </Box>
                }
            />

            <CardContent>
                <SelectConnTypes
                    meterReport={reportRes?.data.meterReport}
                    selectedConnTypes={selectedConnTypes}
                    setSelectedConnTypes={setSelectedConnTypes}
                />
                <ConnTypeCounts
                    meterReport={reportRes?.data.meterReport}
                    selectedConnTypes={selectedConnTypes}
                />
                <Divider sx={{ my: 2 }} />
                <DistributionDonut
                    meterReport={reportRes?.data.meterReport}
                    selectedConnTypes={selectedConnTypes}
                />
                <Divider sx={{ my: 2 }} />
                <ComparisonBar
                    meterReport={reportRes?.data.meterReport}
                    selectedConnTypes={selectedConnTypes}
                />
            </CardContent>
        </Card>
    );
};
