import { FilterAltOutlined } from "@mui/icons-material";
import {
    Alert,
    Box,
    Button,
    Card,
    Grid,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { GET } from "../../../Utilities/BaseService";
import {
    filterSelectionMessage,
    hasValue,
    noDataMessage,
} from "../../../Utilities/constants";
import { MultiPermissionAuthorize } from "../../../Utilities/MultiPermissionAuthorize";
import UpdatedSearchableInput from "../../../Utilities/UpdatedSearchableInput";
import { currencyFormatter } from "../../Reading/Bill/Cells/Last12Summary";
import BaseTable from "../../Table/BaseTable";

type Tag =
    | "customer"
    | "property"
    | "meter"
    | "reading"
    | "readingCollection"
    | "meterReplacement"
    | "paymentcollection"
    | "connection";

type Filter = {
    search: string;
    customer: number | "" | "all";
    property: number | "" | "all";
    meter: number | "" | "all";
    tag: number | "" | "all";
};

const initialFilter: Filter = {
    search: "",
    customer: "",
    property: "",
    meter: "",
    tag: "",
};

const CustomerTimeline = () => {
    const [rowsCount, setRowsCount] = useState(0);
    const [pagination, setPagination] = useState<any>({ page: 0, limit: 100 });
    const [filter, setFilter] = useState(initialFilter);
    const [feedback, setFeedback] = useState("");
    const [changeCount, setChangeCount] = useState(0);
    const [intermediateFilter, setIntermediateFilter] =
        useState<Filter>(initialFilter);

    const theme = useTheme();
    const mdDown = useMediaQuery(theme.breakpoints.down("md"));

    const { data, isLoading } = useQuery(
        ["customer-timeline", filter, pagination],
        () =>
            GET("/consumer/timeline", {
                ...pagination,
                page: pagination.page + 1,
                ...Object.fromEntries(
                    Object.entries(filter).filter(
                        ([k, v]: any) => v && v !== "all"
                    )
                ),
            }),
        {
            enabled: Boolean(changeCount),
            onSuccess(res) {
                setRowsCount(res.data.count);
            },
        }
    );

    const actionFactory = (row: any, tag: Tag, content: string): string => {
        const rowContent = JSON.parse(content);
        let action = "";

        switch (tag) {
            case "customer":
                if (rowContent?.name) {
                    action = `User "${rowContent?.name}" is being added.`;
                } else if (rowContent.amount) {
                    action = `Payment of Rs. ${rowContent?.amount} is created against customer "${rowContent?.name}".`;
                } else action = "Invalid action";

                return action;

            case "property":
                if (rowContent?.name) {
                    action = `${
                        rowContent?.name
                    } is being created and assigned to ${
                        row?.customer?.name ?? "[deleted customer]"
                    }`;
                } else if (rowContent?.amount) {
                    action = `Payment of Rs. ${rowContent?.amount} is created against property "${rowContent?.name}".`;
                } else action = "Invalid action";

                return action;

            case "meter":
                if (rowContent?.meterNo) {
                    action = `Meter "${
                        rowContent.meterNo
                    }" is assigned to property "${
                        row?.property?.name ?? "[Deleted Property]"
                    }  ". `;
                } else if (rowContent?.amount) {
                    action = `Payment of Rs. ${rowContent?.amount} is added against meter "${rowContent?.meterNo}", with property "${row?.property?.name} ?? [deleted property]".`;
                } else action = "Invalid action";

                return action;

            case "reading":
                action = `Reading is made against meter "${
                    row?.meter?.meterNo ?? "[deleted meterNo]"
                }", with property "${
                    row?.property?.name ?? "[deleted property]"
                }  . (Previous Unit : ${
                    rowContent?.prevUnits
                }, Current Units : ${
                    rowContent?.currentUnits
                }, Line Rent : ${currencyFormatter.format(
                    rowContent?.lineRent
                )})`;
                return action;

            case "readingCollection":
                const adjustmentAmount = rowContent?.adjustment;

                action = `${currencyFormatter.format(rowContent?.amount)}  ${
                    adjustmentAmount > 0
                        ? `and adjustment of ${currencyFormatter.format(
                              adjustmentAmount
                          )}`
                        : ""
                } is collected against meter "${
                    row?.meter?.meterNo ?? "[Deleted MeteNo]"
                }", with property "${
                    row?.property?.name ?? "[Deleted property]"
                }".`;
                return action;

            case "paymentcollection":
                action = `${currencyFormatter.format(rowContent?.amount)} of "${
                    rowContent?.payment
                }" is collected.`;

                return action;

            case "meterReplacement":
                action = `Meter "${
                    rowContent?.prevMeterNo
                }" is replaced with new meter "${
                    row?.meter?.meterNo ?? "[Deleted MeteNo]"
                }", with property "${
                    row?.property?.name
                } ?? "[Deleted property]".`;
                return action;

            case "connection":
                action = `New connection of meter "${
                    row?.meter?.meterNo ?? "[Deleted MeterNo]"
                } " is assigned to customer "${
                    row?.customer?.name ?? "[Deleted customer]"
                }", with property "${
                    row?.property?.name ?? "[Deleted property]"
                }".`;

                return action;

            default:
                return "";
        }
    };

    useEffect(() => {
        if (changeCount) {
            setFilter({ ...filter, ...intermediateFilter });
        }
    }, [changeCount]);

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                height: mdDown ? "auto" : `calc(100vh - 96px)`,
            }}
        >
            <Card
                elevation={0}
                sx={{
                    bgcolor: theme.palette.common.white,
                    p: 2,
                    mb: "1rem",
                }}
            >
                <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Grid item xs={12}>
                        <Typography variant="h5" color="GrayText">
                            Consumer History
                        </Typography>
                    </Grid>

                    <Grid item container xs={12} spacing={1.5} mt={1}>
                        <MultiPermissionAuthorize ops={["READ CUSTOMER"]}>
                            <Grid item flex={1}>
                                <UpdatedSearchableInput
                                    api="/customer"
                                    label="Customer"
                                    filter={intermediateFilter}
                                    setFilter={setIntermediateFilter}
                                />
                            </Grid>
                        </MultiPermissionAuthorize>
                        <MultiPermissionAuthorize ops={["READ PROPERTY"]}>
                            <Grid item flex={1}>
                                <UpdatedSearchableInput
                                    api="/customer/property"
                                    label="Property"
                                    filter={intermediateFilter}
                                    setFilter={setIntermediateFilter}
                                    dep={intermediateFilter.customer}
                                    params={
                                        intermediateFilter.customer &&
                                        intermediateFilter.customer !== "all"
                                            ? {
                                                  customer:
                                                      intermediateFilter.customer,
                                              }
                                            : undefined
                                    }
                                />
                            </Grid>
                        </MultiPermissionAuthorize>
                        <MultiPermissionAuthorize ops={["READ METER"]}>
                            <Grid item flex={1}>
                                <UpdatedSearchableInput
                                    api="/customer/meter"
                                    label="Meter"
                                    filter={intermediateFilter}
                                    setFilter={setIntermediateFilter}
                                    optionsPreprocessor={(opt: any) => ({
                                        ...opt,
                                        name: opt.meterNo,
                                    })}
                                    dep={intermediateFilter.property}
                                    params={
                                        intermediateFilter.property &&
                                        intermediateFilter.property !== "all"
                                            ? {
                                                  property:
                                                      intermediateFilter.property,
                                              }
                                            : undefined
                                    }
                                />
                            </Grid>
                        </MultiPermissionAuthorize>

                        <Grid item flex={1}>
                            <UpdatedSearchableInput
                                api="/consumer/timeline/tags"
                                label="Tag"
                                filter={intermediateFilter}
                                setFilter={setIntermediateFilter}
                                optionsPreprocessor={(opt: any) => ({
                                    ...opt,
                                    name: opt.label,
                                    id: opt.type,
                                })}
                            />
                        </Grid>

                        <Grid item>
                            <Button
                                color="secondary"
                                variant="outlined"
                                onClick={() => setChangeCount(changeCount + 1)}
                                sx={{ minWidth: "22ch", height: "100%" }}
                                startIcon={<FilterAltOutlined />}
                                fullWidth
                            >
                                filter
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Card>

            {data?.data.rows.length > 0 ? (
                <Box sx={{ height: "70%" }}>
                    <BaseTable
                        headers={{
                            date: "Date",
                            customer: "Customer Name",
                            action: {
                                label: "Action",
                                styles: (item: any) => ({
                                    whiteSpace: "normal",
                                }),
                                props: (item: any) => ({}),
                            },
                            tag: "Tag",
                        }}
                        load={Boolean(changeCount)}
                        defaultSelectedHeaders={[
                            "date",
                            "customer",
                            "action",
                            "tag",
                        ]}
                        data={data?.data.rows.map((row: any) => ({
                            ...row,
                            date: new Date(row.createdAt).toDateString(),
                            customer: row?.customer?.name,
                            action: actionFactory(row, row.tag, row.content),
                        }))}
                        delEndPoint="/region/district"
                        feedback={feedback}
                        setFeedback={setFeedback}
                        rowsCount={rowsCount}
                        pagination={pagination}
                        setPagination={setPagination}
                        isLoading={isLoading}
                        permissions={{
                            edit: [""],
                            delete: [""],
                        }}
                        queryKey={["customer-timeline", filter, pagination]}
                        reportName="Consumer History"
                    />
                </Box>
            ) : hasValue(filter) ? (
                <Alert severity="info">{noDataMessage}</Alert>
            ) : (
                <Alert severity="info">{filterSelectionMessage}</Alert>
            )}
        </Box>
    );
};

export default CustomerTimeline;
