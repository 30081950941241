import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
    palette: {
        primary: { main: "#EFC904" },
        secondary: { main: "#486C22" },
        action: { hover: "#486C22", selectedOpacity: 0.25 },
        common: { white: "#eceeef" },
    },
    components: {
        MuiMenuItem: {
            styleOverrides: {
                gutters: {},
                focusVisible: {},
                dense: {},
                root: {},
                selected: {
                    // color: "red",
                    // fontSize: "5rem",
                    // backgroundColor: "red",
                },
                disabled: {},
                divider: {},
            },
        },
    },
});
