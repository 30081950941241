import { Collapse, Grid, IconButton, TextField } from "@mui/material";
import { Dispatch, SetStateAction, useMemo } from "react";
import { LastRange } from "./LastRange";
import {
    AddOutlined,
    AllInclusiveOutlined,
    Close,
    ExpandLessOutlined,
    ExpandMoreOutlined,
} from "@mui/icons-material";
import { RuleRange } from "./RuleRange";

type AdaptiveRuleProps = {
    rule: UnitChargeRules;
    setUnitChargeRules: Dispatch<SetStateAction<UnitChargeRules>>;
    consumptionIndex: number;
    addNewRange: () => void;
};

export const AdaptiveRule = ({
    rule,
    setUnitChargeRules,
    consumptionIndex,
    addNewRange,
}: AdaptiveRuleProps) => {
    const deleteConsumption = (id: number) => {
        const targetConsumptions = Object.fromEntries(
            Object.entries(rule).filter(([k, v]) => parseInt(k) !== id)
        );

        setUnitChargeRules(targetConsumptions);
    };

    const handleCollapse = (action: "open" | "close") => {
        if (action === "open")
            setUnitChargeRules({
                ...rule,
                [consumptionIndex]: { ...rule[consumptionIndex], open: true },
            });
        else
            setUnitChargeRules({
                ...rule,
                [consumptionIndex]: { ...rule[consumptionIndex], open: false },
            });
    };

    const maxStartNCostInRule = useMemo(() => {
        const allStartsNCosts = Object.values(
            rule[consumptionIndex].components
        ).map((item) => ({ start: item.start, cost: item.cost }));

        const allStartsInRule = allStartsNCosts.map((s) => s.start);
        const allCostsInRule = allStartsNCosts.map((c) => c.cost);

        const maxStart = allStartsInRule.reduce(
            (prev, curr) => Math.max(prev, curr),
            0
        );

        const maxCost = allCostsInRule.reduce(
            (prev, curr) => Math.max(prev, curr),
            0
        );

        return { maxCost, maxStart };
    }, [rule[consumptionIndex]]);

    const lastEnd = useMemo(() => {
        const comps = Object.values(rule[consumptionIndex].components);
        return comps[comps.length - 1]?.end ?? 0;
    }, [rule[consumptionIndex].components]);

    return (
        <>
            <Grid item xs={12}>
                <TextField
                    label="Consumption"
                    size="small"
                    disabled={consumptionIndex === -1}
                    value={
                        consumptionIndex === -1
                            ? ""
                            : rule[consumptionIndex].consumption
                    }
                    onChange={(e) =>
                        setUnitChargeRules({
                            ...rule,
                            [consumptionIndex]: {
                                ...rule[consumptionIndex],
                                consumption: parseInt(e.target.value) || 0,
                            },
                        })
                    }
                    InputProps={{
                        startAdornment:
                            consumptionIndex === -1 ? (
                                <AllInclusiveOutlined />
                            ) : undefined,

                        endAdornment: (
                            <>
                                <IconButton
                                    size="small"
                                    color="success"
                                    onClick={addNewRange}
                                    disabled={
                                        lastEnd >=
                                            rule[consumptionIndex]
                                                .consumption &&
                                        consumptionIndex !== -1
                                    }
                                >
                                    <AddOutlined fontSize="small" />
                                </IconButton>

                                {consumptionIndex !== -1 && (
                                    <IconButton
                                        size="small"
                                        color="error"
                                        onClick={() =>
                                            deleteConsumption(consumptionIndex)
                                        }
                                    >
                                        <Close fontSize="small" />
                                    </IconButton>
                                )}

                                <IconButton
                                    size="small"
                                    onClick={() =>
                                        handleCollapse(
                                            rule[consumptionIndex].open
                                                ? "close"
                                                : "open"
                                        )
                                    }
                                >
                                    {rule[consumptionIndex].open ? (
                                        <ExpandLessOutlined fontSize="small" />
                                    ) : (
                                        <ExpandMoreOutlined fontSize="small" />
                                    )}
                                </IconButton>
                            </>
                        ),
                    }}
                    fullWidth
                />
            </Grid>

            <Grid
                item
                container
                xs={12}
                component={Collapse}
                in={rule[consumptionIndex].open}
            >
                <Grid item container spacing={2} mb={2} alignItems="center">
                    {Object.entries(rule[consumptionIndex].components).map(
                        ([rangeIndex, range]) => (
                            <RuleRange
                                key={rangeIndex}
                                range={range}
                                unitChargeRules={rule}
                                setUnitChargeRules={setUnitChargeRules}
                                index={parseInt(rangeIndex)}
                                isAdaptive={true}
                                consumption={consumptionIndex}
                            />
                        )
                    )}
                </Grid>

                <Grid item container xs={12} spacing={2}>
                    <LastRange
                        range={{
                            start: Math.max(
                                maxStartNCostInRule.maxStart + 1,
                                lastEnd + 1
                            ),
                            cost: rule[consumptionIndex].costAfterLastRule,
                        }}
                        isAdaptive={true}
                        index={-1}
                        consumption={consumptionIndex}
                        unitChargeRules={rule}
                        setUnitChargeRules={setUnitChargeRules}
                        minCost={maxStartNCostInRule.maxCost + 1}
                    />
                </Grid>
            </Grid>
        </>
    );
};
