import {
    Alert,
    Box,
    Button,
    Chip,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    LinearProgress,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Tooltip,
} from "@mui/material";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { GET, POST } from "../../../Utilities/BaseService";
import { notConnectedMessage } from "../../../Utilities/constants";
import { monthFormatter } from "../../Reading/Bill/SingleBill";

type Props = {
    meter: any;
    open: boolean;
    close: () => void;
};

const TR = ({ month, correction }: { month: any; correction: number }) => {
    return (
        <TableRow id={month.id}>
            <TableCell>
                {monthFormatter.format(
                    new Date(month.year, month.month - 1, 1)
                )}
            </TableCell>
            <TableCell>
                {month.prevUnits}&nbsp;
                <Tooltip title="After change">
                    <Chip
                        sx={{ color: "action.disabled" }}
                        size="small"
                        variant="filled"
                        label={month.prevUnits + correction}
                    />
                </Tooltip>
            </TableCell>
            <TableCell>
                {month.currentUnits}&nbsp;
                <Tooltip title="After change">
                    <Chip
                        sx={{ color: "action.disabled" }}
                        size="small"
                        variant="filled"
                        label={month.currentUnits + correction}
                    />
                </Tooltip>
            </TableCell>
        </TableRow>
    );
};

const AffectedMonths = ({
    affectedMonths,
    data,
    setData,
}: {
    affectedMonths: any[];
    data: any;
    setData: Dispatch<SetStateAction<any>>;
}) => {
    return (
        <Table size="small">
            <TableHead>
                <TableRow>
                    <TableCell>Month</TableCell>
                    <TableCell>Prev. Units</TableCell>
                    <TableCell>Current Units</TableCell>
                </TableRow>
            </TableHead>

            <TableBody>
                <TableRow>
                    <TableCell>
                        {monthFormatter.format(
                            new Date(
                                affectedMonths[0].year,
                                affectedMonths[0].month - 1,
                                1
                            )
                        )}
                    </TableCell>
                    <TableCell>{affectedMonths[0].prevUnits}</TableCell>
                    <TableCell
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "4px",
                        }}
                    >
                        <TextField
                            required
                            sx={{
                                width: "15ch",
                            }}
                            size="small"
                            type="number"
                            value={data.newUnits}
                            onChange={(ev) => {
                                const val = Math.max(
                                    affectedMonths[0].prevUnits,
                                    parseInt(ev.target.value ?? "0") ?? 0
                                );
                                setData({
                                    ...data,
                                    newUnits: val,
                                    correction:
                                        val - affectedMonths[0].currentUnits,
                                });
                            }}
                        />
                        <Chip
                            sx={{ color: "action.disabled" }}
                            size="small"
                            variant="filled"
                            label={affectedMonths[0].currentUnits}
                        />
                    </TableCell>
                </TableRow>

                {affectedMonths.slice(1).map((month) => (
                    <TR
                        month={month}
                        correction={data.correction}
                        key={month.id}
                    />
                ))}
            </TableBody>
        </Table>
    );
};

export const UnitCorrection = ({ meter, open, close }: Props) => {
    const [affectedMonths, setAffectedMonths] = useState<any[]>([]);
    const [data, setData] = useState({ month: "", correction: 0, newUnits: 0 });
    const [feedback, setFeedback] = useState({ status: "idle", message: "" });
    const [status, setStatus] = useState("idle");

    const handleSubmit = async () => {
        setFeedback({ ...feedback, status: "loading" });
        try {
            const [year, month] = data.month.split("-");
            const response = await POST("/reading/correction", {
                meterId: meter.id,
                newUnits: data.newUnits,
                month,
                year,
            });
            setFeedback({
                status: "success",
                message: response?.data?.message,
            });
        } catch (err: any) {
            setFeedback({
                status: "error",
                message: err?.response?.data?.message || notConnectedMessage,
            });
        }
    };

    const getAffectedRows = async () => {
        setStatus("loading");
        const [year, month] = data.month.split("-");

        try {
            const response = await GET("/reading/correction-affected-months", {
                meterId: meter.id,
                month,
                year,
            });
            setAffectedMonths(response?.data?.rows ?? []);

            if (response?.data?.rows.length > 1) {
                setData({
                    ...data,
                    newUnits: response?.data?.rows[0].currentUnits,
                });
            }
        } catch (err) {}

        setStatus("idle");
    };

    useEffect(() => {
        if (data.month) {
            getAffectedRows();
        }
    }, [meter, data.month]);

    return (
        <Dialog
            fullWidth
            open={Boolean(open)}
            onClose={close}
            onKeyUp={(ev) => {
                if (ev.key === "Enter") {
                    handleSubmit();
                }
            }}
        >
            <DialogTitle>
                Unit Correction&nbsp;-&nbsp;{meter?.meterNo}
            </DialogTitle>

            <DialogContent>
                <Grid container spacing={1} sx={{ pt: 1 }}>
                    <Grid item xs={12}>
                        {feedback.message.length > 0 && (
                            <Alert severity={feedback.status as any}>
                                {feedback.message}
                            </Alert>
                        )}
                        {feedback.status === "loading" && <LinearProgress />}
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            InputLabelProps={{ shrink: true }}
                            required
                            fullWidth
                            size="small"
                            type="month"
                            label="Month"
                            value={data.month}
                            onChange={(ev) =>
                                setData({ ...data, month: ev.target.value })
                            }
                        />
                    </Grid>

                    <Grid item xs={12}>
                        {status === "loading" && (
                            <Box
                                sx={{
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                                }}
                            >
                                <CircularProgress size="2rem" />
                            </Box>
                        )}
                        {affectedMonths.length > 1 && (
                            <AffectedMonths
                                data={data}
                                setData={setData}
                                affectedMonths={affectedMonths}
                            />
                        )}
                    </Grid>
                </Grid>
            </DialogContent>

            <DialogActions>
                <Button color="inherit" onClick={close}>
                    Cancel
                </Button>
                <Button onClick={handleSubmit}>Update</Button>
            </DialogActions>
        </Dialog>
    );
};
