import {
    Box,
    Card,
    CardContent,
    CardHeader,
    CircularProgress,
    Typography,
} from "@mui/material";
import { useContext } from "react";
import { numberFormatter } from "../../Reading/Bill/Cells/Last12Summary";
import { SimpleDashboardCtx } from "./SimpleDashboard";

export enum DCardVariant {
    Table,
    Simple,
}

type DCardTableData = {};
type DCardSimpleData = {
    label: string;
    value: number;
    ident: string;
};

type Props = {
    variant: DCardVariant;
    data: DCardSimpleData | DCardTableData;
};

const DashboardCardSimple = ({ data }: { data: DCardSimpleData }) => {
    const { status } = useContext(SimpleDashboardCtx);

    return (
        <Card variant="outlined" sx={{ bgcolor: "common.white" }}>
            <CardHeader
                sx={{
                    p: 1,
                }}
                title={
                    <div
                        style={{
                            // display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            overflow: "hidden",
                            display: "-webkit-box",
                            WebkitBoxOrient: "vertical",
                            WebkitLineClamp: 1,
                        }}
                    >
                        <Typography variant="body1">{data.label}</Typography>

                        {/* <IconButton size="small">
                            <CachedOutlined />
                        </IconButton> */}
                    </div>
                }
                titleTypographyProps={{ variant: "subtitle1" }}
                disableTypography
            />

            {status[data.ident] === "success" ? (
                <>
                    <CardContent sx={{ p: 1, px: 1.5 }}>
                        <Typography variant="h5">
                            {numberFormatter.format(data.value)}
                        </Typography>
                    </CardContent>
                </>
            ) : status[data.ident] === "loading" ? (
                <Box
                    mb={2}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                >
                    <CircularProgress size="2rem" />
                </Box>
            ) : null}
        </Card>
    );
};

const DashboardCardTable = ({ data }: { data: DCardTableData }) => {
    return null;
};

export const DashboardCard = ({ variant, data }: Props) => {
    switch (variant) {
        case DCardVariant.Simple:
            return <DashboardCardSimple data={data as DCardSimpleData} />;

        case DCardVariant.Table:
            return <DashboardCardTable data={data as DCardTableData} />;

        default:
            return null;
    }
};
