import { InfoOutlined } from "@mui/icons-material";
import {
    Alert,
    Box,
    Card,
    CardContent,
    CardHeader,
    CircularProgress,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Tooltip,
    Typography,
    useTheme,
} from "@mui/material";
import { currencyFormatter } from "../../Reading/Bill/Cells/Last12Summary";
import Donut from "./Donut";

type Props = {
    isFetching: boolean;
    title: string;
    data: {
        chartData: { series: number[]; labels: string[] };
        cardData: { total: number; collected: number; arrears: number };
    };
};

const compactCurrencyFormatter = Intl.NumberFormat("en-PK", {
    currency: "PKR",
    style: "currency",
    notation: "compact",
});

const DashboardCard = ({ isFetching, title, data }: Props) => {
    const theme = useTheme();
    const { chartData, cardData } = data;

    return (
        <Grid item xs={12}>
            <Card variant="outlined" elevation={0}>
                <CardHeader
                    title={
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                        >
                            {title}

                            {isFetching && (
                                <Box>
                                    <Typography
                                        variant="body2"
                                        sx={{ color: theme.palette.info.light }}
                                        display="flex"
                                        alignItems="center"
                                        gap={theme.spacing(1)}
                                    >
                                        <InfoOutlined fontSize="small" />
                                        Please wait, this may take a while...
                                    </Typography>
                                </Box>
                            )}
                        </Box>
                    }
                    titleTypographyProps={{ color: theme.palette.primary.main }}
                />
                <CardContent>
                    <Grid container justifyContent="space-between">
                        <Grid
                            item
                            xs={12}
                            md={6}
                            display="flex"
                            flexDirection="column"
                        >
                            <Table size="small">
                                <TableBody>
                                    {Object.entries(cardData).map(
                                        ([key, value]: any) => (
                                            <TableRow key={key}>
                                                <TableCell
                                                    sx={{
                                                        textTransform:
                                                            "capitalize",
                                                    }}
                                                >
                                                    {key}
                                                </TableCell>

                                                <TableCell>
                                                    {!isFetching ? (
                                                        <Tooltip
                                                            title={currencyFormatter.format(
                                                                value
                                                            )}
                                                        >
                                                            <Typography fontWeight="medium">
                                                                {compactCurrencyFormatter.format(
                                                                    value
                                                                )}
                                                            </Typography>
                                                        </Tooltip>
                                                    ) : (
                                                        <CircularProgress
                                                            size={12}
                                                        />
                                                    )}
                                                </TableCell>
                                            </TableRow>
                                        )
                                    )}
                                </TableBody>
                            </Table>
                        </Grid>

                        <Grid
                            item
                            xs={12}
                            md={6}
                            textAlign={!isFetching ? "left" : "center"}
                        >
                            {!isFetching ? (
                                <Donut
                                    series={chartData.series}
                                    labels={chartData.labels}
                                />
                            ) : (
                                <CircularProgress />
                            )}
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Grid>
    );
};

export default DashboardCard;
