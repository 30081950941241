import { Add } from "@mui/icons-material";
import {
    Alert,
    Button,
    Card,
    CardContent,
    CardHeader,
    CircularProgress,
    Divider,
    Grid,
    MenuItem,
    Snackbar,
    TextField,
} from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { theme } from "../../../Theme/theme";
import { GET, PATCH, POST } from "../../../Utilities/BaseService";
import SearchableInput from "../../Dashboard/Components/SearchableInput";
import UpdatedSearchableInput from "../../../Utilities/UpdatedSearchableInput";

const getVillages = () => {
    return GET("/region/village/", { limit: 0 });
};

const addVillage = (hamlet: any) => POST("/region/hamlet", hamlet);

const updateHamlet = ({ hamlet, id }: any) => {
    return PATCH("/region/hamlet", hamlet, { id });
};

const AddHamlet = ({ updateMode }: any) => {
    const [id, setId] = useState("");
    const [hamlet, setHamlet] = useState({ name: "", village: "" });
    const [filter, setFilter] = useState({ district: "" });
    const [feedback, setFeedback] = useState();
    const [defaultRegions, setDefaultRegions] = useState({
        district: "",
        village: "",
    });
    const [clearAll, setClearAll] = useState({
        district: false,
        village: false,
    });
    const location = useLocation();
    const navigate = useNavigate();

    const { data } = useQuery(["villages"], getVillages);

    const getHamletById = () => {
        return GET(`/region/hamlet?id=${id}`);
    };

    const { data: hamletById } = useQuery([`hamlet`], getHamletById, {
        enabled: Boolean(id),
        onSuccess(response) {
            if (response.data.rows.length > 0) {
                const _h = response.data.rows[0];

                setHamlet({ name: _h.name, village: _h.village.id });

                setFilter({ district: _h.village.district.id });

                setDefaultRegions({
                    district: _h.village.district.id,
                    village: _h.village.id,
                });
            }
        },
        refetchOnMount: true,
    });

    const handleSubmit = (ev: any) => {
        ev.preventDefault();
        {
            !updateMode
                ? addMutation.mutate(hamlet)
                : updateMutation.mutate({ hamlet, id });
        }
    };

    const addMutation = useMutation(addVillage, {
        onSuccess(res) {
            return setFeedback(res.data.message);
        },
        onError(err: any) {
            return setFeedback(err.response.data.message);
        },
    });

    const updateMutation = useMutation(updateHamlet, {
        onSuccess(res) {
            setFeedback(res.data.message);
        },
        onError(err: any) {
            return setFeedback(err.response.data.message);
        },
    });

    useEffect(() => {
        if (updateMode) {
            const params = new URLSearchParams(location.search);
            const _id = params.get("id");

            if (_id) {
                setId(_id);
            }
        }
    }, [updateMode]);

    useEffect(() => {
        if (filter.district === "all") {
            setClearAll({
                district: true,
                village: true,
            });

            setHamlet({
                ...hamlet,
                village: "",
            });
        }

        if (defaultRegions.district !== filter.district) {
            setClearAll({
                ...clearAll,
                village: true,
            });

            setDefaultRegions({
                ...defaultRegions,
                district: filter.district,
            });
        }

        return () => {
            setClearAll({
                district: false,
                village: false,
            });
        };
    }, [filter.district, hamlet.village]);

    return (
        <>
            <Card
                elevation={0}
                sx={{ bgcolor: theme.palette.common.white, mb: "1rem" }}
            >
                <CardHeader
                    title={updateMode ? "update hamlet" : "add hamlet"}
                    titleTypographyProps={{ color: "gray" }}
                    sx={{
                        textTransform: "capitalize",
                        bgcolor: theme.palette.common.white,
                    }}
                />

                <CardContent>
                    <Divider sx={{ mb: 5 }} />

                    <Grid
                        container
                        component="form"
                        onSubmit={handleSubmit}
                        spacing={2}
                    >
                        <Grid item xs={12} md={6} lg={4}>
                            <TextField
                                name="name"
                                value={hamlet.name}
                                label="Hamlet Name"
                                onChange={(ev) =>
                                    setHamlet({
                                        ...hamlet,
                                        name: ev.target.value,
                                    })
                                }
                                fullWidth
                                type="text"
                                required
                                size="small"
                            />
                        </Grid>

                        <Grid item xs={12} md={6} lg={4}>
                            <UpdatedSearchableInput
                                label="District"
                                api="/region/district"
                                filter={filter}
                                setFilter={setFilter}
                                defaultValue={
                                    updateMode && defaultRegions.district
                                }
                                clearAll={clearAll.district}
                            />
                        </Grid>

                        <Grid item xs={12} md={6} lg={4}>
                            <UpdatedSearchableInput
                                label="Village"
                                api="/region/village"
                                filter={hamlet}
                                setFilter={setHamlet}
                                dep={filter.district}
                                required
                                params={
                                    filter.district && filter.district !== "all"
                                        ? filter
                                        : undefined
                                }
                                defaultValue={
                                    updateMode && defaultRegions.village
                                }
                                clearAll={clearAll.village}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Button
                                startIcon={<Add />}
                                endIcon={
                                    addMutation.isLoading ? (
                                        <CircularProgress
                                            color="secondary"
                                            size="1rem"
                                        />
                                    ) : (
                                        ""
                                    )
                                }
                                color="secondary"
                                variant="outlined"
                                size="small"
                                type="submit"
                            >
                                {updateMode ? "update" : "add"} hamlet
                            </Button>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>

            {addMutation.isSuccess || updateMutation.isSuccess ? (
                <Alert severity="success">{feedback}.</Alert>
            ) : addMutation.isError || updateMutation.isError ? (
                <Alert severity="error">{feedback}.</Alert>
            ) : null}
        </>
    );
};

export default AddHamlet;
