import {
    KeyboardArrowDownOutlined,
    KeyboardArrowUpOutlined,
    RestoreOutlined,
} from "@mui/icons-material";
import {
    Box,
    Card,
    Chip,
    CircularProgress,
    IconButton,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    Typography,
} from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useEffect, useMemo, useState } from "react";
import { POST } from "../../../Utilities/BaseService";

export const Reorder = ({
    setting,
    labels,
    name,
    updatedSettings,
    setUpdatedSettings,
}: any) => {
    const [order, setOrder] = useState<any>({});
    // const [feedback, setFeedback] = useState({ loading: false, show: false });

    const queryClient = useQueryClient();

    useEffect(() => {
        if (setting) {
            const _order = JSON.parse(setting.value);

            // console.log(setting);
            if (_order.length > 0) {
                setOrder(
                    Object.fromEntries(
                        _order.map((item: any, idx: number) => [idx, item])
                    )
                );
            }
        }
    }, [setting]);

    const handleMoveUp = (idx: any) => {
        const prevPos = parseInt(idx) - 1;
        if (prevPos < 0) return;
        const objAtNextPos = order[prevPos];

        const newOrder = {
            ...order,
            [prevPos]: order[idx],
            [idx]: objAtNextPos,
        };

        setOrder(newOrder);
        setUpdatedSettings({
            ...updatedSettings,
            [setting.key]: JSON.stringify(Object.values(newOrder)),
        });
    };

    const handleMoveDown = (idx: any) => {
        const nextPos = parseInt(idx) + 1;
        if (nextPos >= Object.keys(order).length) return;
        const objAtNextPos = order[nextPos];

        const newOrder = {
            ...order,
            [nextPos]: order[idx],
            [idx]: objAtNextPos,
        };

        setOrder(newOrder);
        setUpdatedSettings({
            ...updatedSettings,
            [setting.key]: JSON.stringify(Object.values(newOrder)),
        });
    };

    const isGlobal = useMemo(() => {
        if (setting) {
            const regions = Object.fromEntries(
                Object.entries(setting)?.filter(
                    ([k, v]) =>
                        k === "district" || k === "village" || k === "hamlet"
                )
            );

            if (regions.district) return false;
        }

        return true;
    }, [setting]);

    const restoreMut = useMutation(
        (sett: any) => POST("/settings/add-single", sett),
        {
            onSuccess(res) {
                window.location.reload();
            },
        }
    );

    return (
        <Card
            elevation={0}
            sx={{
                bgcolor: "common.white",
                p: 2,
                mb: 1,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
            }}
        >
            <Box display="flex" alignItems="center" gap={1}>
                <Typography display="flex" alignItems="center" gap={1}>
                    {name}
                </Typography>

                {!setting?.IsDefault && (
                    <Chip
                        label={
                            restoreMut.isLoading ? "Restoring..." : "Restore"
                        }
                        size="small"
                        color="info"
                        variant="outlined"
                        deleteIcon={
                            restoreMut.isLoading ? (
                                <CircularProgress size={15} />
                            ) : (
                                <RestoreOutlined />
                            )
                        }
                        onDelete={() =>
                            restoreMut.mutate({
                                id: setting.id,
                                key: setting.key,
                                isGlobal,
                            })
                        }
                    />
                )}
            </Box>

            <List sx={{ minWidth: "30ch" }}>
                {Object.entries(order).map((entry: any[], idx) => (
                    <ListItem key={idx}>
                        <ListItemText primary={labels[entry[1]]} />
                        <ListItemSecondaryAction>
                            <IconButton
                                size="small"
                                onClick={() => handleMoveUp(entry[0])}
                            >
                                <KeyboardArrowUpOutlined />
                            </IconButton>

                            <IconButton
                                size="small"
                                onClick={() => handleMoveDown(entry[0])}
                            >
                                <KeyboardArrowDownOutlined />
                            </IconButton>
                        </ListItemSecondaryAction>
                    </ListItem>
                ))}
            </List>
        </Card>
    );
};
