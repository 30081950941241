import { AddOutlined, Remove } from "@mui/icons-material";
import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    TextField,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import UpdatedSearchableInput from "../../../../Utilities/UpdatedSearchableInput";
import { ReadingCtx } from "../AddReadings";

const initialCharge = { head: "", amount: "", editable: false };

export const AddAcs = () => {
    const { activeMeter, setActiveMeter, readingsData, setReadingsData, mode } =
        useContext(ReadingCtx);
    const [charges, setCharges] = useState<any>({ 0: initialCharge });

    const close = () => setActiveMeter(null);
    const clearState = () => setCharges({ 0: initialCharge });

    const handleAddCharge = () => {
        const lastIndex = Object.keys(charges)[Object.keys(charges).length - 1];

        setCharges({ ...charges, [parseInt(lastIndex) + 1]: initialCharge });
    };

    const handleRemoveCharge = (currIndex: number | string) => {
        const targetCharge = Object.fromEntries(
            Object.entries(charges).filter(([k, v]) => k != currIndex)
        );

        setCharges(targetCharge);
    };

    useEffect(() => {
        if (activeMeter && mode) {
            console.log(readingsData[activeMeter].additionalCharges);

            setCharges(
                readingsData[activeMeter].additionalCharges.reduce(
                    (pre: any, curr: any, idx: number) => ({
                        ...pre,
                        [idx]: {
                            head: curr.head?.id ?? curr.head,
                            amount:
                                curr.amount ??
                                (curr.head.editable
                                    ? curr.amount
                                    : curr.head.amount),
                            editable: curr.head.editable ?? curr.editable,
                        },
                    }),
                    {}
                )
            );
        }
    }, [activeMeter, mode]);

    useEffect(() => {
        if (activeMeter) {
            const isAcsEmpty =
                readingsData[activeMeter].additionalCharges.length == 0;

            if (isAcsEmpty) {
                console.log("acs mounted");

                setCharges({ 0: initialCharge });
            }
        }
    }, [activeMeter]);

    return (
        <Dialog
            open={Boolean(activeMeter)}
            fullWidth
            onClose={() => {
                close();
                clearState();
            }}
        >
            <DialogTitle>Add additional charges</DialogTitle>

            <DialogContent>
                {Object.entries(charges).map(([key, ch]: any) => (
                    <Grid container key={key} spacing={2} py={1}>
                        <Grid item flex={1}>
                            <UpdatedSearchableInput
                                api="/payment/head"
                                label="Payment Head"
                                _name={key}
                                filter={charges}
                                setFilter={setCharges}
                                optionsPreprocessor={(opt: any) => opt}
                                optionsFilter={(opts: any[]) =>
                                    opts.filter(
                                        (opt) => opt.targetType === "reading"
                                    )
                                }
                                getFullRecord
                                defaultValue={ch.head}
                            />
                        </Grid>

                        <Grid item xs={4}>
                            <TextField
                                size="small"
                                value={ch.amount}
                                disabled={!ch.editable}
                                onChange={(ev) =>
                                    setCharges({
                                        ...charges,
                                        [key]: {
                                            ...charges[key],
                                            amount: ev.target.value,
                                        },
                                    })
                                }
                            />
                        </Grid>

                        <Grid item>
                            {key == "0" ? (
                                <IconButton
                                    size="small"
                                    color="success"
                                    onClick={handleAddCharge}
                                >
                                    <AddOutlined />
                                </IconButton>
                            ) : (
                                <IconButton
                                    size="small"
                                    color="error"
                                    onClick={() => handleRemoveCharge(key)}
                                >
                                    <Remove />
                                </IconButton>
                            )}
                        </Grid>
                    </Grid>
                ))}

                <Button
                    variant="contained"
                    sx={{ mt: 2 }}
                    disableElevation
                    onClick={
                        activeMeter
                            ? () =>
                                  setReadingsData({
                                      ...readingsData,
                                      [activeMeter]: {
                                          ...readingsData[activeMeter],
                                          select: true,
                                          additionalCharges: Object.values(
                                              charges
                                          ).map((ch: any) => ({
                                              amount: ch.amount,
                                              editable: ch.editable,
                                              head: ch.id ?? ch.head,
                                          })),
                                      },
                                  })
                            : undefined
                    }
                >
                    save charges
                </Button>
            </DialogContent>
        </Dialog>
    );
};
