import { Visibility } from "@mui/icons-material";
import { Button } from "@mui/material";
import { Dispatch, SetStateAction } from "react";

type Props = {
    label?: string;
    load: boolean;
    setLoad: Dispatch<SetStateAction<boolean>>;
    fullWidth?: boolean;
};

const LoadViewButton = ({
    label = "load view",
    load,
    setLoad,
    fullWidth = false,
}: Props) => {
    return (
        <Button
            color="secondary"
            variant={load ? "outlined" : "contained"}
            startIcon={<Visibility />}
            disableElevation
            onClick={() => setLoad(true)}
            fullWidth={fullWidth}
        >
            {label}
        </Button>
    );
};

export default LoadViewButton;
