import { AddOutlined, ExpandMore } from "@mui/icons-material";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Alert,
    Box,
    Button,
    Chip,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    LinearProgress,
    MenuItem,
    Table,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
    useTheme,
} from "@mui/material";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Fragment, useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../Utilities/AppContext";
import { POST } from "../../Utilities/BaseService";
import { Print } from "../../Utilities/Print";
import { Footer } from "../Header/Footer";
import { Header } from "../Header/Header";
import { getReportTemplates } from "../Payment/Payment";
import { monthFormatter } from "../Reading/Bill/SingleBill";
import { BlindPagination } from "../Table/Components/BlindPagination";
import ReportBody from "./Components/ReportBody";
import ReportHeader from "./Components/ReportHeader";
import ReportsFilter from "./Components/ReportsFilter";

type Filter = {
    cashSourceOpts: {};
    dtOpts: {
        start?: string;
        end?: string;
        reference?: string;
    };
    customerFilter: {
        district?: number | number[];
        village?: number | number[];
        hamlet?: number | number[];
        meterNo?: string;
    };
    pagination: {
        page?: number;
        limit?: number;
    };
};

type ReportDetails = {
    date?: any;
    lineRent?: string;
    connectionCharges?: string;
    units?: string;
    amountInDueDate?: string;
    amountAfterDueDate?: string;
    additionalCharges?: string;
    totalSurcharge?: string;
    payedSurcharge?: string;
    remainingAmount?: string;
    paidAmount?: string;
};
type CustomerDetails = any;

type Reading = {
    [key: number]: {
        date: string;
        lineRent: number;
        totalSurcharge: number;
        payedSurcharge: number;
        units: number;
        amountInDueDate: number;
        amountAfterDueDate: number;
        connectionCharges: number;
        additionalCharges: number;
        remainingAmount: number;
        paidAmount: number;
    }[];
};

const getCustomerReport = ({
    cashSourceOpts,
    dtOpts,
    customerFilter,
    pagination,
}: any) => {
    return POST("/reports/customer-report", {
        cashSourceOpts,
        dtOpts,
        customerFilter,
        pagination,
    });
};

const addReportTemplate = (template: {
    key: string;
    value: string;
    user: number;
    category: string;
}) => POST("/preferences", template);

const initialReportColumns = [
    "lineRent",
    "units",
    "additionalCharges",
    "amountInDueDate",
    "amountAfterDueDate",
    "paidAmount",
    "remainingAmount",
];

const getRemainingAmount = (reading: any, _referenceDate: any) => {
    if (reading.completed) {
        return 0;
    }

    let totalAmount = reading.totalAmount;
    let remainingAmount = 0;

    const referenceDate = _referenceDate
        ? Date.parse(_referenceDate)
        : new Date().getTime();

    const collectedAmount = reading.collections.reduce(
        (prev: any, curr: any) => curr.amount + curr.adjustment + prev,
        0
    );

    const sortedCollections: any[] = reading.collections.sort(
        (a: any, b: any) =>
            new Date(a.collectionDate).getTime() -
            new Date(b.collectionDate).getTime()
    );

    const isCollectionMade = Boolean(sortedCollections.length);
    const isFirstCollectionAfterDueDate = isCollectionMade
        ? new Date(sortedCollections[0].collectionDate) >
          new Date(reading.dueDate)
        : false;

    if (!isCollectionMade) {
        const surchargeApplies =
            new Date(reading.dueDate).getTime() < referenceDate;
        if (surchargeApplies) {
            totalAmount += reading.applicableSurcharge + reading.payedSurcharge;
        }
    } else if (isFirstCollectionAfterDueDate) {
        totalAmount += reading.applicableSurcharge + reading.payedSurcharge;
    }

    remainingAmount = totalAmount - collectedAmount;

    return remainingAmount;
};

const amountAfterDueDate = (reading: any) => {
    return (
        reading.totalAmount +
        reading.applicableSurcharge +
        reading.payedSurcharge
    );
};

const CustomerReport = () => {
    const [expanded, setExpanded] = useState(true);
    const [printMode, setPrintMode] = useState(false);
    const [openAddReportTemplate, setOpenAddReportTemplate] = useState(false);
    const [filter, setFilter] = useState<Filter>({
        customerFilter: {},
        cashSourceOpts: {},
        dtOpts: { reference: new Date().toISOString().split("T")[0] },
        pagination: { page: 0, limit: 10 },
    });
    const [customerDetails, setCustomerDetails] = useState<CustomerDetails>({
        name: "Name",
        cnic: "CNIC",
        meterNo: "Meter No.",
        contact: "Contact",
        property: "Property",
        fatherName: "Father name",
        propertyAddress: "Property Address",
    });
    const [selectedCustomerDetails, setSelectedCustomerDetails] = useState([
        "name",
        "meterNo",
    ]);
    const [reportDetails, setReportDetails] = useState<ReportDetails>({
        lineRent: "Line Rent",
        units: "Units",
        additionalCharges: "Additional Charges",
        amountInDueDate: "Amount Within Due Date",
        amountAfterDueDate: "Amount After Due Date",
        paidAmount: "Paid Amount",
        remainingAmount: "Remaining Amount",
        totalSurcharge: "Total Surcharge",
        payedSurcharge: "Paid Surcharge",
        connectionCharges: "Connection Charges",
    });
    const [selectedReportDetails, setSelectedReportDetails] =
        useState(initialReportColumns);
    const [newReportTemplate, setNewReportTemplate] = useState({
        key: "",
        value: [],
    });
    const [reportTemplateFeedback, setReportTemplateFeedback] = useState({
        show: false,
        loading: false,
        message: "",
        status: "",
    });
    const [selectedReportTemplate, setSelectedReportTemplate] = useState<{
        name: string;
        columns: string[];
    }>({
        name: "",
        columns: [],
    });

    const [readings, setReadings] = useState<Reading>();

    const theme = useTheme();
    const printRef = useRef<HTMLDivElement | null>(null);
    const { user } = useContext(AppContext);

    const queryClient = useQueryClient();

    const handleCustomerReportSuccess = (res: any) => {
        const reports: any[] = res.data.rows;

        if (reports.length > 0) {
            const targetReadings = reports.reduce(
                (prev: any, curr: any) => ({
                    ...prev,
                    [curr.id]: curr.readings.map((reading: any) => ({
                        date: monthFormatter.format(
                            new Date(reading.year, reading.month - 1)
                        ),
                        lineRent: reading.lineRent,

                        totalSurcharge:
                            reading.applicableSurcharge +
                            reading.payedSurcharge,

                        payedSurcharge: reading.payedSurcharge,

                        units: reading.currentUnits - reading.prevUnits,

                        collections: reading.collections,

                        amountInDueDate: reading.totalAmount,

                        amountAfterDueDate: amountAfterDueDate(reading),

                        connectionCharges: reading.connectionChargesIncluded
                            ? curr.connectionCharges ?? 0
                            : 0,

                        additionalCharges: reading.additionalCharges.reduce(
                            (acc: any, curr: any) => acc + curr.amount,
                            0
                        ),

                        remainingAmount: getRemainingAmount(
                            reading,
                            filter.dtOpts.reference
                        ),

                        paidAmount:
                            reading.totalAmount -
                            (reading.totalAmount -
                                reading.collections
                                    .map((r: any) => r.amount + r.adjustment)
                                    .reduce(
                                        (prev: any, curr: any) => prev + curr,
                                        0
                                    )),
                    })),
                }),
                {}
            );

            setReadings(targetReadings);
        }
    };

    const { data, isFetching, isSuccess } = useQuery(
        ["customer-report", filter],
        () => getCustomerReport(filter),
        {
            enabled: Boolean(filter.customerFilter.meterNo),
            onSuccess: handleCustomerReportSuccess,
        }
    );

    const { data: reportTemplates } = useQuery(["report-templates"], () =>
        getReportTemplates(user.id, "Customer Report")
    );

    const handleAddReportTemplate = () => {
        setReportTemplateFeedback({
            ...reportTemplateFeedback,
            loading: true,
        });
        reportTemplateMutation.mutate({
            ...newReportTemplate,
            value: JSON.stringify(newReportTemplate.value),
            user: user.id,
            category: "Customer Report",
        });
    };

    const reportTemplateMutation = useMutation(addReportTemplate, {
        onSuccess(res) {
            setReportTemplateFeedback({
                show: true,
                loading: false,
                message: res.data.message,
                status: "success",
            });
        },

        onError(err) {
            setReportTemplateFeedback({
                show: true,
                loading: false,
                message: err as string,
                status: "error",
            });
        },
    });

    useEffect(() => {
        if (selectedReportTemplate?.name) {
            console.log(selectedReportTemplate.columns);
            setSelectedReportDetails(selectedReportTemplate.columns);
        } else {
            setSelectedReportDetails(initialReportColumns);
        }
    }, [selectedReportTemplate]);

    return (
        <Box>
            <Accordion
                variant="outlined"
                elevation={0}
                sx={{ bgcolor: theme.palette.common.white }}
                expanded={expanded}
                onChange={() => setExpanded(!expanded)}
            >
                <AccordionSummary expandIcon={<ExpandMore />}>
                    <Typography variant="h5" color="GrayText">
                        Customer Report
                    </Typography>
                </AccordionSummary>

                <AccordionDetails>
                    <ReportsFilter
                        filter={filter}
                        setFilter={setFilter}
                        customerDetails={customerDetails}
                        setCustomerDetails={setCustomerDetails}
                        selectedCustomerDetails={selectedCustomerDetails}
                        setSelectedCustomerDetails={setSelectedCustomerDetails}
                        reportDetails={reportDetails}
                        setReportDetails={setReportDetails}
                        selectedReportDetails={selectedReportDetails}
                        setSelectedReportDetails={setSelectedReportDetails}
                    />
                </AccordionDetails>
            </Accordion>
            <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                mb={1.5}
            >
                {!Boolean(filter.customerFilter.meterNo) && (
                    <Grid item xs={12}>
                        <Alert color="info" severity="info">
                            Please enter a meter number to generate customer
                            report.
                        </Alert>
                    </Grid>
                )}

                {!Boolean(data?.data.rows.length) && isSuccess && (
                    <Grid item xs={12}>
                        <Alert color="info" severity="info">
                            Found 0 records.
                        </Alert>
                    </Grid>
                )}

                <Grid item xs={12} my={2}>
                    <TextField
                        label="Reference Date"
                        type="date"
                        size="small"
                        name="reference"
                        value={filter.dtOpts.reference}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) =>
                            setFilter({
                                ...filter,
                                dtOpts: {
                                    ...filter.dtOpts,
                                    reference: e.target.value,
                                },
                            })
                        }
                        fullWidth
                    />
                </Grid>

                <Grid item>
                    <Print
                        componentRef={printRef}
                        setPrintMode={setPrintMode}
                    />
                </Grid>

                <Grid
                    item
                    container
                    flex={1}
                    justifyContent="center"
                    spacing={1}
                >
                    {reportTemplates?.data.rows.length > 0 &&
                        reportTemplates?.data.rows.map(
                            (report: any, index: number) => (
                                <Grid item key={index}>
                                    <Chip
                                        variant={
                                            selectedReportTemplate.name ===
                                            report.key
                                                ? "filled"
                                                : "outlined"
                                        }
                                        color="secondary"
                                        onDelete={
                                            selectedReportTemplate.name ===
                                            report.key
                                                ? () =>
                                                      setSelectedReportTemplate(
                                                          {
                                                              name: "",
                                                              columns: [],
                                                          }
                                                      )
                                                : undefined
                                        }
                                        size="small"
                                        label={report.key}
                                        onClick={() =>
                                            setSelectedReportTemplate({
                                                name: report.key,
                                                columns: JSON.parse(
                                                    report.value
                                                ),
                                            })
                                        }
                                    />
                                </Grid>
                            )
                        )}

                    <Grid item>
                        <IconButton
                            size="small"
                            onClick={() => setOpenAddReportTemplate(true)}
                            title="Add new report template"
                        >
                            <AddOutlined fontSize="small" />
                        </IconButton>

                        <Dialog
                            open={openAddReportTemplate}
                            onClose={() => {
                                setOpenAddReportTemplate(false);
                                setNewReportTemplate({
                                    key: "",
                                    value: [],
                                });
                                setReportTemplateFeedback({
                                    ...reportTemplateFeedback,
                                    show: false,
                                    message: "",
                                });

                                queryClient.invalidateQueries([
                                    "report-templates",
                                ]);
                            }}
                            fullWidth
                        >
                            <DialogTitle>Add Report Template</DialogTitle>

                            <DialogContent>
                                <Grid container spacing={1}>
                                    {reportTemplateFeedback.show && (
                                        <Grid item xs={12} mb={2}>
                                            <Alert
                                                severity={
                                                    reportTemplateFeedback.status as any
                                                }
                                            >
                                                {reportTemplateFeedback.message}
                                            </Alert>
                                        </Grid>
                                    )}

                                    <Grid item xs={12} mt={1}>
                                        <TextField
                                            label="Report Name"
                                            size="small"
                                            fullWidth
                                            value={newReportTemplate.key}
                                            onChange={(e) =>
                                                setNewReportTemplate({
                                                    ...newReportTemplate,
                                                    key: e.target.value,
                                                })
                                            }
                                            required
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <TextField
                                            select
                                            required
                                            SelectProps={{ multiple: true }}
                                            size="small"
                                            fullWidth
                                            label="Columns"
                                            value={newReportTemplate.value}
                                            onChange={(e) =>
                                                setNewReportTemplate({
                                                    ...newReportTemplate,
                                                    value: e.target
                                                        .value as any,
                                                })
                                            }
                                        >
                                            {Object.entries(reportDetails).map(
                                                ([k, v]: any[]) => (
                                                    <MenuItem key={k} value={k}>
                                                        {v}
                                                    </MenuItem>
                                                )
                                            )}
                                        </TextField>
                                    </Grid>

                                    <Grid item mt={1}>
                                        <Button
                                            variant="contained"
                                            disableElevation
                                            disabled={
                                                newReportTemplate.key.length ==
                                                    0 ||
                                                newReportTemplate.value
                                                    .length == 0 ||
                                                reportTemplateFeedback.loading
                                            }
                                            onClick={handleAddReportTemplate}
                                        >
                                            {reportTemplateFeedback.loading
                                                ? "saving "
                                                : "save "}
                                            template
                                        </Button>
                                    </Grid>
                                </Grid>
                            </DialogContent>
                        </Dialog>
                    </Grid>
                </Grid>

                <Grid item>
                    <BlindPagination
                        pagination={filter.pagination as any}
                        setPagination={(value) =>
                            setFilter({ ...filter, pagination: value }) as any
                        }
                    />
                </Grid>
            </Grid>
            {isFetching && <LinearProgress sx={{ my: 1 }} />}

            <TableContainer
                ref={printRef}
                sx={{ p: printMode ? 2 : undefined }}
            >
                {data?.data.rows.map((report: any, idx: number) => (
                    <Fragment key={report.id}>
                        {readings && report && (
                            <Table
                                key={report.id}
                                padding="none"
                                sx={{ mb: 5 }}
                            >
                                <TableHead>
                                    <TableRow>
                                        <TableCell
                                            colSpan={
                                                selectedReportDetails.length + 1
                                            }
                                        >
                                            {printMode && (
                                                <Header
                                                    name={
                                                        selectedReportTemplate.name ||
                                                        "Customer Report"
                                                    }
                                                />
                                            )}

                                            <ReportHeader
                                                printMode={printMode}
                                                customerData={{
                                                    meterNo: report.meterNo,
                                                    name: report.property
                                                        .customer.name,
                                                    fatherName:
                                                        report.property.customer
                                                            .fatherName,
                                                    cnic: report.property
                                                        .customer.cnic,
                                                    contact:
                                                        report.property.customer
                                                            .mobileNo,
                                                    property:
                                                        report.property.name,
                                                    propertyAddress:
                                                        report.property.address,
                                                }}
                                                customerDetails={
                                                    customerDetails
                                                }
                                                selectedCustomerDetails={
                                                    selectedCustomerDetails
                                                }
                                            />
                                        </TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell
                                            sx={{
                                                px: 1,
                                                py: 2,
                                                fontWeight: "bolder",
                                            }}
                                        >
                                            Month
                                        </TableCell>
                                        {selectedReportDetails.map(
                                            (item: any, idx) => (
                                                <TableCell
                                                    key={idx}
                                                    sx={{
                                                        px: 1,
                                                        py: 2,
                                                        fontWeight: "bolder",
                                                    }}
                                                >
                                                    {
                                                        reportDetails[
                                                            item as keyof ReportDetails
                                                        ]
                                                    }
                                                </TableCell>
                                            )
                                        )}
                                    </TableRow>
                                </TableHead>

                                <ReportBody
                                    reportData={readings[report.id]}
                                    reportDetails={reportDetails}
                                    selectedReportDetails={
                                        selectedReportDetails
                                    }
                                    calculationStrategy={JSON.parse(
                                        report.priceType.surcharge
                                            .calculationStrategy
                                    )}
                                    printMode={printMode}
                                />

                                {printMode && (
                                    <Footer
                                        span={selectedReportDetails.length + 1}
                                    />
                                )}
                            </Table>
                        )}
                    </Fragment>
                ))}
            </TableContainer>
        </Box>
    );
};

export default CustomerReport;
