import { Grid, TextField } from "@mui/material";
import SearchableInput from "../Dashboard/Components/SearchableInput";

export const LogFilter = ({ filter, setFilter }: any) => {
    return (
        <>
            <Grid item xs={12} md={6}>
                {/* <MultiPermissionAuthorize ops={["READ DATE"]}> */}
                <TextField
                    size="small"
                    name="date"
                    type="date"
                    label="Day"
                    fullWidth
                    value={filter.date}
                    InputLabelProps={{ shrink: true }}
                    onChange={(ev) =>
                        setFilter({ ...filter, date: ev.target.value })
                    }
                />
                {/* </MultiPermissionAuthorize> */}
            </Grid>

            <Grid item xs={12} md={6}>
                <SearchableInput
                    api="/user"
                    label="Operator"
                    filter={filter}
                    setFilter={setFilter}
                />
            </Grid>
        </>
    );
};
