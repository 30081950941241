import { Logout, RefreshOutlined } from "@mui/icons-material";
import Menu from "@mui/icons-material/Menu";
import {
    Avatar,
    Button,
    Divider,
    Hidden,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Toolbar,
} from "@mui/material";
import MuiAppBar from "@mui/material/AppBar";
import { styled } from "@mui/material/styles";
import { useContext, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AppContext } from "../../Utilities/AppContext";
import { GET, POST } from "../../Utilities/BaseService";
import { S3Service } from "../../Utilities/S3Service";
import { awsDirStruct } from "../../Utilities/constants";
import { appVersion } from "../../constants";

export const Topbar = ({ sidebarOpen, setSidebarOpen }: any) => {
    const [targetAppVersion, setTargetAppVersion] = useState<number | null>(
        null
    );
    const [info, setInfo] = useState<any>([]);
    const location = useLocation();
    const { user, store, setStore } = useContext(AppContext);
    let navigate = useNavigate();
    const [loginform, setLoginForm] = useState<any>({
        username: "",
        password: "",
    });

    const [logoSrc, setLogoSrc] = useState("");

    const drawerWidth = useMemo(() => 240, []);
    const AppBar = useMemo(
        () =>
            styled(MuiAppBar, {
                shouldForwardProp: (prop) => prop !== "open",
            })(({ theme }) => ({
                transition: theme.transitions.create(["margin", "width"], {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                ...(sidebarOpen && {
                    width: `calc(100% - ${drawerWidth}px)`,
                    marginLeft: `${drawerWidth}px`,
                    transition: theme.transitions.create(["margin", "width"], {
                        easing: theme.transitions.easing.easeOut,
                        duration: theme.transitions.duration.enteringScreen,
                    }),
                }),
            })),
        [sidebarOpen]
    );

    const handleLogout = async (ev: any) => {
        ev.preventDefault();
        localStorage.removeItem("sessionExpiry");
        localStorage.removeItem("user");
        const res = await POST("/user/logout");

        if (res) {
            navigate("/login");
            window.location.replace("/login");
        }
    };

    const getLogo = async () => {
        if (store.logoURL) {
            setLogoSrc(store.logoURL);
        } else {
            try {
                const response = await GET("/settings/logos", {
                    default: true,
                });
                const firstDefault = response.data.rows[0];
                const [{ url }, err] = await S3Service.getS3ByKey(
                    `${awsDirStruct.logos}/${firstDefault.id}`
                );

                const _response = await fetch(url);
                const blob = await _response.blob();
                const blobURL = URL.createObjectURL(blob);
                setLogoSrc(blobURL);
                setStore({ ...store, logoURL: blobURL });
            } catch (err) {}
        }
    };

    const handleUpdateApp = async () => {
        if (appVersion !== targetAppVersion) {
            const cacheKeys = await window.caches.keys();
            Promise.all(cacheKeys.map((cn) => window.caches.delete(cn))).then(
                () => {
                    window.location.reload();
                }
            );
        }
    };

    const getVersion = async () => {
        const res = await GET("/version");

        if (res.data) {
            setTargetAppVersion(res.data.version);
        }
    };

    useEffect(() => {
        getLogo();
        getVersion();
    }, []);

    return location.pathname !== "/no-internet" ? (
        <AppBar
            position="fixed"
            sx={{ bgcolor: "background.default" }}
            elevation={0}
        >
            <Toolbar
                sx={{ justifyContent: "space-between", alignItems: "center" }}
            >
                <IconButton
                    color="primary"
                    onClick={() => setSidebarOpen((open: boolean) => !open)}
                    edge="start"
                    sx={{ mr: 2, ...(sidebarOpen && { display: "none" }) }}
                >
                    <Menu style={{ color: "#808080" }} />
                </IconButton>

                <a href="/">
                    <img src={logoSrc} width="50" />
                </a>

                <Hidden mdDown>
                    <List disablePadding>
                        <ListItem disablePadding>
                            <ListItemAvatar>
                                <Avatar>{user?.name[0]}</Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={user?.name} />
                        </ListItem>
                    </List>
                </Hidden>

                {appVersion != targetAppVersion && (
                    <IconButton onClick={handleUpdateApp}>
                        <RefreshOutlined />
                    </IconButton>
                )}

                <Button
                    startIcon={<Logout />}
                    variant="outlined"
                    disableElevation
                    color="secondary"
                    onClick={handleLogout}
                >
                    logout
                </Button>
            </Toolbar>
            <Divider />
        </AppBar>
    ) : null;
};
