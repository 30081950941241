import { useTheme } from "@mui/material";
import Chart from "react-apexcharts";

type Props = {
    series: number[];
    labels: string[];
};

const Donut = ({ series, labels }: Props) => {
    const theme = useTheme();

    return (
        <Chart
            height="200"
            options={{
                labels: labels,
                plotOptions: { pie: { donut: { size: "45px" } } },
                colors: [
                    theme.palette.secondary.main,
                    theme.palette.primary.light,
                    theme.palette.primary.dark,
                    theme.palette.primary.main,
                ],
            }}
            series={series}
            type="donut"
        />
    );
};

export default Donut;
