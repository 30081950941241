import { Add } from "@mui/icons-material";
import {
    Alert,
    Button,
    Card,
    CardContent,
    CardHeader,
    CircularProgress,
    Divider,
    Grid,
    useTheme,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { FormEvent, useEffect, useState } from "react";
import { SlipService } from "../../../Services/SlipService";
import UpdatedSearchableInput from "../../../Utilities/UpdatedSearchableInput";
import SlipCustomFields from "../SlipCustomFields";

type Props = { updateMode?: boolean };

type Customer = {
    name: string;
    fatherName: string;
    cnic: string;
    mobileNo: string;
    [key: string]: string;
};
type Property = { id: number; customer: Customer };
type Region = { id: number; name: string };
type Meter = {
    id: number;
    createdAt: string;
    status: "active" | "inactive" | "replaced";
    initialReading: number;
    lineRentEditable: boolean;
    connectionCharges: number;
    connectionChargesApplied: boolean;
    connectionDate: string;
    credit: number;
    prevMeter: Meter | null;
    district: Region;
    village: Region;
    hamlet: Region;
    powerstation: Region;
    meterNo: string;
    property: Property;
};

type ConnectionData = { meter: Meter | null };

export type SlipField = { key: string; label: string; checked: boolean };

const initialConnectionData: ConnectionData = { meter: null };

const initialCustomFields: { [key: string]: SlipField[] } = {
    customer: [
        { key: "name", label: "Name", checked: true },
        { key: "fatherName", label: "Father Name", checked: false },
        { key: "cnic", label: "CNIC", checked: false },
        { key: "mobileNo", label: "Mobile No", checked: false },
    ],

    meter: [
        { key: "meterNo", label: "Meter No.", checked: false },
        { key: "connectionDate", label: "Connection Date", checked: false },
        { key: "connectionCharges", label: "ConnectionCharges", checked: true },
        { key: "status", label: "Status", checked: false },
        { key: "distName", label: "District", checked: false },
        { key: "villName", label: "Village", checked: false },
        { key: "hamName", label: "Hamlet", checked: false },
        { key: "pstName", label: "Powerstation", checked: false },
    ],
};

const AddConnectionSlip = ({ updateMode = false }: Props) => {
    const [connectionData, setConnectionData] = useState(initialConnectionData);
    const [customFields, setCustomFields] = useState(initialCustomFields);
    const [slipData, setSlipData] = useState("");
    const [feedback, setFeedback] = useState("");

    const theme = useTheme();

    const addSlips = useMutation(SlipService.getInstance().addSlip, {
        onSuccess(res: any) {
            setFeedback(res.data.message);
        },

        onError(error: { message: string }) {
            setFeedback(error.message);
        },
    });

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        addSlips.mutate({
            target: connectionData.meter?.meterNo!,
            type: "connection",
            data: slipData,
        });
    };

    useEffect(() => {
        if (connectionData.meter?.id) {
            const customerInfo = Object.values(customFields["customer"])
                .filter((cf) => cf.checked)
                .reduce(
                    (prev, curr) => ({
                        ...prev,
                        [curr.label]:
                            connectionData.meter?.property.customer[curr.key],
                    }),
                    {}
                );
            const meterInfo = Object.values(customFields["meter"])
                .filter((cf) => cf.checked)
                .reduce(
                    (prev, curr) => ({
                        ...prev,
                        [curr.label]: connectionData.meter?.id
                            ? connectionData.meter[curr.key as keyof Meter]
                            : undefined,
                    }),
                    {}
                );

            setSlipData(() =>
                JSON.stringify({ ...customerInfo, ...meterInfo })
            );
        }
    }, [customFields, connectionData.meter]);

    return (
        <>
            <Card
                elevation={0}
                sx={{ bgcolor: theme.palette.common.white, mb: "1rem" }}
            >
                <CardHeader
                    title="Generate Connection Slip"
                    sx={{
                        textTransform: "capitalize",
                        bgcolor: theme.palette.common.white,
                    }}
                />
                <CardContent>
                    <Divider sx={{ mb: 5 }} />

                    <Grid
                        container
                        component="form"
                        onSubmit={handleSubmit}
                        spacing={2}
                    >
                        <Grid item flex={1}>
                            <UpdatedSearchableInput
                                label="Meter"
                                _name="meter"
                                api="/customer/meter"
                                filter={connectionData}
                                setFilter={setConnectionData}
                                optionsPreprocessor={(opt) => ({
                                    ...opt,
                                    name: opt.meterNo,
                                    distName: opt.district.name,
                                    villName: opt.village.name,
                                    hamName: opt.hamlet.name,
                                    pstName: opt.powerstation.name,
                                })}
                                getFullRecord
                            />
                        </Grid>

                        <Grid item>
                            <Button
                                startIcon={<Add />}
                                endIcon={
                                    addSlips.isLoading ? (
                                        <CircularProgress
                                            color="secondary"
                                            size="1rem"
                                        />
                                    ) : (
                                        ""
                                    )
                                }
                                color="secondary"
                                variant="outlined"
                                sx={{ height: "100%" }}
                                type="submit"
                                disabled={
                                    addSlips.isLoading ||
                                    !connectionData.meter?.id
                                }
                            >
                                {addSlips.isLoading
                                    ? "generating slip..."
                                    : "generate slips"}
                            </Button>
                        </Grid>

                        <Grid item xs={12}>
                            {connectionData.meter &&
                                connectionData.meter !== ("all" as any) && (
                                    <SlipCustomFields
                                        fields={customFields}
                                        setFields={setCustomFields}
                                        categories={{
                                            customer: "Customer Info",
                                            meter: "Meter Info",
                                        }}
                                    />
                                )}
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>

            {addSlips.isSuccess /* || updateMutation.isSuccess */ ? (
                <Alert severity="success">{feedback}</Alert>
            ) : addSlips.isError /* || updateMutation.isError */ ? (
                <Alert severity="error">{feedback}</Alert>
            ) : null}
        </>
    );
};

export default AddConnectionSlip;
