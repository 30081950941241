import { Add } from "@mui/icons-material";
import {
    Alert,
    Button,
    Card,
    CardContent,
    CardHeader,
    CircularProgress,
    Divider,
    Grid,
    TextField,
    Typography,
    useTheme,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { POST } from "../../Utilities/BaseService";
import UpdatedSearchableInput from "../../Utilities/UpdatedSearchableInput";

type TargetType = "meter" | "customer" | "property" | "";

type Payment = {
    amount: number | string;
    targetType: TargetType;
    targetId: number | string;
    head: number | string;
    description?: string;
};

const addPayment = (payment: Payment) => {
    return POST("/payment", {
        ...payment,
        metaData: JSON.stringify({}),
    });
};

const initialClearAll = {
    district: false,
    village: false,
    hamlet: false,
    powerstation: false,
    targetId: false,
};

export const AddPayment = () => {
    const [payment, setPayment] = useState<Payment>({
        amount: "",
        targetType: "",
        head: "",
        targetId: "",
        description: "",
    });
    const [filter, setFilter] = useState<any>({
        head: "",
        targetId: "",
        district: "",
        village: "",
        hamlet: "",
        powerstation: "",
    });
    const [feedback, setFeedback] = useState("");
    const [clearAll, setClearAll] = useState(initialClearAll);
    const [defaultRegions, setDefaultRegions] = useState({
        district: "",
        village: "",
        hamlet: "",
        powerstation: "",
    });

    const theme = useTheme();

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setPayment({ ...payment, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        addMutation.mutate(payment);
    };

    const addMutation = useMutation(addPayment, {
        onSuccess(res) {
            setFeedback(res.data.message);
        },

        onError(err: any) {
            setFeedback(err.response.data.message);
        },
    });

    useEffect(() => {
        if (filter.head?.id) {
            setPayment({
                ...payment,
                head: filter.head.id,
                targetType: filter.head.targetType,
                amount: filter.head.amount,
            });
        }

        if (filter.head === "all") {
            setPayment({
                ...payment,
                head: "",
                targetType: "",
                targetId: "",
            });

            setClearAll({ ...clearAll, targetId: true });
        }

        if (filter.targetId && filter.targetId !== "all") {
            setPayment({ ...payment, targetId: filter.targetId });
        }

        if (filter.district === "all") {
            setClearAll({
                ...clearAll,
                district: true,
                village: true,
                hamlet: true,
                powerstation: true,
                targetId: true,
            });
            setFilter({
                ...filter,
                district: "",
                village: "",
                hamlet: "",
                powerstation: "",
                targetId: "",
            });
        }

        if (filter.village === "all") {
            setClearAll({
                ...clearAll,
                village: true,
                hamlet: true,
                targetId: true,
            });
            setFilter({ ...filter, village: "", hamlet: "", targetId: "" });
        }

        if (filter.hamlet === "all") {
            setClearAll({ ...clearAll, hamlet: true });
            setFilter({ ...filter, hamlet: "" });
        }

        if (defaultRegions.district !== filter.district) {
            setClearAll({
                ...clearAll,
                village: true,
                hamlet: true,
                powerstation: true,
            });

            setDefaultRegions({
                ...defaultRegions,
                district: filter.district as any,
            });

            setFilter({
                ...filter,
                village: "",
                hamlet: "",
                powerstation: "",
            });
        }

        if (filter.targetType || filter.targetType === "all") {
            setClearAll({ ...clearAll, targetId: true });
            setFilter({ ...filter, head: "all" });
        }

        return () => {
            setClearAll(initialClearAll);
        };
    }, [filter]);

    return (
        <>
            <Card
                elevation={0}
                sx={{ bgcolor: theme.palette.common.white, mb: "1rem" }}
            >
                <CardHeader
                    title="add payment"
                    sx={{
                        textTransform: "capitalize",
                        bgcolor: theme.palette.common.white,
                    }}
                />
                <CardContent>
                    <Divider sx={{ mb: 2 }}>
                        <Typography>Filter</Typography>
                    </Divider>

                    <Grid
                        container
                        component="form"
                        onSubmit={handleSubmit}
                        spacing={2}
                    >
                        <Grid item container xs={12} spacing={2}>
                            <Grid item xs={12} md={6} lg={3}>
                                <UpdatedSearchableInput
                                    api="/region/district"
                                    label="District"
                                    filter={filter}
                                    setFilter={setFilter}
                                    clearAll={clearAll.district}
                                />
                            </Grid>

                            <Grid item xs={12} md={6} lg={3}>
                                <UpdatedSearchableInput
                                    api="/region/village"
                                    label="Village"
                                    filter={filter}
                                    setFilter={setFilter}
                                    dep={filter.district}
                                    params={{ district: filter.district }}
                                    clearAll={clearAll.village}
                                />
                            </Grid>

                            <Grid item xs={12} md={6} lg={3}>
                                <UpdatedSearchableInput
                                    api="/region/hamlet"
                                    label="Hamlet"
                                    filter={filter}
                                    setFilter={setFilter}
                                    dep={filter.village}
                                    params={
                                        filter.village &&
                                        filter.village !== "all"
                                            ? { village: [filter.village] }
                                            : { village: [0] }
                                    }
                                    clearAll={clearAll.hamlet}
                                />
                            </Grid>

                            <Grid item xs={12} md={6} lg={3}>
                                <UpdatedSearchableInput
                                    api="/powerstation"
                                    label="Powerstation"
                                    filter={filter}
                                    setFilter={setFilter}
                                    dep={filter.district}
                                    params={{
                                        district: filter.district,
                                    }}
                                    clearAll={clearAll.powerstation}
                                />
                            </Grid>
                        </Grid>

                        <Grid item xs={12} mt={2}>
                            <Divider>
                                <Typography>Payment Detials</Typography>
                            </Divider>
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <UpdatedSearchableInput
                                api="/payment/head"
                                label="Payment Head"
                                _name="head"
                                filter={filter}
                                setFilter={setFilter}
                                getFullRecord
                                required
                                dep={
                                    payment.targetType
                                        ? {
                                              targetType: payment.targetType,
                                          }
                                        : undefined
                                }
                                optionsPreprocessor={(opt: any) => opt}
                                optionsFilter={(opts: any[]) =>
                                    opts.filter(
                                        (opt: any) =>
                                            opt.targetType !== "reading"
                                    )
                                }
                            />
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <TextField
                                size="small"
                                name="amount"
                                value={payment.amount}
                                label="Amount"
                                onChange={handleChange}
                                fullWidth
                                type="number"
                                inputProps={{ min: 0 }}
                                disabled={!filter.head?.editable}
                            />
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <UpdatedSearchableInput
                                api={
                                    payment.targetType
                                        ? payment.targetType === "customer"
                                            ? "/customer"
                                            : payment.targetType === "meter"
                                            ? "/customer/meter"
                                            : "/customer/property"
                                        : ""
                                }
                                label="Target"
                                _name="targetId"
                                filter={filter}
                                setFilter={setFilter}
                                dep={[
                                    payment.targetType,
                                    filter.district,
                                    filter.village,
                                    filter.hamlet,
                                    filter.powerstation,
                                ]}
                                optionsPreprocessor={
                                    payment.targetType === "meter"
                                        ? (opt: any) => ({
                                              ...opt,
                                              name: opt.meterNo,
                                          })
                                        : undefined
                                }
                                required
                                disabled={!payment.head}
                                params={
                                    payment.targetType !== "customer"
                                        ? Object.fromEntries(
                                              Object.entries(filter).filter(
                                                  ([k, v]) =>
                                                      [
                                                          "district",
                                                          "village",
                                                          "hamlet",
                                                      ].includes(k) &&
                                                      v &&
                                                      v !== "all"
                                              )
                                          )
                                        : undefined
                                }
                                clearAll={clearAll.targetId}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                size="small"
                                name="description"
                                value={payment.description}
                                label="Description"
                                onChange={handleChange}
                                fullWidth
                                type="text"
                                multiline
                                minRows={2}
                                placeholder="Add some description about the payment (Optional)..."
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Button
                                startIcon={<Add />}
                                endIcon={
                                    addMutation.isLoading ? (
                                        <CircularProgress
                                            color="secondary"
                                            size="1rem"
                                        />
                                    ) : (
                                        ""
                                    )
                                }
                                color="secondary"
                                variant="outlined"
                                size="small"
                                type="submit"
                            >
                                add payment
                            </Button>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>

            {addMutation.isSuccess ? (
                <Alert severity="success">{feedback}.</Alert>
            ) : addMutation.isError ? (
                <Alert severity="error">{feedback}.</Alert>
            ) : null}
        </>
    );
};
