import { Typography } from "@mui/material";
import { useState } from "react";
import { Link } from "react-router-dom";

type Meter = { id: number; meterNo: string };
type Property = { id: number; name: string };
type Customer = { id: number; name: string };

type Props = {
    targetType: "customer" | "property" | "meter";
    collectee: Meter | Property | Customer;
};

const PaymentCollectee = ({ targetType, collectee }: Props) => {
    const [label, setLabel] = useState(
        targetType === "meter"
            ? (collectee as Meter).meterNo
            : (collectee as Property | Customer).name
    );

    return (
        <Typography variant="body2">
            <Link
                to={
                    targetType === `meter`
                        ? `/customer/meters?id=${collectee.id}`
                        : targetType === `customer`
                        ? `/customer/consumers?id=${collectee.id}`
                        : `/customer/property?id=${collectee.id}`
                }
                style={{ textDecoration: "inherit", color: "inherit" }}
            >
                {label}
            </Link>
        </Typography>
    );
};

export default PaymentCollectee;
