import { Add } from "@mui/icons-material";
import {
    Alert,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    CircularProgress,
    Divider,
    Grid,
    TextField,
} from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import React, { FormEvent, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { theme } from "../../../Theme/theme";
import { GET, PATCH, POST } from "../../../Utilities/BaseService";

const AddProType = (name: any) => {
    return POST("/customer/property/type", name);
};

const updateProType = ({ name, id }: any) => {
    return PATCH("/customer/property/type", { name }, { id });
};

const AddPropertyType = ({ updateMode }: any) => {
    const [name, setName] = useState("");
    const [feedback, setFeedback] = useState("");
    const location = useLocation();
    const navigate = useNavigate();
    const [id, setId] = useState("");

    const getPropertyType = () => {
        return GET(`/customer/property/type?id=${id}`);
    };
    const { data } = useQuery([`propertyType`], getPropertyType, {
        enabled: Boolean(id),
        onSuccess(response) {
            if (response.data.rows.length > 0) {
                const _d = response.data.rows[0];
                console.log(_d.name);
                setName(_d.name);
            }
        },
        refetchOnMount: true,
    });

    const handleSubmit = (ev: any) => {
        ev.preventDefault();
        {
            !updateMode
                ? addMutation.mutate({ name: name })
                : updateMutation.mutate({ name: name, id: id });
        }
    };

    const addMutation = useMutation(AddProType, {
        onSuccess(res) {
            setFeedback(res.data.message);
        },

        onError(err: any) {
            setFeedback(err.response.data.message);
        },
    });

    const updateMutation = useMutation(updateProType, {
        onSuccess(res) {
            setFeedback(res.data.message);
        },

        onError(err: any) {
            setFeedback(err.response.data.message);
        },
    });

    useEffect(() => {
        if (updateMode) {
            const params = new URLSearchParams(location.search);
            const _id = params.get("id");

            if (_id) {
                setId(_id);
            }
        }
    }, [updateMode]);

    return (
        <>
            <Card
                elevation={0}
                sx={{ bgcolor: theme.palette.common.white, mb: "1rem" }}
            >
                <CardHeader
                    title={
                        updateMode
                            ? "Update Property Type"
                            : "Add Property Type"
                    }
                    sx={{
                        textTransform: "capitalize",
                        bgcolor: theme.palette.common.white,
                    }}
                />
                <CardContent>
                    <Divider sx={{ mb: 5 }} />

                    <Grid
                        container
                        component="form"
                        onSubmit={handleSubmit}
                        spacing={2}
                    >
                        <Grid item xs={12} md={4}>
                            <TextField
                                size="small"
                                name="name"
                                value={name}
                                label="Name"
                                onChange={(ev) => setName(ev.target.value)}
                                fullWidth
                                type="text"
                                required
                            />
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <Button
                                startIcon={<Add />}
                                endIcon={
                                    addMutation.isLoading ? (
                                        <CircularProgress
                                            color="secondary"
                                            size="1rem"
                                        />
                                    ) : (
                                        ""
                                    )
                                }
                                color="secondary"
                                variant="outlined"
                                size="small"
                                type="submit"
                                fullWidth
                                style={{ height: "100%" }}
                            >
                                {updateMode ? "update" : "add"} property
                            </Button>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>

            {addMutation.isSuccess || updateMutation.isSuccess ? (
                <Alert severity="success">{feedback}.</Alert>
            ) : addMutation.isError || updateMutation.isError ? (
                <Alert severity="error">{feedback}.</Alert>
            ) : null}
        </>
    );
};

export default AddPropertyType;
