import {
    Dialog,
    DialogContent,
    DialogTitle,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from "@mui/material";
import React, { Dispatch, SetStateAction } from "react";
import { currencyFormatter } from "../../Reading/Bill/Cells/Last12Summary";

type Props = {
    row: any;
    open: any;
    setOpen: Dispatch<SetStateAction<any>>;
};

const PaymentBreakDown = ({ row, open, setOpen }: Props) => {
    const date = new Date();
    return (
        <Dialog
            open={open ?? false}
            onClose={() => setOpen({ [row.id]: false })}
            fullWidth
        >
            <DialogTitle>Collections</DialogTitle>

            <DialogContent>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Payment Date</TableCell>

                            <TableCell>Amount</TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {row.map((item: any) => (
                            <TableRow key={item.id}>
                                <TableCell>
                                    {new Date(
                                        item.createdAt
                                    ).toLocaleDateString(undefined, {
                                        weekday: "long",
                                        year: "numeric",
                                        month: "short",
                                        day: "numeric",
                                    })}
                                </TableCell>

                                <TableCell>
                                    {currencyFormatter.format(item.amount)}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </DialogContent>
        </Dialog>
    );
};

export default PaymentBreakDown;
