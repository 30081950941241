import { Grid, Paper } from "@mui/material";
import { createContext, useEffect, useState } from "react";
import { DashboardService } from "../../../Services/DashboardService";
import { DCardVariant, DashboardCard } from "./DashboardCard";
import { Filters } from "./Filters";

type Dashboard = {
    connections: {
        count: {
            [key: string]: any;
        };
        amount: {
            [key: string]: any;
        };
    };
    bill: {
        amount: { [key: string]: any };
    };
    totalAdjustment: number | "";
    totalUnitsGenerated: number | "";
    surcharge: {
        total: number | "";
        payed: number | "";
        remaining: number | "";
    };

    billsCount: {
        total: number | "";
        completed: number | "";
        remaining: number | "";
    };
};

export const SimpleDashboardCtx = createContext({} as any);

export const SimpleDashboard = () => {
    const [status, setStatus] = useState({
        connAmt: "idle",
        connCount: "idle",
        billAmt: "idle",
        adjsAmt: "idle",
        totalUnitsGenerated: "idle",
        surchargeCount: "idle",
        billsCount: "idle",
    });

    const [dashbaord, setDashbaord] = useState<Dashboard>({
        connections: {
            count: {
                Domestic: 0,
                Commercial: 0,
            },
            amount: {
                Domestic: 0,
                Commercial: 0,
            },
        },
        bill: {
            amount: {},
        },
        totalAdjustment: "",
        totalUnitsGenerated: "",
        surcharge: { total: "", payed: "", remaining: "" },
        billsCount: { total: "", completed: "", remaining: "" },
    });
    const [filter, setFilter] = useState({
        district: "",
        village: "",
        hamlet: "",
    });

    const getDashboardCount = async () => {
        setStatus((s) => ({ ...s, connCount: "loading" }));
        const connCountReport =
            await DashboardService.getInstance().getConnectionsCount(filter);

        if (connCountReport.data) {
            setStatus((s) => ({ ...s, connCount: "success" }));
            setDashbaord((d) => ({
                ...d,
                connections: {
                    ...d.connections,
                    count: connCountReport.data,
                },
            }));
        } else {
            setStatus((s) => ({ ...s, connCount: "error" }));
        }
    };

    const getDashboardAmounts = async () => {
        setStatus((s) => ({ ...s, connAmt: "loading" }));
        const connAmtReport =
            await DashboardService.getInstance().getConnectionsAmount(filter);

        if (connAmtReport.data) {
            setStatus((s) => ({ ...s, connAmt: "success" }));
            setDashbaord((d) => ({
                ...d,
                connections: {
                    ...d.connections,
                    amount: connAmtReport.data,
                },
            }));
        } else {
            setStatus((s) => ({ ...s, connAmt: "error" }));
        }
    };

    const getBillAmounts = async () => {
        setStatus((s) => ({ ...s, billAmt: "loading" }));
        const billAmounts =
            await DashboardService.getInstance().getReadingAmount(filter);

        if (billAmounts.data) {
            setStatus((s) => ({ ...s, billAmt: "success" }));
            setDashbaord((d) => ({
                ...d,
                bill: {
                    ...d.bill,
                    amount: billAmounts.data,
                },
            }));
        } else {
            setStatus((s) => ({ ...s, billAmt: "error" }));
        }
    };

    const getAdjustedAmount = async () => {
        setStatus((s) => ({ ...s, adjsAmt: "loading" }));
        const adjAmtRes =
            await DashboardService.getInstance().getAdjustedAmount(filter);

        if (adjAmtRes.data) {
            setStatus((s) => ({ ...s, adjsAmt: "success" }));
            setDashbaord((d) => ({
                ...d,
                totalAdjustment: adjAmtRes.data.adj.totalAdjustment,
            }));
        } else {
            setStatus((s) => ({ ...s, adjsAmt: "error" }));
        }
    };

    const getTotalUnitsGenerated = async () => {
        setStatus((s) => ({ ...s, totalUnitsGenerated: "loading" }));
        const totalUnitsRes =
            await DashboardService.getInstance().getTotalGeneratedUnits(filter);

        if (totalUnitsRes.data) {
            setStatus((s) => ({ ...s, totalUnitsGenerated: "success" }));
            setDashbaord((d) => ({
                ...d,
                totalUnitsGenerated: totalUnitsRes.data.totalUnits,
            }));
        } else {
            setStatus((s) => ({ ...s, totalUnitsGenerated: "error" }));
        }
    };

    const getBillsCount = async () => {
        setStatus((s) => ({ ...s, billsCount: "loading" }));
        const billsCountRes =
            await DashboardService.getInstance().getBillsCount(filter);

        if (billsCountRes.data) {
            setStatus((s) => ({ ...s, billsCount: "success" }));
            setDashbaord((d) => ({
                ...d,
                billsCount: {
                    total: billsCountRes.data.total,
                    completed: billsCountRes.data.completedBills,
                    remaining: billsCountRes.data.remainingBills,
                },
            }));
        } else {
            setStatus((s) => ({ ...s, billsCount: "error" }));
        }
    };

    useEffect(() => {
        getDashboardCount();
        getDashboardAmounts();
        getBillAmounts();
        getAdjustedAmount();
        getTotalUnitsGenerated();
        getBillsCount();
    }, [filter]);

    return (
        <SimpleDashboardCtx.Provider value={{ status }}>
            <Grid container spacing={1}>
                <Grid
                    item
                    container
                    spacing={1}
                    xs={12}
                    component={Paper}
                    variant="outlined"
                    sx={{ bgcolor: "common.white", mt: 1, ml: 1, p: 1 }}
                >
                    <Filters filter={filter} setFilter={setFilter} />
                </Grid>

                <Grid item xs={12} md={4}>
                    <DashboardCard
                        variant={DCardVariant.Simple}
                        data={{
                            label: "Commercial connections",
                            value:
                                dashbaord.connections["count"]["Commercial"] ||
                                0,
                            ident: "connCount",
                        }}
                    />
                </Grid>

                <Grid item xs={12} md={4}>
                    <DashboardCard
                        variant={DCardVariant.Simple}
                        data={{
                            label: "Domestic connections",
                            value:
                                dashbaord.connections["count"]["Domestic"] || 0,
                            ident: "connCount",
                        }}
                    />
                </Grid>

                <Grid item xs={12} md={4}>
                    <DashboardCard
                        variant={DCardVariant.Simple}
                        data={{
                            label: "Total connections",
                            value: Object.values(
                                dashbaord.connections.count
                            ).reduce((curr, acc) => curr + acc, 0),
                            ident: "connCount",
                        }}
                    />
                </Grid>

                <Grid item xs={12} md={4}>
                    <DashboardCard
                        variant={DCardVariant.Simple}
                        data={{
                            label: "Commercial connections amount",
                            value:
                                dashbaord.connections["amount"]["Commercial"] ||
                                0,
                            ident: "connAmt",
                        }}
                    />
                </Grid>

                <Grid item xs={12} md={4}>
                    <DashboardCard
                        variant={DCardVariant.Simple}
                        data={{
                            label: "Domestic connections amount",
                            value:
                                dashbaord.connections["amount"]["Domestic"] ||
                                0,
                            ident: "connAmt",
                        }}
                    />
                </Grid>

                <Grid item xs={12} md={4}>
                    <DashboardCard
                        variant={DCardVariant.Simple}
                        data={{
                            label: "Total connections amount",
                            value: Object.values(
                                dashbaord.connections.amount
                            ).reduce((curr, acc) => curr + acc, 0),
                            ident: "connAmt",
                        }}
                    />
                </Grid>

                <Grid item xs={12} md={4}>
                    <DashboardCard
                        variant={DCardVariant.Simple}
                        data={{
                            label: "Total amount generated",
                            value: dashbaord.bill["amount"]["totalAmount"],
                            ident: "billAmt",
                        }}
                    />
                </Grid>

                <Grid item xs={12} md={4}>
                    <DashboardCard
                        variant={DCardVariant.Simple}
                        data={{
                            label: "Total amount collected",
                            value: dashbaord.bill["amount"]["collected"],
                            ident: "billAmt",
                        }}
                    />
                </Grid>

                <Grid item xs={12} md={4}>
                    <DashboardCard
                        variant={DCardVariant.Simple}
                        data={{
                            label: "Arrears remaining",
                            value: Math.max(
                                0,
                                dashbaord.bill["amount"]["totalAmount"] -
                                    dashbaord.bill["amount"]["collected"]
                            ),
                            ident: "billAmt",
                        }}
                    />
                </Grid>

                <Grid item xs={6}>
                    <DashboardCard
                        variant={DCardVariant.Simple}
                        data={{
                            label: "Total Adjustment",
                            value: dashbaord.totalAdjustment,
                            ident: "adjsAmt",
                        }}
                    />
                </Grid>

                <Grid item xs={6}>
                    <DashboardCard
                        variant={DCardVariant.Simple}
                        data={{
                            label: "Total Units Generated",
                            value: dashbaord.totalUnitsGenerated,
                            ident: "totalUnitsGenerated",
                        }}
                    />
                </Grid>

                {/* <Grid item xs={12} md={4}>
                    <DashboardCard
                        variant={DCardVariant.Simple}
                        data={{
                            label: "Total Surcharge",
                            value: dashbaord.surcharge.total,
                            ident: "surchargeCount",
                        }}
                    />
                </Grid>

                <Grid item xs={12} md={4}>
                    <DashboardCard
                        variant={DCardVariant.Simple}
                        data={{
                            label: "Payed Surcharge",
                            value: dashbaord.surcharge.payed,
                            ident: "surchargeCount",
                        }}
                    />
                </Grid>

                <Grid item xs={12} md={4}>
                    <DashboardCard
                        variant={DCardVariant.Simple}
                        data={{
                            label: "Remaining Surcharge",
                            value: dashbaord.surcharge.remaining,
                            ident: "surchargeCount",
                        }}
                    />
                </Grid> */}

                <Grid item xs={12} md={4}>
                    <DashboardCard
                        variant={DCardVariant.Simple}
                        data={{
                            label: "Total bills generated",
                            value: dashbaord.billsCount.total,
                            ident: "billsCount",
                        }}
                    />
                </Grid>

                <Grid item xs={12} md={4}>
                    <DashboardCard
                        variant={DCardVariant.Simple}
                        data={{
                            label: "Completed bills",
                            value: dashbaord.billsCount.completed,
                            ident: "billsCount",
                        }}
                    />
                </Grid>

                <Grid item xs={12} md={4}>
                    <DashboardCard
                        variant={DCardVariant.Simple}
                        data={{
                            label: "Remaining bills",
                            value: dashbaord.billsCount.remaining,
                            ident: "billsCount",
                        }}
                    />
                </Grid>
            </Grid>
        </SimpleDashboardCtx.Provider>
    );
};
