import { Chip, Stack } from "@mui/material";
import { Dispatch, SetStateAction } from "react";

type Props = {
    meterReport: MeterCountReport | null;
    selectedConnTypes: "all" | number[];
    setSelectedConnTypes: Dispatch<SetStateAction<"all" | number[]>>;
};

export const SelectConnTypes = ({
    meterReport,
    selectedConnTypes,
    setSelectedConnTypes,
}: Props) => {
    const handleClick = (typeId: number) => {
        if (selectedConnTypes === "all") {
            setSelectedConnTypes([typeId]);
        } else {
            if (selectedConnTypes.includes(typeId)) {
                setSelectedConnTypes(
                    selectedConnTypes.filter(
                        (connTypeId) => connTypeId !== typeId
                    )
                );
            } else {
                setSelectedConnTypes([...selectedConnTypes, typeId]);
            }
        }
    };

    if (meterReport) {
        return (
            <Stack direction="row" spacing={1} overflow="auto">
                <Chip
                    label="All"
                    color={selectedConnTypes === "all" ? "primary" : "default"}
                    onClick={() =>
                        setSelectedConnTypes(
                            selectedConnTypes === "all" ? [] : "all"
                        )
                    }
                />
                {meterReport.map((connType) => (
                    <Chip
                        color={
                            selectedConnTypes === "all" ||
                            selectedConnTypes.includes(connType.typeId)
                                ? "primary"
                                : "default"
                        }
                        label={connType.typeName}
                        onClick={(ev) => handleClick(connType.typeId)}
                    />
                ))}
            </Stack>
        );
    } else {
        return null;
    }
};
