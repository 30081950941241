import { Box, Divider, Grid, Typography } from "@mui/material";

export const Cell = ({
    label,
    value,
    labelUrdu = "",
    noTypography = false,
    noLabel = false,
    labelSx = {},
    padding = 1,
    ...props
}: {
    labelSx?: any;
    label?: string;
    labelUrdu?: string;
    noTypography?: boolean;
    noLabel?: boolean;
    value: string | JSX.Element | null;
    [key: string]: any;
    padding?: number;
}) => {
    return (
        <Grid item xs={2} padding={padding} {...props}>
            {!noLabel && (
                <Box display="flex" flexDirection="column">
                    <Typography
                        sx={labelSx}
                        variant="body2"
                        textAlign="center"
                        fontWeight="bold"
                    >
                        {label}
                    </Typography>
                    {labelUrdu && (
                        <Typography
                            sx={labelSx}
                            variant="body2"
                            textAlign="center"
                            fontWeight="bold"
                        >
                            {labelUrdu}
                        </Typography>
                    )}

                    <Divider flexItem />
                </Box>
            )}
            {noTypography ? (
                value
            ) : (
                <Typography variant="body2" textAlign="center" sx={labelSx}>
                    {value}
                </Typography>
            )}
        </Grid>
    );
};
