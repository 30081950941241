import { Autorenew } from "@mui/icons-material";
import {
    Alert,
    Button,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    TextField,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { POST } from "../../../Utilities/BaseService";
import UpdatedSearchableInput from "../../../Utilities/UpdatedSearchableInput";

const addMeterReplacement = (data: any) => {
    console.log(data);
    return POST("/customer/meter/replace", {
        ...data,
        metaData: JSON.stringify({ date: data.metaData }),
    });
};

type Payment = {
    head: { id: number | string; editable: boolean; amount: number };
    amount: number;
};

const MeterReplacement = ({ activeRow, setActiveRow }: any) => {
    const [open, setOpen] = useState(true);
    const [deduceNow, setDeduceNow] = useState<any>(false);
    const [feedback, setFeedback] = useState("");
    const [payment, setPayment] = useState<Payment>();
    const [meterReplacement, setMeterReplacement] = useState({
        prevMeterNo: activeRow.meterNo,
        meterNo: "",
        initialReading: "",
        amount: "",
        metaData: "",
        head: "",
        description: "",
    });

    const handleChange = (ev: any) => {
        const value = ev.target.value;

        setMeterReplacement({
            ...meterReplacement,
            [ev.target.name]: value,
        });
    };

    const handleSubmit = (ev: any) => {
        ev.preventDefault();
        {
            addMutation.mutate(meterReplacement);
        }
    };

    const addMutation = useMutation(addMeterReplacement, {
        onSuccess(res) {
            setFeedback(res.data.message);
        },

        onError(err: any) {
            setFeedback(err.response.data.message);
        },
    });

    useEffect(() => {
        if (payment?.head && payment.head !== ("all" as any)) {
            setMeterReplacement({
                ...meterReplacement,
                amount: payment.head.amount + "" ?? "",
                head: payment.head.id as string,
            });
        } else {
            setMeterReplacement({
                ...meterReplacement,
                amount: "",
                head: "",
            });
        }
    }, [payment]);

    return (
        <Dialog
            open={open}
            fullWidth
            onClose={() => {
                setActiveRow(null);
                setOpen(false);
            }}
        >
            <DialogTitle>Replace Meter</DialogTitle>

            <DialogContent>
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                        {addMutation.isSuccess ? (
                            <Grid item xs={12} mb={2}>
                                <Alert severity="success">{feedback}.</Alert>{" "}
                            </Grid>
                        ) : addMutation.isError ? (
                            <Grid item xs={12} mb={2}>
                                <Alert severity="error">{feedback}.</Alert>
                            </Grid>
                        ) : null}

                        <Grid item xs={12}>
                            <TextField
                                size="small"
                                name="meterNo"
                                value={meterReplacement.meterNo}
                                label="Meter No."
                                onChange={handleChange}
                                fullWidth
                                required
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                size="small"
                                name="initialReading"
                                value={meterReplacement.initialReading}
                                label="Initial Reading"
                                onChange={handleChange}
                                fullWidth
                                required
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <UpdatedSearchableInput
                                api="/payment/head"
                                label="Payment Head"
                                _name="head"
                                filter={payment}
                                setFilter={setPayment}
                                params={{ targetType: "meter" }}
                                getFullRecord
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                label="Amount"
                                size="small"
                                fullWidth
                                value={meterReplacement.amount}
                                disabled={!payment?.head?.editable}
                                type="number"
                                inputProps={{ min: 0 }}
                                onChange={
                                    payment?.head?.editable
                                        ? (e) =>
                                              setMeterReplacement({
                                                  ...meterReplacement,
                                                  amount: e.target.value,
                                              })
                                        : undefined
                                }
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                label="Description"
                                fullWidth
                                minRows={3}
                                size="small"
                                multiline
                                value={meterReplacement.description}
                                onChange={(e) =>
                                    setMeterReplacement({
                                        ...meterReplacement,
                                        description: e.target.value,
                                    })
                                }
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Button
                                startIcon={<Autorenew />}
                                endIcon={
                                    addMutation.isLoading ? (
                                        <CircularProgress
                                            color="secondary"
                                            size="1rem"
                                        />
                                    ) : (
                                        ""
                                    )
                                }
                                color="secondary"
                                variant="outlined"
                                size="small"
                                type="submit"
                                style={{ height: "100%" }}
                            >
                                replace meter
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </DialogContent>
        </Dialog>
    );
};

export default MeterReplacement;
