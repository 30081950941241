import {
    KeyboardArrowLeftOutlined,
    KeyboardArrowRightOutlined,
} from "@mui/icons-material";
import {
    Box,
    BoxProps,
    IconButton,
    MenuItem,
    TextField,
    Typography,
} from "@mui/material";
import { Dispatch, SetStateAction } from "react";

type PaginationType = any;

type Props = {
    pagination: PaginationType;
    setPagination: Dispatch<SetStateAction<PaginationType>>;
    boxProps?: BoxProps;
    rowsPerPageOpts?: number[];
};

export const BlindPagination = ({
    pagination,
    setPagination,
    boxProps = {},
    rowsPerPageOpts = [10, 50, 100],
}: Props) => {
    return (
        <Box display="flex" alignItems="center" {...boxProps}>
            <TextField
                select
                sx={{ minWidth: "15ch" }}
                size="small"
                value={pagination.limit}
                label="Rows per page"
                onChange={(ev) =>
                    setPagination({
                        ...pagination,
                        limit: parseInt(ev.target.value),
                    })
                }
            >
                {rowsPerPageOpts.map((value) => (
                    <MenuItem key={value} value={value}>
                        {value}
                    </MenuItem>
                ))}
            </TextField>
            <IconButton
                size="small"
                onClick={() => {
                    setPagination({
                        ...pagination,
                        page: Math.max(0, pagination.page - 1),
                    });
                }}
            >
                <KeyboardArrowLeftOutlined />
            </IconButton>
            <Typography variant="body2">{pagination.page + 1}</Typography>
            <IconButton
                size="small"
                onClick={() => {
                    setPagination({ ...pagination, page: pagination.page + 1 });
                }}
            >
                <KeyboardArrowRightOutlined />
            </IconButton>
        </Box>
    );
};
