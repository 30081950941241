import { Add, ExpandLess, ExpandMore } from "@mui/icons-material";
import {
    Alert,
    Button,
    Card,
    CardContent,
    CardHeader,
    Checkbox,
    CircularProgress,
    Collapse,
    Divider,
    FormControl,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,
    TextField,
    useTheme,
} from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import { FormEvent, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { GET, PATCH, POST } from "../../Utilities/BaseService";

type Op = { id: number; label: string; name: string };

type RoleCategory = {
    id: number;
    name: string;
    ops: Op[];
};

const getOpsAndCats = () => {
    return GET("/rolesystem/op-category/", { limit: 0 });
};

const addRole = (data: any) => {
    return POST("/rolesystem/role/from-ops/", data);
};

const updateRole = ({ name, description, permissions, id }: any) => {
    return PATCH(
        `/rolesystem/role/permissions`,
        { name, description, permissions },
        { id }
    );
};

const AddRole = ({ updateMode }: any) => {
    const location = useLocation();
    const [roleCats, setRoleCats] = useState<{
        [key: string]: {
            ops: { [key: number]: { checked: boolean; label: string } };
        };
    }>({});
    const [roleId, setRoleId] = useState("");
    const [name, setName] = useState("");
    const [opsInRole, setOpsInRole] = useState<{ [key: string]: boolean }>({});
    const [open, setOpen] = useState<{ [key: string]: boolean }>({});
    const [description, setDescription] = useState<any>("");
    const [feedback, setFeedback] = useState("");
    const theme = useTheme();

    const getOpsAndCatById = () => {
        return GET(`/rolesystem/role?id=${roleId}`);
    };

    const { data: opAndCategories } = useQuery(["ops&cats"], getOpsAndCats, {
        onSuccess(res: any) {
            const {
                rows,
            }: {
                rows: RoleCategory[];
            } = res.data;

            if (res.data.rows) {
                const targetRoleCats = rows.reduce(
                    (prev: any, curr) => ({
                        ...prev,
                        [curr.name]: {
                            ops: curr.ops.reduce(
                                (prev: any, curr) => ({
                                    ...prev,
                                    [curr.id]: {
                                        checked: false,
                                        label: curr.label,
                                    },
                                }),
                                {}
                            ),
                        },
                    }),
                    {}
                );

                setRoleCats(targetRoleCats);
            }
        },
    });
    const { data: opsAndCatById } = useQuery(
        ["ops&catById", roleId],
        getOpsAndCatById,
        {
            enabled: Boolean(roleId && Object.keys(roleCats).length),
            onSuccess(res) {
                if (res.data.rows.length > 0) {
                    setName(res.data.rows[0].name);
                    setDescription(res.data.rows[0].description);
                    const _role: {
                        permissions: {
                            id: number;
                            op: {
                                id: number;
                                label: string;
                                name: string;
                                category: { id: number; name: string };
                            };
                        }[];
                    } = res.data.rows[0];

                    const withCats: any = {};

                    _role.permissions.forEach((item) => {
                        const categoryName = item.op.category.name;
                        if (!withCats[categoryName]) {
                            withCats[categoryName] = { ops: {} };
                        }
                        withCats[categoryName]["ops"] = {
                            ...roleCats[categoryName]["ops"],
                            ...withCats[categoryName]["ops"],
                            [item.op.id]: {
                                checked: true,
                                label: item.op.label,
                            },
                        };
                    });

                    setRoleCats((state) => ({ ...state, ...withCats }));
                }
            },
            refetchOnMount: true,
        }
    );

    const handleSubmit = async (ev: FormEvent<HTMLFormElement>) => {
        ev.preventDefault();
        const selectedOps = Object.values(roleCats)
            .map((item) =>
                Object.entries(item.ops)
                    .filter((item) => item[1].checked)
                    .map((item) => item[0])
                    .flat()
            )
            .filter((item) => item.length)
            .flat();

        !updateMode
            ? addMutation.mutate({ name, description, ops: selectedOps })
            : updateMutation.mutate({
                  name,
                  description,
                  permissions: selectedOps,
                  id: roleId,
              });
    };

    const addMutation = useMutation(addRole, {
        onSuccess(res) {
            setFeedback(res.data.message);
        },
        onError(err: any) {
            setFeedback(err.response.data.message);
        },
    });

    const updateMutation = useMutation(updateRole, {
        onSuccess(res) {
            setFeedback(res.data.message);
        },
        onError(err: any) {
            setFeedback(err.response.data.message);
        },
    });

    const handleOpCheck = (check: boolean, category: string, opId: number) => {
        setRoleCats((state) => ({
            ...state,
            [category]: {
                ...state[category],
                ops: {
                    ...state[category].ops,
                    [opId]: {
                        ...state[category].ops[opId],
                        checked: check,
                    },
                },
            },
        }));
    };

    useEffect(() => {
        if (updateMode) {
            const params = new URLSearchParams(location.search);
            const _id = params.get("id");

            if (_id) {
                setRoleId(_id);
            }
        }
    }, [updateMode]);

    const isChecked = (cat: any) => {
        const _catObj = opAndCategories?.data.rows.find(
            (c: any) => c.id == cat
        );
        const _catOps = _catObj.ops.map((o: any) => o.id);

        const opsInRoleTrue = Object.entries(opsInRole)
            .filter((e) => e[1])
            .map((e) => parseInt(e[0]));

        for (const op of _catOps) {
            if (!opsInRoleTrue.includes(op)) {
                return false;
            }
        }
        return true;
    };

    return (
        <>
            <Card
                elevation={0}
                sx={{ bgcolor: theme.palette.common.white, mb: "1rem" }}
            >
                <CardHeader
                    title={updateMode ? "Update Role" : "Add Role"}
                    titleTypographyProps={{ color: "gray" }}
                    sx={{
                        textTransform: "capitalize",
                        bgcolor: theme.palette.common.white,
                    }}
                />

                <CardContent>
                    <Divider sx={{ mb: 5 }} />

                    <Grid container component="form" onSubmit={handleSubmit}>
                        <Grid item container spacing={2}>
                            <Grid item xs={12} lg={6}>
                                <TextField
                                    fullWidth
                                    value={name}
                                    onChange={(ev) => setName(ev.target.value)}
                                    variant="outlined"
                                    label="Role name"
                                    name="name"
                                    color="primary"
                                    size="small"
                                />
                            </Grid>

                            <Grid item xs={12} lg={6}>
                                <TextField
                                    fullWidth
                                    value={description}
                                    onChange={(ev) =>
                                        setDescription(ev.target.value)
                                    }
                                    variant="outlined"
                                    label="Role Description"
                                    name="description"
                                    color="primary"
                                    size="small"
                                />
                            </Grid>

                            <Grid item container xs={12} spacing={4}>
                                {Object.entries(roleCats).map(
                                    ([cat, { ops }]) => (
                                        <Grid
                                            item
                                            xs={12}
                                            md={6}
                                            lg={4}
                                            key={cat}
                                        >
                                            <List dense disablePadding>
                                                <ListItem
                                                    divider
                                                    disablePadding
                                                >
                                                    <ListItemAvatar>
                                                        <FormControl>
                                                            <Checkbox
                                                                color="secondary"
                                                                onChange={(
                                                                    e,
                                                                    check
                                                                ) =>
                                                                    setRoleCats(
                                                                        (
                                                                            state
                                                                        ) => ({
                                                                            ...state,
                                                                            [cat]: {
                                                                                ...state[
                                                                                    cat
                                                                                ],
                                                                                ops: Object.fromEntries(
                                                                                    Object.entries(
                                                                                        state[
                                                                                            cat
                                                                                        ]
                                                                                            .ops
                                                                                    ).map(
                                                                                        (
                                                                                            item
                                                                                        ) => [
                                                                                            item[0],
                                                                                            {
                                                                                                ...item[1],
                                                                                                checked:
                                                                                                    check,
                                                                                            },
                                                                                        ]
                                                                                    )
                                                                                ),
                                                                            },
                                                                        })
                                                                    )
                                                                }
                                                                size="small"
                                                                checked={Object.values(
                                                                    roleCats[
                                                                        cat
                                                                    ].ops
                                                                ).every(
                                                                    (x) =>
                                                                        x.checked
                                                                )}
                                                                indeterminate={
                                                                    Object.values(
                                                                        roleCats[
                                                                            cat
                                                                        ].ops
                                                                    ).filter(
                                                                        (x) =>
                                                                            x.checked
                                                                    ).length <
                                                                        Object.keys(
                                                                            roleCats[
                                                                                cat
                                                                            ]
                                                                                .ops
                                                                        )
                                                                            .length &&
                                                                    Object.values(
                                                                        roleCats[
                                                                            cat
                                                                        ].ops
                                                                    ).filter(
                                                                        (x) =>
                                                                            x.checked
                                                                    ).length > 0
                                                                        ? true
                                                                        : undefined
                                                                }
                                                            />
                                                        </FormControl>
                                                    </ListItemAvatar>

                                                    <ListItemText>
                                                        {cat}
                                                    </ListItemText>

                                                    <ListItemSecondaryAction>
                                                        <IconButton
                                                            size="small"
                                                            onClick={() =>
                                                                setOpen({
                                                                    ...open,
                                                                    [cat]: !open[
                                                                        cat
                                                                    ],
                                                                })
                                                            }
                                                        >
                                                            {open[cat] ? (
                                                                <ExpandLess fontSize="small" />
                                                            ) : (
                                                                <ExpandMore fontSize="small" />
                                                            )}
                                                        </IconButton>
                                                    </ListItemSecondaryAction>
                                                </ListItem>

                                                {Object.entries(ops).map(
                                                    ([opId, op]) => (
                                                        <Collapse
                                                            key={opId}
                                                            in={open[cat]}
                                                        >
                                                            <ListItem
                                                                divider
                                                                disablePadding
                                                            >
                                                                <ListItemAvatar>
                                                                    <FormControl>
                                                                        <Checkbox
                                                                            color="secondary"
                                                                            checked={
                                                                                op.checked
                                                                            }
                                                                            size="small"
                                                                            onChange={(
                                                                                e,
                                                                                check
                                                                            ) =>
                                                                                handleOpCheck(
                                                                                    check,
                                                                                    cat,
                                                                                    parseInt(
                                                                                        opId
                                                                                    )
                                                                                )
                                                                            }
                                                                        />
                                                                    </FormControl>
                                                                </ListItemAvatar>
                                                                <ListItemText>
                                                                    {op.label}
                                                                </ListItemText>
                                                            </ListItem>
                                                        </Collapse>
                                                    )
                                                )}
                                            </List>
                                        </Grid>
                                    )
                                )}
                            </Grid>

                            <Grid item xs={12}>
                                <Button
                                    color="secondary"
                                    variant="outlined"
                                    size="small"
                                    type="submit"
                                    disabled={
                                        addMutation.isLoading ||
                                        updateMutation.isLoading
                                    }
                                    startIcon={<Add />}
                                    endIcon={
                                        addMutation.isLoading && (
                                            <CircularProgress
                                                size="1em"
                                                color="secondary"
                                            />
                                        )
                                    }
                                >
                                    {!updateMode ? "add role" : "update role"}
                                </Button>
                            </Grid>

                            {/* <Grid item container>
                                <List sx={{ width: "100%" }}>
                                    <Grid item container>
                                        {opAndCategories &&
                                            opAndCategories?.data.rows.map(
                                                (cat: any, idx: number) => (
                                                    <Grid
                                                        item
                                                        key={idx}
                                                        xs={12}
                                                        md={4}
                                                    >
                                                        <ListItem
                                                            sx={{
                                                                listStyle:
                                                                    "none",
                                                            }}
                                                        >
                                                            <ListItemText
                                                                style={{
                                                                    listStyle:
                                                                        "none",
                                                                    display:
                                                                        "flex",
                                                                    alignItems:
                                                                        "center",
                                                                }}
                                                                disableTypography
                                                            >
                                                                <Checkbox
                                                                    checked={isChecked(
                                                                        cat.id
                                                                    )}
                                                                    onChange={(
                                                                        ev,
                                                                        checked
                                                                    ) =>
                                                                        handleCategoryCheck(
                                                                            cat,
                                                                            checked
                                                                        )
                                                                    }
                                                                />
                                                                <Typography variant="body1">
                                                                    {cat.name}
                                                                </Typography>
                                                            </ListItemText>

                                                            <ListItemSecondaryAction>
                                                                <IconButton
                                                                    onClick={() =>
                                                                        handleCategorieasClick(
                                                                            cat.id
                                                                        )
                                                                    }
                                                                >
                                                                    {open[
                                                                        cat.id
                                                                    ] ? (
                                                                        <ExpandLess />
                                                                    ) : (
                                                                        <ExpandMore />
                                                                    )}
                                                                </IconButton>
                                                            </ListItemSecondaryAction>
                                                        </ListItem>

                                                        <Collapse
                                                            in={open[cat.id]}
                                                            timeout="auto"
                                                            unmountOnExit
                                                        >
                                                            <List
                                                                disablePadding
                                                            >
                                                                {cat.ops.map(
                                                                    (
                                                                        op: any,
                                                                        idx: number
                                                                    ) => (
                                                                        <ListItem
                                                                            key={
                                                                                idx
                                                                            }
                                                                            sx={{
                                                                                paddingLeft: 6,
                                                                            }}
                                                                            disablePadding
                                                                            disableGutters
                                                                        >
                                                                            <ListItemText
                                                                                primaryTypographyProps={{
                                                                                    variant:
                                                                                        "body2",
                                                                                }}
                                                                                primary={
                                                                                    op.label
                                                                                }
                                                                            />
                                                                            <ListItemSecondaryAction>
                                                                                <Checkbox
                                                                                    sx={{
                                                                                        mr: 2,
                                                                                    }}
                                                                                    checked={Boolean(
                                                                                        opsInRole[
                                                                                            op
                                                                                                .id
                                                                                        ]
                                                                                    )}
                                                                                    onChange={(
                                                                                        ev,
                                                                                        checked
                                                                                    ) =>
                                                                                        handleChange(
                                                                                            op.id,
                                                                                            checked
                                                                                        )
                                                                                    }
                                                                                />
                                                                            </ListItemSecondaryAction>
                                                                        </ListItem>
                                                                    )
                                                                )}
                                                            </List>
                                                        </Collapse>
                                                    </Grid>
                                                )
                                            )}
                                    </Grid>
                                </List>

                                <Grid item xs={12}>
                                    <Button
                                        color="secondary"
                                        variant="outlined"
                                        size="small"
                                        type="submit"
                                        disabled={
                                            addMutation.isLoading ||
                                            updateMutation.isLoading
                                        }
                                        startIcon={<Add />}
                                        endIcon={
                                            addMutation.isLoading && (
                                                <CircularProgress
                                                    size="1em"
                                                    color="secondary"
                                                />
                                            )
                                        }
                                    >
                                        {!updateMode
                                            ? "add role"
                                            : "update role"}
                                    </Button>
                                </Grid>
                            </Grid> */}
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>

            {addMutation.isSuccess || updateMutation.isSuccess ? (
                <Alert severity="success" sx={{ mb: 2 }}>
                    {feedback}.
                </Alert>
            ) : addMutation.isError || updateMutation.isError ? (
                <Alert severity="error" sx={{ mb: 2 }}>
                    {feedback}.
                </Alert>
            ) : null}
        </>
    );
};

export default AddRole;
