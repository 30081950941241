import { Add, AttachMoneyOutlined, PercentOutlined } from "@mui/icons-material";
import {
    Alert,
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Checkbox,
    CircularProgress,
    Divider,
    FormControlLabel,
    FormGroup,
    Grid,
    TextField,
    Typography,
    useTheme,
} from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import { FormEvent, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { GET, PATCH, POST } from "../../../Utilities/BaseService";
import UpdatedSearchableInput from "../../../Utilities/UpdatedSearchableInput";

type Surcharge = {
    name: string;
    surchargeName?: string;
    value: number | null;
    isSurchargePct: boolean;

    calculationStrategy: {
        units: boolean;
        lr: boolean;
        arrears: boolean;
        arrearsWithoutSurcharge: boolean;
        acs: boolean;
        connCharges: boolean;
    };
};

type Regions = {
    district: number | null | "all";
    village?: number | null | "all";
    hamlet?: number | null | "all";
    powerstation?: number | null | "all";
};

const addSurcharge = (surcharge: Surcharge, regions: Regions) => {
    return POST("/connection/surcharge", {
        ...surcharge,
        calculationStrategy: JSON.stringify(
            Object.entries(surcharge.calculationStrategy)
                .filter(([k, v]) => v)
                .map(([k, v]) => k)
        ),
        ...Object.fromEntries(
            Object.entries(regions).filter(
                ([k, v]) => Boolean(v) && v !== "all"
            )
        ),
    });
};

const updateSurcharge = (
    surcharge: Surcharge,
    surchargeName: any,
    regions: Regions,
    id: number
) =>
    PATCH(
        "/connection/surcharge",
        {
            ...surcharge,
            calculationStrategy: JSON.stringify(
                Object.entries(surcharge.calculationStrategy)
                    .filter(([k, v]) => v)
                    .map(([k, v]) => k)
            ),
            ...Object.fromEntries(
                Object.entries(regions).map(([k, v]: any) =>
                    v && v !== "all" ? [k, v] : [k, null]
                )
            ),

            name:
                surchargeName == "Default Surcharge"
                    ? "Default Surcharge"
                    : surcharge.name,
        },
        { id }
    );

const AddSurcharge = ({ updateMode = false }) => {
    const [surchargeName, setSurchargeName] = useState("");
    const [surcharge, setSurcharge] = useState<Surcharge>({
        name: "",
        value: null,
        isSurchargePct: false,
        calculationStrategy: {
            units: false,
            lr: false,
            arrears: false,
            acs: false,
            connCharges: false,
            arrearsWithoutSurcharge: false,
        },
    });
    const [regions, setRegions] = useState<Regions>({
        district: null,
    });
    const [id, setId] = useState<unknown>();
    const [defaultRegions, setDefaultRegions] = useState({
        district: "",
        village: "",
        hamlet: "",
        powerstation: "",
    });
    const [feedback, setFeedback] = useState("");
    const [clearAll, setClearAll] = useState({
        district: false,
        village: false,
        hamlet: false,
        powerstation: false,
    });

    const theme = useTheme();
    const location = useLocation();

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        updateMode ? updateMutation.mutate() : addMutation.mutate();
    };

    const addMutation = useMutation(() => addSurcharge(surcharge, regions), {
        onSuccess(res) {
            setFeedback(res.data.message);
        },

        onError(err: any) {
            setFeedback(err.response.data.message);
        },
    });
    const updateMutation = useMutation(
        () => updateSurcharge(surcharge, surchargeName, regions, id as number),
        {
            onSuccess(res) {
                setFeedback(res.data.message);
            },

            onError(err: any) {
                setFeedback(err.response.data.message);
            },
        }
    );

    const { data: surchargeById } = useQuery(
        [`surcharge-${id}`],
        () => GET("/connection/surcharge", { id }),
        {
            enabled: updateMode,
            onSuccess(res) {
                if (res.data.rows.length > 0 && updateMode) {
                    const scharge = res.data.rows[0];
                    setSurchargeName(scharge.name);
                    const currentRegionsIds = {
                        district: scharge.district?.id ?? null,
                        village: scharge.village?.id ?? null,
                        hamlet: scharge.hamlet?.id ?? null,
                        powerstation: scharge.powerstation?.id ?? null,
                    };

                    setSurcharge({
                        name: scharge.name,
                        value: scharge.value,
                        isSurchargePct: scharge.isSurchargePct,
                        calculationStrategy: {
                            ...surcharge.calculationStrategy,
                            ...Object.fromEntries(
                                JSON.parse(scharge.calculationStrategy).map(
                                    (item: any) => [item, true]
                                )
                            ),
                        },
                    });

                    setRegions(currentRegionsIds);

                    setDefaultRegions(currentRegionsIds);
                }
            },
        }
    );

    useEffect(() => {
        if (updateMode) {
            const params = new URLSearchParams(location.search);
            const _id = params.get("id");

            if (_id) {
                setId(_id);
            }
        }
    }, [updateMode]);

    useEffect(() => {
        if (regions.district === "all") {
            setClearAll({
                district: true,
                village: true,
                hamlet: true,
                powerstation: true,
            });

            setRegions({
                district: null,
                village: null,
                hamlet: null,
                powerstation: null,
            });
        }

        if (regions.village === "all") {
            setClearAll({ ...clearAll, village: true, hamlet: true });
        }

        if (regions.hamlet === "all") {
            setClearAll({ ...clearAll, hamlet: true });
        }

        if (defaultRegions.district !== regions.district) {
            setClearAll({
                ...clearAll,
                village: true,
                hamlet: true,
                powerstation: true,
            });

            setDefaultRegions({
                ...defaultRegions,
                district: regions.district as any,
            });

            setRegions({
                ...regions,
                village: null,
                hamlet: null,
                powerstation: null,
            });
        }

        return () => {
            setClearAll({
                district: false,
                village: false,
                hamlet: false,
                powerstation: false,
            });
        };
    }, [regions.district, regions.village, regions.hamlet]);

    return (
        <>
            <Card
                elevation={0}
                sx={{ bgcolor: theme.palette.common.white, mb: "1rem" }}
            >
                <CardHeader
                    title={updateMode ? "Update Surcharge" : "Add Surcharge"}
                    sx={{
                        textTransform: "capitalize",
                        bgcolor: theme.palette.common.white,
                    }}
                />
                <CardContent>
                    <Divider sx={{ mb: 5 }} />

                    <Grid
                        container
                        component="form"
                        onSubmit={handleSubmit}
                        spacing={2}
                    >
                        <Grid item xs={12} md={6} lg={3}>
                            <TextField
                                label="Surcharge Name"
                                name="name"
                                size="small"
                                type="text"
                                value={surcharge.name}
                                onChange={(e) =>
                                    setSurcharge({
                                        ...surcharge,
                                        name: e.target.value,
                                    })
                                }
                                fullWidth
                                required
                            />
                        </Grid>

                        <Grid item xs={12} md={6} lg={3}>
                            <TextField
                                fullWidth
                                required
                                label="Amount"
                                size="small"
                                type="number"
                                value={surcharge.value ?? ""}
                                onChange={(e) =>
                                    setSurcharge({
                                        ...surcharge,
                                        value: parseFloat(e.target.value),
                                    })
                                }
                                InputProps={{
                                    endAdornment: surcharge.isSurchargePct ? (
                                        <PercentOutlined
                                            fontSize="small"
                                            color="disabled"
                                        />
                                    ) : (
                                        <AttachMoneyOutlined
                                            fontSize="small"
                                            color="disabled"
                                        />
                                    ),
                                }}
                                inputProps={{ min: 0, step: "any" }}
                                helperText={
                                    surcharge.value! < 0
                                        ? "Value cant't be less then 0."
                                        : undefined
                                }
                                color={
                                    surcharge.value! < 0 ? "error" : "primary"
                                }
                            />
                        </Grid>

                        <Grid item xs={12} md={6} lg={3}>
                            <UpdatedSearchableInput
                                label="District"
                                api="/region/district"
                                filter={regions}
                                setFilter={setRegions}
                                defaultValue={
                                    updateMode &&
                                    (defaultRegions?.district as any)
                                }
                                clearAll={clearAll.district}
                            />
                        </Grid>

                        <Grid item xs={12} md={6} lg={3}>
                            <UpdatedSearchableInput
                                label="Village"
                                api="/region/village"
                                dep={regions.district as any}
                                params={{ district: regions.district }}
                                filter={regions}
                                setFilter={setRegions}
                                defaultValue={
                                    updateMode &&
                                    (defaultRegions?.village as any)
                                }
                                clearAll={clearAll.village}
                            />
                        </Grid>

                        <Grid item xs={12} md={6} lg={3}>
                            <UpdatedSearchableInput
                                label="Hamlet"
                                api="/region/hamlet"
                                filter={regions}
                                setFilter={setRegions}
                                dep={regions.village as any}
                                params={
                                    regions.village && regions.village !== "all"
                                        ? { village: [regions.village] }
                                        : { village: [0] }
                                }
                                defaultValue={
                                    updateMode &&
                                    (defaultRegions?.hamlet as any)
                                }
                                clearAll={clearAll.hamlet}
                            />
                        </Grid>

                        <Grid item xs={12} md={6} lg={3}>
                            <UpdatedSearchableInput
                                label="Powerstation"
                                api="/powerstation"
                                filter={regions}
                                setFilter={setRegions}
                                dep={regions.district as any}
                                params={{ district: regions.district }}
                                defaultValue={
                                    updateMode &&
                                    (defaultRegions?.powerstation as any)
                                }
                                clearAll={clearAll.powerstation}
                            />
                        </Grid>

                        <Grid item xs={12} md={6} lg={3}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name="isPercentage"
                                        checked={surcharge.isSurchargePct}
                                        onChange={(e) =>
                                            setSurcharge({
                                                ...surcharge,
                                                isSurchargePct:
                                                    e.target.checked,
                                            })
                                        }
                                    />
                                }
                                label="Is surcharge in percentage?"
                            />
                        </Grid>

                        {surcharge.isSurchargePct && (
                            <Grid item xs={12} mt={1}>
                                <Divider>
                                    <Typography>
                                        Surcharge calculation strategy
                                    </Typography>
                                </Divider>

                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={
                                                    surcharge
                                                        .calculationStrategy.acs
                                                }
                                                onChange={(e) =>
                                                    setSurcharge({
                                                        ...surcharge,
                                                        calculationStrategy: {
                                                            ...surcharge.calculationStrategy,
                                                            acs: e.target
                                                                .checked,
                                                        },
                                                    })
                                                }
                                            />
                                        }
                                        label="Additional Charges"
                                    />

                                    <Box display="flex">
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={
                                                        surcharge
                                                            .calculationStrategy
                                                            .arrears
                                                    }
                                                    onChange={(e, checked) =>
                                                        setSurcharge({
                                                            ...surcharge,
                                                            calculationStrategy:
                                                                {
                                                                    ...surcharge.calculationStrategy,
                                                                    arrears:
                                                                        checked,
                                                                    arrearsWithoutSurcharge:
                                                                        checked
                                                                            ? false
                                                                            : surcharge
                                                                                  .calculationStrategy
                                                                                  .arrearsWithoutSurcharge,
                                                                },
                                                        })
                                                    }
                                                />
                                            }
                                            label="Arrears"
                                        />

                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={
                                                        surcharge
                                                            .calculationStrategy
                                                            .arrearsWithoutSurcharge
                                                    }
                                                    onChange={(e, checked) =>
                                                        setSurcharge({
                                                            ...surcharge,
                                                            calculationStrategy:
                                                                {
                                                                    ...surcharge.calculationStrategy,
                                                                    arrearsWithoutSurcharge:
                                                                        checked,
                                                                    arrears:
                                                                        checked
                                                                            ? false
                                                                            : surcharge
                                                                                  .calculationStrategy
                                                                                  .arrears,
                                                                },
                                                        })
                                                    }
                                                />
                                            }
                                            label="Arrears without surcharge"
                                        />
                                    </Box>

                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={
                                                    surcharge
                                                        .calculationStrategy
                                                        .connCharges
                                                }
                                                onChange={(e) =>
                                                    setSurcharge({
                                                        ...surcharge,
                                                        calculationStrategy: {
                                                            ...surcharge.calculationStrategy,
                                                            connCharges:
                                                                e.target
                                                                    .checked,
                                                        },
                                                    })
                                                }
                                            />
                                        }
                                        label="Connection Charges"
                                    />

                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={
                                                    surcharge
                                                        .calculationStrategy.lr
                                                }
                                                onChange={(e) =>
                                                    setSurcharge({
                                                        ...surcharge,
                                                        calculationStrategy: {
                                                            ...surcharge.calculationStrategy,
                                                            lr: e.target
                                                                .checked,
                                                        },
                                                    })
                                                }
                                            />
                                        }
                                        label="Line Rent"
                                    />

                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={
                                                    surcharge
                                                        .calculationStrategy
                                                        .units
                                                }
                                                onChange={(e) =>
                                                    setSurcharge({
                                                        ...surcharge,
                                                        calculationStrategy: {
                                                            ...surcharge.calculationStrategy,
                                                            units: e.target
                                                                .checked,
                                                        },
                                                    })
                                                }
                                            />
                                        }
                                        label="Units"
                                    />
                                </FormGroup>
                            </Grid>
                        )}

                        <Grid item xs={12}>
                            <Button
                                startIcon={<Add />}
                                endIcon={
                                    addMutation.isLoading ? (
                                        <CircularProgress
                                            color="secondary"
                                            size="1rem"
                                        />
                                    ) : (
                                        ""
                                    )
                                }
                                color="secondary"
                                variant="outlined"
                                size="small"
                                type="submit"
                            >
                                {updateMode ? "update" : "add"} surcharge
                            </Button>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>

            {addMutation.isSuccess || updateMutation.isSuccess ? (
                <Alert severity="success">{feedback}.</Alert>
            ) : addMutation.isError || updateMutation.isError ? (
                <Alert severity="error">{feedback}.</Alert>
            ) : null}
        </>
    );
};

export default AddSurcharge;
