import { Grid, List, ListItem, ListItemText, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../Utilities/AppContext";
import { GET } from "../../Utilities/BaseService";
import { S3Service } from "../../Utilities/S3Service";
import { awsDirStruct } from "../../Utilities/constants";

type Props = {
    name: string;
    details?: { [key: string]: { label: string; value: string } };
};

export const Header = ({ name, details }: Props) => {
    const [settings, setSettings] = useState<any[]>([]);
    const { store, setStore } = useContext(AppContext);
    const [logoSrc, setLogoSrc] = useState("");

    const { data } = useQuery(
        ["settings", logoSrc],
        () => {
            return GET("/settings");
        },
        {
            onSuccess(res) {
                if (res?.data?.settings) {
                    setSettings(res.data.settings);
                }
            },
        }
    );

    const getLogo = async () => {
        if (store.logoURL) {
            setLogoSrc(store.logoURL);
        } else {
            const response = await GET("/settings/logos", {
                default: true,
            });
            const firstDefault = response.data.rows[0];
            const [{ url }, err] = await S3Service.getS3ByKey(
                `${awsDirStruct.logos}/${firstDefault.id}`
            );

            const _response = await fetch(url);
            const blob = await _response.blob();
            const blobURL = URL.createObjectURL(blob);
            setLogoSrc(blobURL);
            setStore({ ...store, logoURL: blobURL });
        }
    };

    useEffect(() => {
        getLogo();
    }, []);

    return (
        <Grid
            container
            alignItems="flex-start"
            justifyContent="space-evenly"
            sx={{ px: 4, py: 2, position: "relative" }}
        >
            <Grid item flex={1} sx={{ textAlign: "center", padding: 0 }}>
                <img
                    style={{
                        aspectRatio: 1,
                        // position: "absolute",
                        // left: 4,
                    }}
                    height="50px"
                    alt="logo"
                    src={logoSrc}
                />

                <Typography
                    variant="h6"
                    fontWeight="medium"
                    textTransform="uppercase"
                    letterSpacing={1}
                >
                    {settings?.find((s: any) => s.key === "name")?.value}
                </Typography>

                <Typography variant="body1" fontWeight="medium">
                    {settings?.find((s: any) => s.key === "address")?.value}
                </Typography>

                <Typography variant="body2" letterSpacing={1}>
                    {settings?.find((s: any) => s.key === "phone")?.value}
                </Typography>

                <Typography variant="body2" letterSpacing={1}>
                    {settings?.find((s: any) => s.key === "mobile")?.value}
                </Typography>

                {details && (
                    <List
                        disablePadding
                        sx={{
                            mx: "auto",
                            width: "max-content",
                        }}
                    >
                        {Object.values(details).map((d) => (
                            <ListItem
                                key={d.label}
                                disableGutters
                                disablePadding
                            >
                                <ListItemText
                                    primaryTypographyProps={{
                                        variant: "body2",
                                        fontWeight: "bold",
                                        textAlign: "center",
                                    }}
                                >
                                    {d.label} {" : "} {d.value}
                                </ListItemText>
                            </ListItem>
                        ))}
                    </List>
                )}

                <Typography
                    variant="body2"
                    fontWeight="medium"
                    mt={2}
                    textTransform="uppercase"
                    letterSpacing={1}
                >
                    {name}
                </Typography>
            </Grid>
        </Grid>
    );
};
