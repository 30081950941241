import { AddBoxOutlined, IndeterminateCheckBox } from "@mui/icons-material";
import { Box, Collapse, IconButton, Typography, useTheme } from "@mui/material";
import { useState } from "react";

type Props = {
    regions: {
        [key: string]: { villages: { [key: string]: { hamlets: string[] } } };
    };
};

export const UserRegionsTree = ({ regions = {} }: Props) => {
    const [open, setOpen] = useState<{ [key: string]: boolean }>({});
    const theme = useTheme();

    console.log(regions);

    return (
        <Box>
            {Object.entries(regions).map(([distName, distRegions], idx) => (
                <Box key={idx}>
                    <Typography
                        variant="h6"
                        color={theme.palette.secondary.dark}
                        display="flex"
                        alignItems="center"
                        gap={theme.spacing(1)}
                    >
                        <IconButton
                            size="small"
                            onClick={() =>
                                setOpen({
                                    ...open,
                                    [distName + idx]: !open[distName + idx],
                                })
                            }
                        >
                            {open[distName + idx] ? (
                                <IndeterminateCheckBox
                                    htmlColor={theme.palette.secondary.dark}
                                    fontSize="small"
                                />
                            ) : (
                                <AddBoxOutlined fontSize="small" />
                            )}
                        </IconButton>

                        {distName}

                        {open[distName + idx] && (
                            <Typography variant="caption">
                                (District level)
                            </Typography>
                        )}
                    </Typography>

                    <Box
                        component={Collapse}
                        in={open[distName + idx]}
                        sx={{
                            borderLeft: `1px solid ${theme.palette.secondary.dark}`,
                            pl: theme.spacing(2),
                            ml: theme.spacing(1.75),
                        }}
                    >
                        {!Object.keys(distRegions.villages).length && (
                            <Typography variant="body2">
                                User has access to entire {distName}
                            </Typography>
                        )}

                        {Object.entries(distRegions.villages).map(
                            ([villName, villRegions], key) => (
                                <Box key={key}>
                                    <Typography
                                        color={theme.palette.secondary.main}
                                        fontWeight="medium"
                                    >
                                        <IconButton
                                            onClick={() =>
                                                setOpen({
                                                    ...open,
                                                    [villName + key]:
                                                        !open[villName + key],
                                                })
                                            }
                                        >
                                            {open[villName + key] ? (
                                                <IndeterminateCheckBox
                                                    htmlColor={
                                                        theme.palette.secondary
                                                            .main
                                                    }
                                                    fontSize="small"
                                                />
                                            ) : (
                                                <AddBoxOutlined fontSize="small" />
                                            )}
                                        </IconButton>
                                        {villName}
                                    </Typography>

                                    <Box
                                        component={Collapse}
                                        in={open[villName + key]}
                                        sx={{
                                            borderLeft: `1px solid ${theme.palette.secondary.main}`,
                                            pl: theme.spacing(4),
                                            ml: theme.spacing(2),
                                        }}
                                    >
                                        {villRegions.hamlets.length ? (
                                            villRegions.hamlets.map(
                                                (h, hid) => (
                                                    <Typography
                                                        key={hid}
                                                        color={
                                                            theme.palette
                                                                .secondary.light
                                                        }
                                                    >
                                                        {h}
                                                    </Typography>
                                                )
                                            )
                                        ) : (
                                            <Typography variant="body2">
                                                User has access to entire{" "}
                                                {villName}
                                            </Typography>
                                        )}
                                    </Box>
                                </Box>
                            )
                        )}
                    </Box>
                </Box>
            ))}
        </Box>
    );
};
