import { Search } from "@mui/icons-material";
import { TextField, useTheme } from "@mui/material";
import { ChangeEvent, Dispatch, SetStateAction, useRef, useState } from "react";

type Props = {
    filter: any;
    setFilter: Dispatch<SetStateAction<any>>;
    debounceChange?: boolean;
};

const SearchBar = ({ filter, setFilter, debounceChange = false }: Props) => {
    const [searchTerm, setSearchTerm] = useState("");
    const theme = useTheme();
    const searchRef = useRef(0);

    const handleChange = (ev: ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(ev.target.value);

        if (!debounceChange) {
            setFilter({ ...filter, search: ev.target.value });
        } else {
            searchRef.current++;
            const newRef = searchRef.current;

            setTimeout(() => {
                if (newRef === searchRef.current) {
                    setFilter({ ...filter, search: ev.target.value });
                    searchRef.current = 0;
                }
            }, 1000);
        }

        /*  searchRef.current++;
        const newRef = searchRef.current;

        setTimeout(() => {
            if (newRef === searchRef.current) {
                setFilter({ ...filter, search: ev.target.value });
                searchRef.current = 0;
            }
        }, 1000); */
    };

    return (
        <div style={{ position: "relative", width: "100%" }}>
            <TextField
                fullWidth
                size="small"
                type="text"
                placeholder="Search"
                name="query"
                value={searchTerm}
                onChange={handleChange}
                InputProps={{
                    startAdornment: (
                        <Search
                            fontSize="small"
                            htmlColor={theme.palette.action.disabled}
                            sx={{ mr: theme.spacing(1) }}
                        />
                    ),
                }}
            />
        </div>
    );
};

export default SearchBar;
