import { FilterAltOffOutlined, ReceiptLongOutlined } from "@mui/icons-material";
import {
    Button,
    Card,
    CardContent,
    CircularProgress,
    Divider,
    Grid,
    TextField,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import {
    ChangeEvent,
    Dispatch,
    FormEvent,
    SetStateAction,
    useMemo,
    useState,
} from "react";
import { GET } from "../../../Utilities/BaseService";
import { MultiPermissionAuthorize } from "../../../Utilities/MultiPermissionAuthorize";
import UpdatedSearchableInput from "../../../Utilities/UpdatedSearchableInput";
import SearchableInput from "../../Dashboard/Components/SearchableInput";
import { getAllDistricts } from "../../Region/District/District";
import { fetchVillages } from "../../Region/Village/Villages";

type Props = {
    filter: any;
    setFilter: Dispatch<SetStateAction<any>>;
    setShowBills: Dispatch<SetStateAction<any>>;
    isLoading: boolean;
};

export const fetchHamlets = (params: any = {}) => {
    return GET("/region/hamlet", params);
};

export const fetchPowerstations = (params: any = {}) => {
    return GET("/powerstation", params);
};

export const BillFilter = ({
    filter,
    setFilter,
    isLoading,
    setShowBills,
}: Props) => {
    const [intermediateFilter, setIntermediateFilter] = useState({
        ...filter,
        year: "",
        month: "",
    });
    const [clearAll, setClearAll] = useState(false);

    const handleChange = (ev: ChangeEvent<any>) => {
        setIntermediateFilter((f: any) => ({
            ...f,
            [ev.target.name]: ev.target.value,
        }));
    };

    const handleFilter = (ev: FormEvent<any>) => {
        ev.preventDefault();
        let [month, year] = [undefined, undefined];

        if (intermediateFilter.date) {
            [year, month] = intermediateFilter.date
                .split("-")
                .map((x: any) => parseInt(x));

            setFilter({
                ...intermediateFilter,
                meterNo: intermediateFilter.meterNo.reduce(
                    (prev: any, curr: any) => [...prev, curr?.meterNo],
                    []
                ),
                date: undefined,
                month,
                year,
            });
            setShowBills(true);
        } else {
            setFilter({
                ...intermediateFilter,
                meterNo: intermediateFilter.meterNo.reduce(
                    (prev: any, curr: any) => [...prev, curr?.meterNo],
                    []
                ),
                date: undefined,
                month: undefined,
                year: undefined,
            });
            setShowBills(true);
        }
    };

    const { data: districtsResponse } = useQuery(["districts"], () =>
        getAllDistricts({ page: 1, limit: 0 }, {})
    );

    const { data: villageResponse } = useQuery(
        ["villages", intermediateFilter.district],
        () => fetchVillages({ district: intermediateFilter.district }),
        {
            enabled:
                Boolean(intermediateFilter.district) &&
                intermediateFilter.district !== "all",
        }
    );

    const { data: powerstationsResponse } = useQuery(
        ["powerstations", intermediateFilter.district],
        () => fetchPowerstations({ district: intermediateFilter.district }),
        {
            enabled:
                Boolean(intermediateFilter.district) &&
                intermediateFilter.district !== "all",
        }
    );

    const { data: hamletResponse } = useQuery(
        ["hamlets", intermediateFilter.village],
        () => fetchHamlets({ village: intermediateFilter.village }),
        {
            enabled:
                Boolean(intermediateFilter.village) &&
                intermediateFilter.village !== "all",
        }
    );

    const months = useMemo(() => {
        const formatter = Intl.DateTimeFormat("en", { month: "long" });
        const months = [];
        let d = new Date(2022, 0, 1);

        for (let i = 0; i < 12; i++) {
            d.setMonth(i);
            months.push({ val: i + 1, label: formatter.format(d) });
        }

        return months;
    }, []);

    return (
        <Grid item xs={12}>
            <Card elevation={0}>
                <CardContent>
                    <Grid
                        container
                        spacing={1}
                        component="form"
                        onSubmit={handleFilter}
                    >
                        <MultiPermissionAuthorize ops={["READ DISTRICT"]}>
                            <Grid item xs={12} md={6} lg={3}>
                                <SearchableInput
                                    label="District"
                                    api="/region/district"
                                    filter={intermediateFilter}
                                    setFilter={setIntermediateFilter}
                                    multiple={false}
                                    clearAll={clearAll}
                                />
                            </Grid>
                        </MultiPermissionAuthorize>

                        <MultiPermissionAuthorize ops={["READ VILLAGE"]}>
                            <Grid item xs={12} md={6} lg={3}>
                                <SearchableInput
                                    label="Village"
                                    api="/region/village"
                                    dep={{
                                        district: intermediateFilter.district,
                                    }}
                                    filter={intermediateFilter}
                                    setFilter={setIntermediateFilter}
                                    multiple={false}
                                    clearAll={clearAll}
                                />
                            </Grid>
                        </MultiPermissionAuthorize>

                        <MultiPermissionAuthorize ops={["READ HAMLET"]}>
                            <Grid item xs={12} md={6} lg={3}>
                                <UpdatedSearchableInput
                                    label="Hamlet"
                                    api="/region/hamlet"
                                    dep={[
                                        intermediateFilter.village,
                                        intermediateFilter.village,
                                    ]}
                                    params={
                                        intermediateFilter.village &&
                                        intermediateFilter.village !== "all"
                                            ? {
                                                  village: [
                                                      intermediateFilter.village,
                                                  ],
                                              }
                                            : { village: [0] }
                                    }
                                    filter={intermediateFilter}
                                    setFilter={setIntermediateFilter}
                                    multiple={false}
                                    clearAll={clearAll}
                                />
                            </Grid>
                        </MultiPermissionAuthorize>

                        <MultiPermissionAuthorize ops={["READ POWERSTATION"]}>
                            <Grid item xs={12} md={6} lg={3}>
                                <SearchableInput
                                    label="Powerstation"
                                    api="/powerstation"
                                    dep={{
                                        district: intermediateFilter.district,
                                    }}
                                    filter={intermediateFilter}
                                    setFilter={setIntermediateFilter}
                                    multiple={false}
                                    clearAll={clearAll}
                                />
                            </Grid>
                        </MultiPermissionAuthorize>

                        <MultiPermissionAuthorize ops={["READ METER"]}>
                            <Grid item xs={12} md={6} lg={3}>
                                <UpdatedSearchableInput
                                    api="/customer/meter"
                                    filter={intermediateFilter}
                                    setFilter={setIntermediateFilter}
                                    _name="meterNo"
                                    label="Meter No."
                                    dep={[
                                        intermediateFilter?.district,
                                        intermediateFilter?.village,
                                        intermediateFilter?.hamlet,
                                        intermediateFilter?.powerstation,
                                    ]}
                                    params={{
                                        district:
                                            intermediateFilter?.district &&
                                            intermediateFilter.district !==
                                                "all"
                                                ? intermediateFilter.district
                                                : undefined,
                                        village:
                                            intermediateFilter?.village &&
                                            intermediateFilter.village !== "all"
                                                ? intermediateFilter.village
                                                : undefined,
                                        hamlet:
                                            intermediateFilter?.hamlet &&
                                            intermediateFilter.hamlet !== "all"
                                                ? intermediateFilter.hamlet
                                                : undefined,
                                        powerstation:
                                            intermediateFilter?.powerstation &&
                                            intermediateFilter.powerstation !==
                                                "all"
                                                ? intermediateFilter.powerstation
                                                : undefined,
                                    }}
                                    optionsPreprocessor={(opt: any) => ({
                                        ...opt,
                                        name: opt.meterNo,
                                    })}
                                    multiple
                                    getFullRecord
                                />
                            </Grid>
                        </MultiPermissionAuthorize>

                        <Grid item xs={12} md={6} lg={3}>
                            <TextField
                                onChange={handleChange}
                                fullWidth
                                name="date"
                                label="Billing month"
                                value={intermediateFilter.date}
                                size="small"
                                type="month"
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>

                        <Grid item container xs={12} lg={6} spacing={1}>
                            <Grid item xs={6}>
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="outlined"
                                    endIcon={
                                        isLoading ? (
                                            <CircularProgress size="1rem" />
                                        ) : (
                                            <ReceiptLongOutlined />
                                        )
                                    }
                                    disabled={isLoading}
                                >
                                    Generate
                                </Button>
                            </Grid>

                            <Grid item xs={6}>
                                <Button
                                    variant="outlined"
                                    color="secondary"
                                    fullWidth
                                    startIcon={<FilterAltOffOutlined />}
                                    // sx={{ height: "100%" }}
                                    onClick={() => {
                                        setClearAll(true);

                                        setTimeout(() => {
                                            setClearAll(false);
                                        }, 1000);

                                        window.location.reload();
                                    }}
                                >
                                    clear filters
                                </Button>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} mt={2}>
                            <Divider />
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Grid>
    );
};
